import { Currency, PriceBound, RecurringFeeContext, RecurringFeePricingStrategy } from "../../api";
import React from "react";
import { Money } from "@cobira/ui-library";
import { RecurringFeeContextLabels } from "../../labels/RecurringFeeContextLabels";
import { HStack, Text } from "@chakra-ui/react";

export interface CustomerRecurringFeeSinglePriceBoundProps {
    priceBound: PriceBound;
    strategy: RecurringFeePricingStrategy;
    currency: Currency;
    recurringFeeContext: RecurringFeeContext;
}

const CustomerRecurringFeeSinglePriceBound = ({
    priceBound,
    strategy,
    currency,
    recurringFeeContext,
}: CustomerRecurringFeeSinglePriceBoundProps) => {
    if (strategy === "FIXED") {
        return (
            <HStack align={"start"}>
                <Money value={priceBound.price} currency={currency} />
                <Text>will be charged at every recurrence</Text>
            </HStack>
        );
    } else {
        return (
            <HStack align={"start"}>
                <Money value={priceBound.price} currency={currency} />
                <Text>per {RecurringFeeContextLabels[recurringFeeContext]} will be charged at every recurrence</Text>
            </HStack>
        );
    }
};

export default CustomerRecurringFeeSinglePriceBound;
