/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { PriceBound } from './PriceBound';
import {
    PriceBoundFromJSON,
    PriceBoundFromJSONTyped,
    PriceBoundToJSON,
} from './PriceBound';
import type { RecurringFeePricingStrategy } from './RecurringFeePricingStrategy';
import {
    RecurringFeePricingStrategyFromJSON,
    RecurringFeePricingStrategyFromJSONTyped,
    RecurringFeePricingStrategyToJSON,
} from './RecurringFeePricingStrategy';

/**
 * Pricing bounds define the the actual price based on the value provided from the selected context.
 * For example with a context of ACTIVE_SIM, the price of 0-100 Active SIMs could be set to 1 EUR pr. sim by defining a price bound: 0 - null, 1 EUR.
 * @export
 * @interface RecurringFeePricing
 */
export interface RecurringFeePricing {
    /**
     * 
     * @type {Currency}
     * @memberof RecurringFeePricing
     */
    currency: Currency;
    /**
     * 
     * @type {RecurringFeePricingStrategy}
     * @memberof RecurringFeePricing
     */
    pricingStrategy: RecurringFeePricingStrategy;
    /**
     * 
     * @type {Array<PriceBound>}
     * @memberof RecurringFeePricing
     */
    priceBounds: Array<PriceBound>;
}

/**
 * Check if a given object implements the RecurringFeePricing interface.
 */
export function instanceOfRecurringFeePricing(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "pricingStrategy" in value;
    isInstance = isInstance && "priceBounds" in value;

    return isInstance;
}

export function RecurringFeePricingFromJSON(json: any): RecurringFeePricing {
    return RecurringFeePricingFromJSONTyped(json, false);
}

export function RecurringFeePricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurringFeePricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': CurrencyFromJSON(json['currency']),
        'pricingStrategy': RecurringFeePricingStrategyFromJSON(json['pricingStrategy']),
        'priceBounds': ((json['priceBounds'] as Array<any>).map(PriceBoundFromJSON)),
    };
}

export function RecurringFeePricingToJSON(value?: RecurringFeePricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': CurrencyToJSON(value.currency),
        'pricingStrategy': RecurringFeePricingStrategyToJSON(value.pricingStrategy),
        'priceBounds': ((value.priceBounds as Array<any>).map(PriceBoundToJSON)),
    };
}

