/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { CustomerStates } from './CustomerStates';
import {
    CustomerStatesFromJSON,
    CustomerStatesFromJSONTyped,
    CustomerStatesToJSON,
} from './CustomerStates';

/**
 * Updates the information for a customer.
 * @export
 * @interface UpdateCustomer
 */
export interface UpdateCustomer {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomer
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomer
     */
    shippingAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomer
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomer
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomer
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomer
     */
    billingId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomer
     */
    billingAddress?: string;
    /**
     * 
     * @type {CustomerStates}
     * @memberof UpdateCustomer
     */
    state: CustomerStates;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateCustomer
     */
    billingCurrency: Currency;
}

/**
 * Check if a given object implements the UpdateCustomer interface.
 */
export function instanceOfUpdateCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerName" in value;
    isInstance = isInstance && "shippingAddress" in value;
    isInstance = isInstance && "contactName" in value;
    isInstance = isInstance && "contactEmail" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "billingCurrency" in value;

    return isInstance;
}

export function UpdateCustomerFromJSON(json: any): UpdateCustomer {
    return UpdateCustomerFromJSONTyped(json, false);
}

export function UpdateCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerName': json['customerName'],
        'shippingAddress': json['shippingAddress'],
        'contactName': json['contactName'],
        'contactEmail': json['contactEmail'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'billingId': !exists(json, 'billingId') ? undefined : json['billingId'],
        'billingAddress': !exists(json, 'billingAddress') ? undefined : json['billingAddress'],
        'state': CustomerStatesFromJSON(json['state']),
        'billingCurrency': CurrencyFromJSON(json['billingCurrency']),
    };
}

export function UpdateCustomerToJSON(value?: UpdateCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerName': value.customerName,
        'shippingAddress': value.shippingAddress,
        'contactName': value.contactName,
        'contactEmail': value.contactEmail,
        'accountNumber': value.accountNumber,
        'billingId': value.billingId,
        'billingAddress': value.billingAddress,
        'state': CustomerStatesToJSON(value.state),
        'billingCurrency': CurrencyToJSON(value.billingCurrency),
    };
}

