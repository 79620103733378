import React from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { BillingGroup, Customer } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import {
    CobiraTable,
    PlaceholderText,
    SearchBar,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePagination,
    useSearch,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { createColumnHelper } from "@tanstack/react-table";
import { RatePlanCell } from "../../../../components/Cells/RatePlanCell/RatePlanCell";
import {
    ConsumptionStateSelect,
    SearchSelectConsumptionState,
} from "../../../../components/SearchSelectConsumptionState/SearchSelectConsumptionState";

interface CustomerBillingGroupsPanelProps {
    query: UseQueryResult<Customer>;
}

const billingGroupColumn = createColumnHelper<BillingGroup>();
const COLUMNS = [
    billingGroupColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    billingGroupColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    billingGroupColumn.accessor("ratePlanId", {
        id: "ratePlanId",
        header: "Rate Plan",
        cell: row => <RatePlanCell ratePlanId={row.getValue()} />,
    }),
];

const CustomerBillingGroupsPanel = ({ query: { data: value } }: CustomerBillingGroupsPanelProps) => {
    const { billingGroupApi } = useApi();
    const { navigate } = usePageNavigation<BillingGroup>({
        route: billingGroup => `/customers/${billingGroup?.customerId}/billinggroups/${billingGroup.id}`,
    });
    const { pageState, setPageState, resetPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = useSearch({
        config: {
            search: singleInput<string>("Search"),
            consumptionState: singleInput<ConsumptionStateSelect>("Usage Activity", { display: value => value.label }),
        },
        onChange: resetPageState,
    });
    const { state } = search;

    const { data: billingGroups, isLoading: isLoadingBillingGroups } = useQuery(
        ["customers", value?.id, "billinggroups", pageState, state],
        () =>
            billingGroupApi.getCustomerBillingGroups({
                customerId: value?.id || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                search: state?.search?.value || undefined,
                consumptionState: state?.consumptionState?.value?.value || undefined,
            }),
        { enabled: value?.id !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Search"}
                                />
                            ),
                        },
                        {
                            id: "consumptionState",
                            menuLabel: "Usage Activity",
                            inputComponent: (
                                <SearchSelectConsumptionState
                                    registration={search.registerInput({ id: "consumptionState" })}
                                />
                            ),
                        },
                    ]}
                />
                <CobiraTable
                    columns={COLUMNS}
                    data={billingGroups?.content || []}
                    isLoading={isLoadingBillingGroups}
                    withPagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: billingGroups?.pageProperties?.totalElements || 0,
                        totalPageCount: billingGroups?.pageProperties?.totalPages || 0,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigate,
                    }}
                />
            </VStack>
        </TabPanel>
    );
};

export default CustomerBillingGroupsPanel;
