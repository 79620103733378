import React from "react";
import { NetworkCredentialType } from "../../api";
import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { DeleteIcon, RestoreIcon } from "@cobira/ui-library";

export interface NetworkCredentialTypeTableActionsProps {
    networkCredentialType: NetworkCredentialType;
    actions: {
        onDeleteClicked?: (networkCredentialType: NetworkCredentialType) => void;
        onRestoreClicked?: (networkCredentialType: NetworkCredentialType) => void;
    };
}

const NetworkCredentialTypeTableActions = ({
    actions: { onRestoreClicked, onDeleteClicked },
    networkCredentialType,
}: NetworkCredentialTypeTableActionsProps) => {
    if (networkCredentialType.deletedAt !== undefined) {
        if (onRestoreClicked !== undefined) {
            return (
                <Tooltip label={"Restore Access"}>
                    <IconButton
                        aria-label={"restore"}
                        icon={<Icon as={RestoreIcon} />}
                        colorScheme={"primary"}
                        variant={"icon"}
                        isRound
                        onClick={() => onRestoreClicked(networkCredentialType)}
                    />
                </Tooltip>
            );
        }
    } else {
        if (onDeleteClicked !== undefined) {
            return (
                <Tooltip label={"Disable Access"}>
                    <IconButton
                        aria-label={"disable"}
                        icon={<Icon as={DeleteIcon} />}
                        colorScheme={"red"}
                        variant={"icon"}
                        isRound
                        onClick={() => onDeleteClicked(networkCredentialType)}
                    />
                </Tooltip>
            );
        }
    }

    return null;
};

export default NetworkCredentialTypeTableActions;
