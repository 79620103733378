/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebhookSubscriptionType } from './WebhookSubscriptionType';
import {
    WebhookSubscriptionTypeFromJSON,
    WebhookSubscriptionTypeFromJSONTyped,
    WebhookSubscriptionTypeToJSON,
} from './WebhookSubscriptionType';

/**
 * 
 * @export
 * @interface CreateWebhookSubscription
 */
export interface CreateWebhookSubscription {
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    target: string;
    /**
     * 
     * @type {number}
     * @memberof CreateWebhookSubscription
     */
    maxAttempts: number;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    retryDelay: string;
    /**
     * 
     * @type {Set<WebhookSubscriptionType>}
     * @memberof CreateWebhookSubscription
     */
    subscriptions: Set<WebhookSubscriptionType>;
    /**
     * 
     * @type {Date}
     * @memberof CreateWebhookSubscription
     */
    pausedAt?: Date | null;
    /**
     * The timeout cannot exceed PT15S
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    deliveryTimeout?: string | null;
}

/**
 * Check if a given object implements the CreateWebhookSubscription interface.
 */
export function instanceOfCreateWebhookSubscription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "maxAttempts" in value;
    isInstance = isInstance && "retryDelay" in value;
    isInstance = isInstance && "subscriptions" in value;

    return isInstance;
}

export function CreateWebhookSubscriptionFromJSON(json: any): CreateWebhookSubscription {
    return CreateWebhookSubscriptionFromJSONTyped(json, false);
}

export function CreateWebhookSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWebhookSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'target': json['target'],
        'maxAttempts': json['maxAttempts'],
        'retryDelay': json['retryDelay'],
        'subscriptions': (new Set((json['subscriptions'] as Array<any>).map(WebhookSubscriptionTypeFromJSON))),
        'pausedAt': !exists(json, 'pausedAt') ? undefined : (json['pausedAt'] === null ? null : new Date(json['pausedAt'])),
        'deliveryTimeout': !exists(json, 'deliveryTimeout') ? undefined : json['deliveryTimeout'],
    };
}

export function CreateWebhookSubscriptionToJSON(value?: CreateWebhookSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'target': value.target,
        'maxAttempts': value.maxAttempts,
        'retryDelay': value.retryDelay,
        'subscriptions': (Array.from(value.subscriptions as Set<any>).map(WebhookSubscriptionTypeToJSON)),
        'pausedAt': value.pausedAt === undefined ? undefined : (value.pausedAt === null ? null : value.pausedAt.toISOString()),
        'deliveryTimeout': value.deliveryTimeout,
    };
}

