import { useApi } from "../../../hooks/useApi";
import {
    ActionCompletedNotification,
    ActionFailedNotification,
    Action,
    BasicModal,
    pluralize,
    useActionNotification,
} from "@cobira/ui-library";
import { SimCardActionRequestFilter, SimcardApiSetImeiLockStatesRequest } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import ImeiLockStateForm, { ImeiLockStateFormType } from "../../../forms/ImeiLockStateForm/ImeiLockStateForm";
import { Text, VStack } from "@chakra-ui/react";
import SimCardActionInProgressNotification from "../../ActionNotification/SimCardActionInProgressNotification/SimCardActionInProgressNotification";
import { useSimCardCount } from "../../../hooks/useSimCardCount";

export interface SetImeiLockStateModalProps {
    filter: SimCardActionRequestFilter;
    isOpen: boolean;
    onClose: () => void;
}

const SetImeiLockStateModal = ({ isOpen, filter, onClose }: SetImeiLockStateModalProps) => {
    const { simCardApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});

    const simCardCount = useSimCardCount({ filter });

    const setImeiLockStates = useMutation<Action[], Error, SimcardApiSetImeiLockStatesRequest>(
        variables => simCardApi.setImeiLockStates(variables),
        {
            onSuccess: data => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: successMessage,
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
        },
    );

    const handleSubmit = useCallback(
        (form: ImeiLockStateFormType) => {
            setImeiLockStates.mutate({
                setImeiLockStateAction: {
                    simCardFilter: filter,
                    imeiLockState: form.lockState,
                    imeiLockViolationBehaviour: form.violationBehaviour,
                },
            });
            onClose();
        },
        [setImeiLockStates, filter, onClose],
    );

    const successMessage = useCallback((data: Action) => {
        return (
            <ActionCompletedNotification
                action={data}
                successTitle={"IMEI Lock behaviour updated"}
                successMessage={`New IMEI Lock behaviour configured for ${data.totalItems} SIM Cards`}
                failureComponent={<ActionFailedNotification title={"Failed to update IMEI Lock behaviour"} />}
            />
        );
    }, []);
    const errorMessage = useCallback(
        () => <ActionFailedNotification title={"Failed to update IMEI Lock behaviour"} />,
        [],
    );
    const loadingMessage = useCallback(
        (action: Action) => (
            <SimCardActionInProgressNotification
                actionApi={actionApi}
                actionId={action.id}
                title={"Setting IMEI Lock behaviour SIM Cards"}
                message={(processedSimCount, totalSimCount) =>
                    `IMEI Lock behaviour updated for ${processedSimCount} SIM ${pluralize(
                        "Card",
                        processedSimCount,
                    )} of ${totalSimCount}`
                }
            />
        ),
        [actionApi],
    );

    return (
        <BasicModal title={`Set IMEI Lock behaviour`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <Text>
                    Configure IMEI Locking behaviour for <b>{simCardCount}</b> SIM {pluralize("Card", simCardCount)}
                </Text>
                <ImeiLockStateForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default SetImeiLockStateModal;
