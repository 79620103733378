/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateIntegration
 */
export interface UpdateIntegration {
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegration
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegration
     */
    description?: string | null;
}

/**
 * Check if a given object implements the UpdateIntegration interface.
 */
export function instanceOfUpdateIntegration(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateIntegrationFromJSON(json: any): UpdateIntegration {
    return UpdateIntegrationFromJSONTyped(json, false);
}

export function UpdateIntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateIntegration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function UpdateIntegrationToJSON(value?: UpdateIntegration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

