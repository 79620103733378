import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, CurrencyLabels, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input, Textarea } from "@chakra-ui/react";
import { Currency, Customer, CustomerStates } from "../../api";
import { CustomerStateLabels } from "../../labels/CustomerStateLabels";
import { CURRENCY_OPTIONS } from "../../utils/pricingUtils";

const UpdateCustomerFormSchema = z.object({
    customerName: z.string().min(1, "Name is required"),
    shippingAddress: z.string().optional(),
    contactName: z.string().optional(),
    contactEmail: z.string().optional(),
    accountNumber: z.string().optional(),
    billingId: z.string().optional(),
    billingAddress: z.string().optional(),
    billingCurrency: z.nativeEnum(Currency),
    state: z.nativeEnum(CustomerStates),
});

export type UpdateCustomerFormSchemaType = z.infer<typeof UpdateCustomerFormSchema>;

export interface UpdateCustomerFormProps {
    customer: Customer;
    onSubmit: (form: UpdateCustomerFormSchemaType) => void;
    onCancel?: () => void;
}

const UpdateCustomerForm = ({ customer, onSubmit, onCancel }: UpdateCustomerFormProps) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
    } = useForm<UpdateCustomerFormSchemaType>({
        resolver: zodResolver(UpdateCustomerFormSchema),
        defaultValues: {
            contactName: customer.contactName,
            contactEmail: customer.contactEmail,
            shippingAddress: customer.shippingAddress,
            accountNumber: customer.accountNumber,
            customerName: customer.customerName,
            billingId: customer.billingId,
            billingAddress: customer.billingAddress,
            billingCurrency: customer.billingCurrency,
            state: customer.state,
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.customerName}>
                    <Input {...register("customerName")} />
                </FormField>
                <FormField label={"Shipping Address"} error={errors?.shippingAddress}>
                    <Textarea {...register("shippingAddress")} minH={"6em"} />
                </FormField>
                <FormField label={"Contact Name"} error={errors?.contactName}>
                    <Input {...register("contactName")} />
                </FormField>
                <FormField label={"Contact Email"} error={errors?.contactEmail}>
                    <Input {...register("contactEmail")} />
                </FormField>
                <FormField label={"Account Number"} error={errors?.accountNumber}>
                    <Input {...register("accountNumber")} />
                </FormField>
                <FormField label={"Billing ID"} error={errors?.billingId}>
                    <Input {...register("billingId")} />
                </FormField>
                <FormField label={"Billing Address"} error={errors?.billingAddress}>
                    <Textarea {...register("billingAddress")} minH={"6em"} />
                </FormField>
                <FormField label={"Billing Currency"} error={errors?.billingCurrency}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: "billingCurrency" }}
                        input={{
                            items: Object.values(CURRENCY_OPTIONS),
                            placeholder: "Select Currency to bill the customer in",
                            autocompleteAbleMapping: value => CurrencyLabels[value],
                            displayMapping: value => CurrencyLabels[value],
                        }}
                    />
                </FormField>
                <FormField label={"State"} error={errors?.state}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: "state" }}
                        input={{
                            items: Object.values(CustomerStates),
                            placeholder: "Select the new customer state",
                            autocompleteAbleMapping: value => CustomerStateLabels[value],
                            displayMapping: value => CustomerStateLabels[value],
                        }}
                    />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Update
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default UpdateCustomerForm;
