import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { Integration, IntegrationApiDeleteWebhookSubscriptionRequest, WebhookSubscription } from "../../../api";

export interface ConfirmDeleteWebhookModalProps {
    onClose: () => void;
    webhook?: WebhookSubscription;
    integration?: Integration;
}

const ConfirmDeleteWebhookModal = ({ onClose, webhook, integration }: ConfirmDeleteWebhookModalProps) => {
    const { integrationApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteWebhook = useMutation<void, Error, IntegrationApiDeleteWebhookSubscriptionRequest>(variables =>
        integrationApi.deleteWebhookSubscription(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && webhook?.id && integration?.id) {
                deleteWebhook.mutate(
                    { webhookSubscriptionId: webhook.id, integrationId: integration.id },
                    {
                        onSuccess: () => {
                            notifications.success("Webhook was successfully deleted");
                            queryClient.invalidateQueries(["integrations", integration?.id, "webhooks"]);
                        },
                    },
                );
            }
            onClose();
        },
        [onClose, deleteWebhook, integration, queryClient, webhook, notifications],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!webhook}
            title={"Delete Webhook Subscription"}
            description={
                <Container>
                    <Text>You are about to delete the webhook subscription:</Text>
                    <Text as={"b"}>{webhook?.name}</Text>
                    <Text>This can&apos;t be undone.</Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteWebhookModal;
