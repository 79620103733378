import { useParams } from "react-router";
import { PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import SmsUsageRecordDetails from "../../../../components/Usage/SmsUsageRecordDetails";

const SimCardSmsUsageDetailPage = () => {
    const { usageRecordId, icc } = useParams();
    const { usageRecordApi } = useApi();

    const smsUsageQuery = useQuery(
        ["usage-records", "sms", usageRecordId],
        () => usageRecordApi.getSmsUsageRecord({ usageRecordId: usageRecordId || "" }),
        {
            enabled: !!usageRecordId,
        },
    );
    return (
        <PageLayout
            title={<PageTitle title={"SMS Usage Details"} />}
            navigation={<PageBackButton text={"Back to SIM Card"} path={`/simcards/${icc}?tab=2`} />}
        >
            <SmsUsageRecordDetails query={smsUsageQuery} />
        </PageLayout>
    );
};

export default SimCardSmsUsageDetailPage;
