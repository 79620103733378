import React, { useMemo } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { UsagePackageType, UsagePackageTypePage } from "../../api";
import { createColumnHelper } from "@tanstack/react-table";
import {
    CobiraTable,
    CobiraTableManualPaginationProps,
    DataPackageAllowance,
    DeleteIcon,
    Duration,
    PlaceholderText,
    SmsPackageAllowance,
} from "@cobira/ui-library";
import { CobiraTableRowClickEnabledProps } from "@cobira/ui-library/dist/cjs/components/CobiraTable/CobiraTable";
import { UsagePackageAutomaticActivationTypeLabels } from "../../labels/UsagePackageAutomaticActivationTypeLabels";
import DeletedAt from "../DeletedAt/DeletedAt";
import { IconButton } from "@chakra-ui/react";

const usagePackageTypeColumn = createColumnHelper<UsagePackageType>();

export const USAGE_PACKAGE_TYPE_COLUMNS = [
    usagePackageTypeColumn.accessor("customName", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    usagePackageTypeColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    usagePackageTypeColumn.accessor("automaticActivationType", {
        id: "automaticActivationType",
        header: "Activation method",
        cell: row => UsagePackageAutomaticActivationTypeLabels[row.getValue()],
    }),
    usagePackageTypeColumn.accessor("dataUsageAllowance", {
        id: "dataUsageAllowance",
        header: "Data Allowance",
        cell: row => (
            <DataPackageAllowance
                allowance={row.getValue().dataAllowance || undefined}
                enabled={row.getValue().enabled}
            />
        ),
    }),
    usagePackageTypeColumn.accessor("smsUsageAllowance", {
        id: "smsUsageAllowance",
        header: "SMS Allowance",
        cell: row => (
            <SmsPackageAllowance
                allowance={row.getValue().smsAllowance || undefined}
                enabled={row.getValue().enabled}
            />
        ),
    }),
    usagePackageTypeColumn.accessor("packageDuration", {
        id: "packageDuration",
        header: "Duration",
        cell: row => <Duration value={row.getValue()} />,
    }),
    usagePackageTypeColumn.accessor("supportedCountries", {
        id: "supportedCountries",
        header: "Countries",
        cell: row => row.getValue().length,
    }),
    usagePackageTypeColumn.accessor("deletedAt", {
        id: "deletedAt",
        header: "Deleted At",
        cell: row => <DeletedAt deletedAt={row.getValue()} />,
    }),
];

export interface CostPlanTableProps {
    usagePackageTypeQuery: UseQueryResult<UsagePackageTypePage>;
    withRowClick?: CobiraTableRowClickEnabledProps<UsagePackageType>;
    onDelete?: (usagePackageType: UsagePackageType) => void;
    withPagination?: boolean | CobiraTableManualPaginationProps;
}

const UsagePackageTypeTable = ({
    usagePackageTypeQuery,
    withRowClick,
    onDelete,
    withPagination,
}: CostPlanTableProps) => {
    const { data: usagePackageTypes, isLoading: isLoadingUsagePackageTypes } = usagePackageTypeQuery;

    const memoizedColumns = useMemo(() => {
        const columns = [...USAGE_PACKAGE_TYPE_COLUMNS];
        if (onDelete) {
            columns.push(
                usagePackageTypeColumn.display({
                    id: "actions",
                    meta: {
                        isButton: true,
                    },
                    cell: content => (
                        <IconButton
                            aria-label={"delete"}
                            isDisabled={content.row.original.deletedAt !== undefined}
                            icon={<DeleteIcon />}
                            colorScheme={"red"}
                            variant={"icon"}
                            isRound
                            onClick={() => onDelete(content.row.original)}
                        />
                    ),
                }),
            );
        }
        return columns;
    }, [onDelete]);

    return (
        <CobiraTable
            sizing={"fit-page"}
            columns={memoizedColumns}
            data={usagePackageTypes?.content || []}
            isLoading={isLoadingUsagePackageTypes}
            withPagination={withPagination}
            withRowClick={withRowClick}
        />
    );
};

export default UsagePackageTypeTable;
