/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AmountType } from './AmountType';
import {
    AmountTypeFromJSON,
    AmountTypeFromJSONTyped,
    AmountTypeToJSON,
} from './AmountType';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface BillableUsageLine
 */
export interface BillableUsageLine {
    /**
     * 
     * @type {number}
     * @memberof BillableUsageLine
     */
    price: number;
    /**
     * 
     * @type {Currency}
     * @memberof BillableUsageLine
     */
    currency: Currency;
    /**
     * 
     * @type {number}
     * @memberof BillableUsageLine
     */
    amount: number;
    /**
     * 
     * @type {AmountType}
     * @memberof BillableUsageLine
     */
    amountType: AmountType;
    /**
     * 
     * @type {string}
     * @memberof BillableUsageLine
     */
    billingGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof BillableUsageLine
     */
    ratePlanId: string;
    /**
     * 
     * @type {string}
     * @memberof BillableUsageLine
     */
    classifier: BillableUsageLineClassifierEnum;
    /**
     * 
     * @type {string}
     * @memberof BillableUsageLine
     */
    discriminator: BillableUsageLineDiscriminatorEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof BillableUsageLine
     */
    billableKey: Set<string>;
    /**
     * Can be matched with the equivalent `billableReferenceId` found on one of the cost usage lines
     * @type {string}
     * @memberof BillableUsageLine
     */
    costReferenceId: string;
}


/**
 * @export
 */
export const BillableUsageLineClassifierEnum = {
    Data: 'DATA',
    MoSms: 'MO_SMS',
    MtSms: 'MT_SMS',
    NetworkAccess: 'NETWORK_ACCESS'
} as const;
export type BillableUsageLineClassifierEnum = typeof BillableUsageLineClassifierEnum[keyof typeof BillableUsageLineClassifierEnum];

/**
 * @export
 */
export const BillableUsageLineDiscriminatorEnum = {
    Default: 'DEFAULT',
    ServiceType: 'SERVICE_TYPE',
    Country: 'COUNTRY',
    Network: 'NETWORK',
    NetworkCredentialType: 'NETWORK_CREDENTIAL_TYPE'
} as const;
export type BillableUsageLineDiscriminatorEnum = typeof BillableUsageLineDiscriminatorEnum[keyof typeof BillableUsageLineDiscriminatorEnum];


/**
 * Check if a given object implements the BillableUsageLine interface.
 */
export function instanceOfBillableUsageLine(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "amountType" in value;
    isInstance = isInstance && "billingGroupId" in value;
    isInstance = isInstance && "ratePlanId" in value;
    isInstance = isInstance && "classifier" in value;
    isInstance = isInstance && "discriminator" in value;
    isInstance = isInstance && "billableKey" in value;
    isInstance = isInstance && "costReferenceId" in value;

    return isInstance;
}

export function BillableUsageLineFromJSON(json: any): BillableUsageLine {
    return BillableUsageLineFromJSONTyped(json, false);
}

export function BillableUsageLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillableUsageLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': json['price'],
        'currency': CurrencyFromJSON(json['currency']),
        'amount': json['amount'],
        'amountType': AmountTypeFromJSON(json['amountType']),
        'billingGroupId': json['billingGroupId'],
        'ratePlanId': json['ratePlanId'],
        'classifier': json['classifier'],
        'discriminator': json['discriminator'],
        'billableKey': json['billableKey'],
        'costReferenceId': json['costReferenceId'],
    };
}

export function BillableUsageLineToJSON(value?: BillableUsageLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': value.price,
        'currency': CurrencyToJSON(value.currency),
        'amount': value.amount,
        'amountType': AmountTypeToJSON(value.amountType),
        'billingGroupId': value.billingGroupId,
        'ratePlanId': value.ratePlanId,
        'classifier': value.classifier,
        'discriminator': value.discriminator,
        'billableKey': Array.from(value.billableKey as Set<any>),
        'costReferenceId': value.costReferenceId,
    };
}

