/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { TelcoCost } from './TelcoCost';
import {
    TelcoCostFromJSON,
    TelcoCostFromJSONTyped,
    TelcoCostToJSON,
} from './TelcoCost';

/**
 * 
 * @export
 * @interface TelcoCostPage
 */
export interface TelcoCostPage {
    /**
     * 
     * @type {Page}
     * @memberof TelcoCostPage
     */
    pageProperties: Page;
    /**
     * 
     * @type {Array<TelcoCost>}
     * @memberof TelcoCostPage
     */
    content: Array<TelcoCost>;
}

/**
 * Check if a given object implements the TelcoCostPage interface.
 */
export function instanceOfTelcoCostPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pageProperties" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function TelcoCostPageFromJSON(json: any): TelcoCostPage {
    return TelcoCostPageFromJSONTyped(json, false);
}

export function TelcoCostPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelcoCostPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': PageFromJSON(json['pageProperties']),
        'content': ((json['content'] as Array<any>).map(TelcoCostFromJSON)),
    };
}

export function TelcoCostPageToJSON(value?: TelcoCostPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': ((value.content as Array<any>).map(TelcoCostToJSON)),
    };
}

