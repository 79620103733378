import React from "react";
import { useApi } from "../../../../hooks/useApi";
import { InfoCard } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { faUserGroup } from "@fortawesome/pro-regular-svg-icons";

interface CustomersInfoCardProps {}

export const CustomersInfoCard: React.FC<CustomersInfoCardProps> = () => {
    const { customerApi } = useApi();

    const { data: customers, isLoading: isLoadingCustomers } = useQuery(["customers"], () =>
        customerApi.getCustomers({ pageSize: 1, pageNumber: 0 }),
    );

    const customersValue = isLoadingCustomers
        ? "Loading"
        : customers?.pageProperties && customers?.pageProperties?.totalElements
          ? customers?.pageProperties?.totalElements?.toString()
          : "Unknown";

    return <InfoCard title={"Customers"} value={customersValue} icon={faUserGroup} />;
};
