/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataUsageAllowance
 */
export interface DataUsageAllowance {
    /**
     * Usage allowance specified in bytes
     * @type {number}
     * @memberof DataUsageAllowance
     */
    dataAllowance?: number | null;
    /**
     * Defines whether the `allowanceInMegaBytes` property is to be considered or not. For allowance types with `enabled=false` the data usage will not be constrained.
     * @type {boolean}
     * @memberof DataUsageAllowance
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the DataUsageAllowance interface.
 */
export function instanceOfDataUsageAllowance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function DataUsageAllowanceFromJSON(json: any): DataUsageAllowance {
    return DataUsageAllowanceFromJSONTyped(json, false);
}

export function DataUsageAllowanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataUsageAllowance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataAllowance': !exists(json, 'dataAllowance') ? undefined : json['dataAllowance'],
        'enabled': json['enabled'],
    };
}

export function DataUsageAllowanceToJSON(value?: DataUsageAllowance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataAllowance': value.dataAllowance,
        'enabled': value.enabled,
    };
}

