import React from "react";
import { SimConfiguration } from "../../api";
import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { DeleteIcon, RestoreIcon } from "@cobira/ui-library";

export interface SimConfigurationTableActionsProps {
    simConfiguration: SimConfiguration;
    actions: {
        onDeleteClicked?: (simConfiguration: SimConfiguration) => void;
        onRestoreClicked?: (simConfiguration: SimConfiguration) => void;
    };
}

const SimConfigurationTableActions = ({
    actions: { onRestoreClicked, onDeleteClicked },
    simConfiguration,
}: SimConfigurationTableActionsProps) => {
    if (simConfiguration.deletedAt !== undefined) {
        if (onRestoreClicked !== undefined) {
            return (
                <Tooltip label={"Restore Access"}>
                    <IconButton
                        aria-label={"restore"}
                        icon={<Icon as={RestoreIcon} />}
                        colorScheme={"primary"}
                        variant={"icon"}
                        isRound
                        onClick={() => onRestoreClicked(simConfiguration)}
                    />
                </Tooltip>
            );
        }
    } else {
        if (onDeleteClicked !== undefined) {
            return (
                <Tooltip label={"Disable Access"}>
                    <IconButton
                        aria-label={"disable"}
                        icon={<Icon as={DeleteIcon} />}
                        colorScheme={"red"}
                        variant={"icon"}
                        isRound
                        onClick={() => onDeleteClicked(simConfiguration)}
                    />
                </Tooltip>
            );
        }
    }

    return null;
};

export default SimConfigurationTableActions;
