/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardCapabilityName } from './SimCardCapabilityName';
import {
    SimCardCapabilityNameFromJSON,
    SimCardCapabilityNameFromJSONTyped,
    SimCardCapabilityNameToJSON,
} from './SimCardCapabilityName';

/**
 * Network layer supported SIM configuration.
 * @export
 * @interface SimConfiguration
 */
export interface SimConfiguration {
    /**
     * 
     * @type {string}
     * @memberof SimConfiguration
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SimConfiguration
     */
    name: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof SimConfiguration
     */
    networkCredentialTypeIds: Set<string>;
    /**
     * 
     * @type {Set<SimCardCapabilityName>}
     * @memberof SimConfiguration
     */
    simCardCapabilities?: Set<SimCardCapabilityName>;
    /**
     * 
     * @type {Date}
     * @memberof SimConfiguration
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the SimConfiguration interface.
 */
export function instanceOfSimConfiguration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "networkCredentialTypeIds" in value;

    return isInstance;
}

export function SimConfigurationFromJSON(json: any): SimConfiguration {
    return SimConfigurationFromJSONTyped(json, false);
}

export function SimConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'networkCredentialTypeIds': json['networkCredentialTypeIds'],
        'simCardCapabilities': !exists(json, 'simCardCapabilities') ? undefined : (new Set((json['simCardCapabilities'] as Array<any>).map(SimCardCapabilityNameFromJSON))),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}

export function SimConfigurationToJSON(value?: SimConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'networkCredentialTypeIds': Array.from(value.networkCredentialTypeIds as Set<any>),
        'simCardCapabilities': value.simCardCapabilities === undefined ? undefined : (Array.from(value.simCardCapabilities as Set<any>).map(SimCardCapabilityNameToJSON)),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

