import { FieldError } from "react-hook-form";
import { FormField } from "@cobira/ui-library";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import React from "react";
import { CurrencyLabels } from "../../labels/CurrencyLabels";
import { Currency } from "../../api";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";

export interface AbsoluteDataRateMarkupInputProps {
    currency: Currency;
    error?: FieldError;
    registration: UseFormRegisterReturn<string>;
    unit: string;
}

const AbsoluteRateMarkupInput = ({ registration, error, currency, unit }: AbsoluteDataRateMarkupInputProps) => {
    return (
        <FormField label={"Rate"} error={error}>
            <InputGroup>
                <Input {...registration} type={"number"} />
                <InputRightAddon>
                    {CurrencyLabels[currency]} / {unit}
                </InputRightAddon>
            </InputGroup>
        </FormField>
    );
};

export default AbsoluteRateMarkupInput;
