import React, { useEffect, useState } from "react";
import { InfoCards, PageLayout, PageTitle } from "@cobira/ui-library";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Divider, VStack } from "@chakra-ui/react";
import { CustomersInfoCard } from "./CustomersInfoCard/CustomersInfoCard";
import { TotalSimsInfoCard } from "./TotalSimsInfoCard/TotalSimsInfoCard";
import { ActiveSimsInfoCard } from "./ActiveSimsInfoCard/ActiveSimsInfoCard";
import { DataConsumptionInfoCard } from "./DataConsumptionInfoCard/DataConsumptionInfoCard";
import { SmsUsageInfoCard } from "./SmsUsageInfoCard/SmsUsageInfoCard";
import { TenantSimsChart } from "../../../components/Charts/TenantSimsChart/TenantSimsChart";
import { TenantDataConsumptionChart } from "../../../components/Charts/TenantDataConsumptionChart/TenantDataConsumptionChart";

const DashboardsPage = () => {
    const { isLoading, user } = useAuth0();
    const [name, setName] = useState<string>();

    useEffect(() => {
        setName(user?.name);
    }, [isLoading, user?.name]);

    const title = name ? `Welcome, ${name}` : "Welcome";

    return (
        <PageLayout title={<PageTitle title={title} />}>
            <Divider />
            <VStack w={"100%"} align={"stretch"} spacing={6}>
                <InfoCards>
                    <CustomersInfoCard />
                    <TotalSimsInfoCard />
                    <ActiveSimsInfoCard />
                    <DataConsumptionInfoCard />
                    <SmsUsageInfoCard />
                </InfoCards>
                <Box w={"100%"} h={"600px"}>
                    <TenantSimsChart />
                </Box>
                <Box w={"100%"} h={"600px"}>
                    <TenantDataConsumptionChart />
                </Box>
            </VStack>
        </PageLayout>
    );
};

export default DashboardsPage;
