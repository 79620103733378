import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

const AssignSimConfigurationFormSchema = z.object({
    simConfiguration: z.object({
        id: z.string(),
        name: z.string(),
    }),
});

export type AssignSimConfigurationFormSchemaType = z.infer<typeof AssignSimConfigurationFormSchema>;

export interface AssignSimConfigurationFormProps {
    onSubmit: (form: AssignSimConfigurationFormSchemaType) => void;
    onCancel?: () => void;
}

const AssignSimConfigurationForm = ({ onSubmit, onCancel }: AssignSimConfigurationFormProps) => {
    const { configurationApi } = useApi();
    const {
        formState: { errors },
        control,
        handleSubmit,
    } = useForm<AssignSimConfigurationFormSchemaType>({
        resolver: zodResolver(AssignSimConfigurationFormSchema),
    });

    const { data: simConfigurations } = useQuery(["simconfigurations"], () =>
        configurationApi.getSimConfigurations({
            pageNumber: 0,
            pageSize: 9999,
        }),
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"SIM Configuration"} error={errors?.simConfiguration}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: "simConfiguration" }}
                        input={{
                            placeholder: "Select SIM Configuration",
                            items: simConfigurations?.content || [],
                            displayMapping: value => `${value.name}`,
                            autocompleteAbleMapping: value => `${value.name}`,
                        }}
                    />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Assign
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default AssignSimConfigurationForm;
