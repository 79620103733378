import React from "react";
import { TelcoRate } from "../../../../api";
import { Text, VStack } from "@chakra-ui/react";
import { serviceTypeLabels } from "../../../../labels/RateKeyLabels";

export interface TelcoRateServiceTypeDetailProps {
    telcoRate: TelcoRate;
}

const serviceTypesAsText = (labels: string[]) => {
    if (labels.length === 0) {
        return "Nothing";
    } else if (labels.length === 1) {
        return labels[0];
    } else {
        let concatenatedText = "";
        for (let i = 0; i < labels.length; i++) {
            if (i === labels.length - 1) {
                concatenatedText += ` and ${labels[i]}`;
            } else {
                concatenatedText += `, ${labels[i]}`;
            }
        }
        return concatenatedText;
    }
};

const TelcoRateServiceTypeDetail = ({ telcoRate }: TelcoRateServiceTypeDetailProps) => {
    const serviceTypes = Array.from(telcoRate.rateKey);
    const labels = serviceTypes.map(serviceType => serviceTypeLabels(serviceType));

    return (
        <VStack w={"100"} align={"start"}>
            <Text>
                This rate applies to {serviceTypesAsText(labels)}. If no child rates are specified this rate will apply
                if it matches the used service type.
            </Text>
        </VStack>
    );
};

export default TelcoRateServiceTypeDetail;
