import { Tenant } from "../../../../api";
import { CobiraBarChartPaper, Loading, MetricLabelFormatters, MetricsProvider, newRequest } from "@cobira/ui-library";
import React from "react";
import { useMetricsResolver } from "../../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";

export interface TenantSimCountPaperProps {
    tenant?: Tenant;
    isLoading?: boolean;
}

const TenantSimCountPaper = ({ tenant }: TenantSimCountPaperProps) => {
    const resolver = useMetricsResolver();
    const now = new Date();
    const twelveMonthsAgo = subMonths(now, 12);

    if (!tenant) {
        return <Loading />;
    }

    const requests = {
        activeSim: newRequest({
            bucket: "MONTHLY",
            dataPoint: "ACTIVE_SIM",
            group: "TENANT",
            groupId: tenant.id,
            fromDateTime: twelveMonthsAgo,
            toDateTime: now,
        }),
        assignedSim: newRequest({
            bucket: "MONTHLY",
            dataPoint: "ASSIGNED_SIM",
            group: "TENANT",
            groupId: tenant.id,
            fromDateTime: twelveMonthsAgo,
            toDateTime: now,
        }),
    };

    return (
        <MetricsProvider requests={requests} resolver={resolver}>
            {data => (
                <CobiraBarChartPaper
                    title={"SIM Statistics"}
                    bars={[
                        {
                            dataSet: data => data.activeSim.value,
                            dataSetName: "Active SIMs",
                        },
                        {
                            dataSet: data => data.assignedSim.value,
                            dataSetName: "Assigned SIMs",
                        },
                    ]}
                    data={data}
                    chartConfig={{
                        xAxisValueLabel: data => MetricLabelFormatters.yearAndMonth(data.assignedSim),
                    }}
                />
            )}
        </MetricsProvider>
    );
};

export default TenantSimCountPaper;
