import React from "react";
import { useFormContext } from "react-hook-form";
import { CreateRatePlanSchemaType } from "./CreateRatePlanForm";
import { Button } from "@chakra-ui/react";

const ConfirmCreateRatePlanStep = () => {
    const {
        formState: { isSubmitting },
    } = useFormContext<CreateRatePlanSchemaType>();
    return (
        <Button my={5} mx={"30%"} isLoading={isSubmitting} isDisabled={isSubmitting} type="submit">
            Submit rate plan
        </Button>
    );
};

export default ConfirmCreateRatePlanStep;
