import React from "react";
import { Heading, Text, VStack } from "@chakra-ui/react";

const SimCardQuickFindTooltip = () => {
    return (
        <VStack align={"start"} spacing={2}>
            <Heading size={"sm"}>Quick find</Heading>
            <Text>Search across data fields, including ICC, IMEI, MSISDN, System labels, Serial number and more.</Text>
        </VStack>
    );
};

export default SimCardQuickFindTooltip;
