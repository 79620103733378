import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { CostPlan, NetworkCredentialType } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import NetworkAccessCostTableWithSearch from "../../../../components/NetworkAccessCostTableWithSearch/NetworkAccessCostTableWithSearch";

interface NetworkAccessCostsPanelProps {
    costPlanQuery: UseQueryResult<CostPlan>;
    networkCredentialTypeQuery: UseQueryResult<NetworkCredentialType>;
}

const NetworkAccessCostsPanel = ({
    costPlanQuery: { data: costPlan },
    networkCredentialTypeQuery: { data: networkCredentialType },
}: NetworkAccessCostsPanelProps) => {
    const { configurationApi } = useApi();
    const networkAccessCostQuery = useQuery(
        ["costplans", costPlan?.id, "networkaccesscosts"],
        () =>
            configurationApi.getCostPlanNetworkAccessCosts({
                costPlanId: costPlan?.id || "",
                networkCredentialTypeId: networkCredentialType?.id || "",
                pageNumber: 0,
                pageSize: 10000,
            }),
        { enabled: !!costPlan?.id && !!networkCredentialType?.id },
    );

    return (
        <TabPanel w={"100%"}>
            <NetworkAccessCostTableWithSearch networkAccessCostsQuery={networkAccessCostQuery} costPlan={costPlan} />
        </TabPanel>
    );
};

export default NetworkAccessCostsPanel;
