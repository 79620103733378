import React from "react";
import { CostFeeLine } from "../../../api";
import { CobiraTable, Money } from "@cobira/ui-library";
import { createColumnHelper } from "@tanstack/react-table";
import { CostFeeClassifierLabels } from "../../../labels/CostFeeClassifierLabels";
import { Text } from "@chakra-ui/react";
import { CostFeeDiscriminatorEnumLabels } from "../../../labels/CostFeeDiscriminatorLabels";
import { NetworkCredentialTypeCell } from "../../Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";

const costFeeColumn = createColumnHelper<CostFeeLine>();
const COLUMNS = [
    costFeeColumn.accessor("classifier", {
        id: "classifier",
        header: "Cost Type",
        cell: row => <Text>{CostFeeClassifierLabels[row.getValue()]}</Text>,
    }),
    costFeeColumn.accessor("discriminator", {
        id: "discriminator",
        header: "Cost Grouping",
        cell: row => <Text>{CostFeeDiscriminatorEnumLabels[row.getValue()]}</Text>,
    }),
    costFeeColumn.accessor(row => row, {
        id: "resolvedGrouping",
        header: "Grouping",
        cell: row => {
            switch (row.getValue().discriminator) {
                case "NETWORK_CREDENTIAL_TYPE":
                    return (
                        <NetworkCredentialTypeCell networkCredentialTypeId={row.getValue().costKey} showPlmn={false} />
                    );
            }
        },
    }),
    costFeeColumn.accessor(row => row, {
        id: "cost",
        header: "Cost",
        meta: {
            isNumeric: true,
        },
        cell: row => <Money value={row.getValue().cost} currency={row.getValue().currency} decimals={2} />,
    }),
];

export interface CostFeeTableProps {
    items: CostFeeLine[];
    isLoading?: boolean;
}

const CostFeeTable = ({ isLoading, items }: CostFeeTableProps) => {
    return <CobiraTable columns={COLUMNS} data={items} isLoading={isLoading} />;
};

export default CostFeeTable;
