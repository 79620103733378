/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecurringFeeContext } from './RecurringFeeContext';
import {
    RecurringFeeContextFromJSON,
    RecurringFeeContextFromJSONTyped,
    RecurringFeeContextToJSON,
} from './RecurringFeeContext';
import type { RecurringFeePricing } from './RecurringFeePricing';
import {
    RecurringFeePricingFromJSON,
    RecurringFeePricingFromJSONTyped,
    RecurringFeePricingToJSON,
} from './RecurringFeePricing';
import type { YearMonth } from './YearMonth';
import {
    YearMonthFromJSON,
    YearMonthFromJSONTyped,
    YearMonthToJSON,
} from './YearMonth';

/**
 * 
 * @export
 * @interface UpdateCustomerRecurringFee
 */
export interface UpdateCustomerRecurringFee {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRecurringFee
     */
    name?: string | null;
    /**
     * 
     * @type {RecurringFeeContext}
     * @memberof UpdateCustomerRecurringFee
     */
    context?: RecurringFeeContext;
    /**
     * 
     * @type {YearMonth}
     * @memberof UpdateCustomerRecurringFee
     */
    anniversary?: YearMonth;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerRecurringFee
     */
    recurrence?: number | null;
    /**
     * 
     * @type {RecurringFeePricing}
     * @memberof UpdateCustomerRecurringFee
     */
    pricing?: RecurringFeePricing;
    /**
     * Limits the recurring fee to the selected billing groups, 
     * otherwise it will apply to all billing groups the customer has.
     * @type {Set<string>}
     * @memberof UpdateCustomerRecurringFee
     */
    billingGroupIds?: Set<string> | null;
}

/**
 * Check if a given object implements the UpdateCustomerRecurringFee interface.
 */
export function instanceOfUpdateCustomerRecurringFee(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateCustomerRecurringFeeFromJSON(json: any): UpdateCustomerRecurringFee {
    return UpdateCustomerRecurringFeeFromJSONTyped(json, false);
}

export function UpdateCustomerRecurringFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCustomerRecurringFee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'context': !exists(json, 'context') ? undefined : RecurringFeeContextFromJSON(json['context']),
        'anniversary': !exists(json, 'anniversary') ? undefined : YearMonthFromJSON(json['anniversary']),
        'recurrence': !exists(json, 'recurrence') ? undefined : json['recurrence'],
        'pricing': !exists(json, 'pricing') ? undefined : RecurringFeePricingFromJSON(json['pricing']),
        'billingGroupIds': !exists(json, 'billingGroupIds') ? undefined : json['billingGroupIds'],
    };
}

export function UpdateCustomerRecurringFeeToJSON(value?: UpdateCustomerRecurringFee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'context': RecurringFeeContextToJSON(value.context),
        'anniversary': YearMonthToJSON(value.anniversary),
        'recurrence': value.recurrence,
        'pricing': RecurringFeePricingToJSON(value.pricing),
        'billingGroupIds': value.billingGroupIds === undefined ? undefined : value.billingGroupIds === null ? null : Array.from(value.billingGroupIds as Set<any>),
    };
}

