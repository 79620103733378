import { createColumnHelper } from "@tanstack/react-table";
import { BillingGroup, Customer, DataUsageRecord } from "../../../../api";
import {
    CobiraTable,
    Country,
    CountryInfo,
    DataText,
    DateTime,
    getCountry,
    PlaceholderText,
    SearchBar,
    SearchSingleInput,
    singleInput,
    SlimUuid,
    usePageNavigation,
    usePagination,
    useSearch,
} from "@cobira/ui-library";
import { BillingGroupCell } from "../../../../components/Cells/BillingGroupCell/BillingGroupCell";
import React from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { SearchSelectCustomerInput } from "../../../../components/SimCardSearch/SearchSelectCustomerInput/SearchSelectCustomerInput";
import { SearchSelectBillingGroupInput } from "../../../../components/SimCardSearch/SearchSelectBillingGroupInput/SearchSelectBillingGroupInput";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import { CustomerCell } from "../../../../components/Cells/CustomerCell/CustomerCell";
import { NetworkCredentialTypeCell } from "../../../../components/Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import { RadioTechnologyLabels } from "../../../../labels/RadioTechnologyLabels";

const usageColumn = createColumnHelper<DataUsageRecord>();
const COLUMNS = [
    usageColumn.accessor("id", {
        id: "id",
        header: "Usage ID",
        cell: row => <SlimUuid uuid={row.getValue() || ""} />,
    }),
    usageColumn.accessor("icc", {
        id: "icc",
        header: "ICC",
        cell: row => row.getValue(),
    }),
    usageColumn.accessor("countryCode", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue())} />,
    }),
    usageColumn.accessor("plmn", {
        id: "plmn",
        header: "PLMN",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    usageColumn.accessor("radioTechnology", {
        id: "radioTechnology",
        header: "RAT",
        cell: row => <PlaceholderText text={RadioTechnologyLabels(row.getValue())} />,
    }),
    usageColumn.accessor("billingGroupId", {
        id: "billingGroup",
        header: "Billing Group",
        cell: row => <BillingGroupCell billingGroupId={row.getValue()} />,
    }),
    usageColumn.accessor("customerId", {
        id: "customer",
        header: "Customer",
        cell: row => <CustomerCell customerId={row.getValue()} />,
    }),
    usageColumn.accessor("bytes", {
        id: "bytes",
        header: "Data",
        cell: row => <DataText bytes={row.getValue()} decimals={2}></DataText>,
    }),
    usageColumn.accessor("openedAt", {
        id: "openedAt",
        header: "Session open",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    usageColumn.accessor("closedAt", {
        id: "closedAt",
        header: "Session Close",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    usageColumn.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    usageColumn.accessor("networkCredentialTypeId", {
        id: "networkCredentialType",
        header: "Network Credential Type",
        cell: row => <NetworkCredentialTypeCell networkCredentialTypeId={row.getValue()} />,
    }),
];

const DataUsagePanel = () => {
    const { usageRecordApi } = useApi();
    const pageNavigation = usePageNavigation<DataUsageRecord>({ route: value => `/usage-records/data/${value.id}` });
    const { pageState, setPageState, resetPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = useSearch({
        config: {
            icc: singleInput<string>("ICC"),
            plmn: singleInput<string>("PLMN"),
            billingGroup: singleInput<BillingGroup>("Billing Group", { display: value => value.name }),
            customer: singleInput<Customer>("Customer", { display: value => value.customerName }),
            country: singleInput<Country>("Country", { display: value => value.name }),
        },
        onChange: resetPageState,
    });
    const { state } = search;

    const { data: dataUsage, isLoading } = useQuery(["usage-records", "data", pageState, state], () => {
        const now = new Date();
        const oneMonthAgo = new Date(now);
        oneMonthAgo.setMonth(now.getMonth() - 1);
        return usageRecordApi.getDataUsageRecords({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            fromDate: oneMonthAgo,
            toDate: now,
            icc: state?.icc?.value || undefined,
            billingGroupId: state?.billingGroup?.value?.id,
            plmn: state?.plmn?.value || undefined,
            customerId: state?.customer?.value?.id,
            countryCode: state?.country?.value?.iso2,
        });
    });

    return (
        <TabPanel>
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"icc"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "icc",
                            menuLabel: "ICC",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "icc" })}
                                    placeholder={"Search by ICC"}
                                />
                            ),
                        },
                        {
                            id: "plmn",
                            menuLabel: "PLMN",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "plmn" })}
                                    placeholder={"Search by PLMN"}
                                />
                            ),
                        },
                        {
                            id: "customerId",
                            menuLabel: "Customer",
                            inputComponent: (
                                <SearchSelectCustomerInput registration={search.registerInput({ id: "customer" })} />
                            ),
                        },
                        {
                            id: "billingGroupId",
                            menuLabel: "Billing Group",
                            inputComponent: (
                                <SearchSelectBillingGroupInput
                                    registration={search.registerInput({ id: "billingGroup" })}
                                />
                            ),
                        },
                    ]}
                />
                <CobiraTable
                    sizing={"fit-page"}
                    columns={COLUMNS}
                    data={dataUsage?.content || []}
                    isLoading={isLoading}
                    withPagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: dataUsage?.pageProperties?.totalElements || 0,
                        totalPageCount: dataUsage?.pageProperties?.totalPages || 0,
                    }}
                    withColumnSelection={{
                        tableKey: "data-usage-table",
                        maxColumnCount: 7,
                        minColumnCount: 2,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: pageNavigation.navigate,
                    }}
                />
            </VStack>
        </TabPanel>
    );
};

export default DataUsagePanel;
