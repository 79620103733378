/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SmsDirection } from './SmsDirection';
import {
    SmsDirectionFromJSON,
    SmsDirectionFromJSONTyped,
    SmsDirectionToJSON,
} from './SmsDirection';
import type { SmsOrigin } from './SmsOrigin';
import {
    SmsOriginFromJSON,
    SmsOriginFromJSONTyped,
    SmsOriginToJSON,
} from './SmsOrigin';

/**
 * 
 * @export
 * @interface SmsUsageRecord
 */
export interface SmsUsageRecord {
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    billingGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    icc: string;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    imsi: string;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    networkCredentialTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    usagePackageId?: string;
    /**
     * 
     * @type {SmsDirection}
     * @memberof SmsUsageRecord
     */
    direction: SmsDirection;
    /**
     * 
     * @type {SmsOrigin}
     * @memberof SmsUsageRecord
     */
    origin: SmsOrigin;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageRecord
     */
    countryCode: string;
    /**
     * 
     * @type {Date}
     * @memberof SmsUsageRecord
     */
    recordedAt: Date;
}

/**
 * Check if a given object implements the SmsUsageRecord interface.
 */
export function instanceOfSmsUsageRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "billingGroupId" in value;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "imsi" in value;
    isInstance = isInstance && "networkCredentialTypeId" in value;
    isInstance = isInstance && "direction" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "recordedAt" in value;

    return isInstance;
}

export function SmsUsageRecordFromJSON(json: any): SmsUsageRecord {
    return SmsUsageRecordFromJSONTyped(json, false);
}

export function SmsUsageRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsUsageRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'billingGroupId': json['billingGroupId'],
        'icc': json['icc'],
        'imsi': json['imsi'],
        'networkCredentialTypeId': json['networkCredentialTypeId'],
        'usagePackageId': !exists(json, 'usagePackageId') ? undefined : json['usagePackageId'],
        'direction': SmsDirectionFromJSON(json['direction']),
        'origin': SmsOriginFromJSON(json['origin']),
        'plmn': json['plmn'],
        'countryCode': json['countryCode'],
        'recordedAt': (new Date(json['recordedAt'])),
    };
}

export function SmsUsageRecordToJSON(value?: SmsUsageRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'billingGroupId': value.billingGroupId,
        'icc': value.icc,
        'imsi': value.imsi,
        'networkCredentialTypeId': value.networkCredentialTypeId,
        'usagePackageId': value.usagePackageId,
        'direction': SmsDirectionToJSON(value.direction),
        'origin': SmsOriginToJSON(value.origin),
        'plmn': value.plmn,
        'countryCode': value.countryCode,
        'recordedAt': (value.recordedAt.toISOString()),
    };
}

