/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardProvisioningState } from './SimCardProvisioningState';
import {
    SimCardProvisioningStateFromJSON,
    SimCardProvisioningStateFromJSONTyped,
    SimCardProvisioningStateToJSON,
} from './SimCardProvisioningState';

/**
 * Information tied to a sim card.
 * @export
 * @interface SimCard
 */
export interface SimCard {
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    icc: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    pin1: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    pin2: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    puk1: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    puk2: string;
    /**
     * 
     * @type {SimCardProvisioningState}
     * @memberof SimCard
     */
    provisioningState: SimCardProvisioningState;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    simConfigurationId: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    customName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    billingGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    eSimId?: string;
}

/**
 * Check if a given object implements the SimCard interface.
 */
export function instanceOfSimCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "pin1" in value;
    isInstance = isInstance && "pin2" in value;
    isInstance = isInstance && "puk1" in value;
    isInstance = isInstance && "puk2" in value;
    isInstance = isInstance && "provisioningState" in value;
    isInstance = isInstance && "simConfigurationId" in value;

    return isInstance;
}

export function SimCardFromJSON(json: any): SimCard {
    return SimCardFromJSONTyped(json, false);
}

export function SimCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'icc': json['icc'],
        'pin1': json['pin1'],
        'pin2': json['pin2'],
        'puk1': json['puk1'],
        'puk2': json['puk2'],
        'provisioningState': SimCardProvisioningStateFromJSON(json['provisioningState']),
        'simConfigurationId': json['simConfigurationId'],
        'customName': !exists(json, 'customName') ? undefined : json['customName'],
        'billingGroupId': !exists(json, 'billingGroupId') ? undefined : json['billingGroupId'],
        'eSimId': !exists(json, 'eSimId') ? undefined : json['eSimId'],
    };
}

export function SimCardToJSON(value?: SimCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'icc': value.icc,
        'pin1': value.pin1,
        'pin2': value.pin2,
        'puk1': value.puk1,
        'puk2': value.puk2,
        'provisioningState': SimCardProvisioningStateToJSON(value.provisioningState),
        'simConfigurationId': value.simConfigurationId,
        'customName': value.customName,
        'billingGroupId': value.billingGroupId,
        'eSimId': value.eSimId,
    };
}

