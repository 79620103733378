import { RatePlanWithRates } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { TabPanel } from "@chakra-ui/react";
import React from "react";
import TelcoRateTree from "../../../../components/TelcoRateTree/TelcoRateTree";

export interface RatePlanDetailPanelProps {
    query: UseQueryResult<RatePlanWithRates>;
}

const RatePlanTelcoRatesPanel = ({ query: { data: ratePlan, isLoading } }: RatePlanDetailPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <TelcoRateTree telcoRate={ratePlan?.telcoRates} isLoading={isLoading} heightPercentage={70} />
        </TabPanel>
    );
};

export default RatePlanTelcoRatesPanel;
