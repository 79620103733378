/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Markup } from './Markup';
import {
    MarkupFromJSON,
    MarkupFromJSONTyped,
    MarkupToJSON,
} from './Markup';

/**
 * Tiered markup.
 * @export
 * @interface PostProcessingTier
 */
export interface PostProcessingTier {
    /**
     * 
     * @type {number}
     * @memberof PostProcessingTier
     */
    lowerBound: number;
    /**
     * 
     * @type {number}
     * @memberof PostProcessingTier
     */
    upperBound?: number;
    /**
     * 
     * @type {Markup}
     * @memberof PostProcessingTier
     */
    markup: Markup;
}

/**
 * Check if a given object implements the PostProcessingTier interface.
 */
export function instanceOfPostProcessingTier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lowerBound" in value;
    isInstance = isInstance && "markup" in value;

    return isInstance;
}

export function PostProcessingTierFromJSON(json: any): PostProcessingTier {
    return PostProcessingTierFromJSONTyped(json, false);
}

export function PostProcessingTierFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProcessingTier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lowerBound': json['lowerBound'],
        'upperBound': !exists(json, 'upperBound') ? undefined : json['upperBound'],
        'markup': MarkupFromJSON(json['markup']),
    };
}

export function PostProcessingTierToJSON(value?: PostProcessingTier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lowerBound': value.lowerBound,
        'upperBound': value.upperBound,
        'markup': MarkupToJSON(value.markup),
    };
}

