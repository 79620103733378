import React from "react";
import { BillableUsageLineDiscriminatorEnum, CostUsageLineDiscriminatorEnum, TelcoServiceType } from "../../api";
import { Text } from "@chakra-ui/react";
import { TelcoServiceTypeLabels } from "../../labels/TelcoServiceTypeLabels";

export interface UsageGroupingTextProps {
    grouping: Set<string>;
    discriminator: BillableUsageLineDiscriminatorEnum | CostUsageLineDiscriminatorEnum;
}

const UsageGroupingText = ({ grouping, discriminator }: UsageGroupingTextProps) => {
    switch (discriminator) {
        case "SERVICE_TYPE":
            return (
                <Text>
                    {Array.from(grouping)
                        .map(value => value as TelcoServiceType)
                        .filter(Boolean)
                        .map(value => TelcoServiceTypeLabels[value])
                        .join(", ")}
                </Text>
            );
        case "NETWORK":
        case "COUNTRY":
        case "NETWORK_CREDENTIAL_TYPE":
        case "DEFAULT":
        default:
            return <Text>{new Array(grouping).join(", ")}</Text>;
    }
};

export default UsageGroupingText;
