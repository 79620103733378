import React from "react";
import { NetworkCredentialType } from "../../api";
import { Box, Text } from "@chakra-ui/react";

export interface InlineNetworkCredentialTypeProps {
    networkCredentialType?: NetworkCredentialType;
    showPlmn?: boolean;
    showProvider?: boolean;
}

const InlineNetworkCredentialType = ({
    networkCredentialType,
    showPlmn = true,
    showProvider = true,
}: InlineNetworkCredentialTypeProps) => {
    if (!networkCredentialType) {
        return null;
    }
    return (
        <Box>
            {showPlmn && (
                <Text fontSize={"md"} color={"header"} fontWeight={"bold"}>
                    {networkCredentialType.plmn}
                </Text>
            )}
            {showProvider && <Text>{networkCredentialType.imsiProvider}</Text>}
        </Box>
    );
};

export default InlineNetworkCredentialType;
