import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { NetworkCredentialType, Tenant, TenantApiCreateTenantNetworkCredentialTypeRequest } from "../../../api";

export interface ConfirmRestoreChildTenantNetworkCredentialTypeModalProps {
    childTenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
    networkCredentialType?: NetworkCredentialType;
}

const ConfirmRestoreChildTenantNetworkCredentialTypeModal = ({
    onClose,
    networkCredentialType,
    isOpen,
    childTenant,
}: ConfirmRestoreChildTenantNetworkCredentialTypeModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createNetworkCredentialType = useMutation<void, Error, TenantApiCreateTenantNetworkCredentialTypeRequest>(
        variables => tenantApi.createTenantNetworkCredentialType(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && networkCredentialType?.id && childTenant?.id) {
                createNetworkCredentialType.mutate(
                    { tenantId: childTenant.id, assignNetworkCredentialType: { id: networkCredentialType.id } },
                    {
                        onSuccess: () => {
                            notifications.success("Network Credential Type now available for the tenant");
                            queryClient.invalidateQueries(["tenants", childTenant.id, "networkcredentialtypes"]);
                            queryClient.invalidateQueries([
                                "tenants",
                                childTenant.id,
                                "networkcredentialtypes",
                                networkCredentialType.id,
                            ]);
                        },
                    },
                );
            }
            onClose();
        },
        [childTenant, createNetworkCredentialType, notifications, onClose, queryClient, networkCredentialType],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!networkCredentialType && !!childTenant && isOpen}
            title={"Restore access to Network Credential Type"}
            description={
                <Container>
                    <Text>Are you sure you want to restore the access to the Network Credential Type?</Text>
                    <Text>
                        The Network Credential Type will become accessible to the child tenant again and they will be
                        able to re-sell it to their tenants once more.
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmRestoreChildTenantNetworkCredentialTypeModal;
