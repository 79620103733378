import { Currency, NetworkAccessRate, RatePlanWithRates } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { createColumnHelper } from "@tanstack/react-table";
import { TabPanel } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { CobiraTable } from "@cobira/ui-library";
import MarkupDetails from "../../../../components/MarkupDetails/MarkupDetails";
import { NetworkAccessRateRateKeyCell } from "../../../../components/Cells/NetworkAccessRateRateKeyCell/NetworkAccessRateRateKeyCell";

export interface RatePlanDetailPanelProps {
    query: UseQueryResult<RatePlanWithRates>;
}

const networkAccessRateColumn = createColumnHelper<NetworkAccessRate>();
const COLUMNS = [
    networkAccessRateColumn.accessor(row => row, {
        id: "rateKey",
        header: "Target",
        cell: row => (
            <NetworkAccessRateRateKeyCell
                rateKeys={row.row.original.rateKey}
                rateDiscriminator={row.row.original.rateDiscriminator}
            />
        ),
    }),
    networkAccessRateColumn.accessor("markup", {
        id: "markup",
        header: "Markup",
        cell: row => <MarkupDetails markup={row.getValue()} currency={Currency.Undefined} />,
    }),
];

const RatePlanNetworkAccessRatesPanel = ({ query: { data: ratePlan, isLoading } }: RatePlanDetailPanelProps) => {
    const flattenedRates = useMemo(() => {
        const networkAccessRates = new Array<NetworkAccessRate>();
        if (ratePlan?.networkAccessRates) {
            networkAccessRates.push(ratePlan?.networkAccessRates);
            ratePlan?.networkAccessRates?.childRates?.forEach(rate => networkAccessRates.push(rate));
        }
        return networkAccessRates;
    }, [ratePlan]);
    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                sizing={"fit-page"}
                columns={COLUMNS}
                data={flattenedRates}
                isLoading={isLoading}
                withPagination
            />
        </TabPanel>
    );
};

export default RatePlanNetworkAccessRatesPanel;
