/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        AccessToken,
        AccessTokenWithSecret,
        CreateAccessToken,
        CreateIntegration,
        CreateWebhookSubscription,
        Integration,
        UpdateAccessToken,
        UpdateIntegration,
        UpdateWebhookSubscription,
        WebhookSubscription,
    } from '../models';
        import {
            AccessTokenFromJSON,
            AccessTokenToJSON,
            AccessTokenWithSecretFromJSON,
            AccessTokenWithSecretToJSON,
            CreateAccessTokenFromJSON,
            CreateAccessTokenToJSON,
            CreateIntegrationFromJSON,
            CreateIntegrationToJSON,
            CreateWebhookSubscriptionFromJSON,
            CreateWebhookSubscriptionToJSON,
            IntegrationFromJSON,
            IntegrationToJSON,
            UpdateAccessTokenFromJSON,
            UpdateAccessTokenToJSON,
            UpdateIntegrationFromJSON,
            UpdateIntegrationToJSON,
            UpdateWebhookSubscriptionFromJSON,
            UpdateWebhookSubscriptionToJSON,
            WebhookSubscriptionFromJSON,
            WebhookSubscriptionToJSON,
        } from '../models';

            export interface IntegrationApiCreateAccessTokenRequest {
                integrationId: string;
                createAccessToken: CreateAccessToken;
            }

            export interface IntegrationApiCreateIntegrationRequest {
                createIntegration: CreateIntegration;
            }

            export interface IntegrationApiCreateWebhookSubscriptionRequest {
                integrationId: string;
                createWebhookSubscription: CreateWebhookSubscription;
            }

            export interface IntegrationApiDeleteWebhookSubscriptionRequest {
                integrationId: string;
                webhookSubscriptionId: string;
            }

            export interface IntegrationApiGetAccessTokenRequest {
                integrationId: string;
                accessTokenId: string;
            }

            export interface IntegrationApiGetAccessTokensRequest {
                integrationId: string;
            }

            export interface IntegrationApiGetIntegrationRequest {
                integrationId: string;
            }

            export interface IntegrationApiGetWebhookSubscriptionRequest {
                integrationId: string;
                webhookSubscriptionId: string;
            }

            export interface IntegrationApiGetWebhookSubscriptionsRequest {
                integrationId: string;
            }

            export interface IntegrationApiRevokeAccessTokenRequest {
                integrationId: string;
                accessTokenId: string;
            }

            export interface IntegrationApiRollIntegrationSecretRequest {
                integrationId: string;
            }

            export interface IntegrationApiUpdateAccessTokenRequest {
                integrationId: string;
                accessTokenId: string;
                updateAccessToken: UpdateAccessToken;
            }

            export interface IntegrationApiUpdateIntegrationRequest {
                integrationId: string;
                updateIntegration: UpdateIntegration;
            }

            export interface IntegrationApiUpdateWebhookSubscriptionRequest {
                integrationId: string;
                webhookSubscriptionId: string;
                updateWebhookSubscription: UpdateWebhookSubscription;
            }

        /**
        * 
        */
            export class IntegrationApi extends runtime.BaseAPI {

            /**
                * Create a new access token and display the secret. Once the returned object is no longer available the secret cannot be retrieved again.
                * Create access token
            */
            async createAccessTokenRaw(requestParameters: IntegrationApiCreateAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessTokenWithSecret>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling createAccessToken.');
                    }

                    if (requestParameters.createAccessToken === null || requestParameters.createAccessToken === undefined) {
                    throw new runtime.RequiredError('createAccessToken','Required parameter requestParameters.createAccessToken was null or undefined when calling createAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateAccessTokenToJSON(requestParameters.createAccessToken),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenWithSecretFromJSON(jsonValue));
            }

            /**
                * Create a new access token and display the secret. Once the returned object is no longer available the secret cannot be retrieved again.
                * Create access token
            */
                async createAccessToken(requestParameters: IntegrationApiCreateAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessTokenWithSecret> {
                    const response = await this.createAccessTokenRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create a new integration for setting up access tokens and webhook subscriptions.
                * Create new integration
            */
            async createIntegrationRaw(requestParameters: IntegrationApiCreateIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
                    if (requestParameters.createIntegration === null || requestParameters.createIntegration === undefined) {
                    throw new runtime.RequiredError('createIntegration','Required parameter requestParameters.createIntegration was null or undefined when calling createIntegration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateIntegrationToJSON(requestParameters.createIntegration),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
            }

            /**
                * Create a new integration for setting up access tokens and webhook subscriptions.
                * Create new integration
            */
                async createIntegration(requestParameters: IntegrationApiCreateIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
                    const response = await this.createIntegrationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create a new webhook subscription. If \'pausedAt\' is specified and the time has passed, the webhooks will only be queued but not fired, until the webhook subscription is unpaused.
                * Create webhook subscription
            */
            async createWebhookSubscriptionRaw(requestParameters: IntegrationApiCreateWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebhookSubscription>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling createWebhookSubscription.');
                    }

                    if (requestParameters.createWebhookSubscription === null || requestParameters.createWebhookSubscription === undefined) {
                    throw new runtime.RequiredError('createWebhookSubscription','Required parameter requestParameters.createWebhookSubscription was null or undefined when calling createWebhookSubscription.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/webhooksubscriptions`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateWebhookSubscriptionToJSON(requestParameters.createWebhookSubscription),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => WebhookSubscriptionFromJSON(jsonValue));
            }

            /**
                * Create a new webhook subscription. If \'pausedAt\' is specified and the time has passed, the webhooks will only be queued but not fired, until the webhook subscription is unpaused.
                * Create webhook subscription
            */
                async createWebhookSubscription(requestParameters: IntegrationApiCreateWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebhookSubscription> {
                    const response = await this.createWebhookSubscriptionRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Deletes a webhook subscription. This will also disable all existing webhooks from being re-scheduled by the scheduler.
                * Remove webhook subscription
            */
            async deleteWebhookSubscriptionRaw(requestParameters: IntegrationApiDeleteWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling deleteWebhookSubscription.');
                    }

                    if (requestParameters.webhookSubscriptionId === null || requestParameters.webhookSubscriptionId === undefined) {
                    throw new runtime.RequiredError('webhookSubscriptionId','Required parameter requestParameters.webhookSubscriptionId was null or undefined when calling deleteWebhookSubscription.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/webhooksubscriptions/{webhookSubscriptionId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"webhookSubscriptionId"}}`, encodeURIComponent(String(requestParameters.webhookSubscriptionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Deletes a webhook subscription. This will also disable all existing webhooks from being re-scheduled by the scheduler.
                * Remove webhook subscription
            */
                async deleteWebhookSubscription(requestParameters: IntegrationApiDeleteWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteWebhookSubscriptionRaw(requestParameters, initOverrides);
                }

            /**
                * Get a specific access token by id.
                * Get access token
            */
            async getAccessTokenRaw(requestParameters: IntegrationApiGetAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessToken>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getAccessToken.');
                    }

                    if (requestParameters.accessTokenId === null || requestParameters.accessTokenId === undefined) {
                    throw new runtime.RequiredError('accessTokenId','Required parameter requestParameters.accessTokenId was null or undefined when calling getAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens/{accessTokenId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"accessTokenId"}}`, encodeURIComponent(String(requestParameters.accessTokenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenFromJSON(jsonValue));
            }

            /**
                * Get a specific access token by id.
                * Get access token
            */
                async getAccessToken(requestParameters: IntegrationApiGetAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessToken> {
                    const response = await this.getAccessTokenRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Gets all active access tokens for the integration
                * Get access tokens
            */
            async getAccessTokensRaw(requestParameters: IntegrationApiGetAccessTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AccessToken>>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getAccessTokens.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccessTokenFromJSON));
            }

            /**
                * Gets all active access tokens for the integration
                * Get access tokens
            */
                async getAccessTokens(requestParameters: IntegrationApiGetAccessTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AccessToken>> {
                    const response = await this.getAccessTokensRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a specific integration
                * Get integration
            */
            async getIntegrationRaw(requestParameters: IntegrationApiGetIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getIntegration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
            }

            /**
                * Get a specific integration
                * Get integration
            */
                async getIntegration(requestParameters: IntegrationApiGetIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
                    const response = await this.getIntegrationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get available integrations
                * Get integrations
            */
            async getIntegrationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Integration>>> {
            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IntegrationFromJSON));
            }

            /**
                * Get available integrations
                * Get integrations
            */
                async getIntegrations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Integration>> {
                    const response = await this.getIntegrationsRaw(initOverrides);
                    return await response.value();
                }

            /**
                * Get a specific webhook subscription.
                * Get webhook subscription
            */
            async getWebhookSubscriptionRaw(requestParameters: IntegrationApiGetWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebhookSubscription>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getWebhookSubscription.');
                    }

                    if (requestParameters.webhookSubscriptionId === null || requestParameters.webhookSubscriptionId === undefined) {
                    throw new runtime.RequiredError('webhookSubscriptionId','Required parameter requestParameters.webhookSubscriptionId was null or undefined when calling getWebhookSubscription.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/webhooksubscriptions/{webhookSubscriptionId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"webhookSubscriptionId"}}`, encodeURIComponent(String(requestParameters.webhookSubscriptionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => WebhookSubscriptionFromJSON(jsonValue));
            }

            /**
                * Get a specific webhook subscription.
                * Get webhook subscription
            */
                async getWebhookSubscription(requestParameters: IntegrationApiGetWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebhookSubscription> {
                    const response = await this.getWebhookSubscriptionRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get webhook subscriptions.
                * Get webhook subscriptions
            */
            async getWebhookSubscriptionsRaw(requestParameters: IntegrationApiGetWebhookSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebhookSubscription>>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getWebhookSubscriptions.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/webhooksubscriptions`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebhookSubscriptionFromJSON));
            }

            /**
                * Get webhook subscriptions.
                * Get webhook subscriptions
            */
                async getWebhookSubscriptions(requestParameters: IntegrationApiGetWebhookSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebhookSubscription>> {
                    const response = await this.getWebhookSubscriptionsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Revoke an existing access token. This action is irrevokable and the token can no longer be used.
                * Revoke access token
            */
            async revokeAccessTokenRaw(requestParameters: IntegrationApiRevokeAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling revokeAccessToken.');
                    }

                    if (requestParameters.accessTokenId === null || requestParameters.accessTokenId === undefined) {
                    throw new runtime.RequiredError('accessTokenId','Required parameter requestParameters.accessTokenId was null or undefined when calling revokeAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens/{accessTokenId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"accessTokenId"}}`, encodeURIComponent(String(requestParameters.accessTokenId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Revoke an existing access token. This action is irrevokable and the token can no longer be used.
                * Revoke access token
            */
                async revokeAccessToken(requestParameters: IntegrationApiRevokeAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.revokeAccessTokenRaw(requestParameters, initOverrides);
                }

            /**
                * Roll integration secret
            */
            async rollIntegrationSecretRaw(requestParameters: IntegrationApiRollIntegrationSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling rollIntegrationSecret.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/rollsecret`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
            }

            /**
                * Roll integration secret
            */
                async rollIntegrationSecret(requestParameters: IntegrationApiRollIntegrationSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
                    const response = await this.rollIntegrationSecretRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Update details for an access token. It is not possible to update the secret.
                * Update access token details
            */
            async updateAccessTokenRaw(requestParameters: IntegrationApiUpdateAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessToken>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling updateAccessToken.');
                    }

                    if (requestParameters.accessTokenId === null || requestParameters.accessTokenId === undefined) {
                    throw new runtime.RequiredError('accessTokenId','Required parameter requestParameters.accessTokenId was null or undefined when calling updateAccessToken.');
                    }

                    if (requestParameters.updateAccessToken === null || requestParameters.updateAccessToken === undefined) {
                    throw new runtime.RequiredError('updateAccessToken','Required parameter requestParameters.updateAccessToken was null or undefined when calling updateAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens/{accessTokenId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"accessTokenId"}}`, encodeURIComponent(String(requestParameters.accessTokenId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateAccessTokenToJSON(requestParameters.updateAccessToken),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenFromJSON(jsonValue));
            }

            /**
                * Update details for an access token. It is not possible to update the secret.
                * Update access token details
            */
                async updateAccessToken(requestParameters: IntegrationApiUpdateAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessToken> {
                    const response = await this.updateAccessTokenRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Update integration details.
                * Update integration details
            */
            async updateIntegrationRaw(requestParameters: IntegrationApiUpdateIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling updateIntegration.');
                    }

                    if (requestParameters.updateIntegration === null || requestParameters.updateIntegration === undefined) {
                    throw new runtime.RequiredError('updateIntegration','Required parameter requestParameters.updateIntegration was null or undefined when calling updateIntegration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateIntegrationToJSON(requestParameters.updateIntegration),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
            }

            /**
                * Update integration details.
                * Update integration details
            */
                async updateIntegration(requestParameters: IntegrationApiUpdateIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
                    const response = await this.updateIntegrationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Delta update a webhook subscription. Fields that are left out will be omitted from the update.
                * Update webhook subscription details
            */
            async updateWebhookSubscriptionRaw(requestParameters: IntegrationApiUpdateWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebhookSubscription>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling updateWebhookSubscription.');
                    }

                    if (requestParameters.webhookSubscriptionId === null || requestParameters.webhookSubscriptionId === undefined) {
                    throw new runtime.RequiredError('webhookSubscriptionId','Required parameter requestParameters.webhookSubscriptionId was null or undefined when calling updateWebhookSubscription.');
                    }

                    if (requestParameters.updateWebhookSubscription === null || requestParameters.updateWebhookSubscription === undefined) {
                    throw new runtime.RequiredError('updateWebhookSubscription','Required parameter requestParameters.updateWebhookSubscription was null or undefined when calling updateWebhookSubscription.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/webhooksubscriptions/{webhookSubscriptionId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"webhookSubscriptionId"}}`, encodeURIComponent(String(requestParameters.webhookSubscriptionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateWebhookSubscriptionToJSON(requestParameters.updateWebhookSubscription),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => WebhookSubscriptionFromJSON(jsonValue));
            }

            /**
                * Delta update a webhook subscription. Fields that are left out will be omitted from the update.
                * Update webhook subscription details
            */
                async updateWebhookSubscription(requestParameters: IntegrationApiUpdateWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebhookSubscription> {
                    const response = await this.updateWebhookSubscriptionRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
