import React from "react";
import { TelcoRate } from "../../../../api";
import { Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { CountryInfo, getCountry, pluralize } from "@cobira/ui-library";

export interface TelcoRateCountryDetailProps {
    telcoRate: TelcoRate;
}

const TelcoRateCountryDetail = ({ telcoRate }: TelcoRateCountryDetailProps) => {
    const countries = Array.from(telcoRate.rateKey);
    const countryCount = Array.from(telcoRate.rateKey).length;

    return (
        <VStack w={"100"} align={"start"}>
            <Text>
                This rate applies to the {pluralize("country", countryCount)} shown below. The parent rates must all be
                applicable in order for this to be applied. In case a child rate is specified targeting a specific
                network, this is the rate that will be used if it matches.
            </Text>
            <Wrap spacing={2}>
                {countries.map(countryCode => (
                    <WrapItem key={countryCode}>
                        <CountryInfo country={getCountry(countryCode)} />
                    </WrapItem>
                ))}
            </Wrap>
        </VStack>
    );
};

export default TelcoRateCountryDetail;
