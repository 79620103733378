import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { Tenant, TenantApiDeleteTenantUsagePackageTypeRequest, UsagePackageType } from "../../../api";

export interface ConfirmDeleteContactModalProps {
    childTenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
    usagePackageType?: UsagePackageType;
}

const ConfirmDeleteChildTenantUsagePackageTypeModal = ({
    onClose,
    usagePackageType,
    isOpen,
    childTenant,
}: ConfirmDeleteContactModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteUsagePackageType = useMutation<void, Error, TenantApiDeleteTenantUsagePackageTypeRequest>(variables =>
        tenantApi.deleteTenantUsagePackageType(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && usagePackageType?.id && childTenant?.id) {
                deleteUsagePackageType.mutate(
                    { usagePackageTypeId: usagePackageType.id, tenantId: childTenant.id },
                    {
                        onSuccess: () => {
                            notifications.success("Bundle no longer available for assignment");
                            queryClient.invalidateQueries(["tenants", childTenant.id, "usagepackagetypes"]);
                            queryClient.invalidateQueries([
                                "tenants",
                                childTenant.id,
                                "usagepackagetypes",
                                usagePackageType.id,
                            ]);
                        },
                    },
                );
            }
            onClose();
        },
        [childTenant, deleteUsagePackageType, notifications, onClose, queryClient, usagePackageType],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!usagePackageType && !!childTenant && isOpen}
            title={"Deactivate Bundle"}
            description={
                <Container>
                    <Text>Are you sure you want to de-activate the bundle?</Text>
                    <Text>
                        The bundle will no longer be available for assignment after this operation, the tenant will
                        still be allowed to remove the bundle from SIM Cards, and existing bundle assignments will
                        continue to function until terminated.
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteChildTenantUsagePackageTypeModal;
