import React from "react";
import { useFormContext } from "react-hook-form";
import { Currency } from "../../api";
import { MarkupType } from "../../utils/markupUtils";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { CurrencyLabels } from "../../labels/CurrencyLabels";
import { MarkupTypeLabels } from "../../labels/MarkupTypeLabels";
import RateMarkupInput from "../TelcoRateForm/RateMarkupInput";
import { CreateRatePlanSchemaType } from "./CreateRatePlanForm";
import DiscountPricingInput from "../TelcoRateForm/DiscountPricingInput";
import { PricingOption } from "../TelcoRateForm/TelcoRateFormSchema";
import { PricingOptionLabels } from "../../labels/PricingOptionLabels";

export interface CreateRatePlanServiceTypeRateFormStepProps {
    rateIdentifier: "dataRate" | "moSmsRate" | "mtSmsRate";
}

const CreateRatePlanServiceTypeRateFormStep = ({ rateIdentifier }: CreateRatePlanServiceTypeRateFormStepProps) => {
    const {
        formState: { errors },
        control,
        watch,
        register,
    } = useFormContext<CreateRatePlanSchemaType>();
    const currency: Currency = watch(`${rateIdentifier}.currency`);
    const markupType: MarkupType = watch(`${rateIdentifier}.rate.markup`);
    const pricingStrategy: PricingOption = watch(`${rateIdentifier}.pricingStrategy`);
    return (
        <FormContainer>
            <FormField label={"Pricing Option"} error={errors[rateIdentifier]?.pricingStrategy}>
                <ControlledAutoCompleteSingleSelectInput
                    control={{ control: control, name: `${rateIdentifier}.pricingStrategy` }}
                    input={{
                        items: Object.values(PricingOption),
                        displayMapping: value => PricingOptionLabels[value as PricingOption],
                        autocompleteAbleMapping: value => PricingOptionLabels[value as PricingOption],
                    }}
                />
            </FormField>
            {pricingStrategy === PricingOption.SIMPLE && (
                <>
                    <FormField label={"Rate pricing"} error={errors[rateIdentifier]?.rate?.markup}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{ control: control, name: `${rateIdentifier}.rate.markup` }}
                            input={{
                                items: Object.values(MarkupType),
                                displayMapping: value => MarkupTypeLabels[value as MarkupType],
                                autocompleteAbleMapping: value => MarkupTypeLabels[value as MarkupType],
                            }}
                        />
                    </FormField>
                    {markupType === MarkupType.ABSOLUTE && (
                        <FormField label={"Currency"} error={errors[rateIdentifier]?.currency}>
                            <ControlledAutoCompleteSingleSelectInput
                                control={{ control: control, name: `${rateIdentifier}.currency` }}
                                input={{
                                    items: Object.values(Currency).filter(currency => currency !== Currency.Undefined),
                                    displayMapping: value => CurrencyLabels[value as Currency],
                                    autocompleteAbleMapping: value => CurrencyLabels[value as Currency],
                                }}
                            />
                        </FormField>
                    )}
                    <RateMarkupInput
                        currency={currency}
                        markupType={markupType}
                        error={errors[rateIdentifier]?.rate?.markupAmount}
                        registration={register(`${rateIdentifier}.rate.markupAmount`, { valueAsNumber: true })}
                        unit={rateIdentifier === "dataRate" ? "MB" : "SMS"}
                    />
                </>
            )}
            {pricingStrategy === PricingOption.ADVANCED && <DiscountPricingInput rateIdentifier={"dataRate"} />}
        </FormContainer>
    );
};

export default CreateRatePlanServiceTypeRateFormStep;
