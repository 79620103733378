import { Customer } from "../../../../api";
import { CobiraBarChartPaper, Loading, MetricLabelFormatters, MetricsProvider, newRequest } from "@cobira/ui-library";
import React from "react";
import { useMetricsResolver } from "../../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";

export interface CustomerSmsUsagePaperProps {
    customer?: Customer;
    isLoading?: boolean;
}

const CustomerSmsUsagePaper = ({ customer }: CustomerSmsUsagePaperProps) => {
    const resolver = useMetricsResolver();
    const now = new Date();
    const twelveMonthsAgo = subMonths(now, 12);

    if (!customer) {
        return <Loading />;
    }

    const requests = {
        smsUsage: newRequest({
            bucket: "MONTHLY",
            dataPoint: "SMS_USAGE",
            group: "CUSTOMER",
            groupId: customer.id,
            fromDateTime: twelveMonthsAgo,
            toDateTime: now,
        }),
    };

    return (
        <MetricsProvider requests={requests} resolver={resolver}>
            {data => (
                <CobiraBarChartPaper
                    title={"SMS Usage"}
                    bars={[
                        {
                            dataSet: data => data.smsUsage.value,
                            dataSetName: customer.customerName,
                        },
                    ]}
                    data={data}
                    chartConfig={{
                        xAxisValueLabel: data => MetricLabelFormatters.yearAndMonth(data.smsUsage),
                    }}
                />
            )}
        </MetricsProvider>
    );
};

export default CustomerSmsUsagePaper;
