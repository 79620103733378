/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CustomerStates = {
    Trial: 'TRIAL',
    Active: 'ACTIVE',
    Terminated: 'TERMINATED'
} as const;
export type CustomerStates = typeof CustomerStates[keyof typeof CustomerStates];


export function CustomerStatesFromJSON(json: any): CustomerStates {
    return CustomerStatesFromJSONTyped(json, false);
}

export function CustomerStatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerStates {
    return json as CustomerStates;
}

export function CustomerStatesToJSON(value?: CustomerStates | null): any {
    return value as any;
}

