import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { User, UserApiDeleteUserRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";

export interface ConfirmDeleteUserModalProps {
    onClose: () => void;
    user?: User;
}

const ConfirmDeleteUserModal = ({ onClose, user }: ConfirmDeleteUserModalProps) => {
    const { userApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteUser = useMutation<void, Error, UserApiDeleteUserRequest>(variables => userApi.deleteUser(variables));
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && user?.id) {
                deleteUser.mutate(
                    { userId: user.id },
                    {
                        onSuccess: () => {
                            notifications.success("Team member blocked");
                            queryClient.invalidateQueries(["users", user?.id]);
                            queryClient.invalidateQueries(["users"]);
                        },
                    },
                );
            }
            onClose();
        },
        [user, deleteUser, notifications, onClose, queryClient],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!user}
            title={"Block team member"}
            description={
                <Container>
                    <Text>Are you sure you want to block the team member?</Text>
                    <Text as={"b"}>
                        {user?.name} ({user?.email})
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteUserModal;
