import { Currency, useUserPreferences } from "@cobira/ui-library";

export interface UseUserSettingsReturn {
    currency: Currency;
}

export const useUserSettings = () => {
    const defaultValue: UseUserSettingsReturn = {
        currency: "EUR",
    };
    const { preferences } = useUserPreferences("user-settings", defaultValue);
    return preferences;
};
