import { useParams } from "react-router";
import { PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";
import DataUsageRecordDetails from "../../../components/Usage/DataUsageRecordDetails";

const DataUsageDetailPage = () => {
    const { usageRecordId } = useParams();
    const { usageRecordApi } = useApi();

    const dataUsageQuery = useQuery(
        ["usage-records", "data", usageRecordId],
        () => usageRecordApi.getDataUsageRecord({ usageRecordId: usageRecordId || "" }),
        {
            enabled: !!usageRecordId,
        },
    );
    return (
        <PageLayout
            title={<PageTitle title={"Data Usage Details"} />}
            navigation={<PageBackButton text={"Back to Usage Records"} path={`/usage-records`} />}
        >
            <DataUsageRecordDetails query={dataUsageQuery} />
        </PageLayout>
    );
};

export default DataUsageDetailPage;
