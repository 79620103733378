import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    ControlledAutoCompleteMultiSelectInput,
    ControlledAutoCompleteSingleSelectInput,
    ControlledCountryInput,
    ControlledDataInput,
    ControlledSwitch,
    FormContainer,
    FormField,
} from "@cobira/ui-library";
import { Button, ButtonGroup, GridItem, Input, SimpleGrid } from "@chakra-ui/react";
import { AutomaticActivationTypes } from "../../api";
import { UsagePackageAutomaticActivationTypeLabels } from "../../labels/UsagePackageAutomaticActivationTypeLabels";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

const CreateUsagePackageTypeFormSchema = z
    .object({
        name: z.string().min(1, "Name is required"),
        supportedCountries: z
            .array(
                z.object({
                    iso2: z.string(),
                    iso3: z.string(),
                    name: z.string(),
                }),
            )
            .min(1, "You must select at least 1 country"),
        activationType: z.nativeEnum(AutomaticActivationTypes),
        networkCredentialTypes: z
            .array(
                z.object({
                    id: z.string(),
                    plmn: z.string(),
                    imsiProvider: z.string().optional(),
                }),
            )
            .min(1, "Please select at least 1"),
        duration: z.string().min(1, "Please specify a duration"),
        dataBundle: z
            .object({
                enabled: z.boolean(),
                allowance: z.number().optional(),
            })
            .refine(
                dataAllowance => {
                    if (dataAllowance.enabled) {
                        return dataAllowance.allowance !== undefined && dataAllowance.allowance > 0;
                    }
                    return true;
                },
                { message: "An allowance must be specified when data is enabled" },
            ),
        smsBundle: z
            .object({
                enabled: z.boolean(),
                allowance: z.number().optional(),
            })
            .refine(
                smsAllowance => {
                    if (smsAllowance.enabled) {
                        return smsAllowance.allowance !== undefined && smsAllowance.allowance > 0;
                    }
                    return true;
                },
                { message: "An allowance must be specified when SMS is enabled" },
            ),
    })
    .refine(arg => arg.smsBundle.enabled || arg.dataBundle.enabled, {
        message: "At least one bundle must be enabled",
        path: ["dataBundle"],
    });

export type CreateUsagePackageTypeFormSchemaType = z.infer<typeof CreateUsagePackageTypeFormSchema>;

export interface CreateProductFormProps {
    onSubmit: (form: CreateUsagePackageTypeFormSchemaType) => void;
    onCancel?: () => void;
}

const CreateUsagePackageTypeForm = ({ onSubmit, onCancel }: CreateProductFormProps) => {
    const { configurationApi } = useApi();
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
    } = useForm<CreateUsagePackageTypeFormSchemaType>({
        resolver: zodResolver(CreateUsagePackageTypeFormSchema),
        defaultValues: {
            dataBundle: {
                enabled: false,
                allowance: 0,
            },
            smsBundle: {
                enabled: false,
                allowance: 0,
            },
        },
    });

    const { data: networkCredentialTypes } = useQuery(["networkcredentialtypes"], () =>
        configurationApi.getNetworkCredentialTypes({
            pageNumber: 0,
            pageSize: 9999,
        }),
    );

    const dataUsageAllowanceEnabled = watch("dataBundle.enabled");
    const smsUsageAllowanceEnabled = watch("smsBundle.enabled");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <FormField label={"Bundle Duration"} error={errors?.duration}>
                    <Input {...register("duration")} placeholder={"ISO8601 duration string, e.g. P30D for 30 days"} />
                </FormField>
                <FormField label={"Activation Type"} error={errors?.activationType}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: "activationType" }}
                        input={{
                            items: Object.values(AutomaticActivationTypes),
                            displayMapping: value => UsagePackageAutomaticActivationTypeLabels[value],
                            autocompleteAbleMapping: value => UsagePackageAutomaticActivationTypeLabels[value],
                        }}
                    />
                </FormField>
                <FormField label={"Network Credential Types"} error={errors?.networkCredentialTypes}>
                    <ControlledAutoCompleteMultiSelectInput
                        control={{ control: control, name: "networkCredentialTypes" }}
                        input={{
                            placeholder: "Select network credential types the bundle should support",
                            items: networkCredentialTypes?.content || [],
                            displayMapping: value => `${value.plmn} - ${value.imsiProvider}`,
                            autocompleteAbleMapping: value => `${value.plmn} - ${value.imsiProvider}`,
                        }}
                    />
                </FormField>
                <FormField label={"Data Bundle"} error={errors?.dataBundle?.root}>
                    <SimpleGrid columns={2} columnGap={2} templateColumns={"auto minmax(0, 1fr)"}>
                        <GridItem alignContent={"center"}>
                            <ControlledSwitch
                                name={"dataBundle.enabled"}
                                control={control}
                                size={"lg"}
                            ></ControlledSwitch>
                        </GridItem>
                        <GridItem zIndex={1}>
                            <ControlledDataInput
                                name={"dataBundle.allowance"}
                                control={control}
                                isDisabled={!dataUsageAllowanceEnabled}
                            />
                        </GridItem>
                    </SimpleGrid>
                </FormField>
                <FormField label={"SMS Bundle"} error={errors?.smsBundle?.root}>
                    <SimpleGrid columns={2} columnGap={2} templateColumns={"auto minmax(0, 1fr)"}>
                        <GridItem alignContent={"center"}>
                            <ControlledSwitch name={"smsBundle.enabled"} control={control} size={"lg"} />
                        </GridItem>
                        <GridItem>
                            <Input
                                {...register("smsBundle.allowance", { valueAsNumber: true })}
                                isDisabled={!smsUsageAllowanceEnabled}
                            />
                        </GridItem>
                    </SimpleGrid>
                </FormField>
                <FormField label={"Countries"} error={errors?.supportedCountries}>
                    <ControlledCountryInput
                        control={{
                            control: control,
                            name: "supportedCountries",
                        }}
                        input={{ isMulti: true }}
                    />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default CreateUsagePackageTypeForm;
