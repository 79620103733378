import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Customer, UsagePackageApiCreateCustomerUsagePackageTypeRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import AddPackageToCatalogueForm, {
    AddPackageToCatalogueFormSchemaType,
} from "../../../forms/AddPackageToCatalogueForm/AddPackageToCatalogueForm";

export interface AddPackageToCatalogueModalProps {
    customer?: Customer;
    isOpen: boolean;
    onClose: () => void;
}

const AddPackageToCatalogueModal = ({ customer, isOpen, onClose }: AddPackageToCatalogueModalProps) => {
    const { usagePackageApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const addPackageToCustomerCatalogue = useMutation<
        void,
        Error,
        UsagePackageApiCreateCustomerUsagePackageTypeRequest
    >(variables => usagePackageApi.createCustomerUsagePackageType(variables), {
        onSuccess: () => {
            notifications.success(`Bundle added to catalogue`);
            queryClient.invalidateQueries(["customers", customer?.id, "usagepackagetypes"]);
        },
    });

    const handleSubmit = useCallback(
        (form: AddPackageToCatalogueFormSchemaType) => {
            addPackageToCustomerCatalogue.mutate({
                customerId: customer?.id || "",
                createUsagePackageCatalogue: {
                    usagePackageTypeId: form.usagePackageType.id,
                    customName: form.catalogueName || undefined,
                    description: form.catalogueDescription || undefined,
                },
            });
            onClose();
        },
        [addPackageToCustomerCatalogue, onClose, customer],
    );

    if (!customer) {
        return <></>;
    }

    return (
        <BasicModal title={`Add Bundle to Catalogue`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <AddPackageToCatalogueForm customer={customer} onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default AddPackageToCatalogueModal;
