/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        DataUsageRecord,
        DataUsageRecordPage,
        SmsUsageRecord,
        SmsUsageRecordPage,
    } from '../models';
        import {
            DataUsageRecordFromJSON,
            DataUsageRecordToJSON,
            DataUsageRecordPageFromJSON,
            DataUsageRecordPageToJSON,
            SmsUsageRecordFromJSON,
            SmsUsageRecordToJSON,
            SmsUsageRecordPageFromJSON,
            SmsUsageRecordPageToJSON,
        } from '../models';

            export interface UsageRecordApiGetDataUsageRecordRequest {
                usageRecordId: string;
            }

            export interface UsageRecordApiGetDataUsageRecordsRequest {
                pageSize: number;
                pageNumber: number;
                fromDate: Date;
                toDate: Date;
                customerId?: string;
                billingGroupId?: string;
                icc?: string;
                plmn?: string;
                countryCode?: string;
            }

            export interface UsageRecordApiGetSmsUsageRecordRequest {
                usageRecordId: string;
            }

            export interface UsageRecordApiGetSmsUsageRecordsRequest {
                pageSize: number;
                pageNumber: number;
                fromDate: Date;
                toDate: Date;
                customerId?: string;
                billingGroupId?: string;
                icc?: string;
                plmn?: string;
                countryCode?: string;
            }

        /**
        * 
        */
            export class UsageRecordApi extends runtime.BaseAPI {

            /**
                * Retrieve a single Data Usage Record
            */
            async getDataUsageRecordRaw(requestParameters: UsageRecordApiGetDataUsageRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataUsageRecord>> {
                    if (requestParameters.usageRecordId === null || requestParameters.usageRecordId === undefined) {
                    throw new runtime.RequiredError('usageRecordId','Required parameter requestParameters.usageRecordId was null or undefined when calling getDataUsageRecord.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usage-records/data/{usageRecordId}`.replace(`{${"usageRecordId"}}`, encodeURIComponent(String(requestParameters.usageRecordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => DataUsageRecordFromJSON(jsonValue));
            }

            /**
                * Retrieve a single Data Usage Record
            */
                async getDataUsageRecord(requestParameters: UsageRecordApiGetDataUsageRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataUsageRecord> {
                    const response = await this.getDataUsageRecordRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch data usage records.  The results will be sorted newest to oldest.
            */
            async getDataUsageRecordsRaw(requestParameters: UsageRecordApiGetDataUsageRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataUsageRecordPage>> {
                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getDataUsageRecords.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getDataUsageRecords.');
                    }

                    if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
                    throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling getDataUsageRecords.');
                    }

                    if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
                    throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling getDataUsageRecords.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.customerId !== undefined) {
                            queryParameters['customerId'] = requestParameters.customerId;
                    }

                    if (requestParameters.billingGroupId !== undefined) {
                            queryParameters['billingGroupId'] = requestParameters.billingGroupId;
                    }

                    if (requestParameters.icc !== undefined) {
                            queryParameters['icc'] = requestParameters.icc;
                    }

                    if (requestParameters.fromDate !== undefined) {
                            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.toDate !== undefined) {
                            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.plmn !== undefined) {
                            queryParameters['plmn'] = requestParameters.plmn;
                    }

                    if (requestParameters.countryCode !== undefined) {
                            queryParameters['countryCode'] = requestParameters.countryCode;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usage-records/data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => DataUsageRecordPageFromJSON(jsonValue));
            }

            /**
                * Fetch data usage records.  The results will be sorted newest to oldest.
            */
                async getDataUsageRecords(requestParameters: UsageRecordApiGetDataUsageRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataUsageRecordPage> {
                    const response = await this.getDataUsageRecordsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Retrieve a single SMS CDR record
            */
            async getSmsUsageRecordRaw(requestParameters: UsageRecordApiGetSmsUsageRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsUsageRecord>> {
                    if (requestParameters.usageRecordId === null || requestParameters.usageRecordId === undefined) {
                    throw new runtime.RequiredError('usageRecordId','Required parameter requestParameters.usageRecordId was null or undefined when calling getSmsUsageRecord.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usage-records/sms/{usageRecordId}`.replace(`{${"usageRecordId"}}`, encodeURIComponent(String(requestParameters.usageRecordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SmsUsageRecordFromJSON(jsonValue));
            }

            /**
                * Retrieve a single SMS CDR record
            */
                async getSmsUsageRecord(requestParameters: UsageRecordApiGetSmsUsageRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsUsageRecord> {
                    const response = await this.getSmsUsageRecordRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch SMS Usage Records.  The results will be sorted newest to oldest.
            */
            async getSmsUsageRecordsRaw(requestParameters: UsageRecordApiGetSmsUsageRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsUsageRecordPage>> {
                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSmsUsageRecords.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSmsUsageRecords.');
                    }

                    if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
                    throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling getSmsUsageRecords.');
                    }

                    if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
                    throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling getSmsUsageRecords.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.customerId !== undefined) {
                            queryParameters['customerId'] = requestParameters.customerId;
                    }

                    if (requestParameters.billingGroupId !== undefined) {
                            queryParameters['billingGroupId'] = requestParameters.billingGroupId;
                    }

                    if (requestParameters.icc !== undefined) {
                            queryParameters['icc'] = requestParameters.icc;
                    }

                    if (requestParameters.fromDate !== undefined) {
                            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.toDate !== undefined) {
                            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.plmn !== undefined) {
                            queryParameters['plmn'] = requestParameters.plmn;
                    }

                    if (requestParameters.countryCode !== undefined) {
                            queryParameters['countryCode'] = requestParameters.countryCode;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usage-records/sms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SmsUsageRecordPageFromJSON(jsonValue));
            }

            /**
                * Fetch SMS Usage Records.  The results will be sorted newest to oldest.
            */
                async getSmsUsageRecords(requestParameters: UsageRecordApiGetSmsUsageRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsUsageRecordPage> {
                    const response = await this.getSmsUsageRecordsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
