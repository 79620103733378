import React from "react";
import { SimCardCapabilityName } from "../../api";
import { Box, Text } from "@chakra-ui/react";
import {
    SimCardCapabilityNameDescriptions,
    SimCardCapabilityNameLabels,
} from "../../labels/SimCardCapabilityNameLabels";

export interface InlineSimCardCapabilityNameProps {
    simCardCapability: SimCardCapabilityName;
}

const InlineSimCardCapabilityName = ({ simCardCapability }: InlineSimCardCapabilityNameProps) => {
    const header = SimCardCapabilityNameLabels[simCardCapability];
    const description = SimCardCapabilityNameDescriptions[simCardCapability];

    return (
        <Box>
            <Text fontSize={"md"} color={"header"} fontWeight={"bold"}>
                {header}
            </Text>
            <Text>{description}</Text>
        </Box>
    );
};

export default InlineSimCardCapabilityName;
