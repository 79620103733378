import { CustomerStates } from "../api";

export const CustomerStateLabels = {
    [CustomerStates.Trial]: "Trial",
    [CustomerStates.Terminated]: "Terminated",
    [CustomerStates.Active]: "Active",
};

export const CustomerStateLabelsExplanation = {
    [CustomerStates.Trial]: "Customer is currently in trial and will not be billed",
    [CustomerStates.Terminated]: "Customer is terminated and SIM Cards can be suspended",
    [CustomerStates.Active]: "Customer is active and will be billed normally",
    ["UNDEFINED"]: "N/A",
};
