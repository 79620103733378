import React, { useMemo } from "react";
import { NodeApi } from "react-arborist";
import { HStack, Tag } from "@chakra-ui/react";
import { TelcoRate } from "../../../../api";
import { serviceTypeLabels } from "../../../../labels/RateKeyLabels";

export interface TelcoRateServiceTypeHeadingProps {
    node: NodeApi<TelcoRate>;
}

const TelcoRateServiceTypeHeading = ({ node }: TelcoRateServiceTypeHeadingProps) => {
    const serviceTypeTags = useMemo(
        () =>
            Array.from(node.data.rateKey).map(serviceType => (
                <Tag key={serviceType}>{serviceTypeLabels(serviceType)}</Tag>
            )),
        [node],
    );
    return <HStack gap={2}>{serviceTypeTags}</HStack>;
};

export default TelcoRateServiceTypeHeading;
