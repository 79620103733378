import React from "react";
import { CobiraTable, Money, monthsAgoInterval, PageLayout, PageTitle, usePageNavigation } from "@cobira/ui-library";
import { useUserSettings } from "../../hooks/useUserSettings";
import { useAggregatedBillingReports } from "../../hooks/useAggregatedBillingReports";
import { aggregateBillingReportAggregates, BillingReportAggregate } from "../../utils/billingReportUtils";
import { createColumnHelper } from "@tanstack/react-table";
import { MonthLabels } from "../../labels/MonthLabels";
import { Button, useBoolean } from "@chakra-ui/react";
import GenerateBillingReportsModal from "../../components/Modal/GenerateBillingReportsModal/GenerateBillingReportsModal";

const billingReportColumn = createColumnHelper<BillingReportAggregate>();
const COLUMNS = [
    billingReportColumn.accessor(row => row, {
        id: "yearAndDate",
        header: "Date",
        cell: row => `${row.getValue().year} - ${MonthLabels[row.getValue().month]}`,
    }),
    billingReportColumn.accessor(row => row, {
        id: "totalCost",
        header: "Total Cost",
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <Money
                value={row.getValue().totalFeeCost + row.getValue().totalUsageCost}
                currency={row.getValue().currency}
                decimals={2}
            />
        ),
    }),
    billingReportColumn.accessor(row => row, {
        id: "totalBillable",
        header: "Total Billable",
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <Money
                value={row.getValue().totalFeeBillable + row.getValue().totalUsageBillable}
                currency={row.getValue().currency}
                decimals={2}
            />
        ),
    }),
    billingReportColumn.accessor(row => row, {
        id: "margin",
        header: "Margin",
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <Money
                value={
                    row.getValue().totalFeeBillable +
                    row.getValue().totalUsageBillable -
                    row.getValue().totalFeeCost -
                    row.getValue().totalUsageCost
                }
                currency={row.getValue().currency}
                decimals={2}
            />
        ),
    }),
];

const BillingReportsPage = () => {
    const { currency } = useUserSettings();
    const [isGeneratingReport, { on, off }] = useBoolean(false);
    const { navigate } = usePageNavigation<BillingReportAggregate>({
        route: report => `/billing/reports/${report.year}/${report.month}`,
    });
    const now = new Date();
    const yearMonths = [
        monthsAgoInterval(1, now).from,
        monthsAgoInterval(2, now).from,
        monthsAgoInterval(3, now).from,
        monthsAgoInterval(4, now).from,
        monthsAgoInterval(5, now).from,
        monthsAgoInterval(6, now).from,
        monthsAgoInterval(7, now).from,
        monthsAgoInterval(8, now).from,
        monthsAgoInterval(9, now).from,
        monthsAgoInterval(10, now).from,
        monthsAgoInterval(11, now).from,
        monthsAgoInterval(12, now).from,
    ];

    const queryResults = useAggregatedBillingReports({
        targetCurrency: currency,
        yearAndMonths: yearMonths,
    });
    const isLoading = queryResults
        .map(result => result.isFetching)
        .reduce((previousValue, currentValue) => previousValue || currentValue, false);
    const billingReportAggregates = queryResults
        .map(query => aggregateBillingReportAggregates(query?.data || []))
        .filter(Boolean)
        .map(value => value as BillingReportAggregate);
    return (
        <PageLayout
            title={<PageTitle title={"Billing Overview"} />}
            pageContext={<Button onClick={on}>Generate New Report</Button>}
        >
            <CobiraTable
                sizing={"fit-page"}
                columns={COLUMNS}
                data={billingReportAggregates || []}
                isLoading={isLoading}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: navigate,
                }}
            />
            <GenerateBillingReportsModal isOpen={isGeneratingReport} onClose={off} />
        </PageLayout>
    );
};

export default BillingReportsPage;
