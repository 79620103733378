/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TimeBucket = {
    Lifetime: 'LIFETIME',
    Yearly: 'YEARLY',
    Monthly: 'MONTHLY',
    Daily: 'DAILY',
    Hourly: 'HOURLY',
    Unknown: 'UNKNOWN'
} as const;
export type TimeBucket = typeof TimeBucket[keyof typeof TimeBucket];


export function TimeBucketFromJSON(json: any): TimeBucket {
    return TimeBucketFromJSONTyped(json, false);
}

export function TimeBucketFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeBucket {
    return json as TimeBucket;
}

export function TimeBucketToJSON(value?: TimeBucket | null): any {
    return value as any;
}

