import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Textarea } from "@chakra-ui/react";
import { MessageType } from "../../api";
import { MessageTypeLabels } from "../../labels/MessageTypeLabels";

const SendSMSFormSchema = z.object({
    payload: z.string().min(1, "Payload is required"),
    messageType: z.nativeEnum(MessageType),
});

export type SendSMSFormType = z.infer<typeof SendSMSFormSchema>;

export interface SendSMSFormProps {
    onSubmit: (form: SendSMSFormType) => void;
    onCancel?: () => void;
}

const SendSMSForm = ({ onSubmit, onCancel }: SendSMSFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
    } = useForm<SendSMSFormType>({
        resolver: zodResolver(SendSMSFormSchema),
        defaultValues: {
            messageType: MessageType.Ucs2,
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Encoding"} error={errors?.messageType}>
                    <ControlledAutoCompleteSingleSelectInput
                        input={{
                            items: Object.values(MessageType),
                            displayMapping: value => MessageTypeLabels[value],
                            autocompleteAbleMapping: value => value,
                            placeholder: "Choose encoding",
                        }}
                        control={{
                            name: "messageType",
                            control: control,
                        }}
                    />
                </FormField>
                <FormField label={"Payload"} error={errors?.payload}>
                    <Textarea {...register("payload")} minH={"6rem"} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Send
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default SendSMSForm;
