import React from "react";
import { Text } from "@chakra-ui/react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";

export interface CustomerCellProps {
    customerId?: string;
}

export const CustomerCell = ({ customerId }: CustomerCellProps) => {
    const { customerApi } = useApi();
    if (!customerId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => customerApi.getCustomer({ customerId: customerId }),
                queryKey: ["customers", customerId],
            }}
            render={result => <Text>{result?.data?.customerName}</Text>}
        />
    );
};
