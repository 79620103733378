import { NodeApi } from "react-arborist";
import { TelcoRate, TelcoServiceType } from "../../../api";
import { DataUnits, MoneyUnit } from "@cobira/ui-library";

export const determineMoneyContext = (node: NodeApi<TelcoRate>): MoneyUnit | undefined => {
    function isData() {
        const rateKey = Array.from(node.data.rateKey);
        return rateKey.includes(TelcoServiceType.Data) && rateKey.length === 1;
    }

    function isSms() {
        const rateKey = Array.from(node.data.rateKey);
        return (
            rateKey.includes(TelcoServiceType.SmsMobileTerminated) ||
            rateKey.includes(TelcoServiceType.SmsMobileOriginated)
        );
    }

    if (node.data.rateDiscriminator === "SERVICE_TYPE") {
        if (isData()) {
            return [DataUnits.b.unit, DataUnits.mb.unit];
        }
        if (isSms()) {
            return "SMS";
        }
    }

    if (node.parent) {
        return determineMoneyContext(node.parent);
    } else {
        return undefined;
    }
};
