import { Controller, ControllerProps, FieldValues, Path } from "react-hook-form";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteInput, AutoCompleteInputProps } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { BillingGroup } from "../../../api";
import { useApi } from "../../../hooks/useApi";

export type ControlledBillingGroupSelectInputProps<
    IsMulti extends boolean,
    TFieldValues extends FieldValues,
    TName extends Path<TFieldValues>,
> = {
    customerIdConstraint?: string;
    control: Omit<ControllerProps<TFieldValues, TName>, "render">;
    input: Omit<
        AutoCompleteInputProps<BillingGroup, IsMulti>,
        "onChange" | "value" | "displayMapping" | "autocompleteAbleMapping" | "items"
    >;
};

export const ControlledBillingGroupSelectInput = <
    IsMulti extends boolean,
    TFieldValues extends FieldValues,
    TName extends Path<TFieldValues>,
>({
    customerIdConstraint,
    control,
    input,
}: ControlledBillingGroupSelectInputProps<IsMulti, TFieldValues, TName>) => {
    const { billingGroupApi } = useApi();

    const queryKey = useMemo(
        () => (customerIdConstraint ? ["customers", customerIdConstraint, "billinggroups"] : ["billinggroups"]),
        [customerIdConstraint],
    );

    const query = useCallback(() => {
        return customerIdConstraint
            ? billingGroupApi.getCustomerBillingGroups({
                  customerId: customerIdConstraint,
                  pageSize: 999,
                  pageNumber: 0,
              })
            : billingGroupApi.getBillingGroups({ pageNumber: 0, pageSize: 99999 });
    }, [billingGroupApi, customerIdConstraint]);

    const { data: billingGroups } = useQuery(queryKey, query);

    return (
        <Controller
            {...control}
            render={({ field: { value, onChange } }) => (
                <AutoCompleteInput
                    {...input}
                    value={value}
                    onChange={onChange}
                    autocompleteAbleMapping={value => `${value.name}`}
                    displayMapping={value => `${value.name}`}
                    items={billingGroups?.content || []}
                    isMulti={input.isMulti}
                />
            )}
        />
    );
};
