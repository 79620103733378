import React from "react";
import { RowRendererProps } from "react-arborist";
import { TelcoRate } from "../../../api";
import { Flex } from "@chakra-ui/react";

const TelcoRateRow = ({ node, children }: RowRendererProps<TelcoRate>) => {
    return (
        <Flex pl={`${node.level * 1}em`} m={2}>
            {children}
        </Flex>
    );
};

export default TelcoRateRow;
