import React from "react";
import { Text } from "@chakra-ui/react";
import { PlaceholderText } from "@cobira/ui-library";
import { NetworkAccessRateRateDiscriminatorEnum } from "../../../api";
import { NetworkCredentialTypeCell } from "../NetworkCredentialTypeCell/NetworkCredentialTypeCell";

export interface NetworkAccessRateRateKeyCellProps {
    rateDiscriminator?: NetworkAccessRateRateDiscriminatorEnum;
    rateKeys?: Set<string>;
}

export const NetworkAccessRateRateKeyCell = ({ rateKeys, rateDiscriminator }: NetworkAccessRateRateKeyCellProps) => {
    if (!rateKeys || rateKeys.size <= 0 || !rateDiscriminator) {
        return <PlaceholderText text={null} />;
    }
    if (rateDiscriminator === NetworkAccessRateRateDiscriminatorEnum.Default) {
        return <Text>Default</Text>;
    }
    if (rateDiscriminator === NetworkAccessRateRateDiscriminatorEnum.NetworkCredentialType) {
        const networkCredentialTypeCells = Array.from(rateKeys).map(networkCredentialTypeId => (
            <NetworkCredentialTypeCell
                key={networkCredentialTypeId}
                networkCredentialTypeId={networkCredentialTypeId}
            />
        ));

        return <>{networkCredentialTypeCells}</>;
    }

    return <PlaceholderText text={null} />;
};
