import React, { useCallback } from "react";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import WebhookForm, { WebhookFormType } from "../../../forms/WebhookForm/WebhookForm";
import { useApi } from "../../../hooks/useApi";
import { useMutation } from "@tanstack/react-query";
import { Integration, IntegrationApiCreateWebhookSubscriptionRequest, WebhookSubscription } from "../../../api";

export interface CreateWebhookModalProps {
    integration?: Integration;
    isOpen: boolean;
    onClose: (created: boolean) => void;
}

const CreateWebhookModal = ({ isOpen, onClose, integration }: CreateWebhookModalProps) => {
    const { integrationApi } = useApi();
    const notifications = useNotifications();

    const createWebhook = useMutation<WebhookSubscription, Error, IntegrationApiCreateWebhookSubscriptionRequest>(
        variables => integrationApi.createWebhookSubscription(variables),
        {
            onSuccess: webhook => {
                notifications.success(`Created ${webhook.name}`);
                onClose(true);
            },
        },
    );

    const handleWebhookFormSubmit = useCallback(
        (form: WebhookFormType) => {
            createWebhook.mutate({
                integrationId: integration?.id || "",
                createWebhookSubscription: {
                    name: form.name,
                    description: form.description,
                    target: form.target,
                    subscriptions: new Set(form.subscriptions),
                    maxAttempts: 10,
                    retryDelay: "PT5S",
                },
            });
        },
        [createWebhook, integration],
    );

    if (!integration) {
        return <></>;
    }

    return (
        <BasicModal isOpen={isOpen} onClose={() => onClose(false)} title={"Create webhook subscription"}>
            <WebhookForm onSubmit={handleWebhookFormSubmit} />
        </BasicModal>
    );
};

export default CreateWebhookModal;
