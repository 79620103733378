import React from "react";
import { CobiraTabs, PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import TenantNetworkCredentialTypeCostPlansPanel from "./CostPlansPanel/TenantNetworkCredentialTypeCostPlansPanel";
import TenantNetworkCredentialTypeDetailPanel from "./NetworkCredentialTypeDetailPanel/TenantNetworkCredentialTypeDetailPanel";
import { tab } from "../../../utils/tabUtils";

const TenantNetworkCredentialTypeDetailPage = () => {
    const { tenantApi } = useApi();
    const { tenantId, networkCredentialTypeId } = useParams();

    const networkCredentialTypeQuery = useQuery(
        ["tenants", tenantId, "networkcredentialtypes", networkCredentialTypeId],
        () =>
            tenantApi.getTenantNetworkCredentialType({
                tenantId: tenantId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
            }),
        {
            enabled: !!networkCredentialTypeId && !!tenantId,
        },
    );

    const tenantNetworkCredentialTypeDetailTabs = [
        tab(
            "Details",
            <TenantNetworkCredentialTypeDetailPanel query={networkCredentialTypeQuery} tenantId={tenantId} />,
        ),
        tab(
            "Cost Plans",
            <TenantNetworkCredentialTypeCostPlansPanel query={networkCredentialTypeQuery} tenantId={tenantId} />,
        ),
    ];

    return (
        <PageLayout
            title={<PageTitle title={networkCredentialTypeQuery?.data?.plmn || "Network Credential Type"} />}
            navigation={<PageBackButton text={"Back to tenant"} path={`/tenants/${tenantId}`} />}
        >
            <CobiraTabs tabs={tenantNetworkCredentialTypeDetailTabs} />
        </PageLayout>
    );
};

export default TenantNetworkCredentialTypeDetailPage;
