import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";

export interface RatePlanCellProps {
    ratePlanId?: string;
}

export const RatePlanCell = ({ ratePlanId }: RatePlanCellProps) => {
    const { ratePlanApi } = useApi();
    if (!ratePlanId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => ratePlanApi.getRatePlanById({ ratePlanId: ratePlanId }),
                queryKey: ["rateplans", ratePlanId],
            }}
            render={result => <PlaceholderText text={result?.data?.customName} />}
        />
    );
};
