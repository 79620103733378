import React from "react";
import { NodeApi } from "react-arborist";
import { HStack, Tag } from "@chakra-ui/react";
import { TelcoRate } from "../../../../api";
import { pluralize } from "@cobira/ui-library";

export interface TelcoRateNetworkHeadingProps {
    node: NodeApi<TelcoRate>;
}

const TelcoRateNetworkHeading = ({ node }: TelcoRateNetworkHeadingProps) => {
    return (
        <HStack gap={2}>
            <Tag>{pluralize("Network", Array.from(node.data.rateKey).length)}</Tag>
        </HStack>
    );
};

export default TelcoRateNetworkHeading;
