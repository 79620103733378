import React, { useEffect, useState } from "react";
import { DebugPage, JsonModal, randomFrom, useMetadata, useUserPreferences } from "@cobira/ui-library";

type ColorPreferences = {
    favoriteColor: string;
};

const MvnoDebugPage = () => {
    const { preferences, setPreferences, removePreferences } = useUserPreferences<ColorPreferences>("debugPage", {
        favoriteColor: "green",
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const metadata = useMetadata();

    useEffect(() => {
        console.log(metadata);
    }, [metadata]);

    const handleUpdatePreferences = () => {
        setPreferences({ favoriteColor: randomFrom(["blue", "green", "red", "yellow"]) });
    };

    const handleRemovePreferences = () => {
        removePreferences();
    };

    return (
        <DebugPage>
            <p>Hello, World!!</p>
            <p>Your favorite color is: {preferences.favoriteColor ?? "Don't know yet!"}</p>
            <hr />
            <button onClick={handleUpdatePreferences}>Update Preferences</button>
            <button onClick={handleRemovePreferences}>Remove Preferences</button>
            <button onClick={() => setIsModalOpen(true)}>Open Modal</button>
            <JsonModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                resource={'{"hello": "world"}'}
                header={"The JSON"}
                onCopyText={"It just works"}
            />
        </DebugPage>
    );
};

export default MvnoDebugPage;
