/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessToken } from './AccessToken';
import {
    AccessTokenFromJSON,
    AccessTokenFromJSONTyped,
    AccessTokenToJSON,
} from './AccessToken';

/**
 * 
 * @export
 * @interface AccessTokenWithSecret
 */
export interface AccessTokenWithSecret {
    /**
     * 
     * @type {AccessToken}
     * @memberof AccessTokenWithSecret
     */
    tokenDetails: AccessToken;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenWithSecret
     */
    secret: string;
}

/**
 * Check if a given object implements the AccessTokenWithSecret interface.
 */
export function instanceOfAccessTokenWithSecret(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tokenDetails" in value;
    isInstance = isInstance && "secret" in value;

    return isInstance;
}

export function AccessTokenWithSecretFromJSON(json: any): AccessTokenWithSecret {
    return AccessTokenWithSecretFromJSONTyped(json, false);
}

export function AccessTokenWithSecretFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessTokenWithSecret {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tokenDetails': AccessTokenFromJSON(json['tokenDetails']),
        'secret': json['secret'],
    };
}

export function AccessTokenWithSecretToJSON(value?: AccessTokenWithSecret | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tokenDetails': AccessTokenToJSON(value.tokenDetails),
        'secret': value.secret,
    };
}

