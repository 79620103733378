import { useParams } from "react-router";
import { PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import DataUsageRecordDetails from "../../../../components/Usage/DataUsageRecordDetails";

const SimCardDataUsageDetailPage = () => {
    const { usageRecordId, icc } = useParams();
    const { usageRecordApi } = useApi();

    const dataUsageQuery = useQuery(
        ["usage-records", "data", usageRecordId],
        () => usageRecordApi.getDataUsageRecord({ usageRecordId: usageRecordId || "" }),
        {
            enabled: !!usageRecordId,
        },
    );
    return (
        <PageLayout
            title={<PageTitle title={"Usage Record Details"} />}
            navigation={<PageBackButton text={"Back to SIM Card"} path={`/simcards/${icc}?tab=1`} />}
        >
            <DataUsageRecordDetails query={dataUsageQuery} />
        </PageLayout>
    );
};

export default SimCardDataUsageDetailPage;
