import { SimCardActionRequestFilter } from "../../../api";
import ChangeStateSimCardsModal from "../ChangeStateSimCardsModal/ChangeStateSimCardsModal";
import SetImeiLockStateModal from "../SetImeiLockStateModal/SetImeiLockStateModal";
import SendSMSModal from "../SendSMSModal/SendSMSModal";
import AssignUsagePackageModal from "../AssignUsagePackageModal/AssignUsagePackageModal";
import RevokeUsagePackageModal from "../RevokeUsagePackageModal/RevokeUsagePackageModal";
import MoveSimCardModal from "../MoveSimCardModal/MoveSimCardModal";
import SetOverageStateModal from "../SetOverageStateModal/SetOverageStateModal";
import React from "react";
import SetImeiLockModal from "../SetImeiLockModal/SetImeiLockModal";

export type SimActionType =
    | "activate"
    | "suspend"
    | "reset"
    | "imei-lock-state"
    | "imei-lock"
    | "assign-usage-package"
    | "send-sms"
    | "revoke-usage-package"
    | "move-sim-cards"
    | "set-overage-state";

export interface SimCardActionModalsProps {
    actionType: SimActionType | null;
    filter: SimCardActionRequestFilter;
    onClose: () => void;
}

const SimCardActionModals = ({ actionType, filter, onClose }: SimCardActionModalsProps) => {
    if (actionType === null) {
        return null;
    }

    switch (actionType) {
        case "activate":
            return <ChangeStateSimCardsModal filter={filter} isOpen onClose={onClose} targetState={"ACTIVATE"} />;
        case "suspend":
            return <ChangeStateSimCardsModal filter={filter} isOpen onClose={onClose} targetState={"SUSPEND"} />;
        case "reset":
            return <ChangeStateSimCardsModal filter={filter} isOpen onClose={onClose} targetState={"RESET"} />;
        case "imei-lock-state":
            return <SetImeiLockStateModal filter={filter} isOpen onClose={onClose} />;
        case "imei-lock":
            if (filter.iccSet?.size === 1) {
                return <SetImeiLockModal icc={filter.iccSet.values().next().value} isOpen onClose={onClose} />;
            } else {
                return null;
            }
        case "assign-usage-package":
            return <AssignUsagePackageModal filter={filter} isOpen onClose={onClose} />;
        case "send-sms":
            return <SendSMSModal filter={filter} isOpen onClose={onClose} />;
        case "revoke-usage-package":
            return <RevokeUsagePackageModal filter={filter} isOpen onClose={onClose} />;
        case "move-sim-cards":
            return <MoveSimCardModal filter={filter} isOpen onClose={onClose} />;
        case "set-overage-state":
            return <SetOverageStateModal filter={filter} isOpen onClose={onClose} />;
    }
};

export default SimCardActionModals;
