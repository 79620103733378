import { AutoCompleteInput, SearchMenuIcon } from "@cobira/ui-library";
import React, { useState } from "react";
import { Button, HStack } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { Customer } from "../../../api";

export interface CustomerCompareSelectionInputProps {
    onChange: (customers: Customer[]) => void;
}

const CustomerCompareSelectionInput = ({ onChange }: CustomerCompareSelectionInputProps) => {
    const { customerApi } = useApi();
    const [customerSearchValue, setCustomerSearchValue] = useState<string | null>(null);
    const { data: customers, isLoading } = useQuery(["customers", customerSearchValue], () =>
        customerApi.getCustomers({
            search: customerSearchValue || undefined,
            pageNumber: 0,
            pageSize: 50,
        }),
    );
    const [customerSelection, setCustomerSelection] = useState<Customer[]>([]);

    const isDisabled = !(customerSelection.length >= 1);

    return (
        <HStack gap={4}>
            <AutoCompleteInput
                items={customers?.content || []}
                displayMapping={customer => customer.customerName}
                autocompleteAbleMapping={customer => customer.customerName}
                isMulti={true}
                onChange={setCustomerSelection}
                value={customerSelection}
                async={{ onInputChange: setCustomerSearchValue, isLoading }}
                isClearable
                placeholder={"Type to search and filter customers"}
                inputStyles={{ bg: "white", variant: "outline" }}
            />
            <Button
                leftIcon={<SearchMenuIcon />}
                isDisabled={isDisabled}
                onClick={() => onChange(customerSelection)}
                px={"8"}
            >
                Compare selection
            </Button>
        </HStack>
    );
};

export default CustomerCompareSelectionInput;
