import React, { useMemo } from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { Customer, NetworkCredentialType, UsagePackageType } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import {
    CobiraTable,
    Country,
    CountryInfo,
    DataPackageAllowance,
    Duration,
    getCountries,
    PlaceholderText,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    SmsPackageAllowance,
    usePageNavigation,
    usePagination,
    usePersistedSearch,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { createColumnHelper } from "@tanstack/react-table";
import { UsagePackageAutomaticActivationTypeLabels } from "../../../../labels/UsagePackageAutomaticActivationTypeLabels";
import { BooleanLabels } from "../../../../labels/BooleanLabels";
import { mapBooleanFilter } from "../../../../utils/mapperUtils";
import { SearchSelectNetworkCredentialTypeInput } from "../../../../components/SearchSelectNetworkCredentialTypeInput/SearchSelectNetworkCredentialTypeInput";

interface CustomerPackageCataloguePanelProps {
    query: UseQueryResult<Customer>;
}

const customerUsagePackageColumn = createColumnHelper<UsagePackageType>();
const COLUMNS = [
    customerUsagePackageColumn.accessor("customName", {
        id: "usagePackageTypeName",
        header: "Name",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    customerUsagePackageColumn.accessor("description", {
        id: "usagePackageTypeDescription",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    customerUsagePackageColumn.accessor("dataUsageAllowance", {
        id: "dataUsageAllowance",
        header: "Data Allowance",
        cell: row => (
            <DataPackageAllowance
                allowance={row.getValue().dataAllowance || undefined}
                enabled={row.getValue().enabled}
            />
        ),
    }),
    customerUsagePackageColumn.accessor("smsUsageAllowance", {
        id: "smsUsageAllowance",
        header: "SMS Allowance",
        cell: row => (
            <SmsPackageAllowance
                allowance={row.getValue().smsAllowance || undefined}
                enabled={row.getValue().enabled}
            />
        ),
    }),
    customerUsagePackageColumn.accessor("packageDuration", {
        id: "usagePackageTypeDuration",
        header: "Duration",
        cell: row => <Duration value={row.getValue()} />,
    }),
    customerUsagePackageColumn.accessor("automaticActivationType", {
        id: "automaticActivationType",
        header: "Activation",
        cell: row => UsagePackageAutomaticActivationTypeLabels[row.getValue()],
    }),
    customerUsagePackageColumn.accessor("supportedCountries", {
        id: "supportedCountries",
        header: "Countries",
        cell: row => row.getValue().length,
    }),
];

const CustomerPackageCataloguePanel = ({ query: { data: value } }: CustomerPackageCataloguePanelProps) => {
    const { usagePackageApi } = useApi();
    const { navigate } = usePageNavigation<UsagePackageType>({
        route: value => `/customers/${value.id}/bundles/${value.id}`,
    });
    const countries = useMemo(() => getCountries(), []);
    const { setPageState, resetPageState, pageState } = usePagination({ pageIndex: 0, pageSize: 20 });
    const search = usePersistedSearch({
        persistenceKey: "customer-usage-package-type-catalogue-search",
        config: {
            search: singleInput<string>("Search"),
            country: singleInput<Country>("Country", { display: value => `${value.name} (${value.iso2})` }),
            includeDeleted: singleInput<boolean>("Include Deleted", {
                display: value => BooleanLabels(value),
            }),
            dataPackageEnabled: singleInput<boolean>("Data Enabled", {
                display: value => BooleanLabels(value),
            }),
            smsPackageEnabled: singleInput<boolean>("SMS Enabled", {
                display: value => BooleanLabels(value),
            }),
            assigned: singleInput<boolean>("Assigned to SIM", {
                display: value => BooleanLabels(value),
            }),
            networkCredentialType: singleInput<NetworkCredentialType>("Network Credential Type", {
                display: value => `${value.imsiProvider}`,
            }),
        },
        onChange: resetPageState,
    });

    const { data: customerContacts, isLoading: isLoadingCustomerUsagePackages } = useQuery(
        ["customers", value?.id, "usagepackagetypes", pageState, search.state],
        () =>
            usagePackageApi.getCustomerUsagePackageTypes({
                customerId: value?.id || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                search: search.state?.search?.value || undefined,
                country: search.state?.country?.value?.iso2 || undefined,
                includeDeleted: mapBooleanFilter(search.state?.includeDeleted?.value),
                dataPackageEnabled: mapBooleanFilter(search.state?.dataPackageEnabled?.value),
                smsPackageEnabled: mapBooleanFilter(search.state?.smsPackageEnabled?.value),
                usagePackageTypeAssigned: mapBooleanFilter(search.state?.assigned?.value),
                networkCredentialType: search.state?.networkCredentialType?.value?.id,
            }),
        { enabled: value?.id !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Quick find..."}
                                />
                            ),
                        },
                        {
                            id: "country",
                            menuLabel: "Country",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "country" })}
                                    items={countries}
                                    autocompleteAbleMapping={value => `${value.name} (${value.iso2})`}
                                    displayMapping={value => <CountryInfo country={value} />}
                                />
                            ),
                        },
                        {
                            id: "includeDeleted",
                            menuLabel: "Include Deleted",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "includeDeleted" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "dataPackageEnabled",
                            menuLabel: "Data Enabled",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "dataPackageEnabled" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "smsPackageEnabled",
                            menuLabel: "SMS Enabled",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "smsPackageEnabled" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "inUse",
                            menuLabel: "Assigned to SIM",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "assigned" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "networkCredentialType",
                            menuLabel: "Network Credential Type",
                            inputComponent: (
                                <SearchSelectNetworkCredentialTypeInput
                                    registration={search.registerInput({ id: "networkCredentialType" })}
                                />
                            ),
                        },
                    ]}
                />
                <CobiraTable
                    columns={COLUMNS}
                    data={customerContacts?.content || []}
                    isLoading={isLoadingCustomerUsagePackages}
                    withPagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: customerContacts?.pageProperties?.totalElements || 0,
                        totalPageCount: customerContacts?.pageProperties?.totalPages || 0,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigate,
                    }}
                />
            </VStack>
        </TabPanel>
    );
};

export default CustomerPackageCataloguePanel;
