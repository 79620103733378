import React from "react";
import { TelcoRate } from "../../../../api";
import { Text } from "@chakra-ui/react";

export interface TelcoRateDefaultDetailProps {
    telcoRate: TelcoRate;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TelcoRateDefaultDetail = ({ telcoRate }: TelcoRateDefaultDetailProps) => {
    return (
        <Text>
            The default rate will be applied in case no other matching rules are configured, or matched during the
            rating process. It is in place to ensure that everything will be rated properly.
        </Text>
    );
};

export default TelcoRateDefaultDetail;
