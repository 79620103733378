/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        ConsumptionState,
        CreateCustomer,
        CreateCustomerContact,
        CreateCustomerRecurringFee,
        Customer,
        CustomerContact,
        CustomerPage,
        CustomerRecurringFee,
        CustomerRecurringFeePage,
        CustomerStates,
        UpdateCustomer,
        UpdateCustomerRecurringFee,
    } from '../models';
        import {
            ConsumptionStateFromJSON,
            ConsumptionStateToJSON,
            CreateCustomerFromJSON,
            CreateCustomerToJSON,
            CreateCustomerContactFromJSON,
            CreateCustomerContactToJSON,
            CreateCustomerRecurringFeeFromJSON,
            CreateCustomerRecurringFeeToJSON,
            CustomerFromJSON,
            CustomerToJSON,
            CustomerContactFromJSON,
            CustomerContactToJSON,
            CustomerPageFromJSON,
            CustomerPageToJSON,
            CustomerRecurringFeeFromJSON,
            CustomerRecurringFeeToJSON,
            CustomerRecurringFeePageFromJSON,
            CustomerRecurringFeePageToJSON,
            CustomerStatesFromJSON,
            CustomerStatesToJSON,
            UpdateCustomerFromJSON,
            UpdateCustomerToJSON,
            UpdateCustomerRecurringFeeFromJSON,
            UpdateCustomerRecurringFeeToJSON,
        } from '../models';

            export interface CustomerApiCreateCustomerRequest {
                createCustomer: CreateCustomer;
            }

            export interface CustomerApiCreateCustomerContactRequest {
                customerId: string;
                createCustomerContact: CreateCustomerContact;
            }

            export interface CustomerApiCreateCustomerRecurringFeeRequest {
                customerId: string;
                createCustomerRecurringFee: CreateCustomerRecurringFee;
            }

            export interface CustomerApiDeleteCustomerContactRequest {
                customerId: string;
                contactId: string;
            }

            export interface CustomerApiDeleteCustomerRecurringFeeRequest {
                customerId: string;
                customerRecurringFeeId: string;
            }

            export interface CustomerApiDeleteCustomerUsagePackageTypeRequest {
                customerId: string;
                usagePackageTypeId: string;
            }

            export interface CustomerApiGetCustomerRequest {
                customerId: string;
            }

            export interface CustomerApiGetCustomerContactsRequest {
                customerId: string;
            }

            export interface CustomerApiGetCustomerRecurringFeeRequest {
                customerId: string;
                customerRecurringFeeId: string;
            }

            export interface CustomerApiGetCustomerRecurringFeesRequest {
                customerId: string;
                pageSize: number;
                pageNumber: number;
            }

            export interface CustomerApiGetCustomersRequest {
                pageNumber: number;
                pageSize: number;
                search?: string;
                consumptionState?: ConsumptionState;
                customerState?: CustomerStates;
            }

            export interface CustomerApiUpdateCustomerRequest {
                customerId: string;
                updateCustomer: UpdateCustomer;
            }

            export interface CustomerApiUpdateCustomerRecurringFeeRequest {
                customerId: string;
                customerRecurringFeeId: string;
                updateCustomerRecurringFee: UpdateCustomerRecurringFee;
            }

        /**
        * 
        */
            export class CustomerApi extends runtime.BaseAPI {

            /**
                * Create a new customer.
                * Create new customer
            */
            async createCustomerRaw(requestParameters: CustomerApiCreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Customer>> {
                    if (requestParameters.createCustomer === null || requestParameters.createCustomer === undefined) {
                    throw new runtime.RequiredError('createCustomer','Required parameter requestParameters.createCustomer was null or undefined when calling createCustomer.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateCustomerToJSON(requestParameters.createCustomer),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
            }

            /**
                * Create a new customer.
                * Create new customer
            */
                async createCustomer(requestParameters: CustomerApiCreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Customer> {
                    const response = await this.createCustomerRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create a new Customer Contact. The email is considered to be unique and cannot be re-used across contacts.
                * Create Customer Contact
            */
            async createCustomerContactRaw(requestParameters: CustomerApiCreateCustomerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerContact>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createCustomerContact.');
                    }

                    if (requestParameters.createCustomerContact === null || requestParameters.createCustomerContact === undefined) {
                    throw new runtime.RequiredError('createCustomerContact','Required parameter requestParameters.createCustomerContact was null or undefined when calling createCustomerContact.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/contacts`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateCustomerContactToJSON(requestParameters.createCustomerContact),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerContactFromJSON(jsonValue));
            }

            /**
                * Create a new Customer Contact. The email is considered to be unique and cannot be re-used across contacts.
                * Create Customer Contact
            */
                async createCustomerContact(requestParameters: CustomerApiCreateCustomerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerContact> {
                    const response = await this.createCustomerContactRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create a new customer recurring fee. The created fee will be applied during the next invoice computation, if the specified recurrence interval is reached based on the anniversary date.
                * Create Customer Recurring Fee
            */
            async createCustomerRecurringFeeRaw(requestParameters: CustomerApiCreateCustomerRecurringFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerRecurringFee>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createCustomerRecurringFee.');
                    }

                    if (requestParameters.createCustomerRecurringFee === null || requestParameters.createCustomerRecurringFee === undefined) {
                    throw new runtime.RequiredError('createCustomerRecurringFee','Required parameter requestParameters.createCustomerRecurringFee was null or undefined when calling createCustomerRecurringFee.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/recurringfees`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateCustomerRecurringFeeToJSON(requestParameters.createCustomerRecurringFee),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerRecurringFeeFromJSON(jsonValue));
            }

            /**
                * Create a new customer recurring fee. The created fee will be applied during the next invoice computation, if the specified recurrence interval is reached based on the anniversary date.
                * Create Customer Recurring Fee
            */
                async createCustomerRecurringFee(requestParameters: CustomerApiCreateCustomerRecurringFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerRecurringFee> {
                    const response = await this.createCustomerRecurringFeeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Delete a customer contact.
                * Delete Customer Contact
            */
            async deleteCustomerContactRaw(requestParameters: CustomerApiDeleteCustomerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteCustomerContact.');
                    }

                    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
                    throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteCustomerContact.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/contacts/{contactId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Delete a customer contact.
                * Delete Customer Contact
            */
                async deleteCustomerContact(requestParameters: CustomerApiDeleteCustomerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteCustomerContactRaw(requestParameters, initOverrides);
                }

            /**
                * Delete customer recurring fee. Once deleted, the recurring fee will not be billed for future invoices.
                * Delete Customer Recurring Fee
            */
            async deleteCustomerRecurringFeeRaw(requestParameters: CustomerApiDeleteCustomerRecurringFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteCustomerRecurringFee.');
                    }

                    if (requestParameters.customerRecurringFeeId === null || requestParameters.customerRecurringFeeId === undefined) {
                    throw new runtime.RequiredError('customerRecurringFeeId','Required parameter requestParameters.customerRecurringFeeId was null or undefined when calling deleteCustomerRecurringFee.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/recurringfees/{customerRecurringFeeId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"customerRecurringFeeId"}}`, encodeURIComponent(String(requestParameters.customerRecurringFeeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Delete customer recurring fee. Once deleted, the recurring fee will not be billed for future invoices.
                * Delete Customer Recurring Fee
            */
                async deleteCustomerRecurringFee(requestParameters: CustomerApiDeleteCustomerRecurringFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteCustomerRecurringFeeRaw(requestParameters, initOverrides);
                }

            /**
                * Remove a usage package type from the customer catalogue.  This will disallow that customer from utilizing that usage package type.
                * Delete Customer Usage Package Type
            */
            async deleteCustomerUsagePackageTypeRaw(requestParameters: CustomerApiDeleteCustomerUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteCustomerUsagePackageType.');
                    }

                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling deleteCustomerUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Remove a usage package type from the customer catalogue.  This will disallow that customer from utilizing that usage package type.
                * Delete Customer Usage Package Type
            */
                async deleteCustomerUsagePackageType(requestParameters: CustomerApiDeleteCustomerUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteCustomerUsagePackageTypeRaw(requestParameters, initOverrides);
                }

            /**
                * Get a single customer.
                * Get customer
            */
            async getCustomerRaw(requestParameters: CustomerApiGetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Customer>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomer.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
            }

            /**
                * Get a single customer.
                * Get customer
            */
                async getCustomer(requestParameters: CustomerApiGetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Customer> {
                    const response = await this.getCustomerRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all customer contacts.
                * Get Customer Contact
            */
            async getCustomerContactsRaw(requestParameters: CustomerApiGetCustomerContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerContact>>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerContacts.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/contacts`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerContactFromJSON));
            }

            /**
                * Get all customer contacts.
                * Get Customer Contact
            */
                async getCustomerContacts(requestParameters: CustomerApiGetCustomerContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerContact>> {
                    const response = await this.getCustomerContactsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch a single customer recurring fee.
                * Get Customer Recurring Fee
            */
            async getCustomerRecurringFeeRaw(requestParameters: CustomerApiGetCustomerRecurringFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerRecurringFee>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerRecurringFee.');
                    }

                    if (requestParameters.customerRecurringFeeId === null || requestParameters.customerRecurringFeeId === undefined) {
                    throw new runtime.RequiredError('customerRecurringFeeId','Required parameter requestParameters.customerRecurringFeeId was null or undefined when calling getCustomerRecurringFee.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/recurringfees/{customerRecurringFeeId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"customerRecurringFeeId"}}`, encodeURIComponent(String(requestParameters.customerRecurringFeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerRecurringFeeFromJSON(jsonValue));
            }

            /**
                * Fetch a single customer recurring fee.
                * Get Customer Recurring Fee
            */
                async getCustomerRecurringFee(requestParameters: CustomerApiGetCustomerRecurringFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerRecurringFee> {
                    const response = await this.getCustomerRecurringFeeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch all recurring fees added to the customer. The recurring fees are billed to their invoice on the negotiated recurrence interval. 
                * Get Customer Recurring Fees
            */
            async getCustomerRecurringFeesRaw(requestParameters: CustomerApiGetCustomerRecurringFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerRecurringFeePage>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerRecurringFees.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCustomerRecurringFees.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCustomerRecurringFees.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/recurringfees`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerRecurringFeePageFromJSON(jsonValue));
            }

            /**
                * Fetch all recurring fees added to the customer. The recurring fees are billed to their invoice on the negotiated recurrence interval. 
                * Get Customer Recurring Fees
            */
                async getCustomerRecurringFees(requestParameters: CustomerApiGetCustomerRecurringFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerRecurringFeePage> {
                    const response = await this.getCustomerRecurringFeesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all customers.
                * Get customers
            */
            async getCustomersRaw(requestParameters: CustomerApiGetCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCustomers.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCustomers.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.consumptionState !== undefined) {
                            queryParameters['consumptionState'] = requestParameters.consumptionState;
                    }

                    if (requestParameters.customerState !== undefined) {
                            queryParameters['customerState'] = requestParameters.customerState;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPageFromJSON(jsonValue));
            }

            /**
                * Get all customers.
                * Get customers
            */
                async getCustomers(requestParameters: CustomerApiGetCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerPage> {
                    const response = await this.getCustomersRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Replace information on a customer with a new representation.
                * Update customer
            */
            async updateCustomerRaw(requestParameters: CustomerApiUpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Customer>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateCustomer.');
                    }

                    if (requestParameters.updateCustomer === null || requestParameters.updateCustomer === undefined) {
                    throw new runtime.RequiredError('updateCustomer','Required parameter requestParameters.updateCustomer was null or undefined when calling updateCustomer.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateCustomerToJSON(requestParameters.updateCustomer),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
            }

            /**
                * Replace information on a customer with a new representation.
                * Update customer
            */
                async updateCustomer(requestParameters: CustomerApiUpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Customer> {
                    const response = await this.updateCustomerRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Updates price and recurrence options for the customer recurring fee. The update will be reflected during the next invoice computation.
                * Update Customer Recurring Fee
            */
            async updateCustomerRecurringFeeRaw(requestParameters: CustomerApiUpdateCustomerRecurringFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerRecurringFee>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateCustomerRecurringFee.');
                    }

                    if (requestParameters.customerRecurringFeeId === null || requestParameters.customerRecurringFeeId === undefined) {
                    throw new runtime.RequiredError('customerRecurringFeeId','Required parameter requestParameters.customerRecurringFeeId was null or undefined when calling updateCustomerRecurringFee.');
                    }

                    if (requestParameters.updateCustomerRecurringFee === null || requestParameters.updateCustomerRecurringFee === undefined) {
                    throw new runtime.RequiredError('updateCustomerRecurringFee','Required parameter requestParameters.updateCustomerRecurringFee was null or undefined when calling updateCustomerRecurringFee.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/recurringfees/{customerRecurringFeeId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"customerRecurringFeeId"}}`, encodeURIComponent(String(requestParameters.customerRecurringFeeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateCustomerRecurringFeeToJSON(requestParameters.updateCustomerRecurringFee),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CustomerRecurringFeeFromJSON(jsonValue));
            }

            /**
                * Updates price and recurrence options for the customer recurring fee. The update will be reflected during the next invoice computation.
                * Update Customer Recurring Fee
            */
                async updateCustomerRecurringFee(requestParameters: CustomerApiUpdateCustomerRecurringFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerRecurringFee> {
                    const response = await this.updateCustomerRecurringFeeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
