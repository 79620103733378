export const RecurrenceLabels = (recurrence?: number) => {
    if (!recurrence) {
        return "Never";
    }
    if (recurrence === 1) {
        return "Billed every month";
    }
    if (recurrence === 2) {
        return "Billed every 2nd month";
    }
    if (recurrence === 3) {
        return "Billed every 3rd month";
    }
    if (recurrence === 12) {
        return "Billed once a year";
    }
    return `Billed every ${recurrence}th month`;
};
