import React, { FC } from "react";
import { SidebarHeader } from "@cobira/ui-library";

interface MvnoSidebarHeaderProps {}

const MvnoSidebarHeader: FC<MvnoSidebarHeaderProps> = () => {
    return <SidebarHeader logoSrc={"/branding/logo.svg"} />;
};

export default MvnoSidebarHeader;
