/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Provisioning states for a sim card. 
 * If DISABLED, the sim card can no longer be used.
 * PENDING sim cards however require action from your network operator.
 * 
 * If you encounter the UNKNOWN state, please contact support.
 * @export
 */
export const SimCardProvisioningState = {
    Pending: 'PENDING',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Unknown: 'UNKNOWN'
} as const;
export type SimCardProvisioningState = typeof SimCardProvisioningState[keyof typeof SimCardProvisioningState];


export function SimCardProvisioningStateFromJSON(json: any): SimCardProvisioningState {
    return SimCardProvisioningStateFromJSONTyped(json, false);
}

export function SimCardProvisioningStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardProvisioningState {
    return json as SimCardProvisioningState;
}

export function SimCardProvisioningStateToJSON(value?: SimCardProvisioningState | null): any {
    return value as any;
}

