import React from "react";
import { Currency, TelcoServiceType } from "../../api";
import { DataUnits, Money } from "@cobira/ui-library";

export interface TelcoCostPriceProps {
    cost: number;
    currency: Currency;
    serviceType: TelcoServiceType;
}

const TelcoCostPrice = ({ cost, currency, serviceType }: TelcoCostPriceProps) => {
    switch (serviceType) {
        case "SMS_MOBILE_TERMINATED":
        case "SMS_MOBILE_ORIGINATED":
            return <Money unit={"SMS"} currency={currency} value={cost} />;
        case "DATA":
            return <Money unit={[DataUnits.b.unit, DataUnits.mb.unit]} currency={currency} value={cost} />;
        default:
            return (
                <>
                    <Money currency={currency} value={cost} /> / {serviceType}
                </>
            );
    }
};

export default TelcoCostPrice;
