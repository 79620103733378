/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';

/**
 * Describes properties for assigning sim cards to either a tenant or moving to a billing group.
 * One of the two must be specified in order for the operation to be accepted.
 * @export
 * @interface AssignSimCards
 */
export interface AssignSimCards {
    /**
     * Tenant Unique ID
     * @type {string}
     * @memberof AssignSimCards
     */
    tenantId?: string | null;
    /**
     * Billing Group UniqueId
     * @type {string}
     * @memberof AssignSimCards
     */
    billingGroupId?: string | null;
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof AssignSimCards
     */
    filter: SimCardActionRequestFilter;
}

/**
 * Check if a given object implements the AssignSimCards interface.
 */
export function instanceOfAssignSimCards(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filter" in value;

    return isInstance;
}

export function AssignSimCardsFromJSON(json: any): AssignSimCards {
    return AssignSimCardsFromJSONTyped(json, false);
}

export function AssignSimCardsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignSimCards {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'billingGroupId': !exists(json, 'billingGroupId') ? undefined : json['billingGroupId'],
        'filter': SimCardActionRequestFilterFromJSON(json['filter']),
    };
}

export function AssignSimCardsToJSON(value?: AssignSimCards | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'billingGroupId': value.billingGroupId,
        'filter': SimCardActionRequestFilterToJSON(value.filter),
    };
}

