import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { Customer, CustomerApiDeleteCustomerContactRequest, CustomerContact } from "../../../api";

export interface ConfirmDeleteContactModalProps {
    onClose: () => void;
    customer?: Customer;
    contact?: CustomerContact;
}

const ConfirmDeleteContactModal = ({ onClose, contact, customer }: ConfirmDeleteContactModalProps) => {
    const { customerApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteContact = useMutation<void, Error, CustomerApiDeleteCustomerContactRequest>(variables =>
        customerApi.deleteCustomerContact(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && contact?.id && customer?.id) {
                deleteContact.mutate(
                    { customerId: customer.id, contactId: contact.id },
                    {
                        onSuccess: () => {
                            notifications.success("Contact deleted");
                            queryClient.invalidateQueries(["customers", customer?.id, "contacts"]);
                        },
                    },
                );
            }
            onClose();
        },
        [customer, contact, deleteContact, notifications, onClose, queryClient],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!contact}
            title={"Delete Contact"}
            description={
                <Container>
                    <Text>Are you sure you want to delete the contact?</Text>
                    <Text as={"b"}>{contact?.name}</Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteContactModal;
