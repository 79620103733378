import React, { useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi";

enum AssignmentType {
    TENANT = "TENANT",
    BILLING_GROUP = "BILLING_GROUP",
}

const AssignSimCardFormSchema = z
    .object({
        assignmentType: z.nativeEnum(AssignmentType),
        tenantAssignment: z
            .object({
                name: z.string(),
                id: z.string(),
            })
            .optional(),
        billingGroupAssignmentCustomer: z
            .object({
                customerName: z.string(),
                id: z.string(),
            })
            .optional(),
        billingGroupAssignmentBillingGroup: z
            .object({
                name: z.string(),
                id: z.string(),
            })
            .optional(),
    })
    .refine(
        arg => {
            if (arg.assignmentType === AssignmentType.TENANT) {
                return arg.tenantAssignment !== undefined;
            }
            return true;
        },
        { message: "Tenant must be selected", path: ["tenantAssignment"] },
    )
    .refine(
        arg => {
            if (arg.assignmentType === AssignmentType.BILLING_GROUP) {
                return arg.billingGroupAssignmentCustomer !== undefined;
            }
            return true;
        },
        { message: "Customer must be selected", path: ["billingGroupAssignmentCustomer"] },
    )
    .refine(
        arg => {
            if (arg.assignmentType === AssignmentType.BILLING_GROUP) {
                return arg.billingGroupAssignmentBillingGroup !== undefined;
            }
            return true;
        },
        { message: "Billing Group must be selected", path: ["billingGroupAssignmentBillingGroup"] },
    );

export type AssignSimCardFormSchemaType = z.infer<typeof AssignSimCardFormSchema>;

export interface SelectUsagePackageFormProps {
    onSubmit: (form: AssignSimCardFormSchemaType) => void;
    onCancel?: () => void;
}

const AssignSimCardForm = ({ onSubmit, onCancel }: SelectUsagePackageFormProps) => {
    const { tenantApi, customerApi, billingGroupApi } = useApi();
    const [customerSearchInput, setCustomerSearchInput] = useState<string | undefined>(undefined);
    const { data: tenants } = useQuery(["tenants"], () => tenantApi.getTenants({ pageSize: 9999, pageNumber: 0 }));
    const { data: customers, isLoading: isLoadingCustomers } = useQuery(["customers", customerSearchInput], () =>
        customerApi.getCustomers({ pageSize: 9999, pageNumber: 0, search: customerSearchInput }),
    );
    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
    } = useForm<AssignSimCardFormSchemaType>({
        resolver: zodResolver(AssignSimCardFormSchema),
    });
    const selectedCustomer = watch("billingGroupAssignmentCustomer");
    const selectedAssignmentType = watch("assignmentType");

    const { data: billingGroups } = useQuery(
        ["customers", selectedCustomer?.id, "billinggroups"],
        () =>
            billingGroupApi.getCustomerBillingGroups({
                pageSize: 9999,
                pageNumber: 0,
                customerId: selectedCustomer?.id || "",
            }),
        { enabled: !!selectedCustomer?.id },
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Choose Assignment Type"}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: "assignmentType" }}
                        input={{
                            items: Object.values(AssignmentType),
                            isClearable: false,
                            displayMapping: value => (value === "TENANT" ? "Tenant" : "Billing Group"),
                            autocompleteAbleMapping: value => (value === "TENANT" ? "Tenant" : "Billing Group"),
                        }}
                    />
                </FormField>
                {selectedAssignmentType === AssignmentType.TENANT && (
                    <FormField label={"Select Tenant"} error={errors?.tenantAssignment}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{ control: control, name: "tenantAssignment" }}
                            input={{
                                items: tenants?.content || [],
                                displayMapping: value => value.name,
                                autocompleteAbleMapping: value => value.name,
                            }}
                        />
                    </FormField>
                )}
                {selectedAssignmentType === AssignmentType.BILLING_GROUP && (
                    <>
                        <FormField label={"Select Customer"} error={errors?.billingGroupAssignmentCustomer}>
                            <ControlledAutoCompleteSingleSelectInput
                                control={{ control: control, name: "billingGroupAssignmentCustomer" }}
                                input={{
                                    async: {
                                        onInputChange: value => setCustomerSearchInput(value || undefined),
                                        isLoading: isLoadingCustomers,
                                    },
                                    items: customers?.content || [],
                                    displayMapping: value => value.customerName,
                                    autocompleteAbleMapping: value => value.customerName,
                                }}
                            />
                        </FormField>
                        <FormField label={"Select Billing Group"} error={errors?.billingGroupAssignmentBillingGroup}>
                            <ControlledAutoCompleteSingleSelectInput
                                input={{
                                    items: billingGroups?.content || [],
                                    displayMapping: value => value.name,
                                    autocompleteAbleMapping: value => value?.name || "",
                                    placeholder: "Choose Billing Group",
                                }}
                                control={{
                                    name: "billingGroupAssignmentBillingGroup",
                                    control: control,
                                }}
                            />
                        </FormField>
                    </>
                )}
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Confirm
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default AssignSimCardForm;
