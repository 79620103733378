import {
    CobiraTable,
    Money,
    Month,
    NotFound,
    PageBackButton,
    PageLayout,
    PageTitle,
    usePageNavigation,
    YearMonth,
} from "@cobira/ui-library";
import React from "react";
import { useParams } from "react-router";
import { useAggregatedBillingReports } from "../../hooks/useAggregatedBillingReports";
import { useUserSettings } from "../../hooks/useUserSettings";
import { MonthLabels } from "../../labels/MonthLabels";
import { createColumnHelper } from "@tanstack/react-table";
import { AggregatedBillingReport } from "../../utils/billingReportUtils";
import { CustomerCell } from "../../components/Cells/CustomerCell/CustomerCell";

const billingReportColumn = createColumnHelper<AggregatedBillingReport>();
const COLUMNS = [
    billingReportColumn.accessor("customerId", {
        id: "customer",
        header: "Customer",
        cell: row => <CustomerCell customerId={row.getValue()} />,
    }),
    billingReportColumn.accessor(row => row, {
        id: "totalFeeCost",
        header: "Fee Costs",
        meta: {
            isNumeric: true,
        },
        cell: row => <Money value={row.getValue().totalFeeCost} currency={row.getValue().currency} decimals={2} />,
    }),
    billingReportColumn.accessor(row => row, {
        id: "totalUsageCosts",
        header: "Usage Costs",
        meta: {
            isNumeric: true,
        },
        cell: row => <Money value={row.getValue().totalUsageCost} currency={row.getValue().currency} decimals={2} />,
    }),
    billingReportColumn.accessor(row => row, {
        id: "totalFeeBillable",
        header: "Billable Fees",
        meta: {
            isNumeric: true,
        },
        cell: row => <Money value={row.getValue().totalFeeBillable} currency={row.getValue().currency} decimals={2} />,
    }),
    billingReportColumn.accessor(row => row, {
        id: "totalUsageBillable",
        header: "Billable Usage",
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <Money value={row.getValue().totalUsageBillable} currency={row.getValue().currency} decimals={2} />
        ),
    }),
    billingReportColumn.accessor(row => row, {
        id: "margin",
        header: "Margin",
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <Money
                value={
                    row.getValue().totalFeeBillable +
                    row.getValue().totalUsageBillable -
                    row.getValue().totalFeeCost -
                    row.getValue().totalUsageCost
                }
                currency={row.getValue().currency}
                decimals={2}
            />
        ),
    }),
];

const getYearMonthFromParams = (year: string | undefined, month: string | undefined): YearMonth[] => {
    const parsedYear = parseInt(year || "0", 10);
    const parsedMonth = month as Month;

    if (parsedYear === 0 || !parsedMonth) {
        return [];
    }
    return [{ year: parsedYear, month: parsedMonth }];
};

const BillingPeriodReportsPage = () => {
    const { year, month } = useParams();
    const { navigate } = usePageNavigation<AggregatedBillingReport>({
        route: report => `/billing/reports/${report.id}`,
    });
    const { currency } = useUserSettings();
    const yearAndMonth = getYearMonthFromParams(year, month);
    const billingReports = useAggregatedBillingReports({
        targetCurrency: currency,
        yearAndMonths: yearAndMonth,
    });

    if (billingReports.length === 0) {
        return <NotFound resource={"Billing Reports"} />;
    }

    return (
        <PageLayout
            title={
                <PageTitle title={`Billing Reports ${MonthLabels[yearAndMonth[0].month]} - ${yearAndMonth[0].year}`} />
            }
            navigation={<PageBackButton text={"Back to billing overview"} path={"/billing/reports"} />}
        >
            <CobiraTable
                sizing={"fit-page"}
                columns={COLUMNS}
                data={billingReports[0]?.data || []}
                isLoading={billingReports[0]?.isLoading}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: navigate,
                }}
            />
        </PageLayout>
    );
};

export default BillingPeriodReportsPage;
