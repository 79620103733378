import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";

const CreateTenantFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    adminName: z.string().min(1, "Admin name is required"),
    adminEmail: z.string().email("Admin email must be a valid email").min(1, "Admin email is required"),
});

export type CreateTenantFormSchemaType = z.infer<typeof CreateTenantFormSchema>;

export interface CreateTenantFormProps {
    onSubmit: (form: CreateTenantFormSchemaType) => void;
    onCancel?: () => void;
}

const CreateTenantForm = ({ onSubmit, onCancel }: CreateTenantFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<CreateTenantFormSchemaType>({
        resolver: zodResolver(CreateTenantFormSchema),
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <FormField label={"Admin Name"} error={errors?.adminName}>
                    <Input {...register("adminName")} />
                </FormField>
                <FormField label={"Admin Email"} error={errors?.adminEmail}>
                    <Input {...register("adminEmail")} type={"email"} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default CreateTenantForm;
