/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AmountType } from './AmountType';
import {
    AmountTypeFromJSON,
    AmountTypeFromJSONTyped,
    AmountTypeToJSON,
} from './AmountType';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface CostFeeLine
 */
export interface CostFeeLine {
    /**
     * 
     * @type {number}
     * @memberof CostFeeLine
     */
    cost: number;
    /**
     * 
     * @type {Currency}
     * @memberof CostFeeLine
     */
    currency: Currency;
    /**
     * 
     * @type {number}
     * @memberof CostFeeLine
     */
    amount: number;
    /**
     * 
     * @type {AmountType}
     * @memberof CostFeeLine
     */
    amountType: AmountType;
    /**
     * 
     * @type {string}
     * @memberof CostFeeLine
     */
    classifier: CostFeeLineClassifierEnum;
    /**
     * 
     * @type {string}
     * @memberof CostFeeLine
     */
    discriminator: CostFeeLineDiscriminatorEnum;
    /**
     * 
     * @type {string}
     * @memberof CostFeeLine
     */
    costKey: string;
}


/**
 * @export
 */
export const CostFeeLineClassifierEnum = {
    ActiveImsi: 'ACTIVE_IMSI'
} as const;
export type CostFeeLineClassifierEnum = typeof CostFeeLineClassifierEnum[keyof typeof CostFeeLineClassifierEnum];

/**
 * @export
 */
export const CostFeeLineDiscriminatorEnum = {
    NetworkCredentialType: 'NETWORK_CREDENTIAL_TYPE'
} as const;
export type CostFeeLineDiscriminatorEnum = typeof CostFeeLineDiscriminatorEnum[keyof typeof CostFeeLineDiscriminatorEnum];


/**
 * Check if a given object implements the CostFeeLine interface.
 */
export function instanceOfCostFeeLine(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "amountType" in value;
    isInstance = isInstance && "classifier" in value;
    isInstance = isInstance && "discriminator" in value;
    isInstance = isInstance && "costKey" in value;

    return isInstance;
}

export function CostFeeLineFromJSON(json: any): CostFeeLine {
    return CostFeeLineFromJSONTyped(json, false);
}

export function CostFeeLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostFeeLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cost': json['cost'],
        'currency': CurrencyFromJSON(json['currency']),
        'amount': json['amount'],
        'amountType': AmountTypeFromJSON(json['amountType']),
        'classifier': json['classifier'],
        'discriminator': json['discriminator'],
        'costKey': json['costKey'],
    };
}

export function CostFeeLineToJSON(value?: CostFeeLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cost': value.cost,
        'currency': CurrencyToJSON(value.currency),
        'amount': value.amount,
        'amountType': AmountTypeToJSON(value.amountType),
        'classifier': value.classifier,
        'discriminator': value.discriminator,
        'costKey': value.costKey,
    };
}

