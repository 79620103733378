/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Different queryable consumption states
 * @export
 */
export const ConsumptionState = {
    LastMonth: 'LAST_MONTH',
    ThisMonth: 'THIS_MONTH',
    Any: 'ANY',
    Never: 'NEVER'
} as const;
export type ConsumptionState = typeof ConsumptionState[keyof typeof ConsumptionState];


export function ConsumptionStateFromJSON(json: any): ConsumptionState {
    return ConsumptionStateFromJSONTyped(json, false);
}

export function ConsumptionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumptionState {
    return json as ConsumptionState;
}

export function ConsumptionStateToJSON(value?: ConsumptionState | null): any {
    return value as any;
}

