import { CobiraTableManualPaginationProps, OnChangeFn, PaginationState } from "@cobira/ui-library";
import { Page } from "../api";

export const mapPagination = (
    page?: Page,
    onPaginationChange?: OnChangeFn<PaginationState>,
): CobiraTableManualPaginationProps | boolean =>
    page
        ? {
              pageIndex: page?.number || 0,
              pageSize: page?.size || 0,
              totalPageCount: page?.totalPages || 1,
              totalRowCount: page?.numberOfElements || 0,
              onPaginationChange: onPaginationChange ? onPaginationChange : updaterOrValue => updaterOrValue,
          }
        : false;
