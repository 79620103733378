import React from "react";
import { DateTime } from "@cobira/ui-library";
import { Text } from "@chakra-ui/react";

export interface DeletedAtProps {
    deletedAt: Date | undefined | null;
}

const DeletedAt = ({ deletedAt }: DeletedAtProps) => {
    if (deletedAt) {
        return <DateTime value={deletedAt} />;
    }

    return <Text>Active</Text>;
};

export default DeletedAt;
