import { useApi } from "../../../hooks/useApi";
import { BasicModal, useActionNotification } from "@cobira/ui-library";
import { Action, InvoiceApiGenerateBillingReportsRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import RequestGenerateBillingReportsForm, {
    RequestGenerateBillingReportsFormSchemaType,
} from "../../../forms/RequestGenerateBillingReportsForm/RequestGenerateBillingReportsForm";

export interface GenerateBillingReportsModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const GenerateBillingReportsModal = ({ isOpen, onClose }: GenerateBillingReportsModalProps) => {
    const { invoiceApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});
    const queryClient = useQueryClient();

    const generateBillingReport = useMutation<Action, Error, InvoiceApiGenerateBillingReportsRequest>(
        variables => invoiceApi.generateBillingReports(variables),
        {
            onSuccess: (action, request) => {
                trackAction({
                    action: action,
                    fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                });
                queryClient.invalidateQueries(
                    ["billing", "reports", request.generateBillingReport.year, request.generateBillingReport.month],
                    { exact: false },
                );
            },
        },
    );

    const handleSubmit = useCallback(
        (form: RequestGenerateBillingReportsFormSchemaType) => {
            generateBillingReport.mutate({
                generateBillingReport: {
                    year: form.year,
                    month: form.month,
                    customerIds: form.customers?.map(customer => customer.id),
                },
            });
            onClose();
        },
        [generateBillingReport, onClose],
    );

    return (
        <BasicModal title={`Generate Billing Reports`} onClose={onClose} isOpen={isOpen} modalProps={{ size: "xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <RequestGenerateBillingReportsForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default GenerateBillingReportsModal;
