/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Contexts available for dynamically modifying the post processed tier bounds when the invoice is being computed.
 * The context will be computed within the same rate entry in the rate plan.
 * The `CUMULATIVE_ACTIVE_SIM_SET` means all distinct SIM cards that have been active one or more times since they were first assigned to the rated billing group.
 * @export
 */
export const PostProcessingUsageContext = {
    ActiveSim: 'ACTIVE_SIM',
    ActiveImsi: 'ACTIVE_IMSI',
    CumulativeActiveSimSet: 'CUMULATIVE_ACTIVE_SIM_SET',
    None: 'NONE'
} as const;
export type PostProcessingUsageContext = typeof PostProcessingUsageContext[keyof typeof PostProcessingUsageContext];


export function PostProcessingUsageContextFromJSON(json: any): PostProcessingUsageContext {
    return PostProcessingUsageContextFromJSONTyped(json, false);
}

export function PostProcessingUsageContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProcessingUsageContext {
    return json as PostProcessingUsageContext;
}

export function PostProcessingUsageContextToJSON(value?: PostProcessingUsageContext | null): any {
    return value as any;
}

