import React, { useCallback } from "react";
import {
    AddIcon,
    ChevronDownIcon,
    DownloadIcon,
    ErrorPage,
    FaIcon,
    PageLayout,
    PageTitle,
    useTabs,
} from "@cobira/ui-library";
import {
    Button,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tab,
    TabList,
    TabPanels,
    Tabs,
    useBoolean,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import WebhooksPanel from "./WebhooksPanel";
import AccessTokensPanel from "./AccessTokensPanel";
import IntegrationDetailPanel from "./IntegrationDetailPanel";
import ApiDocumentationPanel from "./ApiDocumentationPanel";
import { useApi } from "../../../hooks/useApi";
import ConfirmRollIntegrationSecretModal from "../../../components/Modal/ConfirmRollIntegrationSecretModal/ConfirmRollIntegrationSecretModal";
import CreateWebhookModal from "../../../components/Modal/CreateWebhookModal/CreateWebhookModal";
import CreateAccessTokenModal from "../../../components/Modal/CreateAccessTokenModal/CreateAccessTokenModal";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { useParams } from "react-router";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";

const IntegrationDetailPage = () => {
    const { integrationApi, documentationApi } = useApi();
    const { integrationId } = useParams();
    const queryClient = useQueryClient();
    const { register } = useTabs({ defaultTab: 0 });
    const [isCreatingWebhook, { toggle: toggleCreatingWebhook }] = useBoolean();
    const [isCreatingAccessToken, { toggle: toggleCreatingAccessToken }] = useBoolean();
    const [isRollingSecret, { toggle: toggleRollingSecret }] = useBoolean();
    const { data: integration, isLoading: isIntegrationLoading } = useQuery(
        ["integrations", integrationId],
        () => integrationApi.getIntegration({ integrationId: integrationId || "" }),
        { enabled: !!integrationId },
    );
    const { ref, url, download, fileName } = useDownloadFile({
        downloadFunction: () => documentationApi.downloadWebhookSchemas(),
        fileName: "webhook-schemas.zip",
    });
    const {
        ref: apiSpecRef,
        url: apiSpecUrl,
        download: downloadApiSpec,
        fileName: apiSpecFileName,
    } = useDownloadFile({
        downloadFunction: () => documentationApi.downloadApiDocumentation(),
        fileName: "api-documentation.yaml",
    });

    const handleWebhookCreationSubmitted = useCallback(
        (created: boolean) => {
            if (created) {
                queryClient.invalidateQueries(["integrations", integration?.id, "webhooks"]);
            }
            toggleCreatingWebhook();
        },
        [integration, queryClient, toggleCreatingWebhook],
    );

    const handleAccessTokenCreationSubmitted = useCallback(
        (created: boolean) => {
            if (created) {
                queryClient.invalidateQueries(["integrations", integration?.id, "accesstokens"]);
            }
            toggleCreatingAccessToken();
        },
        [integration, queryClient, toggleCreatingAccessToken],
    );

    if (!integration) {
        if (isIntegrationLoading) {
            return <></>;
        }
        return (
            <ErrorPage
                code={"404"}
                title={"No Integrations"}
                description={"Please contact support about missing integration details"}
                escapeDescription={"Return back to the landing page"}
                escapePath={"/"}
            />
        );
    }

    return (
        <Tabs {...register()}>
            <a href={url} download={fileName} hidden ref={ref} />
            <a href={apiSpecUrl} download={apiSpecFileName} hidden ref={apiSpecRef} />
            <PageLayout
                title={<PageTitle title={integration?.name ?? "API Integration"} />}
                pageContext={
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                            Manage
                        </MenuButton>
                        <MenuList>
                            <MenuItem icon={<AddIcon />} onClick={toggleCreatingWebhook}>
                                Create Webhook
                            </MenuItem>
                            <MenuItem icon={<AddIcon />} onClick={toggleCreatingAccessToken}>
                                Create Access Token
                            </MenuItem>
                            <MenuItem icon={<FaIcon icon={faArrowsRotate} />} onClick={toggleRollingSecret}>
                                Roll Secret
                            </MenuItem>
                            <MenuItem icon={<Icon as={DownloadIcon} />} onClick={download}>
                                Download Webhook Schemas
                            </MenuItem>
                            <MenuItem icon={<Icon as={DownloadIcon} />} onClick={downloadApiSpec}>
                                Download API Documentation
                            </MenuItem>
                        </MenuList>
                    </Menu>
                }
                subtitle={
                    <TabList>
                        <Tab>Info</Tab>
                        <Tab>Webhooks</Tab>
                        <Tab>Access Tokens</Tab>
                        <Tab>API Documentation</Tab>
                    </TabList>
                }
            >
                <TabPanels>
                    <IntegrationDetailPanel integration={integration} />
                    <WebhooksPanel integration={integration} />
                    <AccessTokensPanel integration={integration} />
                    <ApiDocumentationPanel />
                </TabPanels>
                <ConfirmRollIntegrationSecretModal
                    isOpen={isRollingSecret}
                    onClose={toggleRollingSecret}
                    integration={integration}
                />
                <CreateWebhookModal
                    isOpen={isCreatingWebhook}
                    onClose={handleWebhookCreationSubmitted}
                    integration={integration}
                />
                <CreateAccessTokenModal
                    isOpen={isCreatingAccessToken}
                    onClose={handleAccessTokenCreationSubmitted}
                    integration={integration}
                />
            </PageLayout>
        </Tabs>
    );
};

export default IntegrationDetailPage;
