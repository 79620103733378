import React from "react";
import { z } from "zod";
import { useFormContext } from "react-hook-form";
import { FormContainer, FormField } from "@cobira/ui-library";
import { Input, Textarea } from "@chakra-ui/react";

export const RatePlanDetailsSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().optional(),
});

const CreateRatePlanDetailsFormStep = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <FormContainer>
            <FormField label={"Rate Plan Name"} error={errors?.na}>
                <Input id="name" placeholder="Name" {...register("name")} />
            </FormField>
            <FormField label={"Description"} error={errors?.lastName}>
                <Textarea id="description" placeholder="Description" {...register("description")} />
            </FormField>
        </FormContainer>
    );
};

export default CreateRatePlanDetailsFormStep;
