import React, { FC } from "react";
import { SidebarNew } from "@cobira/ui-library";
import { SidebarContent } from "./SidebarContent";
import MvnoSidebarHeader from "./MvnoSidebarHeader/MvnoSidebarHeader";

interface MvnoSidebarProps {}

const MvnoSidebar: FC<MvnoSidebarProps> = () => {
    return <SidebarNew header={<MvnoSidebarHeader />} items={SidebarContent} />;
};

export default MvnoSidebar;
