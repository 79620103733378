import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { SimConfiguration, Tenant, TenantApiCreateTenantSimConfigurationRequest } from "../../../api";

export interface ConfirmRestoreChildTenantSimConfigurationModalProps {
    childTenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
    simConfiguration?: SimConfiguration;
}

const ConfirmRestoreChildTenantSimConfigurationModal = ({
    onClose,
    simConfiguration,
    isOpen,
    childTenant,
}: ConfirmRestoreChildTenantSimConfigurationModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createSimConfiguration = useMutation<void, Error, TenantApiCreateTenantSimConfigurationRequest>(variables =>
        tenantApi.createTenantSimConfiguration(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && simConfiguration?.id && childTenant?.id) {
                createSimConfiguration.mutate(
                    { tenantId: childTenant.id, assignSimConfiguration: { id: simConfiguration.id } },
                    {
                        onSuccess: () => {
                            notifications.success("SIM Configuration now available for the tenant");
                            queryClient.invalidateQueries(["tenants", childTenant.id, "simconfigurations"]);
                            queryClient.invalidateQueries([
                                "tenants",
                                childTenant.id,
                                "simconfigurations",
                                simConfiguration.id,
                            ]);
                        },
                    },
                );
            }
            onClose();
        },
        [childTenant, createSimConfiguration, notifications, onClose, queryClient, simConfiguration],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!simConfiguration && !!childTenant && isOpen}
            title={"Restore access to SIM Configuration"}
            description={
                <Container>
                    <Text>Are you sure you want to restore the access to the SIM Configuration?</Text>
                    <Text>
                        The SIM Configuration will become accessible to the child tenant again and they will be able to
                        re-sell it to their sub-tenants once more.
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmRestoreChildTenantSimConfigurationModal;
