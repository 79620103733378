/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AutomaticActivationTypes = {
    FirstPdpSession: 'FIRST_PDP_SESSION',
    FirstLocationUpdate: 'FIRST_LOCATION_UPDATE',
    Disabled: 'DISABLED'
} as const;
export type AutomaticActivationTypes = typeof AutomaticActivationTypes[keyof typeof AutomaticActivationTypes];


export function AutomaticActivationTypesFromJSON(json: any): AutomaticActivationTypes {
    return AutomaticActivationTypesFromJSONTyped(json, false);
}

export function AutomaticActivationTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomaticActivationTypes {
    return json as AutomaticActivationTypes;
}

export function AutomaticActivationTypesToJSON(value?: AutomaticActivationTypes | null): any {
    return value as any;
}

