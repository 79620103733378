/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UsagePackageManagementAction = {
    Assign: 'ASSIGN',
    Revoke: 'REVOKE'
} as const;
export type UsagePackageManagementAction = typeof UsagePackageManagementAction[keyof typeof UsagePackageManagementAction];


export function UsagePackageManagementActionFromJSON(json: any): UsagePackageManagementAction {
    return UsagePackageManagementActionFromJSONTyped(json, false);
}

export function UsagePackageManagementActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePackageManagementAction {
    return json as UsagePackageManagementAction;
}

export function UsagePackageManagementActionToJSON(value?: UsagePackageManagementAction | null): any {
    return value as any;
}

