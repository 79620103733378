import React, { useMemo, useState } from "react";
import { TelcoRate } from "../../api";
import { Loading, MoneyUnit } from "@cobira/ui-library";
import { Tree } from "react-arborist";
import { Box } from "@chakra-ui/react";
import TelcoRateNode from "./Node/TelcoRateNode";
import TelcoRateRow from "./Row/TelcoRateRow";
import TelcoRateModal from "../Modal/TelcoRateModal/TelcoRateModal";
import { useViewportSize } from "@mantine/hooks";
import { determineMoneyContext } from "./Node/telcoRateNodeUtils";

export interface RatePlanTreeProps {
    telcoRate?: TelcoRate | null;
    isLoading?: boolean;
    heightPercentage: number;
}

const nodeId = (telcoRate: TelcoRate): string => {
    return `${telcoRate.rateDiscriminator}->${Array.from(telcoRate.rateKey).join(",")}`;
};

const nodeChildren = (telcoRate: TelcoRate): TelcoRate[] => {
    return telcoRate.childRates || [];
};

const TelcoRateTree = ({ telcoRate, isLoading, heightPercentage }: RatePlanTreeProps) => {
    const [selectedTelcoRate, setSelectedTelcoRate] = useState<TelcoRate | undefined>(undefined);
    const [selectedTelcoRateMoneyContext, setSelectedTelcoRateMoneyContext] = useState<MoneyUnit | undefined>(
        undefined,
    );
    const { height } = useViewportSize();
    const calculatedHeight = useMemo(() => height * (heightPercentage / 100.0), [height, heightPercentage]);
    if (isLoading) {
        return <Loading />;
    }

    if (!telcoRate) {
        return <></>;
    }

    return (
        <Box w={"100%"} h={"100%"}>
            <Tree
                data={[telcoRate]}
                disableMultiSelection
                openByDefault={true}
                width={"100%"}
                height={calculatedHeight}
                idAccessor={nodeId}
                childrenAccessor={nodeChildren}
                disableDrag={true}
                renderRow={TelcoRateRow}
                onActivate={node => {
                    setSelectedTelcoRate(node.data);
                    setSelectedTelcoRateMoneyContext(determineMoneyContext(node));
                }}
            >
                {TelcoRateNode}
            </Tree>
            {selectedTelcoRate && (
                <TelcoRateModal
                    isOpen={true}
                    moneyUnit={selectedTelcoRateMoneyContext}
                    telcoRate={selectedTelcoRate}
                    onClose={() => setSelectedTelcoRate(undefined)}
                />
            )}
        </Box>
    );
};

export default TelcoRateTree;
