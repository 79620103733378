/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';
import type { SimCardCapabilityHandle } from './SimCardCapabilityHandle';
import {
    SimCardCapabilityHandleFromJSON,
    SimCardCapabilityHandleFromJSONTyped,
    SimCardCapabilityHandleToJSON,
} from './SimCardCapabilityHandle';
import type { SimCardCapabilityName } from './SimCardCapabilityName';
import {
    SimCardCapabilityNameFromJSON,
    SimCardCapabilityNameFromJSONTyped,
    SimCardCapabilityNameToJSON,
} from './SimCardCapabilityName';

/**
 * 
 * @export
 * @interface ManageSimCardCapability
 */
export interface ManageSimCardCapability {
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof ManageSimCardCapability
     */
    simCardFilter: SimCardActionRequestFilter;
    /**
     * 
     * @type {SimCardCapabilityName}
     * @memberof ManageSimCardCapability
     */
    capabilityName: SimCardCapabilityName;
    /**
     * 
     * @type {SimCardCapabilityHandle}
     * @memberof ManageSimCardCapability
     */
    capabilityHandle: SimCardCapabilityHandle;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ManageSimCardCapability
     */
    parameters?: { [key: string]: object; };
}

/**
 * Check if a given object implements the ManageSimCardCapability interface.
 */
export function instanceOfManageSimCardCapability(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "simCardFilter" in value;
    isInstance = isInstance && "capabilityName" in value;
    isInstance = isInstance && "capabilityHandle" in value;

    return isInstance;
}

export function ManageSimCardCapabilityFromJSON(json: any): ManageSimCardCapability {
    return ManageSimCardCapabilityFromJSONTyped(json, false);
}

export function ManageSimCardCapabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageSimCardCapability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'simCardFilter': SimCardActionRequestFilterFromJSON(json['simCardFilter']),
        'capabilityName': SimCardCapabilityNameFromJSON(json['capabilityName']),
        'capabilityHandle': SimCardCapabilityHandleFromJSON(json['capabilityHandle']),
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
    };
}

export function ManageSimCardCapabilityToJSON(value?: ManageSimCardCapability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'simCardFilter': SimCardActionRequestFilterToJSON(value.simCardFilter),
        'capabilityName': SimCardCapabilityNameToJSON(value.capabilityName),
        'capabilityHandle': SimCardCapabilityHandleToJSON(value.capabilityHandle),
        'parameters': value.parameters,
    };
}

