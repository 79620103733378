/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SetOverageState } from './SetOverageState';
import {
    SetOverageStateFromJSON,
    SetOverageStateFromJSONTyped,
    SetOverageStateToJSON,
} from './SetOverageState';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';

/**
 * 
 * @export
 * @interface SetOverageActionRequest
 */
export interface SetOverageActionRequest {
    /**
     * 
     * @type {SetOverageState}
     * @memberof SetOverageActionRequest
     */
    overageState: SetOverageState;
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof SetOverageActionRequest
     */
    simCardFilter: SimCardActionRequestFilter;
}

/**
 * Check if a given object implements the SetOverageActionRequest interface.
 */
export function instanceOfSetOverageActionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "overageState" in value;
    isInstance = isInstance && "simCardFilter" in value;

    return isInstance;
}

export function SetOverageActionRequestFromJSON(json: any): SetOverageActionRequest {
    return SetOverageActionRequestFromJSONTyped(json, false);
}

export function SetOverageActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOverageActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overageState': SetOverageStateFromJSON(json['overageState']),
        'simCardFilter': SimCardActionRequestFilterFromJSON(json['simCardFilter']),
    };
}

export function SetOverageActionRequestToJSON(value?: SetOverageActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overageState': SetOverageStateToJSON(value.overageState),
        'simCardFilter': SimCardActionRequestFilterToJSON(value.simCardFilter),
    };
}

