import React, { useMemo, useState } from "react";
import {
    CobiraTable,
    PageLayout,
    PageTitle,
    PlaceholderText,
    SearchBar,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePagination,
    useSearch,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { Tenant } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { Button, useBoolean, VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import TenantRowActions from "./TenantRowActions";
import ConfirmInviteTenantModal from "../../../components/Modal/ConfirmInviteTenantModal/ConfirmInviteTenantModal";
import CreateTenantModal from "../../../components/Modal/CreateTenantModal/CreateTenantModal";
import {
    ConsumptionStateSelect,
    SearchSelectConsumptionState,
} from "../../../components/SearchSelectConsumptionState/SearchSelectConsumptionState";

const tenantColumn = createColumnHelper<Tenant>();

type OnInviteTenantCallback = (tenant: Tenant) => void;

const isAdminPresent = (tenant: Tenant) => tenant.adminName && tenant.adminEmail;

const COLUMNS = (onInviteTenant: OnInviteTenantCallback) => [
    tenantColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    tenantColumn.accessor(row => row, {
        id: "admin",
        header: "Admin",
        cell: row => (
            <PlaceholderText
                text={
                    isAdminPresent(row.getValue())
                        ? `${row.getValue().adminName} (${row.getValue().adminEmail})`
                        : undefined
                }
            />
        ),
    }),
    tenantColumn.display({
        id: "actions",
        meta: {
            isButton: true,
        },
        cell: context => <TenantRowActions tenant={context.row.original} onInvite={onInviteTenant} />,
    }),
];

const TenantListPage = () => {
    const { tenantApi } = useApi();
    const { navigate: navigateToTenant } = usePageNavigation<Tenant>({ route: value => `/tenants/${value.id}` });
    const { pageState, setPageState, resetPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = useSearch({
        config: {
            search: singleInput<string>("Search"),
            consumptionState: singleInput<ConsumptionStateSelect>("Usage Activity", { display: value => value.label }),
        },
        onChange: resetPageState,
    });
    const { state } = search;
    const { data: tenants, isLoading } = useQuery(["tenants", pageState, state], () => {
        return tenantApi.getTenants({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            search: state?.search?.value || undefined,
            consumptionState: state?.consumptionState?.value?.value || undefined,
        });
    });
    const [isCreatingTenant, { toggle: toggleCreatingTenant }] = useBoolean();
    const [tenantToInvite, setTenantToInvite] = useState<Tenant | undefined>(undefined);
    const columns = useMemo(() => COLUMNS(setTenantToInvite), [setTenantToInvite]);

    return (
        <PageLayout
            title={<PageTitle title="Resellers" />}
            pageContext={<Button onClick={toggleCreatingTenant}>Create Reseller</Button>}
        >
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Search"}
                                />
                            ),
                        },
                        {
                            id: "consumptionState",
                            menuLabel: "Usage Activity",
                            inputComponent: (
                                <SearchSelectConsumptionState
                                    registration={search.registerInput({ id: "consumptionState" })}
                                />
                            ),
                        },
                    ]}
                />
                <CobiraTable
                    sizing={"fit-page"}
                    columns={columns}
                    data={tenants?.content || []}
                    isLoading={isLoading}
                    withPagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: tenants?.pageProperties?.totalElements || 0,
                        totalPageCount: tenants?.pageProperties?.totalPages || 0,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigateToTenant,
                    }}
                />
            </VStack>
            <CreateTenantModal isOpen={isCreatingTenant} onClose={toggleCreatingTenant} />
            <ConfirmInviteTenantModal onClose={() => setTenantToInvite(undefined)} tenant={tenantToInvite} />
        </PageLayout>
    );
};

export default TenantListPage;
