import React from "react";
import { SearchSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { ConsumptionState } from "../../api";
import { ConsumptionStateLabels } from "../../labels/ConsumptionStateLabels";

export type ConsumptionStateSelect = {
    value: ConsumptionState;
    label: string;
};

export interface SearchSelectConsumptionStateProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<ConsumptionStateSelect, K, TSearchStateConfig>, "label"> {}

export const SearchSelectConsumptionState = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "Select an activity option",
}: SearchSelectConsumptionStateProps<K, TSearchStateConfig>) => {
    const items: ConsumptionStateSelect[] = Object.values(ConsumptionState).map(state => ({
        value: state,
        label: ConsumptionStateLabels[state],
    }));
    return (
        <SearchSelectInput
            registration={registration}
            items={items}
            placeholder={placeholder}
            autocompleteAbleMapping={state => state.label}
            displayMapping={state => state.label}
        />
    );
};
