/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebhookSubscriptionType } from './WebhookSubscriptionType';
import {
    WebhookSubscriptionTypeFromJSON,
    WebhookSubscriptionTypeFromJSONTyped,
    WebhookSubscriptionTypeToJSON,
} from './WebhookSubscriptionType';

/**
 * 
 * @export
 * @interface UpdateWebhookSubscription
 */
export interface UpdateWebhookSubscription {
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookSubscription
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookSubscription
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookSubscription
     */
    target?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWebhookSubscription
     */
    maxAttempts?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookSubscription
     */
    retryDelay?: string | null;
    /**
     * 
     * @type {Set<WebhookSubscriptionType>}
     * @memberof UpdateWebhookSubscription
     */
    subscriptions?: Set<WebhookSubscriptionType> | null;
    /**
     * 
     * @type {Date}
     * @memberof UpdateWebhookSubscription
     */
    pausedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookSubscription
     */
    deliveryTimeout?: string | null;
}

/**
 * Check if a given object implements the UpdateWebhookSubscription interface.
 */
export function instanceOfUpdateWebhookSubscription(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateWebhookSubscriptionFromJSON(json: any): UpdateWebhookSubscription {
    return UpdateWebhookSubscriptionFromJSONTyped(json, false);
}

export function UpdateWebhookSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWebhookSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'maxAttempts': !exists(json, 'maxAttempts') ? undefined : json['maxAttempts'],
        'retryDelay': !exists(json, 'retryDelay') ? undefined : json['retryDelay'],
        'subscriptions': !exists(json, 'subscriptions') ? undefined : (json['subscriptions'] === null ? null : new Set((json['subscriptions'] as Array<any>).map(WebhookSubscriptionTypeFromJSON))),
        'pausedAt': !exists(json, 'pausedAt') ? undefined : (json['pausedAt'] === null ? null : new Date(json['pausedAt'])),
        'deliveryTimeout': !exists(json, 'deliveryTimeout') ? undefined : json['deliveryTimeout'],
    };
}

export function UpdateWebhookSubscriptionToJSON(value?: UpdateWebhookSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'target': value.target,
        'maxAttempts': value.maxAttempts,
        'retryDelay': value.retryDelay,
        'subscriptions': value.subscriptions === undefined ? undefined : (value.subscriptions === null ? null : Array.from(value.subscriptions as Set<any>).map(WebhookSubscriptionTypeToJSON)),
        'pausedAt': value.pausedAt === undefined ? undefined : (value.pausedAt === null ? null : value.pausedAt.toISOString()),
        'deliveryTimeout': value.deliveryTimeout,
    };
}

