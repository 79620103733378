import {
    CountryInfo,
    DataText,
    DateTime,
    Duration,
    getCountry,
    Paper,
    PlaceholderText,
    SlimUuid,
    TextColumn,
} from "@cobira/ui-library";
import { Grid, HStack, Text, VStack } from "@chakra-ui/react";
import { NetworkCredentialTypeCell } from "../Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";
import { CustomerCell } from "../Cells/CustomerCell/CustomerCell";
import { BillingGroupCell } from "../Cells/BillingGroupCell/BillingGroupCell";
import { DataUsageRecord } from "../../api";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import moment from "moment";
import { RadioTechnologyLabels } from "../../labels/RadioTechnologyLabels";

export interface DataUsageRecordDetailsProps {
    query: UseQueryResult<DataUsageRecord>;
}

const DataUsageRecordDetails = ({ query: { data: usage, isLoading } }: DataUsageRecordDetailsProps) => {
    return (
        <VStack w={"100%"} align={"stretch"} gap={4}>
            <Paper header={"Data Usage Details"}>
                <Grid templateColumns={"auto auto auto auto"} rowGap={4}>
                    <TextColumn heading={"ID"} isLoading={isLoading}>
                        <SlimUuid uuid={usage?.id || ""} isCopyable />
                    </TextColumn>
                    <TextColumn heading={"ICC"} isLoading={isLoading}>
                        <PlaceholderText text={usage?.icc} />
                    </TextColumn>
                    <TextColumn heading={"IMSI"} isLoading={isLoading}>
                        <PlaceholderText text={usage?.imsi} />
                    </TextColumn>
                    <TextColumn heading={"Network Credential Type"} isLoading={isLoading}>
                        <NetworkCredentialTypeCell networkCredentialTypeId={usage?.networkCredentialTypeId} />
                    </TextColumn>
                    <TextColumn heading={"Customer"} isLoading={isLoading}>
                        <CustomerCell customerId={usage?.customerId} />
                    </TextColumn>
                    <TextColumn heading={"Billing Group"} isLoading={isLoading}>
                        <BillingGroupCell billingGroupId={usage?.billingGroupId} />
                    </TextColumn>
                    <TextColumn heading={"Bytes"} isLoading={isLoading}>
                        <DataText bytes={usage?.bytes} decimals={2} />
                    </TextColumn>
                    <TextColumn heading={"Billable Bytes"} isLoading={isLoading}>
                        <DataText bytes={usage?.billableBytes} decimals={2} />
                    </TextColumn>
                    <TextColumn heading={"Network"} isLoading={isLoading}>
                        <NetworkInfo plmn={usage?.plmn} />
                    </TextColumn>
                    <TextColumn heading={"Country"} isLoading={isLoading}>
                        <CountryInfo country={getCountry(usage?.countryCode)} />
                    </TextColumn>
                    <TextColumn heading={"Radio Technology"} isLoading={isLoading}>
                        <PlaceholderText text={RadioTechnologyLabels(usage?.radioTechnology)} />
                    </TextColumn>
                    <TextColumn heading={"Opened At"} isLoading={isLoading}>
                        <DateTime value={usage?.openedAt} />
                    </TextColumn>
                    <TextColumn heading={"Closed At"} isLoading={isLoading}>
                        <DateTime value={usage?.closedAt} />
                    </TextColumn>
                    <TextColumn heading={"Duration"} isLoading={isLoading}>
                        <HStack gap={1}>
                            <Text>~</Text>
                            <Duration
                                value={
                                    usage?.openedAt &&
                                    usage?.closedAt &&
                                    moment.duration(moment(usage.closedAt).diff(moment(usage.openedAt))).toISOString()
                                }
                                units={["d", "h", "m"]}
                            />
                        </HStack>
                    </TextColumn>
                </Grid>
            </Paper>
        </VStack>
    );
};

export default DataUsageRecordDetails;
