/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TelcoServiceType = {
    Data: 'DATA',
    SmsMobileOriginated: 'SMS_MOBILE_ORIGINATED',
    SmsMobileTerminated: 'SMS_MOBILE_TERMINATED'
} as const;
export type TelcoServiceType = typeof TelcoServiceType[keyof typeof TelcoServiceType];


export function TelcoServiceTypeFromJSON(json: any): TelcoServiceType {
    return TelcoServiceTypeFromJSONTyped(json, false);
}

export function TelcoServiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelcoServiceType {
    return json as TelcoServiceType;
}

export function TelcoServiceTypeToJSON(value?: TelcoServiceType | null): any {
    return value as any;
}

