/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Available properties when updating a tenant.
 * If used to change the admin of the tenant, just provide the admin related properties and skip the name.
 * @export
 * @interface UpdateTenant
 */
export interface UpdateTenant {
    /**
     * 
     * @type {string}
     * @memberof UpdateTenant
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenant
     */
    adminEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenant
     */
    adminName?: string | null;
}

/**
 * Check if a given object implements the UpdateTenant interface.
 */
export function instanceOfUpdateTenant(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateTenantFromJSON(json: any): UpdateTenant {
    return UpdateTenantFromJSONTyped(json, false);
}

export function UpdateTenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'adminEmail': !exists(json, 'adminEmail') ? undefined : json['adminEmail'],
        'adminName': !exists(json, 'adminName') ? undefined : json['adminName'],
    };
}

export function UpdateTenantToJSON(value?: UpdateTenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'adminEmail': value.adminEmail,
        'adminName': value.adminName,
    };
}

