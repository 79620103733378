import React, { useEffect } from "react";
import { CobiraTable, PageLayout, PageTitle, PlaceholderText, usePageNavigation } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { Integration } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";

const integrationColumn = createColumnHelper<Integration>();
const COLUMNS = [
    integrationColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    integrationColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
];

const IntegrationListPage = () => {
    const { integrationApi } = useApi();
    const { navigate: navigateToIntegration } = usePageNavigation<Integration>({
        route: value => `/integrations/${value.id}`,
    });

    const { data: integrations, isLoading } = useQuery(["integrations"], () => {
        return integrationApi.getIntegrations();
    });

    useEffect(() => {
        if (integrations && integrations.length == 1) {
            navigateToIntegration(integrations[0]);
        }
    }, [integrations, navigateToIntegration]);

    return (
        <PageLayout title={<PageTitle title="API Integrations" />}>
            <VStack w={"100%"} gap={2}>
                <CobiraTable
                    sizing={"fit-page"}
                    columns={COLUMNS}
                    data={integrations || []}
                    isLoading={isLoading}
                    withPagination
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigateToIntegration,
                    }}
                />
            </VStack>
        </PageLayout>
    );
};

export default IntegrationListPage;
