import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    ControlledAutoCompleteMultiSelectInput,
    ControlledAutoCompleteSingleSelectInput,
    ControlledMonthInput,
    FormContainer,
    FormField,
    intToMonth,
    Month,
} from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";
import { CustomerStates } from "../../api";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

enum CustomerSelectionOptionEnum {
    Active_Customers = "Active Customers",
    Specific_Customers = "Specific Customers",
}

const RequestGenerateBillingReportsFormSchema = z.object({
    year: z.number().min(2024, "Year is required"),
    month: z.nativeEnum(Month),
    customerSelectionOption: z.nativeEnum(CustomerSelectionOptionEnum),
    customers: z.array(z.object({ id: z.string(), customerName: z.string() })).optional(),
});

export type RequestGenerateBillingReportsFormSchemaType = z.infer<typeof RequestGenerateBillingReportsFormSchema>;

export interface RequestGenerateBillingReportsFormProps {
    onSubmit: (form: RequestGenerateBillingReportsFormSchemaType) => void;
    onCancel?: () => void;
}

const RequestGenerateBillingReportsForm = ({ onSubmit, onCancel }: RequestGenerateBillingReportsFormProps) => {
    const { customerApi } = useApi();
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
    } = useForm<RequestGenerateBillingReportsFormSchemaType>({
        resolver: zodResolver(RequestGenerateBillingReportsFormSchema),
        defaultValues: {
            year: new Date().getUTCFullYear(),
            month: intToMonth(new Date().getUTCMonth()),
        },
    });

    const { data: customers } = useQuery(["customers", CustomerStates.Active], () =>
        customerApi.getCustomers({
            pageNumber: 0,
            pageSize: 9999,
            customerState: "ACTIVE",
        }),
    );
    const customerSelectionOption = watch("customerSelectionOption");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.year}>
                    <Input {...register("year", { valueAsNumber: true })} />
                </FormField>
                <FormField label={"Month"} error={errors?.month}>
                    <ControlledMonthInput name={"month"} control={control} />
                </FormField>
                <FormField label={"Customer Selection"} error={errors?.customerSelectionOption}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: "customerSelectionOption" }}
                        input={{
                            autocompleteAbleMapping: value => value,
                            displayMapping: value => value,
                            items: Object.values(CustomerSelectionOptionEnum),
                        }}
                    />
                </FormField>
                {customerSelectionOption === CustomerSelectionOptionEnum.Specific_Customers && (
                    <FormField label={"Limit to selected customers"} error={errors?.customers}>
                        <ControlledAutoCompleteMultiSelectInput
                            control={{ control: control, name: "customers" }}
                            input={{
                                placeholder: "Optionally select specific customers to generate the billing report for",
                                items: customers?.content || [],
                                displayMapping: value => `${value.customerName}`,
                                autocompleteAbleMapping: value => `${value.customerName}`,
                            }}
                        />
                    </FormField>
                )}
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Generate
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default RequestGenerateBillingReportsForm;
