import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormContainer, FormField } from "@cobira/ui-library";
import { Button, Input } from "@chakra-ui/react";

const AccessTokenFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
});

export type AccessTokenFormType = z.infer<typeof AccessTokenFormSchema>;

export interface AccessTokenFormProps {
    defaultValue?: AccessTokenFormType;
    onSubmit: (form: AccessTokenFormType) => void;
}

const AccessTokenForm = ({ defaultValue, onSubmit }: AccessTokenFormProps) => {
    const {
        formState: { errors },
        register,
        handleSubmit,
    } = useForm<AccessTokenFormType>({
        resolver: zodResolver(AccessTokenFormSchema),
        defaultValues: defaultValue || undefined,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
            </FormContainer>
            <Button type={"submit"}>Create</Button>
        </form>
    );
};

export default AccessTokenForm;
