import React, { useCallback, useState } from "react";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation } from "@tanstack/react-query";
import AccessTokenForm, { AccessTokenFormType } from "../../../forms/AccessTokenForm/AccessTokenForm";
import AccessTokenSecretModal from "../AccessTokenSecretModal/AccessTokenSecretModal";
import { AccessTokenWithSecret, Integration, IntegrationApiCreateAccessTokenRequest } from "../../../api";

export interface CreateAccessTokenModalProps {
    integration: Integration;
    isOpen: boolean;
    onClose: (created: boolean) => void;
}

const CreateAccessTokenModal = ({ isOpen, onClose, integration }: CreateAccessTokenModalProps) => {
    const { integrationApi } = useApi();
    const notifications = useNotifications();
    const [createdAccessToken, setCreatedAccessToken] = useState<AccessTokenWithSecret | undefined>(undefined);

    const createAccessToken = useMutation<AccessTokenWithSecret, Error, IntegrationApiCreateAccessTokenRequest>(
        variables => integrationApi.createAccessToken(variables),
        {
            onSuccess: accessToken => {
                notifications.success(`Created ${accessToken.tokenDetails.name}`);
                setCreatedAccessToken(accessToken);
            },
        },
    );

    const handleAccessTokenFormSubmit = useCallback(
        (form: AccessTokenFormType) => {
            createAccessToken.mutate({
                createAccessToken: {
                    name: form.name,
                },
                integrationId: integration.id,
            });
        },
        [createAccessToken, integration],
    );

    if (createdAccessToken) {
        return (
            <AccessTokenSecretModal
                accessToken={createdAccessToken}
                isOpen={!!createdAccessToken}
                onClose={() => {
                    setCreatedAccessToken(undefined);
                    onClose(true);
                }}
            />
        );
    }

    return (
        <BasicModal isOpen={isOpen} onClose={() => onClose(false)} title={"Create access token"}>
            <AccessTokenForm onSubmit={handleAccessTokenFormSubmit} />
        </BasicModal>
    );
};

export default CreateAccessTokenModal;
