import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { NetworkCredentialType, NetworkCredentialTypePage } from "../../api";
import { CobiraTable, PlaceholderText } from "@cobira/ui-library";
import { UseQueryResult } from "@tanstack/react-query";
import {
    CobiraTableManualPaginationProps,
    CobiraTableRowClickEnabledProps,
} from "@cobira/ui-library/dist/cjs/components/CobiraTable/CobiraTable";
import DeletedAt from "../DeletedAt/DeletedAt";
import NetworkCredentialTypeTableActions from "./NetworkCredentialTypeTableActions";

const networkCredentialTypeColumn = createColumnHelper<NetworkCredentialType>();

const COLUMNS = [
    networkCredentialTypeColumn.accessor("plmn", {
        id: "plmn",
        header: "PLMN",
        cell: row => row.getValue(),
    }),
    networkCredentialTypeColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    networkCredentialTypeColumn.accessor("imsiProvider", {
        id: "imsiProvider",
        header: "Provider",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    networkCredentialTypeColumn.accessor("deletedAt", {
        id: "deletedAt",
        header: "Deleted At",
        cell: row => <DeletedAt deletedAt={row.getValue()} />,
    }),
];

export interface NetworkCredentialTypeTableProps {
    networkCredentialTypeQuery: UseQueryResult<NetworkCredentialTypePage>;
    pagination: CobiraTableManualPaginationProps;
    withRowClick: CobiraTableRowClickEnabledProps<NetworkCredentialType>;
    actions?: {
        onDeleteClicked?: (networkCredentialType: NetworkCredentialType) => void;
        onRestoreClicked?: (networkCredentialType: NetworkCredentialType) => void;
    };
}

const NetworkCredentialTypeTable = ({
    networkCredentialTypeQuery,
    pagination,
    withRowClick,
    actions,
}: NetworkCredentialTypeTableProps) => {
    const { data: networkCredentialTypes, isLoading } = networkCredentialTypeQuery;
    const columns = useMemo(() => {
        const memoizedColumns = [...COLUMNS];
        if (actions !== undefined) {
            memoizedColumns.push(
                networkCredentialTypeColumn.display({
                    id: "actions",
                    meta: {
                        isButton: true,
                    },
                    cell: content => (
                        <NetworkCredentialTypeTableActions
                            networkCredentialType={content.row.original}
                            actions={actions}
                        />
                    ),
                }),
            );
        }

        return memoizedColumns;
    }, [actions]);
    return (
        <CobiraTable
            sizing={"fit-page"}
            columns={columns}
            data={networkCredentialTypes?.content || []}
            isLoading={isLoading}
            withPagination={pagination}
            withRowClick={withRowClick}
        />
    );
};

export default NetworkCredentialTypeTable;
