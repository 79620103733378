import { useApi } from "../../../hooks/useApi";
import {
    ActionCompletedNotification,
    ActionFailedNotification,
    Action,
    BasicModal,
    pluralize,
    useActionNotification,
} from "@cobira/ui-library";
import { SimCardActionRequestFilter, SimcardApiSendSmsToSimCardsRequest } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { Text, VStack } from "@chakra-ui/react";
import SendSMSForm, { SendSMSFormType } from "../../../forms/SendSMSForm/SendSMSForm";
import SimCardActionInProgressNotification from "../../ActionNotification/SimCardActionInProgressNotification/SimCardActionInProgressNotification";
import { useSimCardCount } from "../../../hooks/useSimCardCount";

export interface SendSMSModalProps {
    filter: SimCardActionRequestFilter;
    isOpen: boolean;
    onClose: () => void;
}

const SendSMSModal = ({ isOpen, filter, onClose }: SendSMSModalProps) => {
    const { simCardApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});

    const simCardCount = useSimCardCount({ filter });

    const sendSms = useMutation<Action[], Error, SimcardApiSendSmsToSimCardsRequest>(
        variables => simCardApi.sendSmsToSimCards(variables),
        {
            onSuccess: data => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: successMessage,
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
        },
    );

    const handleSubmit = useCallback(
        (form: SendSMSFormType) => {
            sendSms.mutate({
                sendSmsRequest: {
                    simCardFilter: filter,
                    messageType: form.messageType,
                    payload: form.payload,
                },
            });
            onClose();
        },
        [sendSms, filter, onClose],
    );

    const successMessage = useCallback((data: Action) => {
        return (
            <ActionCompletedNotification
                action={data}
                successTitle={"SMS Delivered"}
                successMessage={`SMS has been sent to ${data.totalItems} SIM ${pluralize("Card", data.totalItems)}`}
                failureComponent={<ActionFailedNotification title={"Failed to send SMS"} />}
            />
        );
    }, []);
    const errorMessage = useCallback(() => <ActionFailedNotification title={"Failed to send SMS"} />, []);
    const loadingMessage = useCallback(
        (action: Action) => (
            <SimCardActionInProgressNotification
                actionApi={actionApi}
                actionId={action.id}
                title={"Sending SMS to SIM Cards"}
                message={(processedSimCount, totalSimCount) =>
                    `SMS sent to ${processedSimCount} SIM ${pluralize("Card", processedSimCount)} of ${totalSimCount}`
                }
            />
        ),
        [actionApi],
    );

    return (
        <BasicModal title={`Send SMS`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <Text>
                    Send SMS to <b>{simCardCount}</b> SIM {pluralize("Card", simCardCount)}
                </Text>
                <SendSMSForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default SendSMSModal;
