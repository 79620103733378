/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';

/**
 * Billing report meta information, such as customer relation, year & month, as well as time of generation.
 * @export
 * @interface BillingReport
 */
export interface BillingReport {
    /**
     * 
     * @type {string}
     * @memberof BillingReport
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BillingReport
     */
    customerId: string;
    /**
     * A specific year between 2020 and 9999.
     * @type {number}
     * @memberof BillingReport
     */
    year: number;
    /**
     * 
     * @type {Month}
     * @memberof BillingReport
     */
    month: Month;
    /**
     * 
     * @type {Date}
     * @memberof BillingReport
     */
    generatedAt: Date;
}

/**
 * Check if a given object implements the BillingReport interface.
 */
export function instanceOfBillingReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "generatedAt" in value;

    return isInstance;
}

export function BillingReportFromJSON(json: any): BillingReport {
    return BillingReportFromJSONTyped(json, false);
}

export function BillingReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'year': json['year'],
        'month': MonthFromJSON(json['month']),
        'generatedAt': (new Date(json['generatedAt'])),
    };
}

export function BillingReportToJSON(value?: BillingReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'year': value.year,
        'month': MonthToJSON(value.month),
        'generatedAt': (value.generatedAt.toISOString()),
    };
}

