import { z } from "zod";
import React from "react";
import { TelcoRateFormSchema } from "../TelcoRateForm/TelcoRateFormSchema";
import CreateRatePlanServiceTypeRateFormStep from "./CreateRatePlanServiceTypeRateFormStep";

export const RatePlanMoSmsRateSchema = z.object({
    moSmsRate: TelcoRateFormSchema,
});

const CreateRatePlanMoSmsRateFormStep = () => {
    return <CreateRatePlanServiceTypeRateFormStep rateIdentifier={"moSmsRate"} />;
};

export default CreateRatePlanMoSmsRateFormStep;
