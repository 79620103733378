import { useApi } from "./useApi";
import { AsyncMetricsResolver } from "@cobira/ui-library";
import { mapMetricResponse } from "../utils/mappers";

export const useMetricsResolver = (): AsyncMetricsResolver => {
    const { metricsApi } = useApi();

    const metricResolver: AsyncMetricsResolver = ({
        bucket,
        dataPoint,
        group,
        groupId,
        fromDateTime,
        toDateTime,
        dimensions,
    }) =>
        metricsApi
            .getMetrics({
                bucket: bucket,
                group: group,
                groupId: groupId,
                dataPoint: dataPoint,
                fromDateTime: fromDateTime,
                toDateTime: toDateTime,
                dimensions: dimensions,
            })
            .then(value => value.map(mapMetricResponse));
    return metricResolver;
};
