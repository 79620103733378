import { Currency, useCurrencyConversion } from "@cobira/ui-library";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useApi } from "./useApi";
import { AggregatedBillingReportWithLines, aggregateReportLines } from "../utils/billingReportUtils";

export interface UseAggregatedBillingReportsOptions {
    targetCurrency: Currency;
    billingReportId?: string;
}

export const useAggregatedBillingReport = ({
    targetCurrency,
    billingReportId,
}: UseAggregatedBillingReportsOptions): UseQueryResult<AggregatedBillingReportWithLines> => {
    const { invoiceApi } = useApi();
    const { convert } = useCurrencyConversion();
    return useQuery(
        ["billing", "reports", billingReportId, "aggregated"],
        () =>
            invoiceApi.getBillingReport({ billingReportId: billingReportId || "" }).then(async report => {
                const lines = await invoiceApi.getBillingReportLines({ billingReportId: report.id });
                const aggregate = aggregateReportLines(lines, convert, targetCurrency);
                return {
                    ...report,
                    ...lines,
                    totalFeeCost: aggregate.totalFeeCost,
                    totalFeeBillable: aggregate.totalFeeBillable,
                    totalUsageCost: aggregate.totalUsageCost,
                    totalUsageBillable: aggregate.totalUsageBillable,
                    currency: targetCurrency,
                    usage: {
                        ...lines.usage,
                        merged: aggregate.mergedUsageLines,
                    },
                    fees: lines.fees,
                };
            }),
        { enabled: !!billingReportId, staleTime: 43200000 },
    );
};
