/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        BillingGroup,
        BillingGroupPage,
        ConsumptionState,
        CreateBillingGroup,
        UpdateBillingGroup,
    } from '../models';
        import {
            BillingGroupFromJSON,
            BillingGroupToJSON,
            BillingGroupPageFromJSON,
            BillingGroupPageToJSON,
            ConsumptionStateFromJSON,
            ConsumptionStateToJSON,
            CreateBillingGroupFromJSON,
            CreateBillingGroupToJSON,
            UpdateBillingGroupFromJSON,
            UpdateBillingGroupToJSON,
        } from '../models';

            export interface BillingGroupApiCreateBillingGroupRequest {
                customerId: string;
                createBillingGroup: CreateBillingGroup;
            }

            export interface BillingGroupApiGetBillingGroupRequest {
                billingGroupId: string;
            }

            export interface BillingGroupApiGetBillingGroupsRequest {
                pageNumber: number;
                pageSize: number;
                search?: string;
                consumptionState?: ConsumptionState;
            }

            export interface BillingGroupApiGetCustomerBillingGroupRequest {
                customerId: string;
                billingGroupId: string;
            }

            export interface BillingGroupApiGetCustomerBillingGroupsRequest {
                customerId: string;
                pageNumber: number;
                pageSize: number;
                search?: string;
                consumptionState?: ConsumptionState;
            }

            export interface BillingGroupApiUpdateBillingGroupRequest {
                customerId: string;
                billingGroupId: string;
                updateBillingGroup: UpdateBillingGroup;
            }

        /**
        * 
        */
            export class BillingGroupApi extends runtime.BaseAPI {

            /**
                * Create a new billing group for a tenant
                * Create new billing group
            */
            async createBillingGroupRaw(requestParameters: BillingGroupApiCreateBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroup>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createBillingGroup.');
                    }

                    if (requestParameters.createBillingGroup === null || requestParameters.createBillingGroup === undefined) {
                    throw new runtime.RequiredError('createBillingGroup','Required parameter requestParameters.createBillingGroup was null or undefined when calling createBillingGroup.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/billinggroups`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateBillingGroupToJSON(requestParameters.createBillingGroup),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupFromJSON(jsonValue));
            }

            /**
                * Create a new billing group for a tenant
                * Create new billing group
            */
                async createBillingGroup(requestParameters: BillingGroupApiCreateBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroup> {
                    const response = await this.createBillingGroupRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single billing group by its id.
                * Get billing group
            */
            async getBillingGroupRaw(requestParameters: BillingGroupApiGetBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroup>> {
                    if (requestParameters.billingGroupId === null || requestParameters.billingGroupId === undefined) {
                    throw new runtime.RequiredError('billingGroupId','Required parameter requestParameters.billingGroupId was null or undefined when calling getBillingGroup.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/billinggroups/{billingGroupId}`.replace(`{${"billingGroupId"}}`, encodeURIComponent(String(requestParameters.billingGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupFromJSON(jsonValue));
            }

            /**
                * Get a single billing group by its id.
                * Get billing group
            */
                async getBillingGroup(requestParameters: BillingGroupApiGetBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroup> {
                    const response = await this.getBillingGroupRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a paginated result containing all billing groups within your tenant.
                * Get all billing groups
            */
            async getBillingGroupsRaw(requestParameters: BillingGroupApiGetBillingGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroupPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getBillingGroups.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getBillingGroups.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.consumptionState !== undefined) {
                            queryParameters['consumptionState'] = requestParameters.consumptionState;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/billinggroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupPageFromJSON(jsonValue));
            }

            /**
                * Get a paginated result containing all billing groups within your tenant.
                * Get all billing groups
            */
                async getBillingGroups(requestParameters: BillingGroupApiGetBillingGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroupPage> {
                    const response = await this.getBillingGroupsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single billing group 
                * Get specific Billing Group
            */
            async getCustomerBillingGroupRaw(requestParameters: BillingGroupApiGetCustomerBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroup>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerBillingGroup.');
                    }

                    if (requestParameters.billingGroupId === null || requestParameters.billingGroupId === undefined) {
                    throw new runtime.RequiredError('billingGroupId','Required parameter requestParameters.billingGroupId was null or undefined when calling getCustomerBillingGroup.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/billinggroups/{billingGroupId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billingGroupId"}}`, encodeURIComponent(String(requestParameters.billingGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupFromJSON(jsonValue));
            }

            /**
                * Get a single billing group 
                * Get specific Billing Group
            */
                async getCustomerBillingGroup(requestParameters: BillingGroupApiGetCustomerBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroup> {
                    const response = await this.getCustomerBillingGroupRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a paginated result containing all billing groups or all tenants
                * Get all billing groups
            */
            async getCustomerBillingGroupsRaw(requestParameters: BillingGroupApiGetCustomerBillingGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroupPage>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerBillingGroups.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCustomerBillingGroups.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCustomerBillingGroups.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.consumptionState !== undefined) {
                            queryParameters['consumptionState'] = requestParameters.consumptionState;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/billinggroups`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupPageFromJSON(jsonValue));
            }

            /**
                * Get a paginated result containing all billing groups or all tenants
                * Get all billing groups
            */
                async getCustomerBillingGroups(requestParameters: BillingGroupApiGetCustomerBillingGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroupPage> {
                    const response = await this.getCustomerBillingGroupsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Allows for updating the billing group.
                * Update billing group
            */
            async updateBillingGroupRaw(requestParameters: BillingGroupApiUpdateBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroup>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateBillingGroup.');
                    }

                    if (requestParameters.billingGroupId === null || requestParameters.billingGroupId === undefined) {
                    throw new runtime.RequiredError('billingGroupId','Required parameter requestParameters.billingGroupId was null or undefined when calling updateBillingGroup.');
                    }

                    if (requestParameters.updateBillingGroup === null || requestParameters.updateBillingGroup === undefined) {
                    throw new runtime.RequiredError('updateBillingGroup','Required parameter requestParameters.updateBillingGroup was null or undefined when calling updateBillingGroup.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/billinggroups/{billingGroupId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billingGroupId"}}`, encodeURIComponent(String(requestParameters.billingGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateBillingGroupToJSON(requestParameters.updateBillingGroup),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupFromJSON(jsonValue));
            }

            /**
                * Allows for updating the billing group.
                * Update billing group
            */
                async updateBillingGroup(requestParameters: BillingGroupApiUpdateBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroup> {
                    const response = await this.updateBillingGroupRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
