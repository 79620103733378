import React from "react";
import { NodeApi } from "react-arborist";
import { Box, IconButton } from "@chakra-ui/react";
import { TelcoRate } from "../../../api";
import { ChevronDownIcon, ChevronUpIcon } from "@cobira/ui-library";

export interface TelcoRateNodeToggleButtonProps {
    node: NodeApi<TelcoRate>;
}

const TelcoRateNodeToggleButton = ({ node }: TelcoRateNodeToggleButtonProps) => {
    if (node.data.childRates?.length) {
        if (node.isOpen) {
            return (
                <IconButton
                    w={"2em"}
                    aria-label={"collapse"}
                    onClick={() => node.toggle()}
                    icon={<ChevronUpIcon />}
                    variant={"icon"}
                    size={"sm"}
                />
            );
        } else {
            return (
                <IconButton
                    w={"2em"}
                    aria-label={"expand"}
                    onClick={() => node.toggle()}
                    icon={<ChevronDownIcon />}
                    variant={"icon"}
                    size={"sm"}
                />
            );
        }
    }

    return <Box w={"2em"}></Box>;
};

export default TelcoRateNodeToggleButton;
