import React from "react";
import { NodeApi } from "react-arborist";
import { TelcoRate } from "../../../api";
import { Tag } from "@chakra-ui/react";

export interface NodePostProcessingTiersTagProps {
    node: NodeApi<TelcoRate>;
}

const NodePostProcessingTiersTag = ({ node }: NodePostProcessingTiersTagProps) => {
    const postProcessing = node.data.postProcessedTiers;
    const enabled = !!postProcessing?.tiers?.length;
    if (enabled) {
        return (
            <Tag colorScheme={"red"} ml={"auto"} h={"fit-content"}>
                Post processing enabled
            </Tag>
        );
    } else {
        return null;
    }
};

export default NodePostProcessingTiersTag;
