import { useApi } from "../../../hooks/useApi";
import { PageBackButton, PageLayout, PageTitle, Paper, useNotifications, usePageNavigation } from "@cobira/ui-library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    Currency,
    RateplanApiCreateRatePlanRequest,
    RatePlanWithRates,
    TelcoRateRateDiscriminatorEnum,
} from "../../../api";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import CreateRatePlanForm, { CreateRatePlanSchemaType } from "../../../forms/CreateRatePlanForm/CreateRatePlanForm";
import { mapToTelcoRate } from "../../../utils/mapperUtils";

const RatePlanCreatePage = () => {
    const { ratePlanApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();
    const { navigate } = usePageNavigation<RatePlanWithRates>({ route: value => `/rateplans/${value.id}` });

    const createRatePlan = useMutation<RatePlanWithRates, Error, RateplanApiCreateRatePlanRequest>(
        variables => ratePlanApi.createRatePlan(variables),
        {
            onSuccess: data => {
                notifications.success(`Rate Plan '${data.customName}' created`);
                queryClient.invalidateQueries(["rateplans"]);
                navigate(data);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: CreateRatePlanSchemaType) => {
            createRatePlan.mutate({
                createRatePlan: {
                    customName: form.name,
                    description: form.description,
                    telcoRates: {
                        rateDiscriminator: TelcoRateRateDiscriminatorEnum.Default,
                        rateKey: new Set(["DEFAULT"]),
                        currency: Currency.Eur,
                        rateMarkup: {
                            absoluteMarkup: 0,
                        },
                        childRates: [
                            mapToTelcoRate(form.dataRate),
                            mapToTelcoRate(form.moSmsRate),
                            mapToTelcoRate(form.mtSmsRate),
                        ],
                    },
                    networkAccessRates: {
                        rateDiscriminator: "DEFAULT",
                        rateKey: new Set(["DEFAULT"]),
                        markup: {
                            percentageMarkup: form.networkAccessRate.percentageMarkup,
                        },
                    },
                },
            });
        },
        [createRatePlan],
    );

    return (
        <PageLayout
            title={<PageTitle title={"Create Rate Plan"} />}
            navigation={<PageBackButton text={"Back to Rate Plans"} path={`/rateplans`} />}
        >
            <VStack w="100%" align="stretch">
                <Paper>
                    <CreateRatePlanForm onSubmit={handleSubmit} />
                </Paper>
            </VStack>
        </PageLayout>
    );
};

export default RatePlanCreatePage;
