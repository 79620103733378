import React from "react";
import { NodeApi } from "react-arborist";
import { TelcoRate } from "../../../../api";
import { Tag } from "@chakra-ui/react";

export interface TelcoRateDefaultHeadingProps {
    node: NodeApi<TelcoRate>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TelcoRateDefaultHeading = ({ node }: TelcoRateDefaultHeadingProps) => {
    return <Tag>Default</Tag>;
};

export default TelcoRateDefaultHeading;
