import React, { PropsWithChildren, useMemo } from "react";
import { PostProcessingTier } from "../../../../api";
import { DataText, isDataUnitType, isSmsUnitType, MoneyUnit, TextColumn } from "@cobira/ui-library";
import { HStack, Text } from "@chakra-ui/react";

export interface TelcoRatePostProcessingBoundProps extends PropsWithChildren {
    tier: PostProcessingTier;
    moneyUnit?: MoneyUnit;
}

const TelcoRatePostProcessingBound = ({ tier, moneyUnit, children }: TelcoRatePostProcessingBoundProps) => {
    const boundsLabel = useMemo(() => {
        if (isDataUnitType(moneyUnit)) {
            return tier.upperBound === undefined ? (
                <HStack gap={1}>
                    <Text>From</Text>
                    <DataText bytes={tier.lowerBound} decimals={2} />
                    <Text>and more</Text>
                </HStack>
            ) : (
                <HStack gap={1}>
                    <Text>From</Text>
                    <DataText bytes={tier.lowerBound} decimals={2} />
                    <Text>to</Text>
                    <DataText bytes={tier.upperBound} decimals={2} />
                </HStack>
            );
        } else if (isSmsUnitType(moneyUnit)) {
            return tier.upperBound === undefined
                ? `From ${tier.lowerBound} messages and more`
                : `From ${tier.lowerBound} to ${tier.upperBound} messages`;
        } else {
            return tier.upperBound === undefined
                ? `From ${tier.lowerBound} and more`
                : `From ${tier.lowerBound} to ${tier.upperBound}`;
        }
    }, [moneyUnit, tier]);

    return (
        <TextColumn key={tier.lowerBound} heading={boundsLabel}>
            {children}
        </TextColumn>
    );
};

export default TelcoRatePostProcessingBound;
