/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * - `VOLUME` strategy will multiply the entire context with the matched price bound. e.g. All 100 Sims to be charged at `x` * `100`
 * - `TIERED` strategy will multiply fractions of the context with the matching tiers, e.g. first 10 SIMs at price `x`, next 10 SIMs at price `y` and so on.
 * - `FIXED` strategy will only consider the context value to determine the matching price bound, no multiplication with the context will be applied.
 * @export
 */
export const RecurringFeePricingStrategy = {
    Volume: 'VOLUME',
    Tiered: 'TIERED',
    Fixed: 'FIXED'
} as const;
export type RecurringFeePricingStrategy = typeof RecurringFeePricingStrategy[keyof typeof RecurringFeePricingStrategy];


export function RecurringFeePricingStrategyFromJSON(json: any): RecurringFeePricingStrategy {
    return RecurringFeePricingStrategyFromJSONTyped(json, false);
}

export function RecurringFeePricingStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurringFeePricingStrategy {
    return json as RecurringFeePricingStrategy;
}

export function RecurringFeePricingStrategyToJSON(value?: RecurringFeePricingStrategy | null): any {
    return value as any;
}

