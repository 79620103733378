import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Action, Tenant, TenantApiCreateTenantUsagePackageTypeRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import CreateUsagePackageTypeForm, {
    CreateUsagePackageTypeFormSchemaType,
} from "../../../forms/CreateUsagePackageTypeForm/CreateUsagePackageTypeForm";
import { mapDataAllowance, mapSmsAllowance } from "../../../utils/mapperUtils";

export interface CreateChildTenantUsagePackageTypeModalProps {
    forTenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
}

const CreateChildTenantUsagePackageTypeModal = ({
    isOpen,
    onClose,
    forTenant,
}: CreateChildTenantUsagePackageTypeModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createUsagePackageType = useMutation<Array<Action>, Error, TenantApiCreateTenantUsagePackageTypeRequest>(
        variables => tenantApi.createTenantUsagePackageType(variables),
        {
            onSuccess: () => {
                notifications.success(`Bundle creation started`);
                queryClient.invalidateQueries(["tenants", forTenant?.id, "usagepackagetypes"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: CreateUsagePackageTypeFormSchemaType) => {
            createUsagePackageType.mutate({
                tenantId: forTenant?.id || "",
                createUsagePackageType: {
                    customName: form.name,
                    packageDuration: form.duration,
                    automaticActivationType: form.activationType,
                    dataUsageAllowance: mapDataAllowance(form.dataBundle),
                    smsUsageAllowance: mapSmsAllowance(form.smsBundle),
                    supportedCountries: form.supportedCountries.map(country => country.iso2),
                    networkCredentialTypes: new Set(
                        form.networkCredentialTypes.map(networkCredentialType => networkCredentialType.id),
                    ),
                },
            });
            onClose();
        },
        [createUsagePackageType, forTenant, onClose],
    );

    if (!forTenant?.id) {
        return null;
    }

    return (
        <BasicModal title={`Create Bundle`} onClose={onClose} isOpen={isOpen} modalProps={{ size: "2xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <CreateUsagePackageTypeForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default CreateChildTenantUsagePackageTypeModal;
