import React from "react";
import { NetworkCredentialType } from "../../api";
import { Text, VStack } from "@chakra-ui/react";
import { QueryCell } from "@cobira/ui-library";
import InlineNetworkCredentialType from "../InlineNetworkCredentialType/InlineNetworkCredentialType";
import { QueryKey } from "@tanstack/react-query";

export type NetworkCredentialTypeIds<TQueryKey extends QueryKey = QueryKey> = {
    ids: string[];
    queryKey: (networkCredentialTypeId: string) => TQueryKey;
    queryFn: (networkCredentialTypeId: string) => Promise<NetworkCredentialType>;
};

export type NetworkCredentialTypeListTypeUnion = NetworkCredentialType[] | undefined | NetworkCredentialTypeIds;

export interface InlineNetworkCredentialTypeListProps {
    networkCredentialTypes: NetworkCredentialTypeListTypeUnion;
}

const idListIsEmpty = (networkCredentialTypes: NetworkCredentialTypeListTypeUnion): boolean => {
    return (networkCredentialTypes as NetworkCredentialTypeIds)?.ids?.length === 0;
};

const InlineNetworkCredentialTypeList = ({ networkCredentialTypes }: InlineNetworkCredentialTypeListProps) => {
    const isEmptyList =
        networkCredentialTypes instanceof Array<NetworkCredentialType> ? networkCredentialTypes.length === 0 : false;
    if (networkCredentialTypes === undefined || isEmptyList || idListIsEmpty(networkCredentialTypes)) {
        return <Text>No Network Credential Types</Text>;
    }

    if (networkCredentialTypes instanceof Array<NetworkCredentialType>) {
        return (
            <VStack align={"start"}>
                {networkCredentialTypes.map(networkCredentialType => (
                    <InlineNetworkCredentialType
                        key={networkCredentialType.id}
                        networkCredentialType={networkCredentialType}
                    />
                ))}
            </VStack>
        );
    } else {
        const renderNetworkCredentialType = (networkCredentialTypeId: string) => (
            <QueryCell
                key={networkCredentialTypeId}
                query={{
                    queryKey: networkCredentialTypes.queryKey(networkCredentialTypeId),
                    queryFn: () => networkCredentialTypes.queryFn(networkCredentialTypeId),
                }}
                render={result => (
                    <InlineNetworkCredentialType key={networkCredentialTypeId} networkCredentialType={result.data} />
                )}
            />
        );
        return <VStack align={"start"}>{networkCredentialTypes.ids.map(renderNetworkCredentialType)}</VStack>;
    }
};

export default InlineNetworkCredentialTypeList;
