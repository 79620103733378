import React from "react";
import { PageBackButton, PageLayout, PageTitle, Paper, TextColumn } from "@cobira/ui-library";
import { Grid, VStack } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import InlineNetworkCredentialTypeList from "../../../components/InlineNetworkCredentialTypeList/InlineNetworkCredentialTypeList";
import InlineSimCardCapabilityNameList from "../../../components/InlineSimCardCapabilityNameList/InlineSimCardCapabilityNameList";

const SimConfigurationDetailPage = () => {
    const { configurationApi } = useApi();
    const { simConfigurationId } = useParams();

    const { data: simConfiguration, isLoading: isLoadingSimConfiguration } = useQuery(
        ["simconfigurations", simConfigurationId],
        () =>
            configurationApi.getSimConfiguration({
                simConfigurationId: simConfigurationId || "",
            }),
        {
            enabled: !!simConfigurationId,
        },
    );

    return (
        <PageLayout
            title={<PageTitle title={simConfiguration?.name || "SIM Configuration"} />}
            navigation={<PageBackButton text={"Back to SIM Configurations"} path={`/simconfigurations`} />}
        >
            <VStack align={"stretch"} w={"100%"} gap={2}>
                <Paper header={"Details"}>
                    <Grid templateColumns="auto auto " gap="4">
                        <TextColumn heading="Name" grid isLoading={isLoadingSimConfiguration}>
                            {simConfiguration?.name}
                        </TextColumn>
                    </Grid>
                </Paper>
                <Paper header={"Network Credential Types"}>
                    <InlineNetworkCredentialTypeList
                        networkCredentialTypes={{
                            ids: Array.from(simConfiguration?.networkCredentialTypeIds || []),
                            queryKey: networkCredentialTypeId => ["networkcredentialtypes", networkCredentialTypeId],
                            queryFn: networkCredentialTypeId =>
                                configurationApi.getNetworkCredentialType({
                                    networkCredentialTypeId: networkCredentialTypeId,
                                }),
                        }}
                    />
                </Paper>
                <Paper header={"Capabilities"}>
                    <InlineSimCardCapabilityNameList
                        simCardCapabilities={Array.from(simConfiguration?.simCardCapabilities || [])}
                    />
                </Paper>
            </VStack>
        </PageLayout>
    );
};

export default SimConfigurationDetailPage;
