import React, { useMemo } from "react";
import { z } from "zod";
import { createWizardStep, DataUnits, Wizard } from "@cobira/ui-library";
import CreateRatePlanDetailsFormStep, { RatePlanDetailsSchema } from "./CreateRatePlanDetailsFormStep";
import CreateRatePlanDataRateFormStep, { RatePlanDataRateSchema } from "./CreateRatePlanDataRateFormStep";
import CreateRatePlanMoSmsRateFormStep, { RatePlanMoSmsRateSchema } from "./CreateRatePlanMoSmsRateFormStep";
import CreateRatePlanMtSmsRateFormStep, { RatePlanMtSmsRateSchema } from "./CreateRatePlanMtSmsRateFormStep";
import ConfirmCreateRatePlanStep from "./ConfirmCreateRatePlanStep";
import { Currency, TelcoRateRateDiscriminatorEnum, TelcoServiceType } from "../../api";
import { MarkupType } from "../../utils/markupUtils";
import CreateRatePlanNetworkAccessRateFormStep, {
    RatePlanNetworkAccessRateSchema,
} from "./CreateRatePlanNetworkAccessRateFormStep";
import { PricingOption } from "../TelcoRateForm/TelcoRateFormSchema";

const CreateRatePlanSchema = RatePlanDetailsSchema.merge(RatePlanDataRateSchema)
    .merge(RatePlanMoSmsRateSchema)
    .merge(RatePlanMtSmsRateSchema)
    .merge(RatePlanNetworkAccessRateSchema);

export type CreateRatePlanSchemaType = z.infer<typeof CreateRatePlanSchema>;

export interface CreateBillingGroupFormProps {
    onSubmit: (form: CreateRatePlanSchemaType) => void;
}

const CreateRatePlanForm = ({ onSubmit }: CreateBillingGroupFormProps) => {
    const formSteps = useMemo(
        () => [
            createWizardStep(<CreateRatePlanDetailsFormStep />, RatePlanDetailsSchema, {
                stepComponent: "Details",
            }),
            createWizardStep(<CreateRatePlanDataRateFormStep />, RatePlanDataRateSchema, {
                stepComponent: "Data Rate",
                defaultValue: {
                    dataRate: {
                        rateDiscriminator: TelcoRateRateDiscriminatorEnum.ServiceType,
                        rateKey: TelcoServiceType.Data,
                        absoluteMarkupConversionRate: DataUnits.mb.factor, // Data is specified in price/MB
                        currency: Currency.Eur,
                        pricingStrategy: PricingOption.SIMPLE,
                        postProcessing: {
                            context: "NONE",
                            discountTierCount: 2,
                            discountTiers: {
                                markup: MarkupType.ABSOLUTE,
                                bounds: [],
                            },
                            strategy: "THRESHOLD",
                        },
                        rate: {
                            markup: MarkupType.ABSOLUTE,
                            markupAmount: 0,
                        },
                    },
                },
            }),
            createWizardStep(<CreateRatePlanMoSmsRateFormStep />, RatePlanMoSmsRateSchema, {
                stepComponent: "MO SMS Rate",
                defaultValue: {
                    moSmsRate: {
                        rateDiscriminator: TelcoRateRateDiscriminatorEnum.ServiceType,
                        rateKey: TelcoServiceType.SmsMobileOriginated,
                        absoluteMarkupConversionRate: 1, // SMS does not need conversion
                        currency: Currency.Eur,
                        pricingStrategy: PricingOption.SIMPLE,
                        postProcessing: {
                            context: "NONE",
                            discountTierCount: 2,
                            discountTiers: {
                                markup: MarkupType.ABSOLUTE,
                                bounds: [],
                            },
                            strategy: "THRESHOLD",
                        },
                        rate: {
                            markup: MarkupType.ABSOLUTE,
                            markupAmount: 0,
                        },
                    },
                },
            }),
            createWizardStep(<CreateRatePlanMtSmsRateFormStep />, RatePlanMtSmsRateSchema, {
                stepComponent: "MT SMS Rate",
                defaultValue: {
                    mtSmsRate: {
                        rateDiscriminator: TelcoRateRateDiscriminatorEnum.ServiceType,
                        rateKey: TelcoServiceType.SmsMobileTerminated,
                        absoluteMarkupConversionRate: 1, // SMS does not need conversion
                        currency: Currency.Eur,
                        pricingStrategy: PricingOption.SIMPLE,
                        postProcessing: {
                            context: "NONE",
                            discountTierCount: 2,
                            discountTiers: {
                                markup: MarkupType.ABSOLUTE,
                                bounds: [],
                            },
                            strategy: "THRESHOLD",
                        },
                        rate: {
                            markup: MarkupType.ABSOLUTE,
                            markupAmount: 0,
                        },
                    },
                },
            }),
            createWizardStep(<CreateRatePlanNetworkAccessRateFormStep />, RatePlanNetworkAccessRateSchema, {
                stepComponent: "Network Access Rate",
                defaultValue: {
                    networkAccessRate: {
                        percentageMarkup: 10,
                    },
                },
            }),
        ],
        [],
    );

    const confirmStep = useMemo(
        () => createWizardStep(<ConfirmCreateRatePlanStep />, CreateRatePlanSchema, { stepComponent: "Confirm" }),
        [],
    );

    return <Wizard steps={formSteps} confirmStep={confirmStep} onSubmit={onSubmit} />;
};

export default CreateRatePlanForm;
