import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { User, UserApiSendUserInviteRequest } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";

export interface ConfirmInviteUserModalProps {
    onClose: () => void;
    user?: User;
}

const ConfirmInviteUserModal = ({ onClose, user }: ConfirmInviteUserModalProps) => {
    const { userApi } = useApi();
    const notifications = useNotifications();

    const inviteUser = useMutation<void, Error, UserApiSendUserInviteRequest>(variables =>
        userApi.sendUserInvite(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && user?.id) {
                inviteUser.mutate(
                    { userId: user.id },
                    {
                        onSuccess: () => {
                            notifications.success("Invitation has been sent");
                        },
                    },
                );
            }
            onClose();
        },
        [user, inviteUser, notifications, onClose],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!user}
            title={"Invite team member"}
            description={
                <Container>
                    <Text>Send a new invitation to:</Text>
                    <Text as={"b"}>
                        {user?.name} ({user?.email})
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmInviteUserModal;
