import { Control, FieldErrors, useFieldArray, useWatch } from "react-hook-form";
import { AddIcon, Currency, CurrencyLabels, FaIcon, FormField } from "@cobira/ui-library";
import { Box, HStack, IconButton, Input, InputGroup, InputRightAddon, Text, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { z } from "zod";
import { RecurringFeeContext } from "../../api";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";

export const CustomerRecurringFeePricingFormSchema = z.object({
    minimumPrice: z.number().min(0, "Price must be larger than 0"),
    priceBounds: z
        .array(
            z.object({
                lowerBound: z.number(),
                price: z.number().min(0, "Price must be larger than 0"),
            }),
        )
        .refine(
            priceBounds => findViolatingPriceBound(priceBounds) === undefined,
            priceBounds => {
                const violatingIndex = findViolatingPriceBound(priceBounds) || 0;
                return {
                    message: " ",
                    path: [violatingIndex, "lowerBound"],
                };
            },
        ),
});

export type CustomerRecurringFeePricingFormSchemaType = z.infer<typeof CustomerRecurringFeePricingFormSchema>;

const findViolatingPriceBound = (priceBounds: { lowerBound: number; price: number }[]): number | undefined => {
    if (priceBounds !== undefined && priceBounds.length > 1) {
        for (let i = 1; i < priceBounds.length; i++) {
            if (priceBounds[i].lowerBound <= priceBounds[i - 1].lowerBound) {
                return i;
            }
        }
    }

    return undefined;
};

export interface CustomerRecurringFeePricingInputProps {
    context?: RecurringFeeContext;
    currency: Currency;
    control: Control<CustomerRecurringFeePricingFormSchemaType>;
    errors: FieldErrors<CustomerRecurringFeePricingFormSchemaType>;
}

const CustomerRecurringFeePricingInput = ({
    context,
    currency,
    control,
    errors,
}: CustomerRecurringFeePricingInputProps) => {
    const register = control.register;
    const { append, remove, fields } = useFieldArray({
        control,
        name: "priceBounds",
    });
    const watch = useWatch({ control: control });

    const priceBounds = watch.priceBounds || [];

    const appendBound = () => {
        const previousBound = priceBounds[priceBounds.length - 1];
        append({
            lowerBound: (previousBound.lowerBound || 0) + 1,
            price: 0,
        });
    };

    const renderedFields = useMemo(() => {
        const boundInput = (index: number) =>
            context === RecurringFeeContext.BytesUsed ? (
                <InputGroup>
                    <Input
                        {...register(`priceBounds.${index}.lowerBound`, { valueAsNumber: true })}
                        type={"number"}
                        step={0.00000000000000000001}
                    />
                    <InputRightAddon>GB</InputRightAddon>
                </InputGroup>
            ) : (
                <Input {...register(`priceBounds.${index}.lowerBound`, { valueAsNumber: true })} type={"number"} />
            );

        return fields.map((_, index) => {
            return (
                <HStack w={"100%"} gap={2} key={index} alignItems={"center"}>
                    <Text>Above</Text>
                    <FormField error={errors?.priceBounds?.[index]?.lowerBound}>{boundInput(index)}</FormField>
                    <FormField error={errors?.priceBounds?.[index]?.price}>
                        <InputGroup>
                            <Input
                                {...register(`priceBounds.${index}.price`, { valueAsNumber: true })}
                                type={"number"}
                                step={0.00000000000000000001}
                            />
                            <InputRightAddon>{CurrencyLabels[currency]}</InputRightAddon>
                        </InputGroup>
                    </FormField>
                    <IconButton
                        isDisabled={fields.length <= 1}
                        aria-label={"Remove bound"}
                        icon={<FaIcon icon={faMinus} />}
                        onClick={() => remove(index)}
                    />
                </HStack>
            );
        });
    }, [context, currency, errors?.priceBounds, fields, register, remove]);

    return (
        <VStack gap={6}>
            <Box mb={4} w={"100%"}>
                <FormField label={"Minimum Price"} error={errors?.minimumPrice}>
                    <InputGroup>
                        <Input
                            {...register("minimumPrice", { valueAsNumber: true })}
                            type={"number"}
                            step={0.00000000000000000001}
                        />
                        <InputRightAddon>{CurrencyLabels[currency]}</InputRightAddon>
                    </InputGroup>
                </FormField>
            </Box>
            {fields && fields.length > 0 && renderedFields}
            <IconButton icon={<AddIcon />} aria-label={"Add bound"} onClick={appendBound} />
        </VStack>
    );
};

export default CustomerRecurringFeePricingInput;
