import React from "react";
import { Grid, Text, VStack } from "@chakra-ui/react";
import { MoneyUnit, TextColumn } from "@cobira/ui-library";
import TelcoRatePostProcessingTiers from "./TelcoRatePostProcessingTiers";
import { PostProcessingUsageContextLabels } from "../../../../labels/PostProcessingContextLabels";
import { PostProcessingRatingStrategyLabels } from "../../../../labels/PostProcessingRatingStratregyLabels";
import { Currency, PostProcessingTiers } from "../../../../api";

export interface TelcoRatePostProcessingDetailsProps {
    moneyUnit?: MoneyUnit;
    currency: Currency;
    postProcessing?: PostProcessingTiers;
}

const TelcoRatePostProcessingDetails = ({
    postProcessing,
    currency,
    moneyUnit,
}: TelcoRatePostProcessingDetailsProps) => {
    if (!postProcessing || !postProcessing?.tiers?.length) {
        return <Text>No post processing configured</Text>;
    }

    return (
        <VStack w={"100%"} align={"start"}>
            <Grid w={"100%"} templateColumns={"auto auto"} mb={2}>
                <TextColumn heading={"Context"} grid>
                    {PostProcessingUsageContextLabels[postProcessing.context]}
                </TextColumn>
                <TextColumn heading={"Strategy"} grid>
                    {PostProcessingRatingStrategyLabels[postProcessing.strategy]}
                </TextColumn>
            </Grid>
            <TelcoRatePostProcessingTiers currency={currency} tiers={postProcessing.tiers} moneyUnit={moneyUnit} />
        </VStack>
    );
};

export default TelcoRatePostProcessingDetails;
