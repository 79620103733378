import React, { useMemo, useState } from "react";
import { IconButton, TabPanel } from "@chakra-ui/react";
import { Customer, CustomerContact } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { CobiraTable, DeleteIcon } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { createColumnHelper } from "@tanstack/react-table";
import ConfirmDeleteContactModal from "../../../../components/Modal/ConfirmDeleteContactModal/ConfirmDeleteContactModal";

type OnDeleteContactCallback = (user: CustomerContact) => void;

interface CustomerContactsPanelProps {
    query: UseQueryResult<Customer>;
}

const customerContactColumn = createColumnHelper<CustomerContact>();
const COLUMNS = (onDeleteContact: OnDeleteContactCallback) => [
    customerContactColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    customerContactColumn.accessor("email", {
        id: "email",
        header: "Email",
        cell: row => row.getValue(),
    }),
    customerContactColumn.display({
        id: "contactDeleteAction",
        meta: {
            isButton: true,
        },
        cell: ({ row }) => (
            <IconButton
                ml={"auto"}
                aria-label={"delete"}
                icon={<DeleteIcon />}
                colorScheme={"red"}
                variant={"icon"}
                isRound
                onClick={() => onDeleteContact(row.original)}
            />
        ),
    }),
];

const CustomerContactsPanel = ({ query: { data: value } }: CustomerContactsPanelProps) => {
    const { customerApi } = useApi();

    const { data: customerContacts, isLoading: isLoadingCustomerContacts } = useQuery(
        ["customers", value?.id, "contacts"],
        () =>
            customerApi.getCustomerContacts({
                customerId: value?.id || "",
            }),
        { enabled: value?.id !== undefined },
    );
    const [contactToDelete, setContactToDelete] = useState<CustomerContact | undefined>(undefined);
    const columns = useMemo(() => COLUMNS(setContactToDelete), [setContactToDelete]);

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={columns}
                data={customerContacts || []}
                isLoading={isLoadingCustomerContacts}
                withPagination
            />
            <ConfirmDeleteContactModal
                onClose={() => setContactToDelete(undefined)}
                contact={contactToDelete}
                customer={value}
            />
        </TabPanel>
    );
};

export default CustomerContactsPanel;
