import React, { useState } from "react";
import { ChevronDownIcon, CobiraTabs, PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import TenantDetailPanel from "./TenantDetailPanel/TenantDetailPanel";
import TenantUsagePackageTypesPanel from "./TenantUsagePackageTypePanel/TenantUsagePackageTypesPanel";
import CreateChildTenantUsagePackageTypeModal from "../../../components/Modal/CreateChildTenantUsagePackageTypeModal/CreateChildTenantUsagePackageTypeModal";
import UpdateTenantModal from "../../../components/Modal/UpdateTenantModal/UpdateTenantModal";
import TenantNetworkCredentialTypePanel from "./TenantNetworkCredentialTypePanel/TenantNetworkCredentialTypePanel";
import AssignChildTenantNetworkCredentialTypeModal from "../../../components/Modal/AssignChildTenantNetworkCredentialTypeModal/AssignChildTenantNetworkCredentialTypeModal";
import TenantSimConfigurationPanel from "./TenantSimConfigurationPanel/TenantSimConfigurationPanel";
import AssignChildTenantSimConfigurationModal from "../../../components/Modal/AssignChildTenantSimConfigurationModal/AssignChildTenantSimConfigurationModal";
import { tab } from "../../../utils/tabUtils";

type TenantActionType =
    | "create-usage-package-type"
    | "assign-network-credential-type"
    | "assign-sim-configuration"
    | "update";

const TenantDetailPage = () => {
    const { tenantApi } = useApi();
    const { tenantId } = useParams();
    const [chosenAction, setChosenAction] = useState<TenantActionType | null>(null);

    const tenantQuery = useQuery(["tenants", tenantId], () => tenantApi.getTenant({ tenantId: tenantId || "" }), {
        enabled: !!tenantId,
    });

    const tenantDetailTabs = [
        tab("Details", <TenantDetailPanel query={tenantQuery} />),
        tab("Bundles", <TenantUsagePackageTypesPanel query={tenantQuery} />),
        tab("Network Credential Types", <TenantNetworkCredentialTypePanel query={tenantQuery} />),
        tab("SIM Configurations", <TenantSimConfigurationPanel query={tenantQuery} />),
    ];

    return (
        <PageLayout
            title={<PageTitle title={tenantQuery?.data?.name || "Tenant"} />}
            navigation={<PageBackButton text={"Back to resellers"} path={"/tenants"} />}
            pageContext={
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        Manage
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => setChosenAction("update")}>Update</MenuItem>
                        <MenuItem onClick={() => setChosenAction("create-usage-package-type")}>Create Bundle</MenuItem>
                        <MenuItem onClick={() => setChosenAction("assign-network-credential-type")}>
                            Assign Network Credential Type
                        </MenuItem>
                        <MenuItem onClick={() => setChosenAction("assign-sim-configuration")}>
                            Assign SIM Configuration
                        </MenuItem>
                    </MenuList>
                </Menu>
            }
        >
            <CobiraTabs tabs={tenantDetailTabs} />

            <CreateChildTenantUsagePackageTypeModal
                isOpen={chosenAction === "create-usage-package-type"}
                onClose={() => setChosenAction(null)}
                forTenant={tenantQuery?.data}
            />
            <AssignChildTenantNetworkCredentialTypeModal
                isOpen={chosenAction === "assign-network-credential-type"}
                onClose={() => setChosenAction(null)}
                forTenant={tenantQuery?.data}
            />
            <AssignChildTenantSimConfigurationModal
                isOpen={chosenAction === "assign-sim-configuration"}
                onClose={() => setChosenAction(null)}
                forTenant={tenantQuery?.data}
            />
            <UpdateTenantModal
                isOpen={chosenAction === "update"}
                onClose={() => setChosenAction(null)}
                tenant={tenantQuery?.data}
            />
        </PageLayout>
    );
};

export default TenantDetailPage;
