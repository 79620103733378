/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebhookSubscriptionType = {
    SimCardUpdated: 'SIM_CARD_UPDATED',
    SimCardDeleted: 'SIM_CARD_DELETED',
    NetworkCredentialUpdated: 'NETWORK_CREDENTIAL_UPDATED',
    ActionUpdated: 'ACTION_UPDATED',
    ActionItemUpdated: 'ACTION_ITEM_UPDATED',
    LocationUpdate: 'LOCATION_UPDATE',
    SessionUsage: 'SESSION_USAGE',
    BillingGroupUpdated: 'BILLING_GROUP_UPDATED',
    CustomerUpdated: 'CUSTOMER_UPDATED',
    BillingGroupDataUsage: 'BILLING_GROUP_DATA_USAGE',
    BillingGroupSmsUsage: 'BILLING_GROUP_SMS_USAGE',
    PhoneNumberUpdated: 'PHONE_NUMBER_UPDATED',
    SimConfigurationUpdated: 'SIM_CONFIGURATION_UPDATED',
    UsagePackageTypeUpdated: 'USAGE_PACKAGE_TYPE_UPDATED',
    UsagePackageUpdated: 'USAGE_PACKAGE_UPDATED',
    UsagePackageCatalogueUpdated: 'USAGE_PACKAGE_CATALOGUE_UPDATED',
    SimCardNetworkStateUpdated: 'SIM_CARD_NETWORK_STATE_UPDATED',
    NetworkUpdated: 'NETWORK_UPDATED',
    CustomerContactUpdated: 'CUSTOMER_CONTACT_UPDATED',
    ImeiLockUpdated: 'IMEI_LOCK_UPDATED',
    NetworkCredentialSettingsUpdated: 'NETWORK_CREDENTIAL_SETTINGS_UPDATED',
    ImeiLockViolated: 'IMEI_LOCK_VIOLATED',
    Unknown: 'UNKNOWN',
    HardwareUpdated: 'HARDWARE_UPDATED',
    EsimUpdated: 'ESIM_UPDATED'
} as const;
export type WebhookSubscriptionType = typeof WebhookSubscriptionType[keyof typeof WebhookSubscriptionType];


export function WebhookSubscriptionTypeFromJSON(json: any): WebhookSubscriptionType {
    return WebhookSubscriptionTypeFromJSONTyped(json, false);
}

export function WebhookSubscriptionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookSubscriptionType {
    return json as WebhookSubscriptionType;
}

export function WebhookSubscriptionTypeToJSON(value?: WebhookSubscriptionType | null): any {
    return value as any;
}

