import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Tenant, TenantApiCreateTenantSimConfigurationRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import AssignSimConfigurationForm, {
    AssignSimConfigurationFormSchemaType,
} from "../../../forms/AssignSimConfigurationForm/AssignSimConfigurationForm";

export interface AssignChildTenantSimConfigurationModalProps {
    forTenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
}

const AssignChildTenantSimConfigurationModal = ({
    isOpen,
    onClose,
    forTenant,
}: AssignChildTenantSimConfigurationModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const assignSimConfiguration = useMutation<void, Error, TenantApiCreateTenantSimConfigurationRequest>(
        variables => tenantApi.createTenantSimConfiguration(variables),
        {
            onSuccess: () => {
                notifications.success(`SIM Configuration now available for tenant`);
                queryClient.invalidateQueries(["tenants", forTenant?.id, "simconfigurations"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: AssignSimConfigurationFormSchemaType) => {
            assignSimConfiguration.mutate({
                tenantId: forTenant?.id || "",
                assignSimConfiguration: {
                    id: form.simConfiguration.id,
                },
            });
            onClose();
        },
        [assignSimConfiguration, forTenant, onClose],
    );

    if (!forTenant?.id) {
        return null;
    }

    return (
        <BasicModal title={`Assign SIM Configuration`} onClose={onClose} isOpen={isOpen} modalProps={{ size: "2xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <AssignSimConfigurationForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default AssignChildTenantSimConfigurationModal;
