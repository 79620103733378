/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { YearMonth } from './YearMonth';
import {
    YearMonthFromJSON,
    YearMonthFromJSONTyped,
    YearMonthToJSON,
} from './YearMonth';

/**
 * Defines the cost of Data and SMS for the related network credential type.
 * @export
 * @interface CostPlan
 */
export interface CostPlan {
    /**
     * 
     * @type {string}
     * @memberof CostPlan
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CostPlan
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CostPlan
     */
    networkCredentialTypeId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CostPlan
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CostPlan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CostPlan
     */
    description?: string;
    /**
     * 
     * @type {YearMonth}
     * @memberof CostPlan
     */
    validAt: YearMonth;
    /**
     * 
     * @type {number}
     * @memberof CostPlan
     */
    activeImsiCost: number;
}

/**
 * Check if a given object implements the CostPlan interface.
 */
export function instanceOfCostPlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "validAt" in value;
    isInstance = isInstance && "activeImsiCost" in value;

    return isInstance;
}

export function CostPlanFromJSON(json: any): CostPlan {
    return CostPlanFromJSONTyped(json, false);
}

export function CostPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'networkCredentialTypeId': !exists(json, 'networkCredentialTypeId') ? undefined : json['networkCredentialTypeId'],
        'createdAt': (new Date(json['createdAt'])),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'validAt': YearMonthFromJSON(json['validAt']),
        'activeImsiCost': json['activeImsiCost'],
    };
}

export function CostPlanToJSON(value?: CostPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'networkCredentialTypeId': value.networkCredentialTypeId,
        'createdAt': (value.createdAt.toISOString()),
        'name': value.name,
        'description': value.description,
        'validAt': YearMonthToJSON(value.validAt),
        'activeImsiCost': value.activeImsiCost,
    };
}

