import React from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { NetworkCredentialType } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import NetworkCredentialTypePaper from "../../../../components/NetworkCredentialTypePaper/NetworkCredentialTypePaper";

export interface NetworkCredentialTypeDetailsPanelProps {
    query: UseQueryResult<NetworkCredentialType>;
}

const NetworkCredentialTypeDetailsPanel = ({ query }: NetworkCredentialTypeDetailsPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} align={"stretch"} gap={6}>
                <NetworkCredentialTypePaper networkCredentialTypeQuery={query} />
            </VStack>
        </TabPanel>
    );
};

export default NetworkCredentialTypeDetailsPanel;
