/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTelcoCost
 */
export interface CreateTelcoCost {
    /**
     * 
     * @type {string}
     * @memberof CreateTelcoCost
     */
    plmn: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTelcoCost
     */
    byteCost?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTelcoCost
     */
    mobileOriginatedCost?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTelcoCost
     */
    mobileTerminatedCost?: number;
}

/**
 * Check if a given object implements the CreateTelcoCost interface.
 */
export function instanceOfCreateTelcoCost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "plmn" in value;

    return isInstance;
}

export function CreateTelcoCostFromJSON(json: any): CreateTelcoCost {
    return CreateTelcoCostFromJSONTyped(json, false);
}

export function CreateTelcoCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTelcoCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plmn': json['plmn'],
        'byteCost': !exists(json, 'byteCost') ? undefined : json['byteCost'],
        'mobileOriginatedCost': !exists(json, 'mobileOriginatedCost') ? undefined : json['mobileOriginatedCost'],
        'mobileTerminatedCost': !exists(json, 'mobileTerminatedCost') ? undefined : json['mobileTerminatedCost'],
    };
}

export function CreateTelcoCostToJSON(value?: CreateTelcoCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plmn': value.plmn,
        'byteCost': value.byteCost,
        'mobileOriginatedCost': value.mobileOriginatedCost,
        'mobileTerminatedCost': value.mobileTerminatedCost,
    };
}

