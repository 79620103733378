import React, { useCallback } from "react";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { User, UserApiCreateUserRequest } from "../../../api";
import CreateUserForm, { CreateUserFormType } from "../../../forms/CreateUserForm/CreateUserForm";

export interface InviteUserModalProps {
    isOpen: boolean;
    onClose: (created: boolean) => void;
}

interface InviteUser {
    user: User;
}

const InviteUserModal = ({ isOpen, onClose }: InviteUserModalProps) => {
    const { userApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createUser = useMutation<User, Error, UserApiCreateUserRequest>(variables => userApi.createUser(variables), {
        onSuccess: user => {
            queryClient.invalidateQueries(["users"]);
            inviteUser.mutate({ user: user });
        },
    });
    const inviteUser = useMutation<void, Error, InviteUser>(
        variables => userApi.sendUserInvite({ userId: variables.user.id }),
        {
            onSuccess: (_, variables) => {
                notifications.success(`Invitation has been sent to ${variables.user.email}`);
                onClose(true);
            },
            onError: () => {
                notifications.error(`Failed to send invitation, please re-send the invitation`);
                onClose(true);
            },
        },
    );

    const handleCreateUserFormSubmit = useCallback(
        (form: CreateUserFormType) => {
            createUser.mutate({
                createUser: {
                    name: form.name,
                    email: form.email,
                },
            });
        },
        [createUser],
    );

    return (
        <BasicModal isOpen={isOpen} onClose={() => onClose(false)} title={"Invite new team member"}>
            <CreateUserForm
                onSubmit={handleCreateUserFormSubmit}
                isSubmitting={createUser.isLoading || inviteUser.isLoading}
            />
        </BasicModal>
    );
};

export default InviteUserModal;
