import { RatePlanWithRates } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { Grid, TabPanel } from "@chakra-ui/react";
import React from "react";

export interface RatePlanDetailPanelProps {
    query: UseQueryResult<RatePlanWithRates>;
}

const RatePlanDetailPanel = ({ query: { data: ratePlan, isLoading } }: RatePlanDetailPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <Paper header={"Details"}>
                <Grid templateColumns={"auto"} gap={2}>
                    <TextColumn heading={"Name"} grid isLoading={isLoading}>
                        {ratePlan?.customName}
                    </TextColumn>
                    <TextColumn heading={"Description"} grid isLoading={isLoading}>
                        <PlaceholderText text={ratePlan?.description} />
                    </TextColumn>
                </Grid>
            </Paper>
        </TabPanel>
    );
};

export default RatePlanDetailPanel;
