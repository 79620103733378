import React, { useMemo } from "react";
import { TelcoRate } from "../../../api";
import { BasicModal, MoneyUnit } from "@cobira/ui-library";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Heading,
    VStack,
} from "@chakra-ui/react";
import TelcoRateNetworkDetail from "./Detail/TelcoRateNetworkDetail";
import TelcoRateDefaultDetail from "./Detail/TelcoRateDefaultDetail";
import TelcoRateServiceTypeDetail from "./Detail/TelcoRateServiceTypeDetail";
import TelcoRateCountryDetail from "./Detail/TelcoRateCountryDetail";
import TelcoRateMarkup from "../../TelcoRateMarkup/TelcoRateMarkup";
import TelcoRatePostProcessingDetails from "./Detail/TelcoRatePostProcessingDetails";

export interface TelcoRateModalProps {
    telcoRate: TelcoRate;
    moneyUnit?: MoneyUnit;
    isOpen: boolean;
    onClose: () => void;
}

const TelcoRateModal = ({ telcoRate, onClose, isOpen, moneyUnit }: TelcoRateModalProps) => {
    const details = useMemo(() => {
        switch (telcoRate?.rateDiscriminator || "DEFAULT") {
            case "SERVICE_TYPE":
                return <TelcoRateServiceTypeDetail telcoRate={telcoRate} />;
            case "NETWORK":
                return <TelcoRateNetworkDetail telcoRate={telcoRate} />;
            case "COUNTRY":
                return <TelcoRateCountryDetail telcoRate={telcoRate} />;
            case "DEFAULT":
            default:
                return <TelcoRateDefaultDetail telcoRate={telcoRate} />;
        }
    }, [telcoRate]);

    return (
        <BasicModal title={`Telco Rate`} onClose={onClose} isOpen={isOpen} modalProps={{ size: "4xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                {details}
                <Accordion mt={2}>
                    <AccordionItem>
                        <AccordionButton pl={0}>
                            <Heading size={"md"} flex={1} textAlign="left">
                                Pricing Tiers
                            </Heading>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} pl={0}>
                            <TelcoRateMarkup
                                currency={telcoRate.currency || "UNDEFINED"}
                                markup={telcoRate.rateMarkup}
                                moneyUnit={moneyUnit}
                            />
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton pl={0}>
                            <Heading size={"md"} flex={1} textAlign="left">
                                Post Processing
                            </Heading>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} pl={0}>
                            <TelcoRatePostProcessingDetails
                                currency={telcoRate.currency || "UNDEFINED"}
                                postProcessing={telcoRate.postProcessedTiers}
                                moneyUnit={moneyUnit}
                            />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </VStack>
        </BasicModal>
    );
};

export default TelcoRateModal;
