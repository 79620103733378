/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecurringFeeContext } from './RecurringFeeContext';
import {
    RecurringFeeContextFromJSON,
    RecurringFeeContextFromJSONTyped,
    RecurringFeeContextToJSON,
} from './RecurringFeeContext';
import type { RecurringFeePricing } from './RecurringFeePricing';
import {
    RecurringFeePricingFromJSON,
    RecurringFeePricingFromJSONTyped,
    RecurringFeePricingToJSON,
} from './RecurringFeePricing';
import type { YearMonth } from './YearMonth';
import {
    YearMonthFromJSON,
    YearMonthFromJSONTyped,
    YearMonthToJSON,
} from './YearMonth';

/**
 * 
 * @export
 * @interface CreateCustomerRecurringFee
 */
export interface CreateCustomerRecurringFee {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRecurringFee
     */
    name: string;
    /**
     * 
     * @type {RecurringFeeContext}
     * @memberof CreateCustomerRecurringFee
     */
    context: RecurringFeeContext;
    /**
     * 
     * @type {YearMonth}
     * @memberof CreateCustomerRecurringFee
     */
    anniversary: YearMonth;
    /**
     * 
     * @type {number}
     * @memberof CreateCustomerRecurringFee
     */
    recurrence: number;
    /**
     * 
     * @type {RecurringFeePricing}
     * @memberof CreateCustomerRecurringFee
     */
    pricing: RecurringFeePricing;
    /**
     * Limits the recurring fee to the selected billing groups, 
     * otherwise it will apply to all billing groups the customer has.
     * @type {Set<string>}
     * @memberof CreateCustomerRecurringFee
     */
    billingGroupIds?: Set<string> | null;
}

/**
 * Check if a given object implements the CreateCustomerRecurringFee interface.
 */
export function instanceOfCreateCustomerRecurringFee(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "context" in value;
    isInstance = isInstance && "anniversary" in value;
    isInstance = isInstance && "recurrence" in value;
    isInstance = isInstance && "pricing" in value;

    return isInstance;
}

export function CreateCustomerRecurringFeeFromJSON(json: any): CreateCustomerRecurringFee {
    return CreateCustomerRecurringFeeFromJSONTyped(json, false);
}

export function CreateCustomerRecurringFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomerRecurringFee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'context': RecurringFeeContextFromJSON(json['context']),
        'anniversary': YearMonthFromJSON(json['anniversary']),
        'recurrence': json['recurrence'],
        'pricing': RecurringFeePricingFromJSON(json['pricing']),
        'billingGroupIds': !exists(json, 'billingGroupIds') ? undefined : json['billingGroupIds'],
    };
}

export function CreateCustomerRecurringFeeToJSON(value?: CreateCustomerRecurringFee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'context': RecurringFeeContextToJSON(value.context),
        'anniversary': YearMonthToJSON(value.anniversary),
        'recurrence': value.recurrence,
        'pricing': RecurringFeePricingToJSON(value.pricing),
        'billingGroupIds': value.billingGroupIds === undefined ? undefined : value.billingGroupIds === null ? null : Array.from(value.billingGroupIds as Set<any>),
    };
}

