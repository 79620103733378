/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        CreateRatePlan,
        RatePlanPage,
        RatePlanWithRates,
        UpdateRatePlan,
    } from '../models';
        import {
            CreateRatePlanFromJSON,
            CreateRatePlanToJSON,
            RatePlanPageFromJSON,
            RatePlanPageToJSON,
            RatePlanWithRatesFromJSON,
            RatePlanWithRatesToJSON,
            UpdateRatePlanFromJSON,
            UpdateRatePlanToJSON,
        } from '../models';

            export interface RateplanApiCreateRatePlanRequest {
                createRatePlan: CreateRatePlan;
            }

            export interface RateplanApiGetRatePlanByIdRequest {
                ratePlanId: string;
            }

            export interface RateplanApiGetRatePlansRequest {
                pageNumber: number;
                pageSize: number;
            }

            export interface RateplanApiUpdateRatePlanRequest {
                ratePlanId: string;
                updateRatePlan: UpdateRatePlan;
            }

        /**
        * 
        */
            export class RateplanApi extends runtime.BaseAPI {

            /**
                * Creates a new rate plan including associated rates.
                * Create rate plan
            */
            async createRatePlanRaw(requestParameters: RateplanApiCreateRatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RatePlanWithRates>> {
                    if (requestParameters.createRatePlan === null || requestParameters.createRatePlan === undefined) {
                    throw new runtime.RequiredError('createRatePlan','Required parameter requestParameters.createRatePlan was null or undefined when calling createRatePlan.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/rateplans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateRatePlanToJSON(requestParameters.createRatePlan),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => RatePlanWithRatesFromJSON(jsonValue));
            }

            /**
                * Creates a new rate plan including associated rates.
                * Create rate plan
            */
                async createRatePlan(requestParameters: RateplanApiCreateRatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RatePlanWithRates> {
                    const response = await this.createRatePlanRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Gets a single rate plan including all associated rates
                * Get rate plan
            */
            async getRatePlanByIdRaw(requestParameters: RateplanApiGetRatePlanByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RatePlanWithRates>> {
                    if (requestParameters.ratePlanId === null || requestParameters.ratePlanId === undefined) {
                    throw new runtime.RequiredError('ratePlanId','Required parameter requestParameters.ratePlanId was null or undefined when calling getRatePlanById.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/rateplans/{ratePlanId}`.replace(`{${"ratePlanId"}}`, encodeURIComponent(String(requestParameters.ratePlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => RatePlanWithRatesFromJSON(jsonValue));
            }

            /**
                * Gets a single rate plan including all associated rates
                * Get rate plan
            */
                async getRatePlanById(requestParameters: RateplanApiGetRatePlanByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RatePlanWithRates> {
                    const response = await this.getRatePlanByIdRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Gets all available rate plans.
                * Get rate plans
            */
            async getRatePlansRaw(requestParameters: RateplanApiGetRatePlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RatePlanPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getRatePlans.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getRatePlans.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/rateplans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => RatePlanPageFromJSON(jsonValue));
            }

            /**
                * Gets all available rate plans.
                * Get rate plans
            */
                async getRatePlans(requestParameters: RateplanApiGetRatePlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RatePlanPage> {
                    const response = await this.getRatePlansRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Update a rate plan. Fields that are omitted will be left out of the update operation. When supplying rates (telco or service) the rates cannot be empty. Neither can the \'customName\' be left empty if present in the body.
                * Update rate plan
            */
            async updateRatePlanRaw(requestParameters: RateplanApiUpdateRatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RatePlanWithRates>> {
                    if (requestParameters.ratePlanId === null || requestParameters.ratePlanId === undefined) {
                    throw new runtime.RequiredError('ratePlanId','Required parameter requestParameters.ratePlanId was null or undefined when calling updateRatePlan.');
                    }

                    if (requestParameters.updateRatePlan === null || requestParameters.updateRatePlan === undefined) {
                    throw new runtime.RequiredError('updateRatePlan','Required parameter requestParameters.updateRatePlan was null or undefined when calling updateRatePlan.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/rateplans/{ratePlanId}`.replace(`{${"ratePlanId"}}`, encodeURIComponent(String(requestParameters.ratePlanId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateRatePlanToJSON(requestParameters.updateRatePlan),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => RatePlanWithRatesFromJSON(jsonValue));
            }

            /**
                * Update a rate plan. Fields that are omitted will be left out of the update operation. When supplying rates (telco or service) the rates cannot be empty. Neither can the \'customName\' be left empty if present in the body.
                * Update rate plan
            */
                async updateRatePlan(requestParameters: RateplanApiUpdateRatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RatePlanWithRates> {
                    const response = await this.updateRatePlanRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
