/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';

/**
 * Information required to generate a new billing report, if the customer IDs property is left out, 
 * all customers marked as "ACTIVE" will have their billing reports generated.
 * @export
 * @interface GenerateBillingReport
 */
export interface GenerateBillingReport {
    /**
     * A specific year between 2020 and 9999.
     * @type {number}
     * @memberof GenerateBillingReport
     */
    year: number;
    /**
     * 
     * @type {Month}
     * @memberof GenerateBillingReport
     */
    month: Month;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateBillingReport
     */
    customerIds?: Array<string> | null;
}

/**
 * Check if a given object implements the GenerateBillingReport interface.
 */
export function instanceOfGenerateBillingReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;

    return isInstance;
}

export function GenerateBillingReportFromJSON(json: any): GenerateBillingReport {
    return GenerateBillingReportFromJSONTyped(json, false);
}

export function GenerateBillingReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateBillingReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'month': MonthFromJSON(json['month']),
        'customerIds': !exists(json, 'customerIds') ? undefined : json['customerIds'],
    };
}

export function GenerateBillingReportToJSON(value?: GenerateBillingReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'month': MonthToJSON(value.month),
        'customerIds': value.customerIds,
    };
}

