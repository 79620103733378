import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormContainer, FormField } from "@cobira/ui-library";
import { Button, Input, Text, VStack } from "@chakra-ui/react";

export const CreateUserFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    email: z.string().min(1, "Email is required"),
});

export type CreateUserFormType = z.infer<typeof CreateUserFormSchema>;

export interface CreateUserFormProps {
    defaultValue?: CreateUserFormType;
    onSubmit: (form: CreateUserFormType) => void;
    isSubmitting: boolean;
}

const CreateUserForm = ({ defaultValue, onSubmit, isSubmitting }: CreateUserFormProps) => {
    const {
        formState: { errors },
        register,
        handleSubmit,
    } = useForm<CreateUserFormType>({
        resolver: zodResolver(CreateUserFormSchema),
        defaultValues: defaultValue || undefined,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack>
                <Text fontSize={"xl"}>Fill the fields below to send the invitation</Text>
            </VStack>
            <FormContainer>
                <FormField label={"Name *"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <FormField label={"Email *"} error={errors?.email}>
                    <Input {...register("email")} type={"email"} />
                </FormField>
            </FormContainer>
            <VStack>
                <Button type={"submit"} isLoading={isSubmitting}>
                    Invite
                </Button>
            </VStack>
        </form>
    );
};

export default CreateUserForm;
