import { ControlledDataInput, FormField } from "@cobira/ui-library";
import React from "react";
import { useFormContext } from "react-hook-form";
import { CreateRatePlanSchemaType } from "../CreateRatePlanForm/CreateRatePlanForm";
import { Input } from "@chakra-ui/react";

export interface BoundInputProps {
    rateIdentifier: "dataRate" | "moSmsRate" | "mtSmsRate";
    index: number;
}

const BoundInput = ({ rateIdentifier, index }: BoundInputProps) => {
    const { control, register } = useFormContext<CreateRatePlanSchemaType>();

    if (rateIdentifier === "dataRate") {
        return (
            <FormField label={`Bound`}>
                <ControlledDataInput
                    name={`${rateIdentifier}.postProcessing.discountTiers.bounds.${index}.upperBound`}
                    control={control}
                />
            </FormField>
        );
    } else {
        return (
            <FormField label={`Bound`}>
                <Input
                    {...register(`${rateIdentifier}.postProcessing.discountTiers.bounds.${index}.upperBound`, {
                        valueAsNumber: true,
                    })}
                    type={"number"}
                    step={1}
                />
            </FormField>
        );
    }
};

export default BoundInput;
