import React, { useEffect } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input, Textarea } from "@chakra-ui/react";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { BillingGroup } from "../../api";

const UpdateBillingGroupFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().optional(),
    ratePlan: z
        .object({
            id: z.string(),
            customName: z.string(),
        })
        .required(),
});

export type UpdateBillingGroupFormType = z.infer<typeof UpdateBillingGroupFormSchema>;

export interface UpdateBillingGroupFormProps {
    billingGroup: BillingGroup;
    onSubmit: (form: UpdateBillingGroupFormType) => void;
    onCancel?: () => void;
}

const UpdateBillingGroupForm = ({ billingGroup, onSubmit, onCancel }: UpdateBillingGroupFormProps) => {
    const { ratePlanApi } = useApi();

    const { data: ratePlans } = useQuery(["rateplans"], () =>
        ratePlanApi.getRatePlans({ pageNumber: 0, pageSize: 9999 }),
    );

    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        setValue,
    } = useForm<UpdateBillingGroupFormType>({
        resolver: zodResolver(UpdateBillingGroupFormSchema),
        defaultValues: {
            name: billingGroup.name,
            description: billingGroup.description,
            ratePlan: billingGroup.ratePlanId
                ? {
                      id: billingGroup.ratePlanId,
                      customName: ratePlans?.content?.find(ratePlan => ratePlan.id === billingGroup.ratePlanId)
                          ?.customName,
                  }
                : undefined,
        },
    });

    useEffect(() => {
        if (billingGroup.ratePlanId && ratePlans) {
            const ratePlan = ratePlans?.content?.find(ratePlan => ratePlan.id === billingGroup.ratePlanId);
            if (ratePlan) {
                setValue("ratePlan", {
                    id: ratePlan.id,
                    customName: ratePlan.customName,
                });
            }
        }
    }, [billingGroup.ratePlanId, ratePlans, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <FormField label={"Rate Plan"} error={errors?.ratePlan}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ name: "ratePlan", control: control }}
                        input={{
                            items: ratePlans?.content || [],
                            placeholder: "Select Rate Plan",
                            isClearable: true,
                            autocompleteAbleMapping: value => value.customName,
                            displayMapping: value => value.customName,
                        }}
                    />
                </FormField>
                <FormField label={"Description"} error={errors?.description}>
                    <Textarea {...register("description")} minH={"6rem"} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Update
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default UpdateBillingGroupForm;
