import { useParams } from "react-router";
import { PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";
import SmsUsageRecordDetails from "../../../components/Usage/SmsUsageRecordDetails";

const SmsUsageDetailPage = () => {
    const { usageRecordId } = useParams();
    const { usageRecordApi } = useApi();

    const smsUsageRecordQuery = useQuery(
        ["usage-records", "sms", usageRecordId],
        () => usageRecordApi.getSmsUsageRecord({ usageRecordId: usageRecordId || "" }),
        {
            enabled: !!usageRecordId,
        },
    );
    return (
        <PageLayout
            title={<PageTitle title={"SMS Usage Details"} />}
            navigation={<PageBackButton text={"Back to Usage Records"} path={"/usage-records"} />}
        >
            <SmsUsageRecordDetails query={smsUsageRecordQuery} />
        </PageLayout>
    );
};

export default SmsUsageDetailPage;
