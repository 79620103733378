import { SimCardActionRequestFilter } from "../api";
import { useQuery } from "@tanstack/react-query";
import { mapFromActionFilterToSearchFilter } from "../utils/mapperUtils";
import { useMemo } from "react";
import { useApi } from "./useApi";

export interface UseSimCardCountProps {
    filter: SimCardActionRequestFilter;
    isLoading?: boolean;
}

export const useSimCardCount = ({ filter, isLoading = false }: UseSimCardCountProps): number => {
    const { simCardApi } = useApi();

    const queryKey = useMemo(() => {
        const billingGroupIdQueryKey = Array.from(filter?.billingGroupIdSet?.values() || []);
        const iccSetQueryKey = Array.from(filter?.iccSet?.values() || []);

        return ["simcards", filter, billingGroupIdQueryKey, iccSetQueryKey];
    }, [filter]);

    const { data: simCards } = useQuery(
        queryKey,
        () => simCardApi.getSimCards(mapFromActionFilterToSearchFilter(0, 1, filter)),
        {
            enabled: !!filter && !filter?.iccSet?.size && !isLoading,
        },
    );

    return useMemo(() => {
        if (filter.iccSet) {
            return filter.iccSet.size;
        }

        return simCards?.pageProperties?.totalElements || 0;
    }, [filter, simCards]);
};
