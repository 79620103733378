import React, { ReactNode, useMemo } from "react";
import { ActionActorInfo, SearchSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { useQueries, useQuery } from "@tanstack/react-query";
import { AccessToken, User } from "../../api";
import { useApi } from "../../hooks/useApi";

export type ActionActorSelect = {
    id: string;
    name: string;
    type: "ACCESS_TOKEN" | "USER";
};

const mapAccessTokenToActor = (token: AccessToken): ActionActorSelect => ({
    id: token.id,
    name: token.name,
    type: "ACCESS_TOKEN",
});
const mapUserToActor = (token: User): ActionActorSelect => ({ id: token.id, name: token.name, type: "USER" });

export interface SearchSelectActionActorInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<ActionActorSelect, K, TSearchStateConfig>, "label"> {}

export const SearchSelectActionActorInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "Select Action Actor",
}: SearchSelectActionActorInputProps<K, TSearchStateConfig>) => {
    const { integrationApi, userApi } = useApi();

    const { data: users = [] } = useQuery(["userActors"], () =>
        userApi.getUsers({ includeDeleted: true }).then(users => users.map(mapUserToActor)),
    );

    const { data: integrations = [] } = useQuery(["integrations"], () => integrationApi.getIntegrations());
    const accessTokenQueries = useQueries({
        queries: integrations?.map(integration => ({
            queryKey: ["integrations", integration.id, "accesstokens"],
            queryFn: () =>
                integrationApi
                    .getAccessTokens({ integrationId: integration.id })
                    .then(accessTokens => accessTokens.map(mapAccessTokenToActor)),
        })),
    });

    const accessTokens = accessTokenQueries
        .map(result => result?.data || [])
        .reduce((result, tokens) => result.concat(tokens), []);

    const allActors = useMemo(() => [...users, ...accessTokens], [users, accessTokens]);

    const autoCompleteMapping = (actor: ActionActorSelect): string => actor.name;

    const displayMapping = (actor: ActionActorSelect): ReactNode => {
        switch (actor.type) {
            case "ACCESS_TOKEN":
                return <ActionActorInfo type={"APPLICATION"} name={actor.name} withIcon withTooltip={false} />;
            case "USER":
                return <ActionActorInfo type={"USER"} name={actor.name} withIcon withTooltip={false} />;
            default:
                return actor.name;
        }
    };

    return (
        <SearchSelectInput
            placeholder={placeholder}
            items={allActors}
            displayMapping={displayMapping}
            autocompleteAbleMapping={autoCompleteMapping}
            registration={registration}
        />
    );
};
