import React from "react";
import { Currency, Markup } from "../../api";
import { HStack, Text } from "@chakra-ui/react";
import MarkupDetails from "../MarkupDetails/MarkupDetails";
import { MoneyUnit } from "@cobira/ui-library";

export interface TelcoRateMarkupProps {
    moneyUnit?: MoneyUnit;
    currency: Currency;
    markup: Markup;
}

const TelcoRateMarkup = ({ markup, currency, moneyUnit }: TelcoRateMarkupProps) => {
    return (
        <HStack align={"start"}>
            <Text>Everything will be charged at</Text>
            <MarkupDetails markup={markup} currency={currency} moneyUnit={moneyUnit} />
        </HStack>
    );
};

export default TelcoRateMarkup;
