import { Currency, useCurrencyConversion, YearMonth } from "@cobira/ui-library";
import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { useApi } from "./useApi";
import { AggregatedBillingReport, aggregateReportLines } from "../utils/billingReportUtils";

export interface UseAggregatedBillingReportsOptions {
    targetCurrency: Currency;
    yearAndMonths: YearMonth[];
}

export const useAggregatedBillingReports = ({
    targetCurrency,
    yearAndMonths,
}: UseAggregatedBillingReportsOptions): UseQueryResult<AggregatedBillingReport[]>[] => {
    const { invoiceApi } = useApi();
    const { convert } = useCurrencyConversion();
    return useQueries({
        queries: yearAndMonths.map(yearMonth => ({
            queryFn: () =>
                invoiceApi
                    .getBillingReports({
                        pageSize: 9999,
                        pageNumber: 0,
                        year: yearMonth.year,
                        month: yearMonth.month,
                    })
                    .then(reports => {
                        return Promise.all(
                            reports.content.map(async report => {
                                const lines = await invoiceApi.getBillingReportLines({ billingReportId: report.id });
                                return {
                                    ...report,
                                    ...aggregateReportLines(lines, convert, targetCurrency),
                                    currency: targetCurrency,
                                };
                            }),
                        );
                    }),
            queryKey: ["billing", "reports", yearMonth.year, yearMonth.month, "aggregated"],
            staleTime: 43200000, // 12 hours
        })),
    });
};
