import React from "react";
import { Tenant } from "../../../api";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { SendInviteIcon } from "@cobira/ui-library";

export interface TenantRowActionsProps {
    tenant: Tenant;
    onInvite: (tenant: Tenant) => void;
}

const TenantRowActions = ({ tenant, onInvite }: TenantRowActionsProps) => {
    return (
        <Tooltip label={"Send Invitation"}>
            <IconButton
                ml={"auto"}
                aria-label={"invite"}
                icon={<SendInviteIcon />}
                variant={"icon"}
                isRound
                onClick={() => onInvite(tenant)}
            />
        </Tooltip>
    );
};

export default TenantRowActions;
