import React, { useState } from "react";
import {
    PageLayout,
    PageTitle,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePersistedSearch,
    UserTable,
} from "@cobira/ui-library";
import { Button, Menu, MenuButton, useBoolean, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";
import { User } from "../../../api";
import ConfirmDeleteUserModal from "../../../components/Modal/ConfirmDeleteUserModal/ConfirmDeleteUserModal";
import InviteUserModal from "../../../components/Modal/InviteUserModal/InviteUserModal";
import ConfirmInviteUserModal from "../../../components/Modal/ConfirmInviteUserModal/ConfirmInviteUserModal";
import ConfirmUnblockUserModal from "../../../components/Modal/ConfirmUnblockUserModal/ConfirmUnblockUserModal";
import { BooleanLabels } from "../../../labels/BooleanLabels";
import { mapBooleanFilter } from "../../../utils/mapperUtils";

const UserListPage = () => {
    const { userApi } = useApi();
    const search = usePersistedSearch({
        persistenceKey: "user-table-search",
        config: {
            search: singleInput<string>("Search"),
            includeDeleted: singleInput<boolean>("Include Blocked", { display: BooleanLabels }),
        },
        initialValues: {
            includeDeleted: {
                value: false,
            },
            search: {
                value: undefined,
            },
        },
    });
    const { data: users, isLoading: isLoadingUsers } = useQuery(["users", search.state], () => {
        return userApi.getUsers({
            search: search.state?.search?.value || undefined,
            includeDeleted: mapBooleanFilter(search.state?.includeDeleted?.value),
        });
    });

    const [isInvitingUser, { toggle: toggleInvitingUser }] = useBoolean();
    const [userToDelete, setUserToDelete] = useState<User | undefined>(undefined);
    const [userToInvite, setUserToInvite] = useState<User | undefined>(undefined);
    const [userToUnblock, setUserToUnblock] = useState<User | undefined>(undefined);

    const userRolesResolver = (userId: string) => userApi.getUserRoles({ userId: userId });

    return (
        <PageLayout
            title={<PageTitle title="Team members" />}
            pageContext={
                <Menu>
                    <MenuButton as={Button} onClick={toggleInvitingUser}>
                        Invite member
                    </MenuButton>
                </Menu>
            }
        >
            <VStack w={"100%"} gap={2} align={"stretch"}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Search by name"}
                                />
                            ),
                        },
                        {
                            id: "includeDeleted",
                            menuLabel: "Include Blocked",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "includeDeleted" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                    ]}
                />
                <UserTable
                    users={users}
                    isLoading={isLoadingUsers}
                    onDeleteUser={setUserToDelete}
                    onInviteUser={setUserToInvite}
                    onUnblockUser={setUserToUnblock}
                    rolesResolver={userRolesResolver}
                />
                <InviteUserModal isOpen={isInvitingUser} onClose={toggleInvitingUser} />
                <ConfirmDeleteUserModal onClose={() => setUserToDelete(undefined)} user={userToDelete} />
                <ConfirmInviteUserModal onClose={() => setUserToInvite(undefined)} user={userToInvite} />
                <ConfirmUnblockUserModal onClose={() => setUserToUnblock(undefined)} user={userToUnblock} />
            </VStack>
        </PageLayout>
    );
};

export default UserListPage;
