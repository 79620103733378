import React from "react";
import { SimCardCapabilityName } from "../../api";
import { Text, VStack } from "@chakra-ui/react";
import InlineSimCardCapabilityName from "../InlineSimCardCapability/InlineSimCardCapabilityName";

export interface InlineSimCardCapabilityNameListProps {
    simCardCapabilities: SimCardCapabilityName[] | undefined;
}

const InlineSimCardCapabilityNameList = ({ simCardCapabilities }: InlineSimCardCapabilityNameListProps) => {
    if (!simCardCapabilities || simCardCapabilities.length === 0) {
        return <Text>No capabilities supported</Text>;
    }

    return (
        <VStack align={"start"}>
            {simCardCapabilities.map(simCardCapability => (
                <InlineSimCardCapabilityName key={simCardCapability} simCardCapability={simCardCapability} />
            ))}
        </VStack>
    );
};

export default InlineSimCardCapabilityNameList;
