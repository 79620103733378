import React from "react";
import { AbsoluteMarkup, Currency, Markup, OffsetMarkup, PercentageMarkup } from "../../api";
import { MarkupType, resolveMarkupType } from "../../utils/markupUtils";
import { Text } from "@chakra-ui/react";
import { Money, MoneyUnit } from "@cobira/ui-library";

export interface MarkupDetailsProps {
    markup: Markup;
    currency: Currency;
    moneyUnit?: MoneyUnit;
}

const MarkupDetails = ({ markup, currency, moneyUnit }: MarkupDetailsProps) => {
    const markupType = resolveMarkupType(markup);
    if (markupType === MarkupType.ABSOLUTE) {
        const absoluteMarkup = (markup as AbsoluteMarkup).absoluteMarkup;
        return <Money unit={moneyUnit} value={absoluteMarkup} currency={currency} />;
    } else if (markupType === MarkupType.PERCENTAGE) {
        const percentageMarkup = (markup as PercentageMarkup).percentageMarkup;
        return <Text>Cost + {percentageMarkup}%</Text>;
    } else if (markupType === MarkupType.OFFSET) {
        const offsetMarkup = (markup as OffsetMarkup).offsetMarkup;
        return <Text>Cost + {offsetMarkup}</Text>;
    } else {
        return null;
    }
};

export default MarkupDetails;
