import React, { useMemo } from "react";
import { Text, Wrap, WrapItem } from "@chakra-ui/react";
import { BillingGroupCell } from "../../../components/Cells/BillingGroupCell/BillingGroupCell";
import { Paper } from "@cobira/ui-library";

export interface CustomerRecurringFeeBillingGroupCoveragePaperProps {
    billingGroupIds: string[];
}

const CustomerRecurringFeeBillingGroupCoveragePaper = ({
    billingGroupIds,
}: CustomerRecurringFeeBillingGroupCoveragePaperProps) => {
    const content = useMemo(() => {
        if (billingGroupIds.length === 0) {
            return <Text>This recurring fee covers all billing groups for the customer</Text>;
        } else {
            return (
                <>
                    <Text>This recurring fee covers the following billing groups</Text>
                    <Wrap w={"100%"} gap={4}>
                        {billingGroupIds.map(billingGroupId => (
                            <WrapItem key={billingGroupId}>
                                <BillingGroupCell billingGroupId={billingGroupId} />
                            </WrapItem>
                        ))}
                    </Wrap>
                </>
            );
        }
    }, [billingGroupIds]);

    return <Paper header={"Billing Group coverage"}>{content}</Paper>;
};

export default CustomerRecurringFeeBillingGroupCoveragePaper;
