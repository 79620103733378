/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';

/**
 * 
 * @export
 * @interface YearMonth
 */
export interface YearMonth {
    /**
     * A specific year between 2020 and 9999.
     * @type {number}
     * @memberof YearMonth
     */
    year: number;
    /**
     * 
     * @type {Month}
     * @memberof YearMonth
     */
    month: Month;
}

/**
 * Check if a given object implements the YearMonth interface.
 */
export function instanceOfYearMonth(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;

    return isInstance;
}

export function YearMonthFromJSON(json: any): YearMonth {
    return YearMonthFromJSONTyped(json, false);
}

export function YearMonthFromJSONTyped(json: any, ignoreDiscriminator: boolean): YearMonth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'month': MonthFromJSON(json['month']),
    };
}

export function YearMonthToJSON(value?: YearMonth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'month': MonthToJSON(value.month),
    };
}

