import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { CustomerCell } from "../CustomerCell/CustomerCell";

export interface SimCardCustomerCellProps {
    billingGroupId?: string;
}

export const SimCardCustomerCell = ({ billingGroupId }: SimCardCustomerCellProps) => {
    const { billingGroupApi } = useApi();
    if (!billingGroupId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => billingGroupApi.getBillingGroup({ billingGroupId: billingGroupId }),
                queryKey: ["billing-groups", billingGroupId],
            }}
            render={result => <CustomerCell customerId={result?.data?.customerId} />}
        />
    );
};
