import React from "react";
import { PageLayout, PageTitle, usePageNavigation, usePagination } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { NetworkCredentialType } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { VStack } from "@chakra-ui/react";
import NetworkCredentialTypeTable from "../../../components/NetworkCredentialTypeTable/NetworkCredentialTypeTable";

const NetworkCredentialTypeListPage = () => {
    const { configurationApi } = useApi();
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { navigate } = usePageNavigation<NetworkCredentialType>({
        route: value => `/networkcredentialtypes/${value.id}`,
    });

    const networkCredentialTypeQuery = useQuery(["networkcredentialtypes", pageState], () => {
        return configurationApi.getNetworkCredentialTypes({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
        });
    });

    return (
        <>
            <PageLayout title={<PageTitle title="Network Credential Types" />}>
                <VStack w={"100%"} gap={2}>
                    <NetworkCredentialTypeTable
                        networkCredentialTypeQuery={networkCredentialTypeQuery}
                        pagination={{
                            pageIndex: pageState.pageIndex,
                            pageSize: pageState.pageSize,
                            onPaginationChange: setPageState,
                            totalRowCount: networkCredentialTypeQuery?.data?.pageProperties?.totalElements || 0,
                            totalPageCount: networkCredentialTypeQuery?.data?.pageProperties?.totalPages || 0,
                        }}
                        withRowClick={{
                            enableHoverStyle: true,
                            onRowClicked: navigate,
                        }}
                    />
                </VStack>
            </PageLayout>
        </>
    );
};

export default NetworkCredentialTypeListPage;
