import { useApi } from "../../../hooks/useApi";
import {
    ActionCompletedNotification,
    ActionFailedNotification,
    Action,
    BasicModal,
    pluralize,
    useActionNotification,
} from "@cobira/ui-library";
import { SimCardActionRequestFilter, SimcardApiAssignSimCardRequest } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import SimCardActionInProgressNotification from "../../ActionNotification/SimCardActionInProgressNotification/SimCardActionInProgressNotification";
import AssignSimCardForm, { AssignSimCardFormSchemaType } from "../../../forms/AssignSimCardForm/AssignSimCardForm";

export interface MoveSimCardModalProps {
    filter: SimCardActionRequestFilter;
    isOpen: boolean;
    onClose: () => void;
}

const MoveSimCardModal = ({ isOpen, filter, onClose }: MoveSimCardModalProps) => {
    const { simCardApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});

    const moveSimCard = useMutation<Action[], Error, SimcardApiAssignSimCardRequest>(
        variables => simCardApi.assignSimCard(variables),
        {
            onSuccess: data => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: successMessage,
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
        },
    );

    const handleSubmit = useCallback(
        (form: AssignSimCardFormSchemaType) => {
            moveSimCard.mutate({
                assignSimCards: {
                    filter: filter,
                    tenantId: form?.tenantAssignment?.id,
                    billingGroupId: form?.billingGroupAssignmentBillingGroup?.id,
                },
            });
            onClose();
        },
        [moveSimCard, filter, onClose],
    );

    const successMessage = useCallback((data: Action) => {
        return (
            <ActionCompletedNotification
                action={data}
                successTitle={"SIM Cards moved"}
                successMessage={`${data.totalItems} SIM ${pluralize("Cards", data.totalItems)} successfully moved.`}
                failureComponent={<ActionFailedNotification title={"Failed to move SIM Cards"} />}
            />
        );
    }, []);
    const errorMessage = useCallback(() => <ActionFailedNotification title={"Failed to move SIM Cards"} />, []);
    const loadingMessage = useCallback(
        (action: Action) => (
            <SimCardActionInProgressNotification
                actionApi={actionApi}
                actionId={action.id}
                title={"Moving SIM Cards"}
                message={(processedSimCount, totalSimCount) =>
                    `${processedSimCount} SIM ${pluralize("Card", processedSimCount)} of ${totalSimCount} moved`
                }
            />
        ),
        [actionApi],
    );

    return (
        <BasicModal title={`Move SIM Cards`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <AssignSimCardForm onSubmit={handleSubmit} />
            </VStack>
        </BasicModal>
    );
};

export default MoveSimCardModal;
