import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";
import { CobiraTable, PageBackButton, PageLayout, PageTitle, usePagination } from "@cobira/ui-library";
import { MonthLabels } from "../../../labels/MonthLabels";
import { createColumnHelper } from "@tanstack/react-table";
import { InvoiceLine } from "../../../api";
import { CurrencyLabels } from "../../../labels/CurrencyLabels";
import { BillingGroupCell } from "../../../components/Cells/BillingGroupCell/BillingGroupCell";

export interface CustomerInvoiceDetailPageProps {}

const invoiceLineColumn = createColumnHelper<InvoiceLine>();
const COLUMNS = [
    invoiceLineColumn.accessor("billingGroupId", {
        id: "billingGroup",
        header: "Billing Group",
        cell: row => <BillingGroupCell billingGroupId={row.getValue()} />,
    }),
    invoiceLineColumn.accessor("currency", {
        id: "currency",
        header: "Currency",
        cell: row => CurrencyLabels[row.getValue()],
    }),
    invoiceLineColumn.accessor("netPrice", {
        id: "netPrice",
        header: "Price",
        cell: row => row.getValue(),
    }),
    invoiceLineColumn.accessor("cost", {
        id: "cost",
        header: "Cost",
        cell: row => row.getValue(),
    }),
    invoiceLineColumn.accessor(row => row, {
        id: "earnings",
        header: "Earnings",
        cell: row => row.getValue().netPrice - row.getValue().cost,
    }),
    invoiceLineColumn.accessor("amount", {
        id: "amount",
        header: "Used Amount",
        cell: row => row.getValue(),
    }),
    invoiceLineColumn.accessor("chargeGroup", {
        id: "chargeGroup",
        header: "Charge Group",
        cell: row => row.getValue(),
    }),
];

const CustomerInvoiceDetailPage = () => {
    const { customerId, invoiceId } = useParams();
    const { invoiceApi } = useApi();
    const { data: invoice } = useQuery(
        ["invoices", invoiceId],
        () => invoiceApi.getInvoice({ invoiceId: invoiceId || "" }),
        { enabled: !!invoiceId },
    );

    const { pageState, setPageState } = usePagination({ pageIndex: 0, pageSize: 20 });
    const { data: invoiceLines, isLoading: isLoadingInvoiceLines } = useQuery(
        ["invoices", invoiceId, "invoicelines"],
        () =>
            invoiceApi.getInvoiceLines({
                invoiceId: invoiceId || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
            }),
        { enabled: !!invoiceId },
    );

    return (
        <PageLayout
            title={
                <PageTitle title={`${invoice?.year} - ${MonthLabels[invoice?.month || "UNDEFINED"]}` || "Invoice"} />
            }
            navigation={<PageBackButton text={"Back to customer"} path={`/customers/${customerId}`} />}
        >
            <CobiraTable
                columns={COLUMNS}
                data={invoiceLines?.content || []}
                isLoading={isLoadingInvoiceLines}
                withPagination={{
                    pageIndex: pageState.pageIndex,
                    pageSize: pageState.pageSize,
                    onPaginationChange: setPageState,
                    totalRowCount: invoiceLines?.pageProperties?.totalElements || 0,
                    totalPageCount: invoiceLines?.pageProperties?.totalPages || 0,
                }}
            />
        </PageLayout>
    );
};

export default CustomerInvoiceDetailPage;
