import React from "react";
import { z } from "zod";
import { WebhookSubscriptionType } from "../../api";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteMultiSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, Input, Textarea } from "@chakra-ui/react";
import { WebhookSubscriptionTypeLabels } from "../../labels/WebhookSubscriptionTypeLabels";

const WebhookFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().optional(),
    target: z
        .string()
        .min(1, "Target is required")
        .url("Not a valid url")
        .refine(url => !url.toLowerCase().includes("cobira.co"), { message: "URL Cannot contain cobira.co" })
        .refine(url => !url.toLowerCase().includes("localhost") && !url.toLowerCase().includes("127.0.0.1"), {
            message: "Localhost not allowed",
        }),
    subscriptions: z.array(z.nativeEnum(WebhookSubscriptionType)).min(1, "At least 1 subscription is required"),
});

export type WebhookFormType = z.infer<typeof WebhookFormSchema>;

export interface WebhookFormProps {
    defaultValue?: WebhookFormType;
    onSubmit: (form: WebhookFormType) => void;
}

const WebhookForm = ({ defaultValue, onSubmit }: WebhookFormProps) => {
    const {
        formState: { errors },
        register,
        handleSubmit,
        control,
    } = useForm<WebhookFormType>({
        resolver: zodResolver(WebhookFormSchema),
        defaultValues: defaultValue || undefined,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <FormField label={"Description"} error={errors?.description}>
                    <Textarea minH={"100px"} {...register("description")} />
                </FormField>
                <FormField label={"Target"} error={errors?.target}>
                    <Input {...register("target")} type={"url"} />
                </FormField>
                <FormField label={"Types"} error={errors?.subscriptions}>
                    <ControlledAutoCompleteMultiSelectInput
                        control={{
                            name: "subscriptions",
                            control: control,
                        }}
                        input={{
                            items: Object.values(WebhookSubscriptionType),
                            displayMapping: type => WebhookSubscriptionTypeLabels[type],
                            autocompleteAbleMapping: type => type,
                        }}
                    />
                </FormField>
            </FormContainer>
            <Button type={"submit"}>Create</Button>
        </form>
    );
};

export default WebhookForm;
