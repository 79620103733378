import React, { useMemo, useState } from "react";
import { IconButton, TabPanel } from "@chakra-ui/react";
import { CobiraTable, DeleteIcon } from "@cobira/ui-library";

import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { AccessToken, Integration } from "../../../api";
import { useApi } from "../../../hooks/useApi";
import AccessTokenDetailModal from "../../../components/Modal/AccessTokenDetailModal/AccessTokenDetailModal";
import ConfirmDeleteAccessTokenModal from "../../../components/Modal/ConfirmDeleteAccessTokenModal/ConfirmDeleteAccessTokenModal";

export interface AccessTokensPanelProps {
    integration: Integration;
}

type OnDeleteFunction = (accessToken: AccessToken) => void;

const accessTokenColumn = createColumnHelper<AccessToken>();
const COLUMNS = (onDelete: OnDeleteFunction) => [
    accessTokenColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    accessTokenColumn.display({
        id: "actions",
        meta: {
            isButton: true,
        },
        cell: context => (
            <IconButton
                aria-label={"delete"}
                icon={<DeleteIcon />}
                colorScheme={"red"}
                variant={"icon"}
                isRound
                onClick={() => onDelete(context.row.original)}
            />
        ),
    }),
];

const AccessTokensPanel = ({ integration }: AccessTokensPanelProps) => {
    const { integrationApi } = useApi();
    const [selectedAccessToken, setSelectedAccessToken] = useState<AccessToken | undefined>(undefined);
    const [accessTokenToDelete, setAccessTokenToDelete] = useState<AccessToken | undefined>(undefined);
    const { data: accessTokens, isLoading } = useQuery(["integrations", integration.id, "accesstokens"], () =>
        integrationApi.getAccessTokens({ integrationId: integration.id }),
    );

    const columns = useMemo(() => COLUMNS(setAccessTokenToDelete), [setAccessTokenToDelete]);
    return (
        <TabPanel>
            <CobiraTable
                columns={columns}
                data={accessTokens || []}
                sizing={"fit-page"}
                isLoading={isLoading}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: setSelectedAccessToken,
                }}
                withPagination
            />
            <AccessTokenDetailModal
                integration={integration}
                onClose={() => setSelectedAccessToken(undefined)}
                accessToken={selectedAccessToken}
            />
            <ConfirmDeleteAccessTokenModal
                onClose={() => setAccessTokenToDelete(undefined)}
                accessToken={accessTokenToDelete}
                integration={integration}
            />
        </TabPanel>
    );
};

export default AccessTokensPanel;
