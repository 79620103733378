/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NetworkAccessRate } from './NetworkAccessRate';
import {
    NetworkAccessRateFromJSON,
    NetworkAccessRateFromJSONTyped,
    NetworkAccessRateToJSON,
} from './NetworkAccessRate';
import type { TelcoRate } from './TelcoRate';
import {
    TelcoRateFromJSON,
    TelcoRateFromJSONTyped,
    TelcoRateToJSON,
} from './TelcoRate';

/**
 * Information required to create a new rate plan.
 * @export
 * @interface CreateRatePlan
 */
export interface CreateRatePlan {
    /**
     * 
     * @type {string}
     * @memberof CreateRatePlan
     */
    customName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRatePlan
     */
    description?: string | null;
    /**
     * 
     * @type {TelcoRate}
     * @memberof CreateRatePlan
     */
    telcoRates: TelcoRate | null;
    /**
     * 
     * @type {NetworkAccessRate}
     * @memberof CreateRatePlan
     */
    networkAccessRates?: NetworkAccessRate | null;
}

/**
 * Check if a given object implements the CreateRatePlan interface.
 */
export function instanceOfCreateRatePlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customName" in value;
    isInstance = isInstance && "telcoRates" in value;

    return isInstance;
}

export function CreateRatePlanFromJSON(json: any): CreateRatePlan {
    return CreateRatePlanFromJSONTyped(json, false);
}

export function CreateRatePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRatePlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customName': json['customName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'telcoRates': TelcoRateFromJSON(json['telcoRates']),
        'networkAccessRates': !exists(json, 'networkAccessRates') ? undefined : NetworkAccessRateFromJSON(json['networkAccessRates']),
    };
}

export function CreateRatePlanToJSON(value?: CreateRatePlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customName': value.customName,
        'description': value.description,
        'telcoRates': TelcoRateToJSON(value.telcoRates),
        'networkAccessRates': NetworkAccessRateToJSON(value.networkAccessRates),
    };
}

