import { Customer, NetworkState } from "../../../../api";
import {
    ActivatedSimCardsIcon,
    ActiveSimCardsIcon,
    ComparisonTrendIndicator,
    formatToKSyntax,
    IdleSimCardsIcon,
    InfoCard,
    InfoCards,
    MetricInfoCard,
    newRequest,
    SimCardIcon,
    SuspendedSimCardsIcon,
} from "@cobira/ui-library";
import React from "react";
import { useSimCardCount } from "../../../../hooks/useSimCardCount";
import { LinkBox, LinkOverlay } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import useSimCardSearch from "../../../../hooks/useSimCardSearch";
import { useMetricsResolver } from "../../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";

export interface CustomerInfoCardsProps {
    customer?: Customer;
    isLoading?: boolean;
}

const CustomerInfoCards = ({ customer, isLoading }: CustomerInfoCardsProps) => {
    const oneMonthAgo = subMonths(new Date(), 1);
    const twoMonthsAgo = subMonths(oneMonthAgo, 1);
    const activatedSimCards = useSimCardCount({
        filter: { networkState: "ACTIVE", customerId: customer?.id },
        isLoading: isLoading,
    });
    const suspendedSimCards = useSimCardCount({
        filter: { networkState: "SUSPENDED", customerId: customer?.id },
        isLoading: isLoading,
    });
    const idleSimCards = useSimCardCount({
        filter: { networkState: "IDLE", customerId: customer?.id },
        isLoading: isLoading,
    });
    const totalSimCards = (activatedSimCards || 0) + (suspendedSimCards || 0) + (idleSimCards || 0);
    const search = useSimCardSearch({});
    const setCustomerSimCardFilter = (simCardState?: NetworkState) => () => {
        search.setState({
            simCardState: {
                value: simCardState,
            },
            customer: {
                value: customer,
            },
        });
    };

    const metricsResolver = useMetricsResolver();

    if (!customer) {
        return <></>;
    }

    return (
        <InfoCards>
            <LinkBox h={"100%"}>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setCustomerSimCardFilter()}>
                    <InfoCard
                        title={"Total SIM Cards"}
                        value={`${totalSimCards}`}
                        icon={SimCardIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
            <LinkBox>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setCustomerSimCardFilter("ACTIVE")}>
                    <InfoCard
                        title={"Activated SIM Cards"}
                        value={`${activatedSimCards}`}
                        icon={ActivatedSimCardsIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
            <MetricInfoCard
                title={"Active SIM Cards"}
                resolver={metricsResolver}
                icon={ActiveSimCardsIcon}
                comparableRequest={{
                    request: newRequest({
                        bucket: "MONTHLY",
                        dataPoint: "ACTIVE_SIM",
                        group: "CUSTOMER",
                        groupId: customer.id,
                        fromDateTime: oneMonthAgo,
                        toDateTime: oneMonthAgo,
                    }),
                    comparisonRequest: newRequest({
                        bucket: "MONTHLY",
                        dataPoint: "ACTIVE_SIM",
                        group: "CUSTOMER",
                        groupId: customer.id,
                        fromDateTime: twoMonthsAgo,
                        toDateTime: twoMonthsAgo,
                    }),
                    cardProps: (metric, compareMetric) => ({
                        value: formatToKSyntax(metric?.value || 0),
                        subtitle: (
                            <ComparisonTrendIndicator
                                oldValue={compareMetric?.value || 0}
                                newValue={metric?.value || 0}
                                suffixText={"since previous month"}
                            />
                        ),
                    }),
                }}
            />
            <LinkBox>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setCustomerSimCardFilter("SUSPENDED")}>
                    <InfoCard
                        title={"Suspended SIM Cards"}
                        value={`${suspendedSimCards}`}
                        icon={SuspendedSimCardsIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
            <LinkBox>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setCustomerSimCardFilter("IDLE")}>
                    <InfoCard
                        title={"Idle SIM Cards"}
                        value={`${idleSimCards}`}
                        icon={IdleSimCardsIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
        </InfoCards>
    );
};

export default CustomerInfoCards;
