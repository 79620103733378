import { useApi } from "../../../hooks/useApi";
import {
    ActionCompletedNotification,
    ActionFailedNotification,
    Action,
    ConfirmModal,
    InfoBox,
    pluralize,
    useActionNotification,
} from "@cobira/ui-library";
import {
    Action as MvnoAction,
    SimCardActionRequestFilter,
    SimcardApiChangeSimCardNetworkStateRequest,
} from "../../../api";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { Text } from "@chakra-ui/react";
import SimCardActionInProgressNotification from "../../ActionNotification/SimCardActionInProgressNotification/SimCardActionInProgressNotification";
import { useSimCardCount } from "../../../hooks/useSimCardCount";

export interface ChangeStateSimCardsModalProps {
    filter: SimCardActionRequestFilter;
    isOpen: boolean;
    onClose: () => void;
    targetState: "ACTIVATE" | "SUSPEND" | "RESET";
}

const ChangeStateSimCardsModal = ({ isOpen, filter, onClose, targetState }: ChangeStateSimCardsModalProps) => {
    const { simCardApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});

    const simCardCount = useSimCardCount({ filter });

    const successMessage = useCallback(
        (data: Action) => {
            switch (targetState) {
                case "ACTIVATE":
                    return (
                        <ActionCompletedNotification
                            action={data}
                            successTitle={"Activation completed"}
                            successMessage={`Activation of ${data.totalItems} SIM ${pluralize(
                                "Cards",
                                data.totalItems,
                            )} completed`}
                            failureComponent={<ActionFailedNotification title={"Activation failure"} />}
                        />
                    );
                case "SUSPEND":
                    return (
                        <ActionCompletedNotification
                            action={data}
                            successTitle={"Suspend completed"}
                            successMessage={`Suspend of ${data.totalItems} SIM ${pluralize(
                                "Cards",
                                data.totalItems,
                            )} completed`}
                            failureComponent={<ActionFailedNotification title={"Suspend failure"} />}
                        />
                    );
                case "RESET":
                    return (
                        <ActionCompletedNotification
                            action={data}
                            successTitle={"Reset completed"}
                            successMessage={`Reset of ${data.totalItems} SIM ${pluralize(
                                "Cards",
                                data.totalItems,
                            )} completed`}
                            failureComponent={<ActionFailedNotification title={"Reset failure"} />}
                        />
                    );
            }
        },
        [targetState],
    );
    const errorMessage = useCallback(() => {
        switch (targetState) {
            case "ACTIVATE":
                return <ActionFailedNotification title={"Activation failed"} />;
            case "SUSPEND":
                return <ActionFailedNotification title={"Suspend failed"} />;
            case "RESET":
                return <ActionFailedNotification title={"Reset failed"} />;
        }
    }, [targetState]);
    const loadingMessage = useCallback(
        (action: MvnoAction) => {
            switch (targetState) {
                case "ACTIVATE":
                    return (
                        <SimCardActionInProgressNotification
                            actionApi={actionApi}
                            actionId={action.id}
                            title={"Activating SIM Cards"}
                            message={(processedSimCount, totalSimCount) =>
                                `${processedSimCount} SIM ${pluralize(
                                    "Card",
                                    processedSimCount,
                                )} of ${totalSimCount} have been activated`
                            }
                        />
                    );
                case "SUSPEND":
                    return (
                        <SimCardActionInProgressNotification
                            actionApi={actionApi}
                            actionId={action.id}
                            title={"Suspending SIM Cards"}
                            message={(processedSimCount, totalSimCount) =>
                                `${processedSimCount} SIM ${pluralize(
                                    "Card",
                                    processedSimCount,
                                )} of ${totalSimCount} have been suspended`
                            }
                        />
                    );
                case "RESET":
                    return (
                        <SimCardActionInProgressNotification
                            actionApi={actionApi}
                            actionId={action.id}
                            title={"Resetting SIM Cards"}
                            message={(processedSimCount, totalSimCount) =>
                                `${processedSimCount} SIM ${pluralize(
                                    "Card",
                                    processedSimCount,
                                )} of ${totalSimCount} have been reset`
                            }
                        />
                    );
            }
        },
        [actionApi, targetState],
    );

    const changeSimCardState = useMutation<MvnoAction[], Error, SimcardApiChangeSimCardNetworkStateRequest>(
        variables => simCardApi.changeSimCardNetworkState(variables),
        {
            onSuccess: data => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: successMessage,
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
        },
    );

    const handleConfirm = useCallback(
        (confirmed: boolean) => {
            if (confirmed) {
                changeSimCardState.mutate({
                    networkStateRequest: {
                        simCardFilter: filter,
                        networkStateAction: targetState,
                    },
                });
            }
            onClose();
        },
        [changeSimCardState, filter, onClose, targetState],
    );

    const label = useMemo(() => {
        switch (targetState) {
            case "ACTIVATE":
                return "activate";
            case "SUSPEND":
                return "suspend";
            case "RESET":
                return "reset";
        }
    }, [targetState]);

    const heading = useMemo(() => {
        switch (targetState) {
            case "ACTIVATE":
                return "Activate";
            case "SUSPEND":
                return "Suspend";
            case "RESET":
                return "Reset";
        }
    }, [targetState]);

    return (
        <ConfirmModal
            title={`${heading} SIM Cards`}
            description={
                <InfoBox
                    withIcon
                    status={"warning"}
                    description={
                        <Text>
                            You are about to {label}
                            <br />
                            <b>{simCardCount}</b> SIM {pluralize("Cards", simCardCount)}
                        </Text>
                    }
                />
            }
            onClose={handleConfirm}
            isOpen={isOpen}
        />
    );
};

export default ChangeStateSimCardsModal;
