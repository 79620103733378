import React from "react";
import { PageLayout, PageTitle, usePageNavigation, usePagination } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { SimConfiguration } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { VStack } from "@chakra-ui/react";
import SimConfigurationTable from "../../../components/SimConfigurationTable/SimConfigurationTable";

const SimConfigurationListPage = () => {
    const { configurationApi } = useApi();
    const { pageState, setPageState } = usePagination({ pageIndex: 0, pageSize: 20 });
    const { navigate } = usePageNavigation<SimConfiguration>({
        route: value => `/simconfigurations/${value.id}`,
    });
    const simConfigurationQuery = useQuery(["simconfigurations", pageState, "deleted=true"], () =>
        configurationApi.getSimConfigurations({
            includeDeleted: true,
            pageSize: pageState.pageSize,
            pageNumber: pageState.pageIndex,
        }),
    );

    return (
        <>
            <PageLayout title={<PageTitle title="SIM Configurations" />}>
                <VStack w={"100%"} gap={2}>
                    <SimConfigurationTable
                        simConfigurationQuery={simConfigurationQuery}
                        pagination={{
                            pageIndex: pageState.pageIndex,
                            pageSize: pageState.pageSize,
                            onPaginationChange: setPageState,
                            totalRowCount: simConfigurationQuery?.data?.pageProperties?.totalElements || 0,
                            totalPageCount: simConfigurationQuery?.data?.pageProperties?.totalPages || 0,
                        }}
                        withRowClick={{
                            enableHoverStyle: true,
                            onRowClicked: navigate,
                        }}
                    />
                </VStack>
            </PageLayout>
        </>
    );
};

export default SimConfigurationListPage;
