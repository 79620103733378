import { CountryInfo, DateTime, getCountry, Paper, PlaceholderText, SlimUuid, TextColumn } from "@cobira/ui-library";
import { Grid, VStack } from "@chakra-ui/react";
import { NetworkCredentialTypeCell } from "../Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";
import { CustomerCell } from "../Cells/CustomerCell/CustomerCell";
import { BillingGroupCell } from "../Cells/BillingGroupCell/BillingGroupCell";
import { SmsUsageRecord } from "../../api";
import { SmsDirectionLabels } from "../../labels/SmsDirectionLabels";
import { SmsOriginLabels } from "../../labels/SmsOriginLabels";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";

export interface SmsUsageRecordDetailsProps {
    query: UseQueryResult<SmsUsageRecord>;
}

const SmsUsageRecordDetails = ({ query: { data: usage, isLoading } }: SmsUsageRecordDetailsProps) => {
    return (
        <VStack w={"100%"} align={"stretch"} gap={4}>
            <Paper header={"SIM Details"}>
                <Grid templateColumns={"auto auto auto auto"} rowGap={4}>
                    <TextColumn heading={"ID"} isLoading={isLoading}>
                        <SlimUuid uuid={usage?.id || ""} isCopyable />
                    </TextColumn>
                    <TextColumn heading={"ICC"} isLoading={isLoading}>
                        <PlaceholderText text={usage?.icc} />
                    </TextColumn>
                    <TextColumn heading={"IMSI"} isLoading={isLoading}>
                        <PlaceholderText text={usage?.imsi} />
                    </TextColumn>
                    <TextColumn heading={"Network Credential Type"} isLoading={isLoading}>
                        <NetworkCredentialTypeCell networkCredentialTypeId={usage?.networkCredentialTypeId} />
                    </TextColumn>
                    <TextColumn heading={"Customer"} isLoading={isLoading}>
                        <CustomerCell customerId={usage?.customerId} />
                    </TextColumn>
                    <TextColumn heading={"Billing Group"} isLoading={isLoading}>
                        <BillingGroupCell billingGroupId={usage?.billingGroupId} />
                    </TextColumn>
                    <TextColumn heading={"SMS Direction"} isLoading={isLoading}>
                        {SmsDirectionLabels[usage?.direction || "UNKNOWN"]}
                    </TextColumn>
                    <TextColumn heading={"Type"} isLoading={isLoading}>
                        {SmsOriginLabels[usage?.origin || "UNKNOWN"]}
                    </TextColumn>
                    <TextColumn heading={"Network"} isLoading={isLoading}>
                        <NetworkInfo plmn={usage?.plmn} />
                    </TextColumn>
                    <TextColumn heading={"Country"} isLoading={isLoading}>
                        <CountryInfo country={getCountry(usage?.countryCode)} />
                    </TextColumn>
                    <TextColumn heading={"Recorded At"} isLoading={isLoading}>
                        <DateTime value={usage?.recordedAt} />
                    </TextColumn>
                </Grid>
            </Paper>
        </VStack>
    );
};

export default SmsUsageRecordDetails;
