/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChargeGroup = {
    Default: 'DEFAULT',
    ServiceType: 'SERVICE_TYPE',
    Country: 'COUNTRY',
    Network: 'NETWORK',
    RecurringFee: 'RECURRING_FEE',
    NetworkAccessFee: 'NETWORK_ACCESS_FEE',
    Unknown: 'UNKNOWN'
} as const;
export type ChargeGroup = typeof ChargeGroup[keyof typeof ChargeGroup];


export function ChargeGroupFromJSON(json: any): ChargeGroup {
    return ChargeGroupFromJSONTyped(json, false);
}

export function ChargeGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeGroup {
    return json as ChargeGroup;
}

export function ChargeGroupToJSON(value?: ChargeGroup | null): any {
    return value as any;
}

