import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Customer, CustomerApiCreateCustomerRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import CreateCustomerForm, { CreateCustomerFormSchemaType } from "../../../forms/CreateCustomerForm/CreateCustomerForm";

export interface CreateCustomerModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateCustomerModal = ({ isOpen, onClose }: CreateCustomerModalProps) => {
    const { customerApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createCustomer = useMutation<Customer, Error, CustomerApiCreateCustomerRequest>(
        variables => customerApi.createCustomer(variables),
        {
            onSuccess: data => {
                notifications.success(`Customer '${data.customerName}' created`);
                queryClient.invalidateQueries(["customers"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: CreateCustomerFormSchemaType) => {
            createCustomer.mutate({
                createCustomer: {
                    customerName: form.customerName,
                    contactName: form.contactName || "N/A",
                    shippingAddress: form.shippingAddress || "N/A",
                    contactEmail: form.contactEmail || "N/A",
                    accountNumber: form.accountNumber,
                    billingId: form.billingId,
                    billingAddress: form.billingAddress,
                    billingCurrency: form.billingCurrency,
                    state: form.state,
                },
            });
            onClose();
        },
        [createCustomer, onClose],
    );

    return (
        <BasicModal title={`Create Customer`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <CreateCustomerForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default CreateCustomerModal;
