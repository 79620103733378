import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { SimCard, SmsUsageRecord } from "../../../../api";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useApi } from "../../../../hooks/useApi";
import {
    CobiraTable,
    CountryInfo,
    DateTime,
    getCountry,
    SlimUuid,
    usePageNavigation,
    usePagination,
} from "@cobira/ui-library";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import { createColumnHelper } from "@tanstack/react-table";
import { SmsDirectionLabels } from "../../../../labels/SmsDirectionLabels";
import { SmsOriginLabels } from "../../../../labels/SmsOriginLabels";
import { NetworkCredentialTypeCell } from "../../../../components/Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";
import { UsagePackageCell } from "../../../../components/Cells/UsagePackageCell/UsagePackageCell";

interface SimCardSmsUsagePanelProps {
    query: UseQueryResult<SimCard>;
}

const column = createColumnHelper<SmsUsageRecord>();
const COLUMNS = [
    column.accessor("id", {
        id: "id",
        header: "Usage ID",
        cell: row => <SlimUuid uuid={row.getValue() || ""} />,
    }),
    column.accessor("countryCode", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue())} />,
    }),
    column.accessor("plmn", {
        id: "plmn",
        header: "PLMN",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    column.accessor("direction", {
        id: "direction",
        header: "Direction",
        cell: row => SmsDirectionLabels[row.getValue()],
    }),
    column.accessor("origin", {
        id: "origin",
        header: "Type",
        cell: row => SmsOriginLabels[row.getValue()],
    }),
    column.accessor("recordedAt", {
        id: "recordedAt",
        header: "Sent At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    column.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    column.accessor("networkCredentialTypeId", {
        id: "networkCredentialType",
        header: "Network Credential Type",
        cell: row => <NetworkCredentialTypeCell networkCredentialTypeId={row.getValue()} />,
    }),
    column.accessor(row => row, {
        id: "usagePackage",
        header: "Package",
        cell: row => <UsagePackageCell icc={row.getValue().icc} usagePackageId={row.getValue().usagePackageId} />,
    }),
];

const SimCardSmsUsagePanel = ({ query: { data: value } }: SimCardSmsUsagePanelProps) => {
    const { usageRecordApi } = useApi();
    const pageNavigation = usePageNavigation<SmsUsageRecord>({
        route: value => `/simcards/${value.icc}/usage-records/sms/${value.id}`,
    });
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: sms, isLoading: isLoadingDataSessions } = useQuery(
        ["simcards", value?.icc, "usage-records", "sms", pageState],
        () => {
            const now = new Date();
            const oneMonthAgo = new Date(now);
            oneMonthAgo.setMonth(now.getMonth() - 1);
            return usageRecordApi.getSmsUsageRecords({
                icc: value?.icc || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                fromDate: oneMonthAgo,
                toDate: now,
            });
        },
        { enabled: value?.icc !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={COLUMNS}
                data={sms?.content || []}
                isLoading={isLoadingDataSessions}
                withPagination={{
                    pageSize: pageState.pageSize,
                    pageIndex: pageState.pageIndex,
                    onPaginationChange: setPageState,
                    totalRowCount: sms?.pageProperties?.totalElements || 0,
                    totalPageCount: sms?.pageProperties?.totalPages || 0,
                }}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: pageNavigation.navigate,
                }}
                withColumnSelection={{
                    tableKey: "sim-card-sms-usage-table",
                    maxColumnCount: 7,
                    minColumnCount: 2,
                }}
            />
        </TabPanel>
    );
};

export default SimCardSmsUsagePanel;
