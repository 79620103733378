import React, { useState } from "react";
import { SearchAsyncSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { Customer } from "../../../api";
import { useApi } from "../../../hooks/useApi";

export interface SearchSelectCustomerInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<Customer, K, TSearchStateConfig>, "label"> {}

export const SearchSelectCustomerInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "Select Customer",
}: SearchSelectCustomerInputProps<K, TSearchStateConfig>) => {
    const { customerApi } = useApi();
    const [customerInputValue, setCustomerInputValue] = useState<string | null>(null);
    const { data: customers, isLoading } = useQuery(["customers", customerInputValue], () =>
        customerApi.getCustomers({
            search: customerInputValue || undefined,
            pageNumber: 0,
            pageSize: 50,
        }),
    );
    return (
        <SearchAsyncSelectInput
            placeholder={placeholder}
            items={customers?.content || []}
            displayMapping={customer => customer.customerName}
            autocompleteAbleMapping={customer => customer.customerName}
            registration={registration}
            isLoading={isLoading}
            onInputChange={value => setCustomerInputValue(value)}
        />
    );
};
