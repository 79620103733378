import {
    ActionApi,
    BaseAPI,
    BillingGroupApi,
    Configuration,
    ConfigurationApi,
    CustomerApi,
    IntegrationApi,
    InvoiceApi,
    MetricsApi,
    NetworkApi,
    RateplanApi,
    SimcardApi,
    TenantApi,
    UsagePackageApi,
    UsageRecordApi,
    UserApi,
} from "../api";
import { useCobiraEnvironment } from "@cobira/ui-library";
import { useAuth0 } from "@auth0/auth0-react";
import * as runtime from "../api/runtime";

class DocumentationApi extends BaseAPI {
    async downloadWebhookSchemas(): Promise<Blob> {
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/zip";

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhook-schemas.zip`,
            method: "GET",
            headers: headerParameters,
        });

        return response.blob();
    }

    async downloadApiDocumentation(): Promise<Blob> {
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/text";
        const response = await this.request({
            path: `/openapi.yaml`,
            method: "GET",
            headers: headerParameters,
        });

        return response.blob();
    }
}

export const useApi = () => {
    const [, environment] = useCobiraEnvironment();
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const apiConfiguration = new Configuration({
        accessToken: getAccessTokenSilently()
            .then(value => value)
            .catch(() => {
                loginWithRedirect();
                return "";
            }),
        basePath: environment.apiUrl,
    });

    return {
        actionApi: new ActionApi(apiConfiguration),
        simCardApi: new SimcardApi(apiConfiguration),
        billingGroupApi: new BillingGroupApi(apiConfiguration),
        networkApi: new NetworkApi(apiConfiguration),
        usagePackageApi: new UsagePackageApi(apiConfiguration),
        integrationApi: new IntegrationApi(apiConfiguration),
        usageRecordApi: new UsageRecordApi(apiConfiguration),
        customerApi: new CustomerApi(apiConfiguration),
        ratePlanApi: new RateplanApi(apiConfiguration),
        invoiceApi: new InvoiceApi(apiConfiguration),
        tenantApi: new TenantApi(apiConfiguration),
        userApi: new UserApi(apiConfiguration),
        metricsApi: new MetricsApi(apiConfiguration),
        configurationApi: new ConfigurationApi(apiConfiguration),
        documentationApi: new DocumentationApi(apiConfiguration),
    };
};
