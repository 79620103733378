import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { Tenant, TenantApiSendTenantInvitationRequest } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";

export interface ConfirmInviteTenantModalProps {
    onClose: () => void;
    tenant?: Tenant;
}

const ConfirmInviteTenantModal = ({ onClose, tenant }: ConfirmInviteTenantModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();

    const inviteTenant = useMutation<void, Error, TenantApiSendTenantInvitationRequest>(variables =>
        tenantApi.sendTenantInvitation(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && tenant?.id) {
                inviteTenant.mutate(
                    { tenantId: tenant.id },
                    {
                        onSuccess: () => {
                            notifications.success("Invitation has been sent");
                        },
                    },
                );
            }
            onClose();
        },
        [tenant, inviteTenant, notifications, onClose],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!tenant}
            title={"Send Tenant Invitation"}
            description={
                <Container>
                    <Text>Send a new invitation to the tenant</Text>
                    <Text as={"b"}>
                        {tenant?.adminName} ({tenant?.adminEmail})
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmInviteTenantModal;
