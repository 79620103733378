/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Describes the unit type of an amount property
 * @export
 */
export const AmountType = {
    Byte: 'BYTE',
    Sms: 'SMS',
    SimCard: 'SIM_CARD',
    Imsi: 'IMSI',
    Pcs: 'PCS'
} as const;
export type AmountType = typeof AmountType[keyof typeof AmountType];


export function AmountTypeFromJSON(json: any): AmountType {
    return AmountTypeFromJSONTyped(json, false);
}

export function AmountTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmountType {
    return json as AmountType;
}

export function AmountTypeToJSON(value?: AmountType | null): any {
    return value as any;
}

