import React from "react";
import { formatMoney, InfoPopover, PlaceholderText } from "@cobira/ui-library";
import { CustomerRecurringFee, RecurringFeePricing } from "../../../api";
import { Text } from "@chakra-ui/react";
import { recurringFeePopoverContentMapper } from "./recurringFeePopoverContentMapper";

export interface RecurringFeePricingCellProps {
    customerRecurringFee?: CustomerRecurringFee;
}

const CustomerRecurringFeePricingCell = ({ customerRecurringFee }: RecurringFeePricingCellProps) => {
    const mapPricingText = (pricing: RecurringFeePricing) => {
        if (pricing.priceBounds.length === 1) {
            return <Text>{formatMoney(pricing.priceBounds[0].price, customerRecurringFee?.pricing.currency)}</Text>;
        } else {
            return <Text>{pricing.priceBounds.length} bounds</Text>;
        }
    };

    if (!customerRecurringFee?.pricing || !customerRecurringFee?.pricing.priceBounds) {
        return <PlaceholderText text={null} />;
    }

    return (
        <InfoPopover value={recurringFeePopoverContentMapper(customerRecurringFee)}>
            {mapPricingText(customerRecurringFee?.pricing)}
        </InfoPopover>
    );
};

export default CustomerRecurringFeePricingCell;
