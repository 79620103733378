import { SimCardCapabilityName } from "../api";

export const SimCardCapabilityNameLabels = {
    [SimCardCapabilityName.NoOp]: "No Op",
    [SimCardCapabilityName.Ping]: "Ping device",
    [SimCardCapabilityName.Sms]: "SMS Support",
    [SimCardCapabilityName.HardwareRegistration]: "Hardware registration",
    [SimCardCapabilityName.CellularLocationReport]: "Cellular based location",
    [SimCardCapabilityName.PrivateApn]: "Private APN",
    [SimCardCapabilityName.PublicApn]: "Public APN",
    [SimCardCapabilityName.SignalConditionsReport]: "Signal conditions reporting",
    [SimCardCapabilityName.UsagePackageControl]: "Usage packages",
    [SimCardCapabilityName.UsageSimulation]: "Usage simulation",
};

export const SimCardCapabilityNameDescriptions = {
    [SimCardCapabilityName.NoOp]: "Doesn't do anything, but allows the caller to try the flow",
    [SimCardCapabilityName.Ping]: "Ping the device to check whether it can be reached",
    [SimCardCapabilityName.Sms]: "Send an SMS to the device",
    [SimCardCapabilityName.HardwareRegistration]: "Fetches information from the device the SIM is slotted into",
    [SimCardCapabilityName.CellularLocationReport]: "Report the approximate location based on cellular information",
    [SimCardCapabilityName.PrivateApn]: "Traffic is routed through a private network",
    [SimCardCapabilityName.PublicApn]: "Device will receive a public static IP",
    [SimCardCapabilityName.SignalConditionsReport]:
        "Report the current signal conditions from the device the SIM is slotted into",
    [SimCardCapabilityName.UsagePackageControl]: "Control usage through Usage Packages",
    [SimCardCapabilityName.UsageSimulation]: "Simulate usage for testing purposes",
};
