import React from "react";
import { Box, TabPanel, VStack } from "@chakra-ui/react";
import {
    CobiraBarChart,
    CobiraPieChart,
    Loading,
    Metric,
    MetricDimensions,
    MetricLabelFormatters,
    MetricsProvider,
    MetricTooltipFormatters,
    Month,
    monthToInt,
    newRequest,
    NotFound,
    TickFormatters,
} from "@cobira/ui-library";
import { SimCard } from "../../../../api";
import { subMonths } from "date-fns";
import { useMetricsResolver } from "../../../../hooks/useMetricsResolver";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import SimCardDataUsagePieChartHeader from "./SimCardDataUsagePieChartHeader";

export interface SimCardDataUsagePanelProps {
    simCard?: SimCard;
    showAllUsage?: boolean;
    selectedUsageYear: number | null;
    selectedUsageMonth: Month | null;
}

const SimCardDataUsagePanel = ({
    simCard,
    selectedUsageYear,
    selectedUsageMonth,
    showAllUsage,
}: SimCardDataUsagePanelProps) => {
    const metricsResolver = useMetricsResolver();

    const now = new Date();
    const twelveMonthsAgo = subMonths(now, 12);

    if (!simCard) {
        return (
            <TabPanel>
                <Loading />
            </TabPanel>
        );
    }
    return (
        <TabPanel>
            <Box w={"100%"} h={"600px"}>
                {showAllUsage && (
                    <MetricsProvider
                        requests={{
                            dataUsage: newRequest({
                                bucket: "MONTHLY",
                                dataPoint: "DATA_USAGE",
                                group: "SIM_CARD",
                                groupId: simCard.id,
                                fromDateTime: twelveMonthsAgo,
                                toDateTime: now,
                            }),
                        }}
                        resolver={metricsResolver}
                    >
                        {data => (
                            <CobiraBarChart
                                bars={[{ dataSet: data => data.dataUsage.value, dataSetName: "Usage" }]}
                                data={data}
                                chartConfig={{
                                    tooltipFormatter: MetricTooltipFormatters.dataTooltip,
                                    yAxisTickFormatter: TickFormatters.dataTickFormatter,
                                    xAxisValueLabel: data => MetricLabelFormatters.yearAndMonth(data.dataUsage),
                                }}
                            />
                        )}
                    </MetricsProvider>
                )}
                {!showAllUsage && selectedUsageYear && selectedUsageMonth && (
                    <MetricsProvider
                        requests={{
                            dataUsage: newRequest({
                                bucket: "MONTHLY",
                                dataPoint: "DATA_USAGE",
                                group: "SIM_CARD",
                                groupId: simCard.id,
                                fromDateTime: new Date(Date.UTC(selectedUsageYear, monthToInt(selectedUsageMonth), 1)),
                                toDateTime: new Date(Date.UTC(selectedUsageYear, monthToInt(selectedUsageMonth), 1)),
                                dimensions: [MetricDimensions.NETWORK_PLMN],
                            }),
                        }}
                        resolver={metricsResolver}
                    >
                        {data =>
                            data.dataUsage.length === 0 ? (
                                <NotFound resource={"Data Usage"} />
                            ) : (
                                <VStack w={"100%"} h={"100%"}>
                                    <SimCardDataUsagePieChartHeader
                                        month={selectedUsageMonth}
                                        year={selectedUsageYear}
                                        data={data.dataUsage}
                                    />
                                    <CobiraPieChart
                                        data={data.dataUsage}
                                        config={{
                                            legend: (metric: Metric) => (
                                                <NetworkInfo plmn={metric.dimensions?.at(0)?.id} />
                                            ),
                                            label: (metric: Metric) =>
                                                MetricTooltipFormatters.dataTooltip(metric.value),
                                        }}
                                    />
                                </VStack>
                            )
                        }
                    </MetricsProvider>
                )}
            </Box>
        </TabPanel>
    );
};

export default SimCardDataUsagePanel;
