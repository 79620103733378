import React, { useState } from "react";
import { TabPanel } from "@chakra-ui/react";
import { NetworkCredentialType, Tenant } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { usePageNavigation, usePagination } from "@cobira/ui-library";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import NetworkCredentialTypeTable from "../../../../components/NetworkCredentialTypeTable/NetworkCredentialTypeTable";
import ConfirmDeleteChildTenantNetworkCredentialTypeModal from "../../../../components/Modal/ConfirmDeleteChildTenantNetworkCredentialTypeModal/ConfirmDeleteChildTenantNetworkCredentialTypeModal";
import ConfirmRestoreChildTenantNetworkCredentialTypeModal from "../../../../components/Modal/ConfirmRestoreChildTenantNetworkCredentialTypeModal/ConfirmRestoreChildTenantNetworkCredentialTypeModal";

export interface TenantNetworkCredentialTypePanelProps {
    query: UseQueryResult<Tenant>;
}

const TenantNetworkCredentialTypePanel = ({ query: { data: tenant } }: TenantNetworkCredentialTypePanelProps) => {
    const { tenantApi } = useApi();
    const { pageState, setPageState } = usePagination({ pageIndex: 0, pageSize: 20 });
    const { navigate } = usePageNavigation<NetworkCredentialType>({
        route: value => `/tenants/${tenant?.id}/networkcredentialtypes/${value.id}`,
    });

    const [networkCredentialTypeToDelete, setNetworkCredentialTypeToDelete] = useState<
        NetworkCredentialType | undefined
    >(undefined);
    const [networkCredentialTypeToRestore, setNetworkCredentialTypeToRestore] = useState<
        NetworkCredentialType | undefined
    >(undefined);
    const networkCredentialTypeQuery = useQuery(
        ["tenants", tenant?.id, "networkcredentialtypes", pageState, "deleted=true"],
        () =>
            tenantApi.getTenantNetworkCredentialTypes({
                tenantId: tenant?.id || "",
                includeDeleted: true,
                pageSize: pageState.pageSize,
                pageNumber: pageState.pageIndex,
            }),
        { enabled: !!tenant?.id },
    );

    return (
        <TabPanel w={"100%"}>
            <NetworkCredentialTypeTable
                networkCredentialTypeQuery={networkCredentialTypeQuery}
                pagination={{
                    pageIndex: pageState.pageIndex,
                    pageSize: pageState.pageSize,
                    onPaginationChange: setPageState,
                    totalRowCount: networkCredentialTypeQuery?.data?.pageProperties?.totalElements || 0,
                    totalPageCount: networkCredentialTypeQuery?.data?.pageProperties?.totalPages || 0,
                }}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: navigate,
                }}
                actions={{
                    onDeleteClicked: setNetworkCredentialTypeToDelete,
                    onRestoreClicked: setNetworkCredentialTypeToRestore,
                }}
            />
            <ConfirmDeleteChildTenantNetworkCredentialTypeModal
                isOpen={!!networkCredentialTypeToDelete}
                onClose={() => setNetworkCredentialTypeToDelete(undefined)}
                networkCredentialType={networkCredentialTypeToDelete}
                childTenant={tenant}
            />
            <ConfirmRestoreChildTenantNetworkCredentialTypeModal
                isOpen={!!networkCredentialTypeToRestore}
                onClose={() => setNetworkCredentialTypeToRestore(undefined)}
                networkCredentialType={networkCredentialTypeToRestore}
                childTenant={tenant}
            />
        </TabPanel>
    );
};

export default TenantNetworkCredentialTypePanel;
