import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";

const CreateCustomerContactFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    email: z.string().email("Please specify a valid email").min(1, "Email is required"),
});

export type CreateCustomerContactFormSchemaType = z.infer<typeof CreateCustomerContactFormSchema>;

export interface CreateCustomerContactFormProps {
    onSubmit: (form: CreateCustomerContactFormSchemaType) => void;
    onCancel?: () => void;
}

const CreateCustomerContactForm = ({ onSubmit, onCancel }: CreateCustomerContactFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<CreateCustomerContactFormSchemaType>({
        resolver: zodResolver(CreateCustomerContactFormSchema),
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <FormField label={"Email"} error={errors?.email}>
                    <Input {...register("email")} type={"email"} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default CreateCustomerContactForm;
