import React, { useMemo } from "react";
import { NodeApi } from "react-arborist";
import { HStack, Tag } from "@chakra-ui/react";
import { TelcoRate } from "../../../../api";
import { CountryInfo, getCountry, pluralize } from "@cobira/ui-library";

export interface TelcoRateCountryBodyProps {
    node: NodeApi<TelcoRate>;
}

const TelcoRateCountryBody = ({ node }: TelcoRateCountryBodyProps) => {
    const content = useMemo(() => {
        const mapCountry = (country: string) => (
            <Tag key={`${node.data.rateDiscriminator}-${country}`} colorScheme={"gray"}>
                <CountryInfo country={getCountry(country)} />
            </Tag>
        );
        const maxRenderedCountries = 3;
        const plmns = Array.from(node.data.rateKey);
        if (plmns.length > maxRenderedCountries) {
            const overflowCountryCount = plmns.length - maxRenderedCountries;
            return [
                ...plmns.slice(0, maxRenderedCountries).map(mapCountry),
                <Tag key={`${node.data.rateDiscriminator}-remaining`}>{`${overflowCountryCount} additional ${pluralize(
                    "country",
                    overflowCountryCount,
                )}`}</Tag>,
            ];
        } else {
            return plmns.map(mapCountry);
        }
    }, [node.data.rateDiscriminator, node.data.rateKey]);
    return <HStack gap={2}>{content}</HStack>;
};

export default TelcoRateCountryBody;
