/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillableFeeLine } from './BillableFeeLine';
import {
    BillableFeeLineFromJSON,
    BillableFeeLineFromJSONTyped,
    BillableFeeLineToJSON,
} from './BillableFeeLine';
import type { CostFeeLine } from './CostFeeLine';
import {
    CostFeeLineFromJSON,
    CostFeeLineFromJSONTyped,
    CostFeeLineToJSON,
} from './CostFeeLine';

/**
 * 
 * @export
 * @interface BillingReportLinesFees
 */
export interface BillingReportLinesFees {
    /**
     * 
     * @type {Array<CostFeeLine>}
     * @memberof BillingReportLinesFees
     */
    costs: Array<CostFeeLine>;
    /**
     * 
     * @type {Array<BillableFeeLine>}
     * @memberof BillingReportLinesFees
     */
    billables: Array<BillableFeeLine>;
}

/**
 * Check if a given object implements the BillingReportLinesFees interface.
 */
export function instanceOfBillingReportLinesFees(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costs" in value;
    isInstance = isInstance && "billables" in value;

    return isInstance;
}

export function BillingReportLinesFeesFromJSON(json: any): BillingReportLinesFees {
    return BillingReportLinesFeesFromJSONTyped(json, false);
}

export function BillingReportLinesFeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingReportLinesFees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costs': ((json['costs'] as Array<any>).map(CostFeeLineFromJSON)),
        'billables': ((json['billables'] as Array<any>).map(BillableFeeLineFromJSON)),
    };
}

export function BillingReportLinesFeesToJSON(value?: BillingReportLinesFees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costs': ((value.costs as Array<any>).map(CostFeeLineToJSON)),
        'billables': ((value.billables as Array<any>).map(BillableFeeLineToJSON)),
    };
}

