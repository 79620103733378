import React from "react";
import { Box, Grid, TabPanel, VStack } from "@chakra-ui/react";
import { Tenant } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { Paper, TextColumn } from "@cobira/ui-library";
import TenantDataUsagePaper from "./TenantDataUsagePaper";
import TenantSmsUsagePaper from "./TenantSmsUsagePaper";
import TenantSimCountPaper from "./TenantSimCountPaper";

export interface TenantDetailPanelProps {
    query: UseQueryResult<Tenant>;
}

const TenantDetailPanel = ({ query: { data: value, isLoading } }: TenantDetailPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} align={"stretch"} gap={6}>
                <Paper header={"Details"}>
                    <Grid templateColumns={"auto auto auto"} rowGap={2}>
                        <TextColumn heading={"Name"} isLoading={isLoading}>
                            {value?.name}
                        </TextColumn>
                        <TextColumn heading={"Administrator Name"} isLoading={isLoading}>
                            {value?.adminName}
                        </TextColumn>
                        <TextColumn heading={"Administrator Email"} isLoading={isLoading}>
                            {value?.adminEmail}
                        </TextColumn>
                    </Grid>
                </Paper>
                <Box w={"100%"} h={"600px"}>
                    <TenantDataUsagePaper tenant={value} isLoading={isLoading} />
                </Box>
                <Box w={"100%"} h={"600px"}>
                    <TenantSmsUsagePaper tenant={value} isLoading={isLoading} />
                </Box>
                <Box w={"100%"} h={"600px"}>
                    <TenantSimCountPaper tenant={value} isLoading={isLoading} />
                </Box>
            </VStack>
        </TabPanel>
    );
};

export default TenantDetailPanel;
