import React from "react";
import { CustomerRecurringFee, PriceBound } from "../../api";
import { Text, VStack } from "@chakra-ui/react";
import CustomerRecurringFeeSinglePriceBound from "./CustomerRecurringFeeSinglePriceBound";
import CustomerRecurringFeePriceBound from "./CustomerRecurringFeePriceBound";
import { sortPriceBounds } from "../../utils/pricingUtils";

export interface CustomerRecurringFeePricingDetailsProps {
    customerRecurringFee?: CustomerRecurringFee;
}

const CustomerRecurringFeePricingDetails = ({ customerRecurringFee }: CustomerRecurringFeePricingDetailsProps) => {
    if (!customerRecurringFee) {
        return <></>;
    }
    if (customerRecurringFee.pricing.priceBounds.length === 0) {
        return <Text>No pricing specified</Text>;
    } else if (customerRecurringFee.pricing.priceBounds.length === 1) {
        return (
            <CustomerRecurringFeeSinglePriceBound
                priceBound={customerRecurringFee.pricing.priceBounds[0]}
                strategy={customerRecurringFee.pricing.pricingStrategy}
                currency={customerRecurringFee.pricing.currency}
                recurringFeeContext={customerRecurringFee.context}
            />
        );
    } else {
        const renderPriceBound = (priceBound: PriceBound) => {
            return (
                <CustomerRecurringFeePriceBound
                    priceBound={priceBound}
                    strategy={customerRecurringFee.pricing.pricingStrategy}
                    currency={customerRecurringFee.pricing.currency}
                    recurringFeeContext={customerRecurringFee.context}
                />
            );
        };

        return (
            <VStack align={"start"}>
                {sortPriceBounds(customerRecurringFee.pricing.priceBounds).map(renderPriceBound)}
            </VStack>
        );
    }
};

export default CustomerRecurringFeePricingDetails;
