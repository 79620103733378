import { SimCard, SimcardApiPatchSimCardDetailsRequest } from "../../../../api";
import { Grid, Heading, HStack } from "@chakra-ui/react";
import { CopyButton, Paper, TextColumn } from "@cobira/ui-library";
import React, { useCallback } from "react";
import { SimCardForm, SimCardFormType } from "../../../../forms/SimCardForm/SimCardForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";

export interface SimCardSummaryPaperProps {
    simCard?: SimCard;
    isLoading?: boolean;
}

const SimCardSummaryPaper = ({ simCard, isLoading }: SimCardSummaryPaperProps) => {
    const { simCardApi } = useApi();
    const queryClient = useQueryClient();
    const updateSimCard = useMutation<SimCard, Error, SimcardApiPatchSimCardDetailsRequest>(
        ["simcards", simCard?.icc],
        variables => simCardApi.patchSimCardDetails(variables),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["simcards"]);
                queryClient.invalidateQueries(["simcards", simCard?.icc]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: SimCardFormType) => {
            if (simCard?.icc) {
                updateSimCard.mutate({
                    updateSimCard: {
                        customName: form.customName || "",
                    },
                    icc: simCard?.icc,
                });
            }
        },
        [simCard, updateSimCard],
    );

    return (
        <Paper header={"Summary"} withDivider>
            <Grid templateColumns="auto auto auto" gap="4">
                <TextColumn heading="Name" grid isLoading={isLoading}>
                    <SimCardForm onSubmit={handleSubmit} simCard={simCard} />
                </TextColumn>
                <TextColumn heading="PIN1" grid isLoading={isLoading}>
                    {simCard?.pin1}
                </TextColumn>
                <TextColumn heading="PUK1" grid isLoading={isLoading}>
                    {simCard?.puk1}
                </TextColumn>
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"sm"}>ICC</Heading>
                            <CopyButton value={simCard?.icc || ""} />
                        </HStack>
                    }
                    grid
                    isLoading={isLoading}
                >
                    {simCard?.icc}
                </TextColumn>
                <TextColumn heading="PIN2" grid isLoading={isLoading}>
                    {simCard?.pin2}
                </TextColumn>
                <TextColumn heading="PUK2" grid isLoading={isLoading}>
                    {simCard?.puk2}
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default SimCardSummaryPaper;
