import React from "react";
import { BasicModal, CopyButton, TextColumn } from "@cobira/ui-library";
import { AccessToken, Integration } from "../../../api";
import { Heading, HStack, Text, VStack } from "@chakra-ui/react";

export interface AccessTokenDetailModalProps {
    integration: Integration;
    accessToken?: AccessToken;
    onClose: () => void;
}

const AccessTokenDetailModal = ({ onClose, accessToken, integration }: AccessTokenDetailModalProps) => {
    return (
        <BasicModal isOpen={!!accessToken} onClose={onClose} title={"Access Token details"} modalProps={{ size: "xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <TextColumn heading={"Name"}>
                    <Text>{accessToken?.name}</Text>
                </TextColumn>
                <Heading size={"md"}>Instructions</Heading>
                <Text>
                    The APIs use HTTP Basic Auth composed of your username shown below, and the Access Token secret
                    provided when the Access Token was created.
                </Text>
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"md"}>Username</Heading>
                            <CopyButton value={integration.id} />
                        </HStack>
                    }
                >
                    {integration.id}
                </TextColumn>
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"md"}>Password</Heading>
                        </HStack>
                    }
                >
                    <i>Displayed only once when this Access Token was created</i>
                </TextColumn>
            </VStack>
        </BasicModal>
    );
};

export default AccessTokenDetailModal;
