import { useApi } from "../../../hooks/useApi";
import { BasicModal, convertData, DataUnits, useNotifications } from "@cobira/ui-library";
import {
    Customer,
    CustomerApiCreateCustomerRecurringFeeRequest,
    CustomerRecurringFee,
    PriceBound,
    RecurringFeeContext,
    RecurringFeePricingStrategy,
} from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import CreateWholesaleCustomerRecurringFeeForm, {
    CreateWholesaleCustomerRecurringFeeFormType,
} from "../../../forms/CreateCustomerRecurringFeeForm/CreateWholesaleCustomerRecurringFeeForm";
import CreateFlatCustomerRecurringFeeForm, {
    CreateFlatCustomerRecurringFeeFormType,
} from "../../../forms/CreateCustomerRecurringFeeForm/CreateFlatCustomerRecurringFeeForm";
import CreateConsumptionBasedCustomerRecurringFeeForm, {
    CreateConsumptionBasedCustomerRecurringFeeFormType,
} from "../../../forms/CreateCustomerRecurringFeeForm/CreateConsumptionBasedCustomerRecurringFeeForm";
import RecurringFeeModalOptions, { RecurringFeeType } from "./RecurringFeeModalOptions";
import { sortPriceBounds } from "../../../utils/pricingUtils";

/**
 * The form submits "bytes used" context as gigabyte inputs, so we adjust them before sending them to the backend
 * @param bound
 * @param context
 */
const adjustBoundByContext = (context: RecurringFeeContext, bound?: number) => {
    if (bound === undefined) {
        return bound;
    }
    if (context === "BYTES_USED") {
        return convertData(bound, DataUnits.b, DataUnits.gb).toBytes();
    }
    return bound;
};

/**
 * The forms deal with simpler bounds to ease the form inputs,
 * we can then translate from the lower bound inputs to valid closed bounds as the API expects.
 * @param minimumPrice
 * @param formBounds
 * @param context
 */
const mapFromFormBoundsToPriceBound = (
    minimumPrice: number,
    formBounds: {
        lowerBound: number;
        price: number;
    }[],
    context: RecurringFeeContext,
) => {
    const sortedBounds = sortPriceBounds(formBounds);
    const priceBounds = new Array<PriceBound>();
    priceBounds.push({
        lowerBound: 0,
        upperBound: adjustBoundByContext(context, sortedBounds?.[0]?.lowerBound),
        price: minimumPrice,
    });

    for (let i = 0; i < sortedBounds.length; i++) {
        const adjustedLowerBound = adjustBoundByContext(context, sortedBounds[i].lowerBound) || 0;
        const adjustedUpperBound = adjustBoundByContext(context, sortedBounds?.[i + 1]?.lowerBound);
        priceBounds.push({
            lowerBound: adjustedLowerBound + 1,
            upperBound: adjustedUpperBound,
            price: sortedBounds[i].price,
        });
    }

    return priceBounds;
};

export interface CreateCustomerRecurringFeeModalProps {
    customer?: Customer;
    isOpen: boolean;
    onClose: () => void;
}

const CreateCustomerRecurringFeeModal = ({ customer, isOpen, onClose }: CreateCustomerRecurringFeeModalProps) => {
    const { customerApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();
    const [recurringFeeType, setRecurringFeeType] = useState<RecurringFeeType | undefined>(undefined);

    const createCustomerRecurringFee = useMutation<
        CustomerRecurringFee,
        Error,
        CustomerApiCreateCustomerRecurringFeeRequest
    >(variables => customerApi.createCustomerRecurringFee(variables), {
        onSuccess: () => {
            notifications.success(`Recurring Fee created`);
            queryClient.invalidateQueries(["customers", customer?.id, "recurringfees"]);
            setRecurringFeeType(undefined);
            onClose();
        },
    });

    const modalTitle = useMemo(() => {
        if (recurringFeeType === undefined) {
            return "Select a type to begin";
        }
        switch (recurringFeeType) {
            case RecurringFeeType.FLAT_FEE:
                return "Create Flat Recurring Fee";
            case RecurringFeeType.WHOLESALE:
                return "Create Wholesale Recurring Fee";
            case RecurringFeeType.CONSUMPTION_BASED:
                return "Create Consumption Based Recurring Fee";
        }
    }, [recurringFeeType]);

    const handleFlatFeeSubmit = useCallback(
        (form: CreateFlatCustomerRecurringFeeFormType) => {
            if (!customer) {
                return;
            }

            const priceBound: PriceBound = {
                price: form.price,
                lowerBound: 0,
                upperBound: undefined,
            };
            createCustomerRecurringFee.mutate({
                customerId: customer.id,
                createCustomerRecurringFee: {
                    name: form.name,
                    context: RecurringFeeContext.None,
                    recurrence: form.recurrence,
                    anniversary: form.anniversary,
                    pricing: {
                        priceBounds: [priceBound],
                        pricingStrategy: RecurringFeePricingStrategy.Fixed,
                        currency: form.currency,
                    },
                },
            });
        },
        [createCustomerRecurringFee, customer],
    );

    const handleWholesaleFeeSubmit = useCallback(
        (form: CreateWholesaleCustomerRecurringFeeFormType) => {
            if (!customer) {
                return;
            }
            const priceBounds = mapFromFormBoundsToPriceBound(form.minimumPrice, form.priceBounds, form.context);

            createCustomerRecurringFee.mutate({
                customerId: customer.id,
                createCustomerRecurringFee: {
                    name: form.name,
                    context: form.context,
                    recurrence: form.recurrence,
                    anniversary: form.anniversary,
                    billingGroupIds: new Set(form.billingGroups?.map(billingGroup => billingGroup.id)),
                    pricing: {
                        priceBounds: priceBounds,
                        pricingStrategy: RecurringFeePricingStrategy.Fixed,
                        currency: form.currency,
                    },
                },
            });
        },
        [createCustomerRecurringFee, customer],
    );

    const handleConsumptionBasedFeeSubmit = useCallback(
        (form: CreateConsumptionBasedCustomerRecurringFeeFormType) => {
            if (!customer) {
                return;
            }
            const priceBounds = mapFromFormBoundsToPriceBound(form.minimumPrice, form.priceBounds, form.context);

            createCustomerRecurringFee.mutate({
                customerId: customer.id,
                createCustomerRecurringFee: {
                    name: form.name,
                    context: form.context,
                    recurrence: form.recurrence,
                    anniversary: form.anniversary,
                    billingGroupIds: new Set(form.billingGroups?.map(billingGroup => billingGroup.id)),
                    pricing: {
                        priceBounds: priceBounds,
                        pricingStrategy: form.strategy,
                        currency: form.currency,
                    },
                },
            });
        },
        [createCustomerRecurringFee, customer],
    );

    const content = useMemo(() => {
        if (!customer) {
            return <></>;
        }

        if (recurringFeeType === undefined) {
            return <RecurringFeeModalOptions onSelection={setRecurringFeeType} />;
        } else {
            switch (recurringFeeType) {
                case RecurringFeeType.FLAT_FEE:
                    return (
                        <CreateFlatCustomerRecurringFeeForm
                            onSubmit={handleFlatFeeSubmit}
                            submitting={createCustomerRecurringFee.isLoading}
                            onCancel={() => setRecurringFeeType(undefined)}
                        />
                    );
                case RecurringFeeType.WHOLESALE:
                    return (
                        <CreateWholesaleCustomerRecurringFeeForm
                            customerId={customer.id}
                            onSubmit={handleWholesaleFeeSubmit}
                            submitting={createCustomerRecurringFee.isLoading}
                            onCancel={() => setRecurringFeeType(undefined)}
                        />
                    );
                case RecurringFeeType.CONSUMPTION_BASED:
                default:
                    return (
                        <CreateConsumptionBasedCustomerRecurringFeeForm
                            customerId={customer.id}
                            onSubmit={handleConsumptionBasedFeeSubmit}
                            submitting={createCustomerRecurringFee.isLoading}
                            onCancel={() => setRecurringFeeType(undefined)}
                        />
                    );
            }
        }
    }, [
        createCustomerRecurringFee.isLoading,
        customer,
        handleConsumptionBasedFeeSubmit,
        handleFlatFeeSubmit,
        handleWholesaleFeeSubmit,
        recurringFeeType,
    ]);

    if (!customer) {
        return <></>;
    }

    return (
        <BasicModal title={modalTitle} onClose={onClose} isOpen={isOpen} modalProps={{ size: "2xl" }}>
            {content}
        </BasicModal>
    );
};

export default CreateCustomerRecurringFeeModal;
