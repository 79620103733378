/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingReport } from './BillingReport';
import {
    BillingReportFromJSON,
    BillingReportFromJSONTyped,
    BillingReportToJSON,
} from './BillingReport';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * Page of billing reports
 * @export
 * @interface BillingReportPage
 */
export interface BillingReportPage {
    /**
     * 
     * @type {Page}
     * @memberof BillingReportPage
     */
    pageProperties: Page;
    /**
     * 
     * @type {Array<BillingReport>}
     * @memberof BillingReportPage
     */
    content: Array<BillingReport>;
}

/**
 * Check if a given object implements the BillingReportPage interface.
 */
export function instanceOfBillingReportPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pageProperties" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function BillingReportPageFromJSON(json: any): BillingReportPage {
    return BillingReportPageFromJSONTyped(json, false);
}

export function BillingReportPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingReportPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': PageFromJSON(json['pageProperties']),
        'content': ((json['content'] as Array<any>).map(BillingReportFromJSON)),
    };
}

export function BillingReportPageToJSON(value?: BillingReportPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': ((value.content as Array<any>).map(BillingReportToJSON)),
    };
}

