import { ActionType } from "../api";

export const ActionTypeLabels = {
    [ActionType.AssignSimCard]: "Assign SIM Card",
    [ActionType.NetworkActivateSimCard]: "Activate SIM Card",
    [ActionType.NetworkResetSimCard]: "Reset SIM Card",
    [ActionType.NetworkSuspendSimCard]: "Suspend SIM Card",
    [ActionType.NetworkTerminateSimCard]: "Terminate SIM Card",
    [ActionType.NetworkManageUsagePackageAction]: "Manage Bundle",
    [ActionType.NetworkSetImeiLockState]: "Configure IMEI Lock",
    [ActionType.NetworkUpdateImeiLock]: "Configure IMEI Lock entries",
    [ActionType.NetworkManageSimCapabilityAction]: "Manage SIM Capability",
    [ActionType.NetworkSetSimProvisioningState]: "Update SIM Provisioning State",
    [ActionType.NetworkSetOverage]: "Set SIM Overage",
    [ActionType.NetworkUsagePackageTypeCreate]: "Create Bundle",
    [ActionType.BillingPrepareInvoice]: "Prepare invoice",
    [ActionType.BillingComputeRecurringFeesInvoiceLines]: "Compute Recurring Fees",
    [ActionType.BillingComputeTelcoInvoiceLines]: "Compute Usage",
    [ActionType.BillingFinalizeTelcoInvoice]: "Finalize Invoice",
    [ActionType.BillingComputeNetworkAccessInvoiceLines]: "Compute Network Access",
    [ActionType.BillingComputeBillingReport]: "Generate Billing Report",
    [ActionType.Unknown]: "N/A",
};
