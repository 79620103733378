import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { OverageState, SetOverageState } from "../../api";
import { SetOverageStateLabels } from "../../labels/OverageStateLabels";

const SetOverageStateFormSchema = z.object({
    overageState: z.nativeEnum(SetOverageState),
});

export type SetOverageStateFormType = z.infer<typeof SetOverageStateFormSchema>;

export interface SetOverageStateFormProps {
    onSubmit: (form: SetOverageStateFormType) => void;
    onCancel?: () => void;
}

const SetOverageStateForm = ({ onSubmit, onCancel }: SetOverageStateFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<SetOverageStateFormType>({
        resolver: zodResolver(SetOverageStateFormSchema),
        defaultValues: {
            overageState: OverageState.Allowed,
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField error={errors?.overageState}>
                    <ControlledAutoCompleteSingleSelectInput
                        input={{
                            items: Object.values(SetOverageState),
                            displayMapping: value => SetOverageStateLabels[value],
                            autocompleteAbleMapping: value => value,
                            placeholder: "Choose overage state",
                        }}
                        control={{
                            name: "overageState",
                            control: control,
                        }}
                    />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Update
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default SetOverageStateForm;
