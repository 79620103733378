import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";
import {
    DateTime,
    Month,
    monthToInt,
    PageBackButton,
    PageLayout,
    PageTitle,
    Paper,
    TextColumn,
} from "@cobira/ui-library";
import { Grid, VStack } from "@chakra-ui/react";
import { RecurringFeeContextLabels } from "../../../labels/RecurringFeeContextLabels";
import { RecurrenceLabels } from "../../../labels/RecurrenceLabels";
import { CurrencyLabels } from "../../../labels/CurrencyLabels";
import CustomerRecurringFeePricingDetails from "../../../components/CustomerRecurringFeePricingDetails/CustomerRecurringFeePricingDetails";
import CustomerRecurringFeeBillingGroupCoveragePaper from "./CustomerRecurringFeeBillingGroupCoveragePaper";
import { RecurringFeePricingStrategyLabels } from "../../../labels/RecurringFeePricingStrategyLabels";

const CustomerRecurringFeeDetailPage = () => {
    const { customerId, customerRecurringFeeId } = useParams();
    const { customerApi } = useApi();
    const { data: customerRecurringFee, isLoading: loadingCustomerRecurringFee } = useQuery(
        ["customers", customerId, "recurringfees", customerRecurringFeeId],
        () =>
            customerApi.getCustomerRecurringFee({
                customerId: customerId || "",
                customerRecurringFeeId: customerRecurringFeeId || "",
            }),
        { enabled: !!customerId && !!customerRecurringFeeId },
    );

    if (!customerRecurringFee) {
        return <></>;
    }

    return (
        <PageLayout
            title={<PageTitle title={`${customerRecurringFee?.name}`} />}
            navigation={<PageBackButton text={"Back to customer"} path={`/customers/${customerId}`} />}
        >
            <VStack w={"100%"} align={"stretch"}>
                <Paper header={"Recurring Fee Details"}>
                    <Grid templateColumns={"auto auto auto"} rowGap={4}>
                        <TextColumn heading={"Name"} isLoading={loadingCustomerRecurringFee}>
                            {customerRecurringFee?.name}
                        </TextColumn>
                        <TextColumn heading={"Context"} isLoading={loadingCustomerRecurringFee}>
                            {RecurringFeeContextLabels[customerRecurringFee?.context || "NONE"]}
                        </TextColumn>
                        <TextColumn heading={"Strategy"} isLoading={loadingCustomerRecurringFee}>
                            {RecurringFeePricingStrategyLabels[customerRecurringFee.pricing.pricingStrategy]}
                        </TextColumn>
                        <TextColumn heading={"Anniversary"} isLoading={loadingCustomerRecurringFee}>
                            {customerRecurringFee?.anniversary && (
                                <DateTime
                                    value={
                                        new Date(
                                            customerRecurringFee.anniversary.year,
                                            monthToInt(customerRecurringFee.anniversary.month as Month),
                                        )
                                    }
                                    format={"MMMM, yyyy"}
                                />
                            )}
                        </TextColumn>
                        <TextColumn heading={"Recurrence"} isLoading={loadingCustomerRecurringFee}>
                            {RecurrenceLabels(customerRecurringFee?.recurrence)}
                        </TextColumn>
                        <TextColumn heading={"Currency"} isLoading={loadingCustomerRecurringFee}>
                            {CurrencyLabels[customerRecurringFee?.pricing.currency || "UNDEFINED"]}
                        </TextColumn>
                    </Grid>
                </Paper>

                <CustomerRecurringFeeBillingGroupCoveragePaper
                    billingGroupIds={Array.from(customerRecurringFee?.billingGroupIds || [])}
                />
                <Paper header={"Pricing Details"}>
                    <CustomerRecurringFeePricingDetails customerRecurringFee={customerRecurringFee} />
                </Paper>
            </VStack>
        </PageLayout>
    );
};

export default CustomerRecurringFeeDetailPage;
