import { FieldError } from "react-hook-form";
import { FormField } from "@cobira/ui-library";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import React from "react";
import { Currency } from "../../api";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import { MarkupType } from "../../utils/markupUtils";
import AbsoluteRateMarkupInput from "./AbsoluteRateMarkupInput";

export interface RateMarkupInputProps {
    markupType: MarkupType;
    currency: Currency;
    error?: FieldError;
    registration: UseFormRegisterReturn<string>;
    unit: string;
}

const RateMarkupInput = ({ registration, error, currency, markupType, unit }: RateMarkupInputProps) => {
    switch (markupType) {
        case MarkupType.ABSOLUTE:
            return (
                <AbsoluteRateMarkupInput currency={currency} registration={registration} error={error} unit={unit} />
            );
        case MarkupType.PERCENTAGE:
            return (
                <FormField label={"Markup"} error={error}>
                    <InputGroup>
                        <Input {...registration} type={"number"} />
                        <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                </FormField>
            );
        case MarkupType.OFFSET:
            return (
                <FormField label={"Offset cost by"} error={error}>
                    <InputGroup>
                        <Input {...registration} type={"number"} />
                    </InputGroup>
                </FormField>
            );
    }
};

export default RateMarkupInput;
