/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Label } from './Label';
import {
    LabelFromJSON,
    LabelFromJSONTyped,
    LabelToJSON,
} from './Label';

/**
 * 
 * @export
 * @interface Labels
 */
export interface Labels {
    /**
     * 
     * @type {Array<Label>}
     * @memberof Labels
     */
    labels?: Array<Label>;
}

/**
 * Check if a given object implements the Labels interface.
 */
export function instanceOfLabels(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LabelsFromJSON(json: any): Labels {
    return LabelsFromJSONTyped(json, false);
}

export function LabelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Labels {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(LabelFromJSON)),
    };
}

export function LabelsToJSON(value?: Labels | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(LabelToJSON)),
    };
}

