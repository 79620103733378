import React from "react";
import { CobiraTabs, PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import TenantNetworkCredentialTypeCostPlanDetailsPanel from "./CostPlanDetailsPanel/TenantNetworkCredentialTypeCostPlanDetailsPanel";
import TenantNetworkCredentialTypeTelcoCostsPanel from "./TelcoCostsPanel/TenantNetworkCredentialTypeTelcoCostsPanel";
import TenantNetworkCredentialTypeNetworkAccessCostsPanel from "./NetworkAccessCostsPanel/TenantNetworkCredentialTypeNetworkAccessCostsPanel";
import { tab } from "../../../utils/tabUtils";

const TenantCostPlanDetailPage = () => {
    const { tenantApi } = useApi();
    const { tenantId, networkCredentialTypeId, costPlanId } = useParams();

    const networkCredentialTypeQuery = useQuery(
        ["tenants", tenantId, "networkcredentialtypes", networkCredentialTypeId],
        () =>
            tenantApi.getTenantNetworkCredentialType({
                tenantId: tenantId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
            }),
        {
            enabled: !!networkCredentialTypeId && !!tenantId,
        },
    );

    const costPlanQuery = useQuery(
        ["tenants", tenantId, "networkcredentialtypes", networkCredentialTypeId, "costplans", costPlanId],
        () =>
            tenantApi.getTenantNetworkCredentialTypeCostPlan({
                networkCredentialTypeId: networkCredentialTypeId || "",
                costPlanId: costPlanId || "",
                tenantId: tenantId || "",
            }),
        {
            enabled: !!networkCredentialTypeId && !!costPlanId && !!tenantId,
        },
    );

    const tenantCostPlanDetailTabs = [
        tab("Details", <TenantNetworkCredentialTypeCostPlanDetailsPanel query={costPlanQuery} tenantId={tenantId} />),
        tab(
            "Cost Plan",
            <TenantNetworkCredentialTypeTelcoCostsPanel
                costPlanQuery={costPlanQuery}
                networkCredentialTypeQuery={networkCredentialTypeQuery}
                tenantId={tenantId}
            />,
        ),
        tab(
            "Network Fees",
            <TenantNetworkCredentialTypeNetworkAccessCostsPanel
                costPlanQuery={costPlanQuery}
                networkCredentialTypeQuery={networkCredentialTypeQuery}
                tenantId={tenantId}
            />,
        ),
    ];

    return (
        <PageLayout
            title={<PageTitle title={costPlanQuery?.data?.name || "Cost Plan"} />}
            navigation={
                <PageBackButton
                    text={"Back to network credential type"}
                    path={`/tenants/${tenantId}/networkcredentialtypes/${networkCredentialTypeId}`}
                />
            }
        >
            <CobiraTabs tabs={tenantCostPlanDetailTabs} />
        </PageLayout>
    );
};

export default TenantCostPlanDetailPage;
