import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input, Textarea } from "@chakra-ui/react";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

const CreateBillingGroupFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().optional(),
    ratePlan: z.object({
        id: z.string(),
        customName: z.string(),
    }),
});

export type CreateBillingGroupFormType = z.infer<typeof CreateBillingGroupFormSchema>;

export interface CreateBillingGroupFormProps {
    onSubmit: (form: CreateBillingGroupFormType) => void;
    onCancel?: () => void;
}

const CreateBillingGroupForm = ({ onSubmit, onCancel }: CreateBillingGroupFormProps) => {
    const { ratePlanApi } = useApi();
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
    } = useForm<CreateBillingGroupFormType>({
        resolver: zodResolver(CreateBillingGroupFormSchema),
    });

    const { data: ratePlans } = useQuery(["rateplans"], () =>
        ratePlanApi.getRatePlans({ pageNumber: 0, pageSize: 9999 }),
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <FormField label={"Rate Plan"} error={errors?.ratePlan}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ name: "ratePlan", control: control }}
                        input={{
                            items: ratePlans?.content || [],
                            placeholder: "Select Rate Plan",
                            isClearable: true,
                            autocompleteAbleMapping: value => value.customName,
                            displayMapping: value => value.customName,
                        }}
                    />
                </FormField>
                <FormField label={"Description"} error={errors?.description}>
                    <Textarea {...register("description")} minH={"6rem"} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default CreateBillingGroupForm;
