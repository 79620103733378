/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostPlan } from './CostPlan';
import {
    CostPlanFromJSON,
    CostPlanFromJSONTyped,
    CostPlanToJSON,
} from './CostPlan';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface CostPlanPage
 */
export interface CostPlanPage {
    /**
     * 
     * @type {Page}
     * @memberof CostPlanPage
     */
    pageProperties?: Page;
    /**
     * 
     * @type {Array<CostPlan>}
     * @memberof CostPlanPage
     */
    content?: Array<CostPlan>;
}

/**
 * Check if a given object implements the CostPlanPage interface.
 */
export function instanceOfCostPlanPage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CostPlanPageFromJSON(json: any): CostPlanPage {
    return CostPlanPageFromJSONTyped(json, false);
}

export function CostPlanPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostPlanPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': !exists(json, 'pageProperties') ? undefined : PageFromJSON(json['pageProperties']),
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(CostPlanFromJSON)),
    };
}

export function CostPlanPageToJSON(value?: CostPlanPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(CostPlanToJSON)),
    };
}

