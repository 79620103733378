import React from "react";
import { SearchSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { NetworkCredentialType } from "../../api";
import { useApi } from "../../hooks/useApi";

export interface SearchSelectNetworkCredentialTypeInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<NetworkCredentialType, K, TSearchStateConfig>, "label"> {}

export const SearchSelectNetworkCredentialTypeInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "Select Network Credential Type",
}: SearchSelectNetworkCredentialTypeInputProps<K, TSearchStateConfig>) => {
    const { configurationApi } = useApi();

    const { data: networkCredentialTypes } = useQuery(["networkcredentialtypes"], () =>
        configurationApi.getNetworkCredentialTypes({
            pageNumber: 0,
            pageSize: 9999,
        }),
    );

    const display = (networkCredentialType: NetworkCredentialType): string =>
        `${networkCredentialType.imsiProvider} (${networkCredentialType.plmn})`;

    return (
        <SearchSelectInput
            placeholder={placeholder}
            items={networkCredentialTypes?.content || []}
            displayMapping={display}
            autocompleteAbleMapping={display}
            registration={registration}
        />
    );
};
