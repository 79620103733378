/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionActor } from './ActionActor';
import {
    ActionActorFromJSON,
    ActionActorFromJSONTyped,
    ActionActorToJSON,
} from './ActionActor';
import type { ActionState } from './ActionState';
import {
    ActionStateFromJSON,
    ActionStateFromJSONTyped,
    ActionStateToJSON,
} from './ActionState';
import type { ActionType } from './ActionType';
import {
    ActionTypeFromJSON,
    ActionTypeFromJSONTyped,
    ActionTypeToJSON,
} from './ActionType';

/**
 * Action properties indicating progress and parameters as well as the type of action.
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    id: string;
    /**
     * 
     * @type {ActionActor}
     * @memberof Action
     */
    actor: ActionActor;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    correlationId: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    stateMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    parameters: string;
    /**
     * 
     * @type {Date}
     * @memberof Action
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    totalItems?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    itemsProcessed?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    progressPercentage?: number;
    /**
     * 
     * @type {ActionState}
     * @memberof Action
     */
    state?: ActionState;
    /**
     * 
     * @type {ActionType}
     * @memberof Action
     */
    actionType?: ActionType;
}

/**
 * Check if a given object implements the Action interface.
 */
export function instanceOfAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "actor" in value;
    isInstance = isInstance && "correlationId" in value;
    isInstance = isInstance && "parameters" in value;

    return isInstance;
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'actor': ActionActorFromJSON(json['actor']),
        'correlationId': json['correlationId'],
        'stateMessage': !exists(json, 'stateMessage') ? undefined : json['stateMessage'],
        'parameters': json['parameters'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'totalItems': !exists(json, 'totalItems') ? undefined : json['totalItems'],
        'itemsProcessed': !exists(json, 'itemsProcessed') ? undefined : json['itemsProcessed'],
        'progressPercentage': !exists(json, 'progressPercentage') ? undefined : json['progressPercentage'],
        'state': !exists(json, 'state') ? undefined : ActionStateFromJSON(json['state']),
        'actionType': !exists(json, 'actionType') ? undefined : ActionTypeFromJSON(json['actionType']),
    };
}

export function ActionToJSON(value?: Action | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'actor': ActionActorToJSON(value.actor),
        'correlationId': value.correlationId,
        'stateMessage': value.stateMessage,
        'parameters': value.parameters,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'totalItems': value.totalItems,
        'itemsProcessed': value.itemsProcessed,
        'progressPercentage': value.progressPercentage,
        'state': ActionStateToJSON(value.state),
        'actionType': ActionTypeToJSON(value.actionType),
    };
}

