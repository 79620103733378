import React from "react";
import { InfoPopover, SimpleInfo } from "@cobira/ui-library";
import { SimCardCapabilityName } from "../../../api";
import {
    SimCardCapabilityNameDescriptions,
    SimCardCapabilityNameLabels,
} from "../../../labels/SimCardCapabilityNameLabels";
import { Text } from "@chakra-ui/react";

export interface SimConfigurationCapabilityCellProps {
    capabilities?: Set<SimCardCapabilityName>;
}

const SimConfigurationCapabilityCell = ({ capabilities }: SimConfigurationCapabilityCellProps) => {
    const mapCapability = (capability: SimCardCapabilityName): SimpleInfo => ({
        title: SimCardCapabilityNameLabels[capability],
        description: SimCardCapabilityNameDescriptions[capability],
    });

    const mapCapabilitiesText = (capabilities?: SimCardCapabilityName[]) => {
        if (!capabilities || capabilities.length === 0) {
            return "No capabilities";
        } else if (capabilities.length === 1) {
            return SimCardCapabilityNameLabels[capabilities[0]];
        } else {
            return `${capabilities.length} capabilities`;
        }
    };

    return (
        <InfoPopover value={Array.from(capabilities || []).map(mapCapability)}>
            <Text>{mapCapabilitiesText(Array.from(capabilities || []))}</Text>
        </InfoPopover>
    );
};

export default SimConfigurationCapabilityCell;
