import React, { useState } from "react";
import { Nullable, SearchAsyncSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { BillingGroup, Customer } from "../../../api";
import { useApi } from "../../../hooks/useApi";

export interface SearchSelectBillingGroupInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<BillingGroup, K, TSearchStateConfig>, "label"> {
    selectedCustomer?: Nullable<Customer>;
}

export const SearchSelectBillingGroupInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "Select Billing Group",
    selectedCustomer,
}: SearchSelectBillingGroupInputProps<K, TSearchStateConfig>) => {
    const { billingGroupApi } = useApi();
    const [billingGroupInputValue, setBillingGroupInputValue] = useState<string | null>(null);

    const queryKey = selectedCustomer
        ? ["customers", selectedCustomer.id, "billinggroups", billingGroupInputValue]
        : ["billinggroups", billingGroupInputValue];

    const query = selectedCustomer
        ? () =>
              billingGroupApi.getCustomerBillingGroups({
                  customerId: selectedCustomer?.id,
                  search: billingGroupInputValue || undefined,
                  pageNumber: 0,
                  pageSize: 50,
              })
        : () =>
              billingGroupApi.getBillingGroups({
                  search: billingGroupInputValue || undefined,
                  pageNumber: 0,
                  pageSize: 50,
              });

    const { data: billingGroups, isLoading } = useQuery(queryKey, query);
    return (
        <SearchAsyncSelectInput
            placeholder={placeholder}
            items={billingGroups?.content || []}
            displayMapping={billingGroup => billingGroup.name}
            autocompleteAbleMapping={billingGroup => billingGroup.name}
            registration={registration}
            isLoading={isLoading}
            onInputChange={value => setBillingGroupInputValue(value)}
        />
    );
};
