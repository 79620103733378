import React from "react";
import { CobiraTabs, PageLayout, PageTitle } from "@cobira/ui-library";
import { tab } from "../../../utils/tabUtils";
import DataUsagePanel from "./DataUsagePanel/DataUsagePanel";
import SmsUsagePanel from "./SmsUsagePanel/SmsUsagePanel";

const UsageRecordListPage = () => {
    const usageListTabs = [tab("Data Usage", <DataUsagePanel />), tab("SMS Usage", <SmsUsagePanel />)];

    return (
        <PageLayout title={<PageTitle title={"Usage Records"} />}>
            <CobiraTabs tabs={usageListTabs} />
        </PageLayout>
    );
};

export default UsageRecordListPage;
