import React from "react";
import {
    DataText,
    DateTime,
    PageBackButton,
    PageLayout,
    PageTitle,
    Paper,
    PlaceholderText,
    TextColumn,
    UsagePackageStatusIndicator,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import UsagePackageTypeDetails from "../../../components/UsagePackageTypeDetails/UsagePackageTypeDetails";
import { Grid, VStack } from "@chakra-ui/react";

const SimCardUsagePackageDetailPage = () => {
    const { usagePackageApi, simCardApi } = useApi();
    const { icc, usagePackageId } = useParams();

    const usagePackageQuery = useQuery(
        ["usagepackages", usagePackageId],
        () =>
            simCardApi
                .getSimCardUsagePackages({ icc: icc || "" })
                .then(usagePackages => usagePackages.find(usagePackage => usagePackage.id === usagePackageId)),
        {
            enabled: !!icc,
        },
    );

    const usagePackageTypeQuery = useQuery(
        ["usagepackagetypes", usagePackageQuery?.data?.usagePackageTypeId],
        () =>
            usagePackageApi.getUsagePackageType({
                usagePackageTypeId: usagePackageQuery?.data?.usagePackageTypeId || "",
            }),
        { enabled: !!usagePackageQuery?.data?.usagePackageTypeId },
    );

    return (
        <PageLayout
            title={<PageTitle title={usagePackageTypeQuery?.data?.customName || "SIM Package"} />}
            navigation={<PageBackButton text={"Back to SIM Packages"} path={`/simcards/${icc}?tab=4`} />}
        >
            <VStack gap={4} align={"stretch"} w={"100%"}>
                <Paper header={"SIM Package"} withDivider cardProps={{ w: "100%" }}>
                    <Grid templateColumns="auto auto" gap="4">
                        <TextColumn heading={"Package State"}>
                            <UsagePackageStatusIndicator state={usagePackageQuery?.data?.packageState} withLabel />
                        </TextColumn>
                        <TextColumn heading="Data Remaining" grid isLoading={usagePackageQuery?.isLoading}>
                            <DataText bytes={usagePackageQuery?.data?.dataRemaining?.dataInBytes} decimals={2} />
                        </TextColumn>
                        <TextColumn heading="SMS Remaining" grid isLoading={usagePackageQuery?.isLoading}>
                            <PlaceholderText text={`${usagePackageQuery?.data?.smsRemaining}`} />
                        </TextColumn>
                        <TextColumn heading="Activated At" grid isLoading={usagePackageQuery?.isLoading}>
                            <DateTime value={usagePackageQuery?.data?.activatedAt} />
                        </TextColumn>
                        <TextColumn heading="Expires At" grid isLoading={usagePackageQuery?.isLoading}>
                            <DateTime value={usagePackageQuery?.data?.expiresAt} />
                        </TextColumn>
                        <TextColumn heading="Terminated At" grid isLoading={usagePackageQuery?.isLoading}>
                            <DateTime value={usagePackageQuery?.data?.terminatedAt} />
                        </TextColumn>
                    </Grid>
                </Paper>
                <UsagePackageTypeDetails query={usagePackageTypeQuery} />
            </VStack>
        </PageLayout>
    );
};

export default SimCardUsagePackageDetailPage;
