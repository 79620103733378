/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * IMEI lock state for a SIM card.
 * If `CHECK` is chosen the SIM card must have at least 1 IMEI lock specified, otherwise when the first IMEI is detected for a SIM card it will be automatically suspended.
 * 
 * When `CHECK_OR_SET` is chosen, the first IMEI to be observed will be stored and used as the check point for future IMEI changes, if the IMEI ever changes from this the violation behaviour will be applied.
 * 
 * Lastly if `DISABLED` is selected, IMEI locking will not be enforced for the SIM card.
 * @export
 */
export const ImeiLockState = {
    Check: 'CHECK',
    CheckOrSet: 'CHECK_OR_SET',
    Disabled: 'DISABLED'
} as const;
export type ImeiLockState = typeof ImeiLockState[keyof typeof ImeiLockState];


export function ImeiLockStateFromJSON(json: any): ImeiLockState {
    return ImeiLockStateFromJSONTyped(json, false);
}

export function ImeiLockStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImeiLockState {
    return json as ImeiLockState;
}

export function ImeiLockStateToJSON(value?: ImeiLockState | null): any {
    return value as any;
}

