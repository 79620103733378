import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { SimConfiguration, Tenant, TenantApiDeleteTenantSimConfigurationRequest } from "../../../api";

export interface ConfirmDeleteChildTenantNetworkCredentialTypeModalProps {
    childTenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
    simConfiguration?: SimConfiguration;
}

const ConfirmDeleteChildTenantSimConfigurationModal = ({
    onClose,
    simConfiguration,
    isOpen,
    childTenant,
}: ConfirmDeleteChildTenantNetworkCredentialTypeModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteSimConfiguration = useMutation<void, Error, TenantApiDeleteTenantSimConfigurationRequest>(variables =>
        tenantApi.deleteTenantSimConfiguration(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && simConfiguration?.id && childTenant?.id) {
                deleteSimConfiguration.mutate(
                    { simConfigurationId: simConfiguration.id, tenantId: childTenant.id },
                    {
                        onSuccess: () => {
                            notifications.success("SIM Configuration no longer available for the tenant");
                            queryClient.invalidateQueries(["tenants", childTenant.id, "simconfigurations"]);
                            queryClient.invalidateQueries([
                                "tenants",
                                childTenant.id,
                                "simconfigurations",
                                simConfiguration.id,
                            ]);
                        },
                    },
                );
            }
            onClose();
        },
        [childTenant, deleteSimConfiguration, notifications, onClose, queryClient, simConfiguration],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!simConfiguration && !!childTenant && isOpen}
            title={"Disable access to SIM Configuration"}
            description={
                <Container>
                    <Text>Are you sure you want to remove the SIM Configuration?</Text>
                    <Text>
                        The SIM Configuration will no longer be available for re-selling or SIM Card assignment. All SIM
                        Cards currently assigned will continue to function normally, but they can no longer be moved by
                        the sub-tenant.
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteChildTenantSimConfigurationModal;
