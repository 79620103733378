import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { CostPlan, NetworkCredentialType } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import TelcoCostTableWithSearch from "../../../../components/TelcoCostTableWithSearch/TelcoCostTableWithSearch";

interface TenantNetworkCredentialTypeTelcoCostsPanelProps {
    costPlanQuery: UseQueryResult<CostPlan>;
    networkCredentialTypeQuery: UseQueryResult<NetworkCredentialType>;
    tenantId?: string;
}

const TenantNetworkCredentialTypeTelcoCostsPanel = ({
    costPlanQuery: { data: costPlan },
    networkCredentialTypeQuery: { data: networkCredentialType },
    tenantId,
}: TenantNetworkCredentialTypeTelcoCostsPanelProps) => {
    const { tenantApi } = useApi();

    const telcoCostsQuery = useQuery(
        [
            "tenants",
            tenantId,
            "networkcredentialtypes",
            networkCredentialType?.id,
            "costplans",
            costPlan?.id,
            "telcocosts",
        ],
        () =>
            tenantApi.getTenantNetworkCredentialTypeCostPlanTelcoCosts({
                tenantId: tenantId || "",
                costPlanId: costPlan?.id || "",
                networkCredentialTypeId: networkCredentialType?.id || "",
                pageNumber: 0,
                pageSize: 10000,
            }),
        { enabled: !!costPlan?.id && !!networkCredentialType?.id },
    );

    return (
        <TabPanel w={"100%"}>
            <TelcoCostTableWithSearch telcoCostsQuery={telcoCostsQuery} costPlan={costPlan} />
        </TabPanel>
    );
};

export default TenantNetworkCredentialTypeTelcoCostsPanel;
