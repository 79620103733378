import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { NetworkCredentialType, Tenant, TenantApiDeleteTenantNetworkCredentialTypeRequest } from "../../../api";

export interface ConfirmDeleteChildTenantNetworkCredentialTypeModalProps {
    childTenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
    networkCredentialType?: NetworkCredentialType;
}

const ConfirmDeleteChildTenantNetworkCredentialTypeModal = ({
    onClose,
    networkCredentialType,
    isOpen,
    childTenant,
}: ConfirmDeleteChildTenantNetworkCredentialTypeModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteNetworkCredentialType = useMutation<void, Error, TenantApiDeleteTenantNetworkCredentialTypeRequest>(
        variables => tenantApi.deleteTenantNetworkCredentialType(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && networkCredentialType?.id && childTenant?.id) {
                deleteNetworkCredentialType.mutate(
                    { networkCredentialTypeId: networkCredentialType.id, tenantId: childTenant.id },
                    {
                        onSuccess: () => {
                            notifications.success("Network Credential Type no longer available for the tenant");
                            queryClient.invalidateQueries(["tenants", childTenant.id, "networkcredentialtypes"]);
                            queryClient.invalidateQueries([
                                "tenants",
                                childTenant.id,
                                "networkcredentialtypes",
                                networkCredentialType.id,
                            ]);
                        },
                    },
                );
            }
            onClose();
        },
        [childTenant, deleteNetworkCredentialType, notifications, onClose, queryClient, networkCredentialType],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!networkCredentialType && !!childTenant && isOpen}
            title={"Disable access to Network Credential Type"}
            description={
                <Container>
                    <Text>Are you sure you want to remove the Network Credential Type?</Text>
                    <Text>
                        The Network Credential Type will no longer be available for re-selling or SIM Card assignment.
                        All SIM Cards currently assigned will continue to function and they will be charged according to
                        the available cost plan.
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteChildTenantNetworkCredentialTypeModal;
