import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { BillingGroup, BillingGroupApiUpdateBillingGroupRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import UpdateBillingGroupForm, {
    UpdateBillingGroupFormType,
} from "../../../forms/UpdateBillingGroupForm/UpdateBillingGroupForm";

export interface UpdateBillingGroupModalProps {
    billingGroup?: BillingGroup;
    isOpen: boolean;
    onClose: () => void;
}

const UpdateBillingGroupModal = ({ billingGroup, isOpen, onClose }: UpdateBillingGroupModalProps) => {
    const { billingGroupApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const updateBillingGroup = useMutation<BillingGroup, Error, BillingGroupApiUpdateBillingGroupRequest>(
        variables => billingGroupApi.updateBillingGroup(variables),
        {
            onSuccess: data => {
                notifications.success(`Billing Group '${data.name}' updated`);
                queryClient.invalidateQueries(["customers", billingGroup?.customerId, "billinggroups"]);
                queryClient.invalidateQueries(["billinggroups", billingGroup?.id]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: UpdateBillingGroupFormType) => {
            updateBillingGroup.mutate({
                billingGroupId: billingGroup?.id || "",
                customerId: billingGroup?.customerId || "",
                updateBillingGroup: {
                    name: form.name,
                    ratePlanId: form.ratePlan.id,
                    description: form.description,
                },
            });
            onClose();
        },
        [updateBillingGroup, billingGroup, onClose],
    );

    if (!billingGroup) {
        return <></>;
    }

    return (
        <BasicModal title={`Update Billing Group`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <UpdateBillingGroupForm onSubmit={handleSubmit} onCancel={onClose} billingGroup={billingGroup} />
            </VStack>
        </BasicModal>
    );
};

export default UpdateBillingGroupModal;
