import { OverageState, SetOverageState } from "../api";

export const OverageStateLabels = {
    [OverageState.Allowed]: "Allowed (Pay-as-you-go Mode)",
    [OverageState.Disallowed]: "Disallowed (Requires a Usage Package)",
    [OverageState.Undetermined]: "Unknown",
};

export const SetOverageStateLabels = {
    [SetOverageState.Allowed]: "Allowed (Pay-as-you-go Mode)",
    [SetOverageState.Disallowed]: "Disallowed (Requires a Usage Package)",
};
