import { z } from "zod";
import React from "react";
import { TelcoRateFormSchema } from "../TelcoRateForm/TelcoRateFormSchema";
import CreateRatePlanServiceTypeRateFormStep from "./CreateRatePlanServiceTypeRateFormStep";

export const RatePlanDataRateSchema = z.object({
    dataRate: TelcoRateFormSchema,
});

const CreateRatePlanDataRateFormStep = () => {
    return <CreateRatePlanServiceTypeRateFormStep rateIdentifier={"dataRate"} />;
};

export default CreateRatePlanDataRateFormStep;
