/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Current state of an action.
 * @export
 */
export const ActionState = {
    Idle: 'IDLE',
    InProgress: 'IN_PROGRESS',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;
export type ActionState = typeof ActionState[keyof typeof ActionState];


export function ActionStateFromJSON(json: any): ActionState {
    return ActionStateFromJSONTyped(json, false);
}

export function ActionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionState {
    return json as ActionState;
}

export function ActionStateToJSON(value?: ActionState | null): any {
    return value as any;
}

