import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { UsagePackageApiDeleteUsagePackageTypeRequest, UsagePackageType } from "../../../api";

export interface ConfirmDeleteContactModalProps {
    isOpen: boolean;
    onClose: () => void;
    usagePackageType?: UsagePackageType;
}

const ConfirmDeleteUsagePackageTypeModal = ({ onClose, usagePackageType, isOpen }: ConfirmDeleteContactModalProps) => {
    const { usagePackageApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteUsagePackageType = useMutation<void, Error, UsagePackageApiDeleteUsagePackageTypeRequest>(variables =>
        usagePackageApi.deleteUsagePackageType(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && usagePackageType?.id) {
                deleteUsagePackageType.mutate(
                    { usagePackageTypeId: usagePackageType.id },
                    {
                        onSuccess: () => {
                            notifications.success("Bundle no longer available for assignment");
                            queryClient.invalidateQueries(["usagepackagetypes"]);
                            queryClient.invalidateQueries(["usagepackagetypes", usagePackageType.id]);
                        },
                    },
                );
            }
            onClose();
        },
        [deleteUsagePackageType, notifications, onClose, queryClient, usagePackageType],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!usagePackageType && isOpen}
            title={"Deactivate Bundle"}
            description={
                <Container>
                    <Text>Are you sure you want to de-activate the bundle?</Text>
                    <Text>
                        The bundle will no longer be available for assignment after this operation, you will still be
                        able to remove the bundle from assigned SIM Cards.
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteUsagePackageTypeModal;
