import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Tenant, TenantApiCreateTenantNetworkCredentialTypeRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import AssignNetworkCredentialTypeForm, {
    AssignNetworkCredentialTypeFormSchemaType,
} from "../../../forms/AssignNetworkCredentialTypeForm/AssignNetworkCredentialTypeForm";

export interface AssignChildTenantNetworkCredentialTypeModalProps {
    forTenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
}

const AssignChildTenantNetworkCredentialTypeModal = ({
    isOpen,
    onClose,
    forTenant,
}: AssignChildTenantNetworkCredentialTypeModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const assignNetworkCredentialType = useMutation<void, Error, TenantApiCreateTenantNetworkCredentialTypeRequest>(
        variables => tenantApi.createTenantNetworkCredentialType(variables),
        {
            onSuccess: () => {
                notifications.success(`Network Credential Type now available for tenant`);
                queryClient.invalidateQueries(["tenants", forTenant?.id, "networkcredentialtypes"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: AssignNetworkCredentialTypeFormSchemaType) => {
            assignNetworkCredentialType.mutate({
                tenantId: forTenant?.id || "",
                assignNetworkCredentialType: {
                    id: form.networkCredentialType.id,
                },
            });
            onClose();
        },
        [assignNetworkCredentialType, forTenant, onClose],
    );

    if (!forTenant?.id) {
        return null;
    }

    return (
        <BasicModal
            title={`Assign Network Credential Type`}
            onClose={onClose}
            isOpen={isOpen}
            modalProps={{ size: "2xl" }}
        >
            <VStack w={"100%"} align={"stretch"}>
                <AssignNetworkCredentialTypeForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default AssignChildTenantNetworkCredentialTypeModal;
