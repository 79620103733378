import React, { useMemo, useState } from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { NetworkCredentialType, Tenant, UsagePackageType } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import ConfirmDeleteChildTenantUsagePackageTypeModal from "../../../../components/Modal/ConfirmDeleteChildTenantUsagePackageTypeModal/ConfirmDeleteChildTenantUsagePackageTypeModal";
import UsagePackageTypeTable from "../../../../components/UsagePackageTypeTable/UsagePackageTypeTable";
import {
    Country,
    CountryInfo,
    getCountries,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePagination,
    usePersistedSearch,
} from "@cobira/ui-library";
import { BooleanLabels } from "../../../../labels/BooleanLabels";
import { mapBooleanFilter } from "../../../../utils/mapperUtils";
import { SearchSelectNetworkCredentialTypeInput } from "../../../../components/SearchSelectNetworkCredentialTypeInput/SearchSelectNetworkCredentialTypeInput";

export interface TenantUsagePackageTypesPanelProps {
    query: UseQueryResult<Tenant>;
}

const TenantUsagePackageTypesPanel = ({ query: { data: tenant } }: TenantUsagePackageTypesPanelProps) => {
    const { tenantApi } = useApi();
    const { navigate } = usePageNavigation<UsagePackageType>({
        route: value => `/tenants/${tenant?.id}/bundles/${value.id}`,
    });
    const countries = useMemo(() => getCountries(), []);
    const [usagePackageTypeToDelete, setUsagePackageTypeToDelete] = useState<UsagePackageType | undefined>(undefined);

    const { setPageState, resetPageState, pageState } = usePagination({ pageIndex: 0, pageSize: 20 });
    const search = usePersistedSearch({
        persistenceKey: "tenant-usage-package-type-search",
        config: {
            search: singleInput<string>("Search"),
            country: singleInput<Country>("Country", { display: value => `${value.name} (${value.iso2})` }),
            includeDeleted: singleInput<boolean>("Include Deleted", {
                display: value => BooleanLabels(value),
            }),
            dataPackageEnabled: singleInput<boolean>("Data Enabled", {
                display: value => BooleanLabels(value),
            }),
            smsPackageEnabled: singleInput<boolean>("SMS Enabled", {
                display: value => BooleanLabels(value),
            }),
            networkCredentialType: singleInput<NetworkCredentialType>("Network Credential Type", {
                display: value => `${value.imsiProvider}`,
            }),
        },
        onChange: resetPageState,
    });

    const tenantUsagePackageTypesQuery = useQuery(
        ["tenants", tenant?.id, "usagepackagetypes", pageState, search.state],
        () =>
            tenantApi.getTenantUsagePackageTypes({
                tenantId: tenant?.id || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                search: search.state?.search?.value || undefined,
                country: search.state?.country?.value?.iso2 || undefined,
                includeDeleted: mapBooleanFilter(search.state?.includeDeleted?.value),
                dataPackageEnabled: mapBooleanFilter(search.state?.dataPackageEnabled?.value),
                smsPackageEnabled: mapBooleanFilter(search.state?.smsPackageEnabled?.value),
                networkCredentialType: search.state?.networkCredentialType?.value?.id,
            }),
        { enabled: !!tenant?.id },
    );

    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Quick find..."}
                                />
                            ),
                        },
                        {
                            id: "country",
                            menuLabel: "Country",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "country" })}
                                    items={countries}
                                    autocompleteAbleMapping={value => `${value.name} (${value.iso2})`}
                                    displayMapping={value => <CountryInfo country={value} />}
                                />
                            ),
                        },
                        {
                            id: "includeDeleted",
                            menuLabel: "Include Deleted",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "includeDeleted" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "dataPackageEnabled",
                            menuLabel: "Data Enabled",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "dataPackageEnabled" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "smsPackageEnabled",
                            menuLabel: "SMS Enabled",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "smsPackageEnabled" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "networkCredentialType",
                            menuLabel: "Network Credential Type",
                            inputComponent: (
                                <SearchSelectNetworkCredentialTypeInput
                                    registration={search.registerInput({ id: "networkCredentialType" })}
                                />
                            ),
                        },
                    ]}
                />
                <UsagePackageTypeTable
                    usagePackageTypeQuery={tenantUsagePackageTypesQuery}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigate,
                    }}
                    withPagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: tenantUsagePackageTypesQuery?.data?.pageProperties?.totalElements || 0,
                        totalPageCount: tenantUsagePackageTypesQuery?.data?.pageProperties?.totalPages || 0,
                    }}
                    onDelete={setUsagePackageTypeToDelete}
                />
            </VStack>
            <ConfirmDeleteChildTenantUsagePackageTypeModal
                isOpen={!!usagePackageTypeToDelete}
                onClose={() => setUsagePackageTypeToDelete(undefined)}
                usagePackageType={usagePackageTypeToDelete}
                childTenant={tenant}
            />
        </TabPanel>
    );
};

export default TenantUsagePackageTypesPanel;
