import React from "react";
import { CobiraTabs, PageBackButton, PageLayout, PageTitle, usePageNavigation } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@chakra-ui/react";
import RatePlanDetailPanel from "./RatePlanDetailPanel/RatePlanDetailPanel";
import RatePlanTelcoRatesPanel from "./RatePlanTelcoRatesPanel/RatePlanTelcoRatesPanel";
import RatePlanNetworkAccessRatesPanel from "./RatePlanNetworkAccessRatesPanel/RatePlanNetworkAccessRatesPanel";
import { RatePlan } from "../../../api";
import { tab } from "../../../utils/tabUtils";

const RatePlanDetailPage = () => {
    const { ratePlanApi } = useApi();
    const { ratePlanId } = useParams();
    const { navigate } = usePageNavigation<RatePlan | undefined>({ route: value => `/rateplans/${value?.id}/update` });

    const ratePlanQuery = useQuery(
        ["rateplans", ratePlanId],
        () => ratePlanApi.getRatePlanById({ ratePlanId: ratePlanId || "" }),
        {
            enabled: ratePlanId != undefined,
        },
    );

    const ratePlanDetailTabs = [
        tab("Details", <RatePlanDetailPanel query={ratePlanQuery} />),
        tab("Usage Rates", <RatePlanTelcoRatesPanel query={ratePlanQuery} />),
        tab("Network Rates", <RatePlanNetworkAccessRatesPanel query={ratePlanQuery} />),
    ];

    return (
        <PageLayout
            title={<PageTitle title={"Rate Plan"} />}
            navigation={<PageBackButton text={"Back to Rate Plans"} path={"/rateplans"} />}
            pageContext={
                <Button ml={"auto"} onClick={() => navigate(ratePlanQuery?.data)}>
                    Edit
                </Button>
            }
        >
            <CobiraTabs tabs={ratePlanDetailTabs} />
        </PageLayout>
    );
};

export default RatePlanDetailPage;
