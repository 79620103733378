import React, { useMemo } from "react";
import { NodeRendererProps } from "react-arborist";
import { Box, Flex, HStack, IconButton, VStack } from "@chakra-ui/react";
import { TelcoRate } from "../../../api";
import TelcoRateNodeToggleButton from "./TelcoRateNodeToggleButton";
import TelcoRateDefaultHeading from "./Default/TelcoRateDefaultHeading";
import TelcoRateServiceTypeHeading from "./ServiceType/TelcoRateServiceTypeHeading";
import TelcoRateNetworkHeading from "./Network/TelcoRateNetworkHeading";
import TelcoRateCountryHeading from "./Country/TelcoRateCountryHeading";
import TelcoRateNetworkBody from "./Network/TelcoRateNetworkBody";
import TelcoRateCountryBody from "./Country/TelcoRateCountryBody";
import NodePriceTiersTag from "./NodePriceTiersTag";
import NodePostProcessingTiersTag from "./NodePostProcessingTiersTag";
import { ExternalLinkIcon } from "@cobira/ui-library";

const TelcoRateNode = ({ node }: NodeRendererProps<TelcoRate>) => {
    const telcoRateNodeHeading = useMemo(() => {
        switch (node.data.rateDiscriminator) {
            case "SERVICE_TYPE":
                return <TelcoRateServiceTypeHeading node={node} />;
            case "NETWORK":
                return <TelcoRateNetworkHeading node={node} />;
            case "COUNTRY":
                return <TelcoRateCountryHeading node={node} />;
            case "DEFAULT":
            default:
                return <TelcoRateDefaultHeading node={node} />;
        }
    }, [node]);

    const telcoRateNodeBody = useMemo(() => {
        switch (node.data.rateDiscriminator) {
            case "SERVICE_TYPE":
                return null;
            case "NETWORK":
                return <TelcoRateNetworkBody node={node} />;
            case "COUNTRY":
                return <TelcoRateCountryBody node={node} />;
            case "DEFAULT":
            default:
                return null;
        }
    }, [node]);

    return (
        <HStack w={"100%"}>
            <TelcoRateNodeToggleButton node={node} />
            <Box bg={"white"} boxShadow={"xl"} minW={"25em"} borderRadius={"xl"} p={2}>
                <Flex direction={"row"} gap={4}>
                    <VStack gap={1} align={"start"}>
                        {telcoRateNodeHeading}
                        {telcoRateNodeBody}
                    </VStack>
                    <NodePriceTiersTag node={node} />
                    <NodePostProcessingTiersTag node={node} />
                    <IconButton
                        w={"fit-content"}
                        ml="auto"
                        aria-label={"more info"}
                        icon={<ExternalLinkIcon />}
                        size={"sm"}
                        variant={"icon"}
                        isRound
                        boxSize={"6"}
                        onClick={() => node.activate()}
                    />
                </Flex>
            </Box>
        </HStack>
    );
};

export default TelcoRateNode;
