import React from "react";
import { useApi } from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { ActionTypeLabels } from "../../../labels/ActionTypeLabels";
import { Action, ActionType, Page } from "../../../api";
import { ActionDetailsPage, ActionItem, usePagination } from "@cobira/ui-library";
import useActionActor from "../../../hooks/useActionActor";
import { mapPagination } from "../../../utils/pageUtils";

const resolveActionType = (action?: Action) => {
    const actionType = ActionTypeLabels[action?.actionType || "UNKNOWN"];
    return actionType != ActionType.Unknown ? actionType : "UNKNOWN";
};

const MvnoActionDetailPage = () => {
    const { actionId } = useParams();
    const { actionApi } = useApi();
    const actionActor = useActionActor();

    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: action, isLoading: isActionLoading } = useQuery(
        ["action", actionId],
        () => actionApi.getAction({ actionId: actionId || "" }),
        {
            enabled: !!actionId,
        },
    );

    const { data: actionItems, isLoading: isActionItemsLoading } = useQuery(
        ["action", actionId, "items", pageState],
        () =>
            actionApi.getActionItems({
                actionId: actionId || "",
                pageSize: pageState.pageSize,
                pageNumber: pageState.pageIndex,
            }),
        {
            enabled: !!actionId,
        },
    );

    const mappedActionItems = actionItems?.content?.map<ActionItem>(actionItem => ({
        itemIdentifier: actionItem.itemIdentifier,
        itemState: actionItem.itemState,
        parametersJson: actionItem.parametersJson,
        processedAt: actionItem.processedAt,
        itemType: actionItem.itemType || "UNKNOWN",
        stateMessage: actionItem.stateMessage,
    }));

    const page: Page | undefined = actionItems?.pageProperties;

    return (
        <ActionDetailsPage
            isLoading={isActionLoading || isActionItemsLoading}
            action={action}
            actionTypeResolver={resolveActionType}
            actionActorResolvers={actionActor}
            actionItems={mappedActionItems}
            pagination={mapPagination(page, setPageState)}
        />
    );
};

export default MvnoActionDetailPage;
