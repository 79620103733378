import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CobiraBaseProvider, CobiraToaster, defaultQueryClient } from "@cobira/ui-library";
import { QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <CobiraBaseProvider envFileUrl="/branding/env.json">
            <QueryClientProvider client={defaultQueryClient}>
                <BrowserRouter>
                    <CobiraToaster />
                    <App />
                </BrowserRouter>
            </QueryClientProvider>
        </CobiraBaseProvider>
    </React.StrictMode>,
);
