import React from "react";
import { ChevronDownIcon, CobiraTabs, PageLayout, PageTitle } from "@cobira/ui-library";
import NetworkCredentialTypeListPanel from "./NetworkCredentialTypeListPanel/NetworkCredentialTypeListPanel";
import { tab } from "../../utils/tabUtils";
import SimConfigurationListPanel from "./SimConfigurationListPanel/SimConfigurationListPanel";
import { Button, Menu, MenuButton, MenuItem, MenuList, useBoolean } from "@chakra-ui/react";
import CreateUsagePackageTypeModal from "../../components/Modal/CreateUsagePackageTypeModal/CreateUsagePackageTypeModal";
import UsagePackageTypeListPanel from "./UsagePackageTypeListPanel/UsagePackageTypeListPanel";

const ConfigurationPage = () => {
    const [isCreatingUsagePackageType, { on: openCreateUsagePackageTypeModal, off: closeUsagePackageTypeModal }] =
        useBoolean(false);

    const configurationTabs = [
        tab("SIM Configuration", <SimConfigurationListPanel />),
        tab("Network Credential Types", <NetworkCredentialTypeListPanel />),
        tab("Bundles", <UsagePackageTypeListPanel />),
    ];

    return (
        <PageLayout
            title={<PageTitle title={"Configuration"} />}
            pageContext={
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        Manage
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={openCreateUsagePackageTypeModal}>Create Bundle</MenuItem>
                    </MenuList>
                </Menu>
            }
        >
            <CobiraTabs tabs={configurationTabs} />
            <CreateUsagePackageTypeModal isOpen={isCreatingUsagePackageType} onClose={closeUsagePackageTypeModal} />
        </PageLayout>
    );
};

export default ConfigurationPage;
