import { AsyncActorResolvers } from "@cobira/ui-library";
import { useApi } from "./useApi";
import { ActionActor, Integration, IntegrationApi } from "../api";

/**
 * This needs improvement in the future when MVNOs have multiple integrations defined.
 * For those scenarios it is no longer sufficient to simply find the first integration in the list.
 * @param integrationApi
 * @param integration
 * @param actionActor
 */
function resolveApplicationOrNull(
    integrationApi: IntegrationApi,
    integration: Integration,
    actionActor: ActionActor,
): Promise<string> {
    return integrationApi
        .getAccessToken({
            integrationId: integration.id,
            accessTokenId: actionActor.id,
        })
        .then(value => `${value.name}`)
        .catch(() => actionActor.id);
}

const useActionActor = (): AsyncActorResolvers => {
    const { userApi, integrationApi } = useApi();

    return {
        USER: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () =>
                userApi
                    .getUserDetails({ userId: actionActor.id })
                    .then(value => `${value.name} (${value.email})`)
                    .catch(() => actionActor.id),
        }),
        APPLICATION: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () =>
                actionActor.parentId
                    ? integrationApi
                          .getIntegrations()
                          .then(integrations => resolveApplicationOrNull(integrationApi, integrations[0], actionActor))
                          .catch(() => actionActor.id)
                    : actionActor.id,
        }),
        PATCH: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () => actionActor.id,
        }),
        IMEI_LOCK: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () => actionActor.id,
        }),
        UNKNOWN: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () => actionActor.id,
        }),
    };
};

export default useActionActor;
