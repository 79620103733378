/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageType } from './MessageType';
import {
    MessageTypeFromJSON,
    MessageTypeFromJSONTyped,
    MessageTypeToJSON,
} from './MessageType';
import type { SendSmsRequestMsisdnSelectionInner } from './SendSmsRequestMsisdnSelectionInner';
import {
    SendSmsRequestMsisdnSelectionInnerFromJSON,
    SendSmsRequestMsisdnSelectionInnerFromJSONTyped,
    SendSmsRequestMsisdnSelectionInnerToJSON,
} from './SendSmsRequestMsisdnSelectionInner';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';

/**
 * The message to send to the SIM Cards targeted by the specified filter
 * @export
 * @interface SendSmsRequest
 */
export interface SendSmsRequest {
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof SendSmsRequest
     */
    simCardFilter?: SimCardActionRequestFilter;
    /**
     * The payload to send, if GSM_7_BIT is selected as messageType, the payload should fit within that alphabet, otherwise use the UCS2 messageType
     * @type {string}
     * @memberof SendSmsRequest
     */
    payload: string;
    /**
     * 
     * @type {MessageType}
     * @memberof SendSmsRequest
     */
    messageType: MessageType;
    /**
     * Pre-select the MSISDN for each ICC, currently selection is limited to 5000 entries at a time.
     * @type {Set<SendSmsRequestMsisdnSelectionInner>}
     * @memberof SendSmsRequest
     */
    msisdnSelection?: Set<SendSmsRequestMsisdnSelectionInner>;
}

/**
 * Check if a given object implements the SendSmsRequest interface.
 */
export function instanceOfSendSmsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payload" in value;
    isInstance = isInstance && "messageType" in value;

    return isInstance;
}

export function SendSmsRequestFromJSON(json: any): SendSmsRequest {
    return SendSmsRequestFromJSONTyped(json, false);
}

export function SendSmsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendSmsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'simCardFilter': !exists(json, 'simCardFilter') ? undefined : SimCardActionRequestFilterFromJSON(json['simCardFilter']),
        'payload': json['payload'],
        'messageType': MessageTypeFromJSON(json['messageType']),
        'msisdnSelection': !exists(json, 'msisdnSelection') ? undefined : (new Set((json['msisdnSelection'] as Array<any>).map(SendSmsRequestMsisdnSelectionInnerFromJSON))),
    };
}

export function SendSmsRequestToJSON(value?: SendSmsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'simCardFilter': SimCardActionRequestFilterToJSON(value.simCardFilter),
        'payload': value.payload,
        'messageType': MessageTypeToJSON(value.messageType),
        'msisdnSelection': value.msisdnSelection === undefined ? undefined : (Array.from(value.msisdnSelection as Set<any>).map(SendSmsRequestMsisdnSelectionInnerToJSON)),
    };
}

