import React from "react";
import { Box, TabPanel, VStack } from "@chakra-ui/react";
import { Customer } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import CustomerSummaryPaper from "./CustomerSummaryPaper";
import CustomerSimCardCountPaper from "./CustomerInfoCards";
import CustomerDataUsagePaper from "./CustomerDataUsagePaper";
import CustomerSmsUsagePaper from "./CustomerSmsUsagePaper";
import CustomerSimCountPaper from "./CustomerSimCountPaper";

export interface CustomerDetailsPanelProps {
    query: UseQueryResult<Customer>;
}

const CustomerDetailsPanel = ({ query: { data: value, isLoading } }: CustomerDetailsPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} align={"stretch"} gap={6}>
                <CustomerSummaryPaper isLoading={isLoading} customer={value} />
                <CustomerSimCardCountPaper isLoading={isLoading} customer={value} />
                <Box w={"100%"} h={"600px"}>
                    <CustomerDataUsagePaper isLoading={isLoading} customer={value} />
                </Box>
                <Box w={"100%"} h={"600px"}>
                    <CustomerSmsUsagePaper isLoading={isLoading} customer={value} />
                </Box>
                <Box w={"100%"} h={"600px"}>
                    <CustomerSimCountPaper isLoading={isLoading} customer={value} />
                </Box>
            </VStack>
        </TabPanel>
    );
};

export default CustomerDetailsPanel;
