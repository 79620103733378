import React, { useState } from "react";
import {
    ChevronDownIcon,
    PageBackButton,
    PageLayout,
    PageTitle,
    Paper,
    PlaceholderText,
    TextColumn,
} from "@cobira/ui-library";
import { Box, Button, Grid, Menu, MenuButton, MenuItem, MenuList, VStack } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import UpdateBillingGroupModal from "../../../components/Modal/UpdateBillingGroupModal/UpdateBillingGroupModal";
import BillingGroupDataUsagePaper from "./BillingGroupDataUsagePaper";
import BillingGroupSmsUsagePaper from "./BillingGroupSmsUsagePaper";
import BillingGroupSimCardCountInfoCards from "./BillingGroupSimCardCountInfoCards";

type BillingGroupOperations = "update-details";

const BillingGroupDetailPage = () => {
    const { billingGroupApi, ratePlanApi } = useApi();
    const { customerId, billingGroupId = "" } = useParams();
    const [chosenOperation, setChosenOperation] = useState<BillingGroupOperations | null>(null);

    const { data: billingGroup, isLoading } = useQuery(
        ["customers", customerId, "billinggroups", billingGroupId],
        () =>
            billingGroupApi.getCustomerBillingGroup({
                customerId: customerId || "",
                billingGroupId: billingGroupId || "",
            }),
        {
            enabled: customerId != undefined && billingGroupId != undefined,
        },
    );

    const { data: ratePlan, isLoading: isLoadingRatePlan } = useQuery(
        ["rateplans", billingGroup?.ratePlanId],
        () => ratePlanApi.getRatePlanById({ ratePlanId: billingGroup?.ratePlanId || "" }),
        {
            enabled: !!billingGroup?.ratePlanId,
        },
    );

    return (
        <PageLayout
            title={<PageTitle title={billingGroup?.name || "Billing Group"} />}
            navigation={<PageBackButton text={"Back to customer"} path={`/customers/${customerId}`} />}
            pageContext={
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        Manage
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => setChosenOperation("update-details")}>Update</MenuItem>
                    </MenuList>
                </Menu>
            }
        >
            <VStack w="100%" align="stretch" gap={6}>
                <Paper header={"Details"}>
                    <Grid templateColumns="auto auto auto" gap="4">
                        <TextColumn heading="Name" grid isLoading={isLoading}>
                            {billingGroup?.name}
                        </TextColumn>
                        <TextColumn heading="Description" grid isLoading={isLoading}>
                            <PlaceholderText text={billingGroup?.description} />
                        </TextColumn>
                        <TextColumn heading={"Rate Plan"} grid isLoading={isLoadingRatePlan}>
                            <PlaceholderText text={ratePlan?.customName} />
                        </TextColumn>
                    </Grid>
                </Paper>
                <BillingGroupSimCardCountInfoCards billingGroup={billingGroup} isLoading={isLoading} />
                <Box w={"100%"} h={"600px"}>
                    <BillingGroupDataUsagePaper billingGroup={billingGroup} isLoading={isLoading} />
                </Box>
                <Box w={"100%"} h={"600px"}>
                    <BillingGroupSmsUsagePaper billingGroup={billingGroup} isLoading={isLoading} />
                </Box>
            </VStack>
            <UpdateBillingGroupModal
                isOpen={chosenOperation === "update-details"}
                onClose={() => setChosenOperation(null)}
                billingGroup={billingGroup}
            />
        </PageLayout>
    );
};

export default BillingGroupDetailPage;
