import React, { useMemo } from "react";
import { NodeApi } from "react-arborist";
import { HStack, Tag } from "@chakra-ui/react";
import { TelcoRate } from "../../../../api";
import { pluralize } from "@cobira/ui-library";

export interface TelcoRateNetworkBodyProps {
    node: NodeApi<TelcoRate>;
}

const TelcoRateNetworkBody = ({ node }: TelcoRateNetworkBodyProps) => {
    const content = useMemo(() => {
        const mapPlmn = (plmn: string) => (
            <Tag key={`${node.data.rateDiscriminator}-${plmn}`} colorScheme={"gray"}>
                {plmn}
            </Tag>
        );
        const maxRenderedPlmns = 5;
        const plmns = Array.from(node.data.rateKey);
        if (plmns.length > maxRenderedPlmns) {
            const overflowPlmnCount = plmns.length - maxRenderedPlmns;
            return [
                ...plmns.slice(maxRenderedPlmns).map(mapPlmn),
                <Tag key={`${node.data.rateDiscriminator}-remaining`}>{`${overflowPlmnCount} additional ${pluralize(
                    "network",
                    overflowPlmnCount,
                )}`}</Tag>,
            ];
        } else {
            return plmns.map(mapPlmn);
        }
    }, [node.data.rateDiscriminator, node.data.rateKey]);
    return <HStack gap={2}>{content}</HStack>;
};

export default TelcoRateNetworkBody;
