import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell, SlimUuid } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";

export interface UsagePackageTypeCellProps {
    icc: string;
    usagePackageId?: string;
}

export const UsagePackageCell = ({ icc, usagePackageId }: UsagePackageTypeCellProps) => {
    const { simCardApi, usagePackageApi } = useApi();
    if (!usagePackageId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () =>
                    simCardApi
                        .getSimCardUsagePackages({ icc: icc })
                        .then(packages => packages.find(usagePackage => usagePackage.id === usagePackageId)),
                queryKey: ["usagepackages", usagePackageId],
            }}
            render={packageResult => (
                <QueryCell
                    query={{
                        queryFn: () =>
                            usagePackageApi.getUsagePackageType({
                                usagePackageTypeId: packageResult?.data?.usagePackageTypeId || "",
                            }),
                        queryKey: ["usagepackagetypes", packageResult?.data?.usagePackageTypeId],
                    }}
                    render={packageTypeResult => (
                        <SlimUuid
                            uuid={packageResult?.data?.id || ""}
                            hasToolTip={true}
                            tooltip={[{ title: "Bundle", description: packageTypeResult?.data?.customName }]}
                        />
                    )}
                />
            )}
        />
    );
};
