/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingReportLinesFees } from './BillingReportLinesFees';
import {
    BillingReportLinesFeesFromJSON,
    BillingReportLinesFeesFromJSONTyped,
    BillingReportLinesFeesToJSON,
} from './BillingReportLinesFees';
import type { BillingReportLinesUsage } from './BillingReportLinesUsage';
import {
    BillingReportLinesUsageFromJSON,
    BillingReportLinesUsageFromJSONTyped,
    BillingReportLinesUsageToJSON,
} from './BillingReportLinesUsage';

/**
 * All cost & billable lines that make up a billing report
 * @export
 * @interface BillingReportLines
 */
export interface BillingReportLines {
    /**
     * 
     * @type {BillingReportLinesFees}
     * @memberof BillingReportLines
     */
    fees: BillingReportLinesFees;
    /**
     * 
     * @type {BillingReportLinesUsage}
     * @memberof BillingReportLines
     */
    usage: BillingReportLinesUsage;
}

/**
 * Check if a given object implements the BillingReportLines interface.
 */
export function instanceOfBillingReportLines(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fees" in value;
    isInstance = isInstance && "usage" in value;

    return isInstance;
}

export function BillingReportLinesFromJSON(json: any): BillingReportLines {
    return BillingReportLinesFromJSONTyped(json, false);
}

export function BillingReportLinesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingReportLines {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fees': BillingReportLinesFeesFromJSON(json['fees']),
        'usage': BillingReportLinesUsageFromJSON(json['usage']),
    };
}

export function BillingReportLinesToJSON(value?: BillingReportLines | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fees': BillingReportLinesFeesToJSON(value.fees),
        'usage': BillingReportLinesUsageToJSON(value.usage),
    };
}

