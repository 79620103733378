import React from "react";
import { Center, Heading, Text, VStack } from "@chakra-ui/react";
import { FaIcon } from "@cobira/ui-library";
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";

const CustomerCompareEmptyState = () => {
    return (
        <Center mt={"auto"} px={"20%"} py={"15%"}>
            <VStack>
                <FaIcon icon={faChartSimple} boxSize={20} color={"primary.500"} />
                <Heading size={"lg"} mt={1}>
                    Compare Customer performance
                </Heading>
                <Text color={"gray.500"} textAlign={"center"} mt={4}>
                    Begin by selecting customers to compare their performance metrics. Use the filter bar above to
                    quickly find and select the customers you&apos;re interested in.
                </Text>
                <Text color={"gray.500"} textAlign={"center"} mt={4}>
                    You have complete flexibility to change your selection at any time. Just add or remove customers as
                    needed to get the insights you require.
                </Text>
            </VStack>
        </Center>
    );
};

export default CustomerCompareEmptyState;
