import React from "react";
import { BasicModal, CopyButton, InfoBox, TextColumn } from "@cobira/ui-library";
import { AccessTokenWithSecret } from "../../../api";
import { Tag, TagLabel, VStack } from "@chakra-ui/react";

export interface AccessTokenSecretModalProps {
    accessToken: AccessTokenWithSecret;
    isOpen: boolean;
    onClose: () => void;
}

const AccessTokenSecretModal = ({ isOpen, onClose, accessToken }: AccessTokenSecretModalProps) => {
    return (
        <BasicModal isOpen={isOpen} onClose={onClose} title={"Access Token Secret"}>
            <VStack w={"100%"} align={"stretch"} gap={4} py={6}>
                <InfoBox
                    status={"warning"}
                    title="Please make sure you store this Secret."
                    description="The secret is only displayed once and can no longer be retrieved once this window is closed."
                    withIcon
                />
                <TextColumn heading={"Secret"}>
                    <Tag colorScheme={"gray"} maxW={"75%"}>
                        <TagLabel>{accessToken.secret}</TagLabel>
                        <CopyButton value={accessToken.secret || ""} />
                    </Tag>
                </TextColumn>
            </VStack>
        </BasicModal>
    );
};

export default AccessTokenSecretModal;
