/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerContact
 */
export interface CustomerContact {
    /**
     * 
     * @type {string}
     * @memberof CustomerContact
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContact
     */
    name?: string;
}

/**
 * Check if a given object implements the CustomerContact interface.
 */
export function instanceOfCustomerContact(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerContactFromJSON(json: any): CustomerContact {
    return CustomerContactFromJSONTyped(json, false);
}

export function CustomerContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function CustomerContactToJSON(value?: CustomerContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
    };
}

