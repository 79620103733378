import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Customer, CustomerApiUpdateCustomerRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import UpdateCustomerForm, { UpdateCustomerFormSchemaType } from "../../../forms/UpdateCustomerForm/UpdateCustomerForm";

export interface UpdateCustomerModalProps {
    customer?: Customer;
    isOpen: boolean;
    onClose: () => void;
}

const UpdateCustomerModal = ({ customer, isOpen, onClose }: UpdateCustomerModalProps) => {
    const { customerApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const updateCustomer = useMutation<Customer, Error, CustomerApiUpdateCustomerRequest>(
        variables => customerApi.updateCustomer(variables),
        {
            onSuccess: data => {
                notifications.success(`Customer '${data.customerName}' updated`);
                queryClient.invalidateQueries(["customers", customer?.id]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: UpdateCustomerFormSchemaType) => {
            updateCustomer.mutate({
                customerId: customer?.id || "",
                updateCustomer: {
                    customerName: form.customerName,
                    contactName: form.contactName || "N/A",
                    shippingAddress: form.shippingAddress || "N/A",
                    contactEmail: form.contactEmail || "N/A",
                    accountNumber: form.accountNumber,
                    billingId: form.billingId,
                    billingAddress: form.billingAddress,
                    billingCurrency: form.billingCurrency,
                    state: form.state,
                },
            });
            onClose();
        },
        [updateCustomer, customer?.id, onClose],
    );

    if (!customer) {
        return <></>;
    }

    return (
        <BasicModal title={`Update Customer`} onClose={onClose} isOpen={isOpen} modalProps={{ size: "xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <UpdateCustomerForm onSubmit={handleSubmit} onCancel={onClose} customer={customer} />
            </VStack>
        </BasicModal>
    );
};

export default UpdateCustomerModal;
