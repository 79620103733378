import { Tenant } from "../../../../api";
import {
    CobiraBarChartPaper,
    Loading,
    MetricLabelFormatters,
    MetricsProvider,
    MetricTooltipFormatters,
    newRequest,
    TickFormatters,
} from "@cobira/ui-library";
import React from "react";
import { useMetricsResolver } from "../../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";

export interface TenantDataUsagePaperProps {
    tenant?: Tenant;
    isLoading?: boolean;
}

const TenantDataUsagePaper = ({ tenant }: TenantDataUsagePaperProps) => {
    const resolver = useMetricsResolver();
    const now = new Date();
    const twelveMonthsAgo = subMonths(now, 12);

    if (!tenant) {
        return <Loading />;
    }

    const requests = {
        dataUsage: newRequest({
            bucket: "MONTHLY",
            dataPoint: "DATA_USAGE",
            group: "TENANT",
            groupId: tenant.id,
            fromDateTime: twelveMonthsAgo,
            toDateTime: now,
        }),
    };

    return (
        <MetricsProvider requests={requests} resolver={resolver}>
            {data => (
                <CobiraBarChartPaper
                    title={"Data Usage"}
                    bars={[
                        {
                            dataSet: data => data.dataUsage.value,
                            dataSetName: tenant?.name,
                        },
                    ]}
                    data={data}
                    chartConfig={{
                        xAxisValueLabel: data => MetricLabelFormatters.yearAndMonth(data.dataUsage),
                        yAxisTickFormatter: TickFormatters.dataTickFormatter,
                        tooltipFormatter: MetricTooltipFormatters.dataTooltip,
                    }}
                />
            )}
        </MetricsProvider>
    );
};

export default TenantDataUsagePaper;
