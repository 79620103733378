/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocationUpdateStatus } from './LocationUpdateStatus';
import {
    LocationUpdateStatusFromJSON,
    LocationUpdateStatusFromJSONTyped,
    LocationUpdateStatusToJSON,
} from './LocationUpdateStatus';

/**
 * 
 * @export
 * @interface SimCardNetworkStateCircuitSwitchingLocationUpdate
 */
export interface SimCardNetworkStateCircuitSwitchingLocationUpdate {
    /**
     * 
     * @type {LocationUpdateStatus}
     * @memberof SimCardNetworkStateCircuitSwitchingLocationUpdate
     */
    status?: LocationUpdateStatus;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateCircuitSwitchingLocationUpdate
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateCircuitSwitchingLocationUpdate interface.
 */
export function instanceOfSimCardNetworkStateCircuitSwitchingLocationUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateCircuitSwitchingLocationUpdateFromJSON(json: any): SimCardNetworkStateCircuitSwitchingLocationUpdate {
    return SimCardNetworkStateCircuitSwitchingLocationUpdateFromJSONTyped(json, false);
}

export function SimCardNetworkStateCircuitSwitchingLocationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateCircuitSwitchingLocationUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : LocationUpdateStatusFromJSON(json['status']),
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateCircuitSwitchingLocationUpdateToJSON(value?: SimCardNetworkStateCircuitSwitchingLocationUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': LocationUpdateStatusToJSON(value.status),
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

