import React from "react";
import { BillableFeeLine } from "../../../api";
import { CobiraTable, formatToKSyntax, Money } from "@cobira/ui-library";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomerRecurringFeeCell } from "../../Cells/CustomerRecurringFeeCell/CustomerRecurringFeeCell";
import { Text } from "@chakra-ui/react";

const billableFeeColumn = createColumnHelper<BillableFeeLine>();
const COLUMNS = (customerId: string) => [
    billableFeeColumn.accessor("customerRecurringFeeId", {
        id: "customerRecurringFeeId",
        header: "Recurring Fee",
        cell: row => <CustomerRecurringFeeCell customerId={customerId} customerRecurringFeeId={row.getValue()} />,
    }),
    billableFeeColumn.accessor("amount", {
        id: "amount",
        header: "Amount",
        meta: {
            isNumeric: true,
        },
        cell: row => <Text>{formatToKSyntax(row.getValue())}</Text>,
    }),
    billableFeeColumn.accessor(row => row, {
        id: "price",
        header: "Price",
        meta: {
            isNumeric: true,
        },
        cell: row => <Money value={row.getValue().price} currency={row.getValue().currency} decimals={2} />,
    }),
];

export interface BillableFeeTableProps {
    customerId: string;
    items: BillableFeeLine[];
    isLoading?: boolean;
}

const BillableFeeTable = ({ customerId, isLoading, items }: BillableFeeTableProps) => {
    return <CobiraTable columns={COLUMNS(customerId)} data={items} isLoading={isLoading} />;
};

export default BillableFeeTable;
