/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChargeGroup } from './ChargeGroup';
import {
    ChargeGroupFromJSON,
    ChargeGroupFromJSONTyped,
    ChargeGroupToJSON,
} from './ChargeGroup';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface InvoiceLine
 */
export interface InvoiceLine {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    billingGroupId?: string;
    /**
     * 
     * @type {ChargeGroup}
     * @memberof InvoiceLine
     */
    chargeGroup: ChargeGroup;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof InvoiceLine
     */
    chargeKey: Array<Array<string>>;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    cost: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    netPrice: number;
    /**
     * 
     * @type {Currency}
     * @memberof InvoiceLine
     */
    currency: Currency;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    appliedMarkup: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    state: InvoiceLineStateEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    stateMessage?: string;
}


/**
 * @export
 */
export const InvoiceLineStateEnum = {
    Ok: 'OK',
    Invalid: 'INVALID'
} as const;
export type InvoiceLineStateEnum = typeof InvoiceLineStateEnum[keyof typeof InvoiceLineStateEnum];


/**
 * Check if a given object implements the InvoiceLine interface.
 */
export function instanceOfInvoiceLine(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "chargeGroup" in value;
    isInstance = isInstance && "chargeKey" in value;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "netPrice" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "appliedMarkup" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function InvoiceLineFromJSON(json: any): InvoiceLine {
    return InvoiceLineFromJSONTyped(json, false);
}

export function InvoiceLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingGroupId': !exists(json, 'billingGroupId') ? undefined : json['billingGroupId'],
        'chargeGroup': ChargeGroupFromJSON(json['chargeGroup']),
        'chargeKey': json['chargeKey'],
        'cost': json['cost'],
        'price': json['price'],
        'netPrice': json['netPrice'],
        'currency': CurrencyFromJSON(json['currency']),
        'amount': json['amount'],
        'appliedMarkup': json['appliedMarkup'],
        'state': json['state'],
        'stateMessage': !exists(json, 'stateMessage') ? undefined : json['stateMessage'],
    };
}

export function InvoiceLineToJSON(value?: InvoiceLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingGroupId': value.billingGroupId,
        'chargeGroup': ChargeGroupToJSON(value.chargeGroup),
        'chargeKey': value.chargeKey,
        'cost': value.cost,
        'price': value.price,
        'netPrice': value.netPrice,
        'currency': CurrencyToJSON(value.currency),
        'amount': value.amount,
        'appliedMarkup': value.appliedMarkup,
        'state': value.state,
        'stateMessage': value.stateMessage,
    };
}

