/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { UsagePackageType } from './UsagePackageType';
import {
    UsagePackageTypeFromJSON,
    UsagePackageTypeFromJSONTyped,
    UsagePackageTypeToJSON,
} from './UsagePackageType';

/**
 * Page containing usage package types
 * @export
 * @interface UsagePackageTypePage
 */
export interface UsagePackageTypePage {
    /**
     * 
     * @type {Page}
     * @memberof UsagePackageTypePage
     */
    pageProperties: Page;
    /**
     * 
     * @type {Array<UsagePackageType>}
     * @memberof UsagePackageTypePage
     */
    content: Array<UsagePackageType>;
}

/**
 * Check if a given object implements the UsagePackageTypePage interface.
 */
export function instanceOfUsagePackageTypePage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pageProperties" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function UsagePackageTypePageFromJSON(json: any): UsagePackageTypePage {
    return UsagePackageTypePageFromJSONTyped(json, false);
}

export function UsagePackageTypePageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePackageTypePage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': PageFromJSON(json['pageProperties']),
        'content': ((json['content'] as Array<any>).map(UsagePackageTypeFromJSON)),
    };
}

export function UsagePackageTypePageToJSON(value?: UsagePackageTypePage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': ((value.content as Array<any>).map(UsagePackageTypeToJSON)),
    };
}

