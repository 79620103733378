import React from "react";
import { z } from "zod";
import { Currency } from "../../api";
import {
    ControlledAutoCompleteSingleSelectInput,
    ControlledMonthInput,
    FormContainer,
    FormField,
    Month,
} from "@cobira/ui-library";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, ButtonGroup, HStack, Input } from "@chakra-ui/react";
import { CurrencyLabels } from "../../labels/CurrencyLabels";
import { CURRENCY_OPTIONS } from "../../utils/pricingUtils";

const CreateFlatCustomerRecurringFeeFormSchema = z.object({
    name: z.string().min(1, "Name must be specified"),
    anniversary: z.object({
        year: z.number().min(2020, "Cannot create fees with anniversary before 2020"),
        month: z.nativeEnum(Month),
    }),
    recurrence: z.number().min(1, "Fee must recur at least once a month"),
    currency: z.nativeEnum(Currency),
    price: z.number().min(0, "Price must be larger than 0"),
});

export type CreateFlatCustomerRecurringFeeFormType = z.infer<typeof CreateFlatCustomerRecurringFeeFormSchema>;

export interface CreateFlatCustomerRecurringFeeFormProps {
    onSubmit: (form: CreateFlatCustomerRecurringFeeFormType) => void;
    submitting?: boolean;
    onCancel?: () => void;
}

const CreateFlatCustomerRecurringFeeForm = ({
    onSubmit,
    onCancel,
    submitting,
}: CreateFlatCustomerRecurringFeeFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
    } = useForm<CreateFlatCustomerRecurringFeeFormType>({
        resolver: zodResolver(CreateFlatCustomerRecurringFeeFormSchema),
        defaultValues: {
            anniversary: {
                year: new Date().getUTCFullYear(),
                month: Object.values(Month)[new Date().getUTCMonth()],
            },
            recurrence: 1,
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <HStack w={"100%"} gap={2}>
                    <FormField label={"Anniversary Year"} error={errors?.anniversary?.year}>
                        <Input {...register("anniversary.year", { valueAsNumber: true })} />
                    </FormField>
                    <FormField label={"Anniversary Month"} error={errors?.anniversary?.month}>
                        <ControlledMonthInput control={control} name={"anniversary.month"} />
                    </FormField>
                </HStack>
                <HStack w={"100%"} gap={2}>
                    <FormField label={"Months between recurrence"} error={errors?.recurrence}>
                        <Input {...register("recurrence", { valueAsNumber: true })} />
                    </FormField>
                    <FormField label={"Currency"} error={errors?.currency}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{
                                control: control,
                                name: "currency",
                            }}
                            input={{
                                items: CURRENCY_OPTIONS,
                                placeholder: "Select Currency",
                                displayMapping: value => CurrencyLabels[value],
                                autocompleteAbleMapping: value => CurrencyLabels[value],
                            }}
                        />
                    </FormField>
                </HStack>
                <FormField label={"Price"} error={errors?.price}>
                    <Input {...register("price", { valueAsNumber: true })} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"} isLoading={submitting} isDisabled={submitting}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"} isDisabled={submitting}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default CreateFlatCustomerRecurringFeeForm;
