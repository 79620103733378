import { z } from "zod";
import {
    Currency,
    PostProcessingRatingStrategy,
    PostProcessingUsageContext,
    TelcoRateRateDiscriminatorEnum,
    TelcoServiceType,
} from "../../api";
import { MarkupType } from "../../utils/markupUtils";

export enum PricingOption {
    SIMPLE = "SIMPLE",
    ADVANCED = "ADVANCED",
}

export const TelcoRateFormSchema = z.object({
    rateDiscriminator: z.nativeEnum(TelcoRateRateDiscriminatorEnum),
    rateKey: z.nativeEnum(TelcoServiceType),
    currency: z.nativeEnum(Currency),
    pricingStrategy: z.nativeEnum(PricingOption),
    absoluteMarkupConversionRate: z.number(),
    rate: z
        .object({
            markup: z.nativeEnum(MarkupType),
            markupAmount: z.number(),
        })
        .optional(),
    postProcessing: z
        .object({
            context: z.nativeEnum(PostProcessingUsageContext),
            strategy: z.nativeEnum(PostProcessingRatingStrategy),
            discountTierCount: z
                .number()
                .min(2, "At least two bounds must be specified, otherwise use the Constant Rate pricing option"),
            discountTiers: z.object({
                markup: z.nativeEnum(MarkupType),
                bounds: z.array(
                    z.object({
                        upperBound: z.number().optional(),
                        markupAmount: z.number(),
                    }),
                ),
            }),
        })
        .optional(),
});

export type TelcoRateFormSchemaType = z.infer<typeof TelcoRateFormSchema>;
