import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

const AssignNetworkCredentialTypeFormSchema = z.object({
    networkCredentialType: z.object({
        id: z.string(),
        plmn: z.string(),
        imsiProvider: z.string().optional(),
    }),
});

export type AssignNetworkCredentialTypeFormSchemaType = z.infer<typeof AssignNetworkCredentialTypeFormSchema>;

export interface AssignNetworkCredentialTypeFormProps {
    onSubmit: (form: AssignNetworkCredentialTypeFormSchemaType) => void;
    onCancel?: () => void;
}

const AssignNetworkCredentialTypeForm = ({ onSubmit, onCancel }: AssignNetworkCredentialTypeFormProps) => {
    const { configurationApi } = useApi();
    const {
        formState: { errors },
        control,
        handleSubmit,
    } = useForm<AssignNetworkCredentialTypeFormSchemaType>({
        resolver: zodResolver(AssignNetworkCredentialTypeFormSchema),
    });

    const { data: networkCredentialTypes } = useQuery(["networkcredentialtypes"], () =>
        configurationApi.getNetworkCredentialTypes({
            pageNumber: 0,
            pageSize: 9999,
        }),
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Network Credential Type"} error={errors?.networkCredentialType}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: "networkCredentialType" }}
                        input={{
                            placeholder: "Select Network Credential Type",
                            items: networkCredentialTypes?.content || [],
                            displayMapping: value => `${value.plmn} - ${value.imsiProvider}`,
                            autocompleteAbleMapping: value => `${value.plmn} - ${value.imsiProvider}`,
                        }}
                    />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Assign
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default AssignNetworkCredentialTypeForm;
