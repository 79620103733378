/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostProcessingRatingStrategy } from './PostProcessingRatingStrategy';
import {
    PostProcessingRatingStrategyFromJSON,
    PostProcessingRatingStrategyFromJSONTyped,
    PostProcessingRatingStrategyToJSON,
} from './PostProcessingRatingStrategy';
import type { PostProcessingTier } from './PostProcessingTier';
import {
    PostProcessingTierFromJSON,
    PostProcessingTierFromJSONTyped,
    PostProcessingTierToJSON,
} from './PostProcessingTier';
import type { PostProcessingUsageContext } from './PostProcessingUsageContext';
import {
    PostProcessingUsageContextFromJSON,
    PostProcessingUsageContextFromJSONTyped,
    PostProcessingUsageContextToJSON,
} from './PostProcessingUsageContext';

/**
 * Tiers for post-processing the rated usage.
 * Tiers may utilize the available context, to determine, for example distinct IMSIs present for the matching rate entry for the rated month.
 * In practice this can be used for creating "included data / sms" packages, where the customer only pays for the excess usage.
 * 
 * If no strategy is specified the default 'THRESHOLD' will be used.
 * @export
 * @interface PostProcessingTiers
 */
export interface PostProcessingTiers {
    /**
     * 
     * @type {PostProcessingUsageContext}
     * @memberof PostProcessingTiers
     */
    context: PostProcessingUsageContext;
    /**
     * 
     * @type {PostProcessingRatingStrategy}
     * @memberof PostProcessingTiers
     */
    strategy: PostProcessingRatingStrategy;
    /**
     * 
     * @type {Array<PostProcessingTier>}
     * @memberof PostProcessingTiers
     */
    tiers: Array<PostProcessingTier>;
}

/**
 * Check if a given object implements the PostProcessingTiers interface.
 */
export function instanceOfPostProcessingTiers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "context" in value;
    isInstance = isInstance && "strategy" in value;
    isInstance = isInstance && "tiers" in value;

    return isInstance;
}

export function PostProcessingTiersFromJSON(json: any): PostProcessingTiers {
    return PostProcessingTiersFromJSONTyped(json, false);
}

export function PostProcessingTiersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProcessingTiers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': PostProcessingUsageContextFromJSON(json['context']),
        'strategy': PostProcessingRatingStrategyFromJSON(json['strategy']),
        'tiers': ((json['tiers'] as Array<any>).map(PostProcessingTierFromJSON)),
    };
}

export function PostProcessingTiersToJSON(value?: PostProcessingTiers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': PostProcessingUsageContextToJSON(value.context),
        'strategy': PostProcessingRatingStrategyToJSON(value.strategy),
        'tiers': ((value.tiers as Array<any>).map(PostProcessingTierToJSON)),
    };
}

