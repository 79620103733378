import React from "react";
import { Box, Button, Card, CardBody, Heading, Stack, StackDivider, Text } from "@chakra-ui/react";

export enum RecurringFeeType {
    FLAT_FEE,
    WHOLESALE,
    CONSUMPTION_BASED,
}

export interface RecurringFeeModalOptionsProps {
    onSelection: (recurringFeeType: RecurringFeeType) => void;
}

const RecurringFeeModalOptions = ({ onSelection }: RecurringFeeModalOptionsProps) => {
    return (
        <Card variant={"unstyled"}>
            <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                    <Box>
                        <Heading size="xs">FLAT FEE</Heading>
                        <Text pt="2" fontSize="sm">
                            Create a simple fee that will be applied on each applicable billing cycle. Can be used to
                            charge for a static service offering. e.g. Platform access fee
                        </Text>
                        <Button size={"sm"} onClick={() => onSelection(RecurringFeeType.FLAT_FEE)} mt={2}>
                            Select
                        </Button>
                    </Box>
                    <Box>
                        <Heading size="xs">WHOLESALE</Heading>
                        <Text pt="2" fontSize="sm">
                            Charge in fixed pricing tiers based on the usage / consumption of the customer. Can be used
                            to charge fixed data amount or for specific amounts of active SIMs / IMSIs.
                        </Text>
                        <Button size={"sm"} onClick={() => onSelection(RecurringFeeType.WHOLESALE)} mt={2}>
                            Select
                        </Button>
                    </Box>
                    <Box>
                        <Heading size="xs">CONSUMPTION BASED</Heading>
                        <Text pt="2" fontSize="sm">
                            Charge consumption in matched tiers / volumes.
                        </Text>
                        <Button size={"sm"} onClick={() => onSelection(RecurringFeeType.CONSUMPTION_BASED)} mt={2}>
                            Select
                        </Button>
                    </Box>
                </Stack>
            </CardBody>
        </Card>
    );
};

export default RecurringFeeModalOptions;
