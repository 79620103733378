import { RadioTechnology } from "../api";

export const RadioTechnologyLabels = (rat?: RadioTechnology) => {
    if (!rat) {
        return undefined;
    }
    switch (rat) {
        case "5G":
            return "5G";
        case "3G":
            return "3G";
        case "4G":
            return "4G";
        case "2G":
            return "2G";
        default:
            return "Unknown";
    }
};
