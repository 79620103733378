/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';
import type { UsagePackageManagementAction } from './UsagePackageManagementAction';
import {
    UsagePackageManagementActionFromJSON,
    UsagePackageManagementActionFromJSONTyped,
    UsagePackageManagementActionToJSON,
} from './UsagePackageManagementAction';

/**
 * Usage package management request and supported arguments. 
 * If no filtering is applied it means all sim cards will be targeted.
 * @export
 * @interface UsagePackageManagementRequest
 */
export interface UsagePackageManagementRequest {
    /**
     * 
     * @type {UsagePackageManagementAction}
     * @memberof UsagePackageManagementRequest
     */
    usagePackageManagementAction: UsagePackageManagementAction;
    /**
     * 
     * @type {string}
     * @memberof UsagePackageManagementRequest
     */
    usagePackageTypeId: string;
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof UsagePackageManagementRequest
     */
    simCardFilter: SimCardActionRequestFilter;
}

/**
 * Check if a given object implements the UsagePackageManagementRequest interface.
 */
export function instanceOfUsagePackageManagementRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "usagePackageManagementAction" in value;
    isInstance = isInstance && "usagePackageTypeId" in value;
    isInstance = isInstance && "simCardFilter" in value;

    return isInstance;
}

export function UsagePackageManagementRequestFromJSON(json: any): UsagePackageManagementRequest {
    return UsagePackageManagementRequestFromJSONTyped(json, false);
}

export function UsagePackageManagementRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePackageManagementRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usagePackageManagementAction': UsagePackageManagementActionFromJSON(json['usagePackageManagementAction']),
        'usagePackageTypeId': json['usagePackageTypeId'],
        'simCardFilter': SimCardActionRequestFilterFromJSON(json['simCardFilter']),
    };
}

export function UsagePackageManagementRequestToJSON(value?: UsagePackageManagementRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usagePackageManagementAction': UsagePackageManagementActionToJSON(value.usagePackageManagementAction),
        'usagePackageTypeId': value.usagePackageTypeId,
        'simCardFilter': SimCardActionRequestFilterToJSON(value.simCardFilter),
    };
}

