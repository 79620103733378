import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { SimConfiguration, SimConfigurationPage } from "../../api";
import { CobiraTable } from "@cobira/ui-library";
import { UseQueryResult } from "@tanstack/react-query";
import {
    CobiraTableManualPaginationProps,
    CobiraTableRowClickEnabledProps,
} from "@cobira/ui-library/dist/cjs/components/CobiraTable/CobiraTable";
import DeletedAt from "../DeletedAt/DeletedAt";
import SimConfigurationTableActions from "./SimConfigurationTableActions";
import SimConfigurationCapabilityCell from "../Cells/SimConfigurationCapabilityCell/SimConfigurationCapabilityCell";
import NetworkCredentialTypesCell from "../Cells/NetworkCredentialTypesCell/NetworkCredentialTypesCell";

const simConfigurationColumn = createColumnHelper<SimConfiguration>();

const COLUMNS = [
    simConfigurationColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    simConfigurationColumn.accessor("simCardCapabilities", {
        id: "capabilities",
        header: "Capabilities",
        cell: row => <SimConfigurationCapabilityCell capabilities={row.getValue()} />,
    }),
    simConfigurationColumn.accessor("networkCredentialTypeIds", {
        id: "networkCredentialTypes",
        header: "Network Credential Types",
        cell: row => <NetworkCredentialTypesCell networkCredentialTypeIds={row.getValue()} />,
    }),
    simConfigurationColumn.accessor("deletedAt", {
        id: "deletedAt",
        header: "Deleted At",
        cell: row => <DeletedAt deletedAt={row.getValue()} />,
    }),
];

export interface SimConfigurationTableProps {
    simConfigurationQuery: UseQueryResult<SimConfigurationPage>;
    pagination: CobiraTableManualPaginationProps;
    withRowClick: CobiraTableRowClickEnabledProps<SimConfiguration>;
    actions?: {
        onDeleteClicked?: (simConfiguration: SimConfiguration) => void;
        onRestoreClicked?: (simConfiguration: SimConfiguration) => void;
    };
}

const SimConfigurationTable = ({
    simConfigurationQuery,
    pagination,
    withRowClick,
    actions,
}: SimConfigurationTableProps) => {
    const { data: simConfigurations, isLoading } = simConfigurationQuery;
    const columns = useMemo(() => {
        const memoizedColumns = [...COLUMNS];
        if (actions !== undefined) {
            memoizedColumns.push(
                simConfigurationColumn.display({
                    id: "actions",
                    meta: {
                        isButton: true,
                    },
                    cell: content => (
                        <SimConfigurationTableActions simConfiguration={content.row.original} actions={actions} />
                    ),
                }),
            );
        }

        return memoizedColumns;
    }, [actions]);
    return (
        <CobiraTable
            sizing={"fit-page"}
            columns={columns}
            data={simConfigurations?.content || []}
            isLoading={isLoading}
            withPagination={pagination}
            withRowClick={withRowClick}
        />
    );
};

export default SimConfigurationTable;
