import React, { useState } from "react";
import { PageLayout, PageTitle } from "@cobira/ui-library";
import { Box, VStack } from "@chakra-ui/react";
import { Customer } from "../../../api";
import CustomerCompareSelectionInput from "./CustomerCompareSelectionInput";
import CustomerCompareCharts from "./CustomerCompareCharts";
import CustomerCompareEmptyState from "./CustomerCompareEmptyState";

const CustomerCompareDashboardPage = () => {
    const [customerSelection, setCustomerSelection] = useState<Customer[]>([]);

    return (
        <PageLayout title={<PageTitle title={"Customer Trends"} />}>
            <VStack w={"100%"} align={"stretch"} spacing={6}>
                <Box alignSelf={"flex-start"} w={"80%"}>
                    <CustomerCompareSelectionInput onChange={setCustomerSelection} />
                </Box>
                {customerSelection.length > 0 ? (
                    <CustomerCompareCharts customers={customerSelection} />
                ) : (
                    <CustomerCompareEmptyState />
                )}
            </VStack>
        </PageLayout>
    );
};

export default CustomerCompareDashboardPage;
