import React from "react";
import { z } from "zod";
import { Currency, RecurringFeeContext } from "../../api";
import {
    ControlledAutoCompleteSingleSelectInput,
    ControlledMonthInput,
    FormContainer,
    FormField,
    Month,
} from "@cobira/ui-library";
import { Control, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, ButtonGroup, HStack, Input } from "@chakra-ui/react";
import { CurrencyLabels } from "../../labels/CurrencyLabels";
import { CURRENCY_OPTIONS } from "../../utils/pricingUtils";
import { ControlledBillingGroupSelectInput } from "../../components/Input/ControlledBillingGroupSelectInput/ControlledBillingGroupSelectInput";
import CustomerRecurringFeePricingInput, {
    CustomerRecurringFeePricingFormSchema,
} from "./CustomerRecurringFeePricingInput";
import { RecurringFeeContextLabels } from "../../labels/RecurringFeeContextLabels";

const WHOLESALE_CONTEXT_OPTIONS = [
    RecurringFeeContext.BytesUsed,
    RecurringFeeContext.ActiveSim,
    RecurringFeeContext.ActiveImsi,
    RecurringFeeContext.InventorySim,
] as const;

const CreateWholesaleCustomerRecurringFeeFormSchema = z
    .object({
        name: z.string().min(1, "Name must be specified"),
        anniversary: z.object({
            year: z.number().min(2020, "Cannot create fees with anniversary before 2020"),
            month: z.nativeEnum(Month),
        }),
        recurrence: z.number().min(1, "Fee must recur at least once a month"),
        currency: z.nativeEnum(Currency),
        context: z.enum(WHOLESALE_CONTEXT_OPTIONS),
        billingGroups: z
            .array(
                z.object({
                    name: z.string(),
                    id: z.string(),
                }),
            )
            .optional(),
    })
    .merge(CustomerRecurringFeePricingFormSchema);

export type CreateWholesaleCustomerRecurringFeeFormType = z.infer<typeof CreateWholesaleCustomerRecurringFeeFormSchema>;

export interface CreateWholesaleCustomerRecurringFeeFormProps {
    customerId: string;
    onSubmit: (form: CreateWholesaleCustomerRecurringFeeFormType) => void;
    submitting?: boolean;
    onCancel?: () => void;
}

const CreateWholesaleCustomerRecurringFeeForm = ({
    customerId,
    onSubmit,
    submitting,
    onCancel,
}: CreateWholesaleCustomerRecurringFeeFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        watch,
        control,
        register,
    } = useForm<CreateWholesaleCustomerRecurringFeeFormType>({
        resolver: zodResolver(CreateWholesaleCustomerRecurringFeeFormSchema),
        defaultValues: {
            anniversary: {
                year: new Date().getUTCFullYear(),
                month: Object.values(Month)[new Date().getUTCMonth()],
            },
            recurrence: 1,
            currency: "EUR",
            priceBounds: [
                {
                    price: 0,
                    lowerBound: 1,
                },
            ],
        },
    });

    const context = watch("context");
    const currency = watch("currency");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <HStack w={"100%"} gap={2}>
                    <FormField label={"Anniversary Year"} error={errors?.anniversary?.year}>
                        <Input {...register("anniversary.year", { valueAsNumber: true })} />
                    </FormField>
                    <FormField label={"Anniversary Month"} error={errors?.anniversary?.month}>
                        <ControlledMonthInput control={control} name={"anniversary.month"} />
                    </FormField>
                </HStack>
                <HStack w={"100%"} gap={2}>
                    <FormField label={"Months between recurrence"} error={errors?.recurrence}>
                        <Input {...register("recurrence", { valueAsNumber: true })} />
                    </FormField>
                    <FormField label={"Currency"} error={errors?.currency}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{
                                control: control,
                                name: "currency",
                            }}
                            input={{
                                items: CURRENCY_OPTIONS,
                                placeholder: "Select Currency",
                                displayMapping: value => CurrencyLabels[value],
                                autocompleteAbleMapping: value => CurrencyLabels[value],
                            }}
                        />
                    </FormField>
                </HStack>
                <FormField label={"Fee Context"} error={errors?.context}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{
                            control: control,
                            name: "context",
                        }}
                        input={{
                            items: [...WHOLESALE_CONTEXT_OPTIONS],
                            placeholder: "Select Context",
                            displayMapping: value => RecurringFeeContextLabels[value],
                            autocompleteAbleMapping: value => RecurringFeeContextLabels[value],
                        }}
                    />
                </FormField>
                <FormField label={"Limit fee to certain billing groups"} error={errors?.billingGroups}>
                    <ControlledBillingGroupSelectInput
                        control={{ control: control, name: "billingGroups" }}
                        input={{ isMulti: true }}
                        customerIdConstraint={customerId}
                    />
                </FormField>
                {context && (
                    <CustomerRecurringFeePricingInput
                        context={context}
                        currency={currency}
                        control={
                            control as Control<
                                Partial<CreateWholesaleCustomerRecurringFeeFormType> &
                                    Pick<CreateWholesaleCustomerRecurringFeeFormType, "priceBounds" | "minimumPrice">
                            >
                        }
                        errors={errors}
                    />
                )}
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"} isLoading={submitting} isDisabled={submitting}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"} isDisabled={submitting}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default CreateWholesaleCustomerRecurringFeeForm;
