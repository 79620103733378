import { BillableUsageLine, BillingReport, BillingReportLines, type CostUsageLine } from "../api";
import { Currency } from "@cobira/ui-library";

export type AggregatedBillingReportWithLines = BillingReportLines &
    AggregatedBillingReport & {
        usage: {
            merged: Array<MergedUsageLine>;
        };
    };

export type MergedUsageLine = CostUsageLine & BillableUsageLine;

export type AggregatedBillingReport = BillingReport & BillingReportAggregate;

export type BillingReportAggregate = Pick<BillingReport, "year" | "month"> & {
    totalFeeCost: number;
    totalFeeBillable: number;
    totalUsageCost: number;
    totalUsageBillable: number;
    currency: Currency;
};

export const aggregateReportLines = (
    lines: BillingReportLines,
    convert: (value: number, from: Currency, to: Currency) => number,
    targetCurrency: Currency,
): {
    totalFeeCost: number;
    totalFeeBillable: number;
    totalUsageCost: number;
    totalUsageBillable: number;
    mergedUsageLines: Array<MergedUsageLine>;
} => {
    lines.usage.billables.forEach(line => {
        line.price = convert(line.price, line.currency, targetCurrency);
        line.currency = targetCurrency;
    });
    lines.usage.costs.forEach(line => {
        line.cost = convert(line.cost, line.currency, targetCurrency);
        line.currency = targetCurrency;
    });
    lines.fees.billables.forEach(line => {
        line.price = convert(line.price, line.currency, targetCurrency);
        line.currency = targetCurrency;
    });
    lines.fees.costs.forEach(line => {
        line.cost = convert(line.cost, line.currency, targetCurrency);
        line.currency = targetCurrency;
    });
    const totalFeeCost = lines.fees.costs.reduce((cost, line) => cost + line.cost, 0);
    const totalFeeBillable = lines.fees.billables.reduce((billable, line) => billable + line.price, 0);
    const totalUsageCost = lines.usage.costs.reduce((cost, line) => cost + line.cost, 0);
    const totalUsageBillable = lines.usage.billables.reduce((billable, line) => billable + line.price, 0);
    const mergedUsageLines: Array<MergedUsageLine> = lines.usage.costs.map(
        cost =>
            ({
                ...cost,
                ...lines.usage.billables.find(billable => billable.costReferenceId === cost.billableReferenceId),
            }) as MergedUsageLine,
    );
    return { totalFeeCost, totalFeeBillable, totalUsageCost, totalUsageBillable, mergedUsageLines };
};

export const aggregateBillingReportAggregates = (
    aggregatedReports: AggregatedBillingReport[],
): BillingReportAggregate | undefined => {
    if (aggregatedReports.length === 0) {
        return undefined;
    }
    const initialValue: BillingReportAggregate = {
        currency: "UNDEFINED",
        year: 0,
        month: "JANUARY",
        totalFeeBillable: 0,
        totalUsageBillable: 0,
        totalFeeCost: 0,
        totalUsageCost: 0,
    };
    return aggregatedReports.reduce(
        (aggregate, currentValue) => ({
            year: currentValue.year,
            month: currentValue.month,
            currency: currentValue.currency,
            totalFeeBillable: aggregate.totalFeeBillable + currentValue.totalFeeBillable,
            totalUsageBillable: aggregate.totalUsageBillable + currentValue.totalUsageBillable,
            totalFeeCost: aggregate.totalFeeCost + currentValue.totalFeeCost,
            totalUsageCost: aggregate.totalUsageCost + currentValue.totalUsageCost,
        }),
        initialValue,
    );
};
