/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillableUsageLine } from './BillableUsageLine';
import {
    BillableUsageLineFromJSON,
    BillableUsageLineFromJSONTyped,
    BillableUsageLineToJSON,
} from './BillableUsageLine';
import type { CostUsageLine } from './CostUsageLine';
import {
    CostUsageLineFromJSON,
    CostUsageLineFromJSONTyped,
    CostUsageLineToJSON,
} from './CostUsageLine';

/**
 * 
 * @export
 * @interface BillingReportLinesUsage
 */
export interface BillingReportLinesUsage {
    /**
     * 
     * @type {Array<CostUsageLine>}
     * @memberof BillingReportLinesUsage
     */
    costs: Array<CostUsageLine>;
    /**
     * 
     * @type {Array<BillableUsageLine>}
     * @memberof BillingReportLinesUsage
     */
    billables: Array<BillableUsageLine>;
}

/**
 * Check if a given object implements the BillingReportLinesUsage interface.
 */
export function instanceOfBillingReportLinesUsage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costs" in value;
    isInstance = isInstance && "billables" in value;

    return isInstance;
}

export function BillingReportLinesUsageFromJSON(json: any): BillingReportLinesUsage {
    return BillingReportLinesUsageFromJSONTyped(json, false);
}

export function BillingReportLinesUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingReportLinesUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costs': ((json['costs'] as Array<any>).map(CostUsageLineFromJSON)),
        'billables': ((json['billables'] as Array<any>).map(BillableUsageLineFromJSON)),
    };
}

export function BillingReportLinesUsageToJSON(value?: BillingReportLinesUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costs': ((value.costs as Array<any>).map(CostUsageLineToJSON)),
        'billables': ((value.billables as Array<any>).map(BillableUsageLineToJSON)),
    };
}

