import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Customer, CustomerApiCreateCustomerContactRequest, CustomerContact } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import CreateCustomerContactForm, {
    CreateCustomerContactFormSchemaType,
} from "../../../forms/CreateCustomerContactForm/CreateCustomerContactForm";

export interface CreateCustomerContactModalProps {
    customer?: Customer;
    isOpen: boolean;
    onClose: () => void;
}

const CreateCustomerContactModal = ({ customer, isOpen, onClose }: CreateCustomerContactModalProps) => {
    const { customerApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createCustomerContact = useMutation<CustomerContact, Error, CustomerApiCreateCustomerContactRequest>(
        variables => customerApi.createCustomerContact(variables),
        {
            onSuccess: data => {
                notifications.success(`Contact '${data.name}' created`);
                queryClient.invalidateQueries(["customers", customer?.id, "contacts"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: CreateCustomerContactFormSchemaType) => {
            createCustomerContact.mutate({
                customerId: customer?.id || "",
                createCustomerContact: {
                    name: form.name,
                    email: form.email,
                },
            });
            onClose();
        },
        [createCustomerContact, onClose, customer],
    );

    if (!customer) {
        return <></>;
    }

    return (
        <BasicModal title={`Create Contact`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <CreateCustomerContactForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default CreateCustomerContactModal;
