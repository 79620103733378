import { z } from "zod";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Currency } from "../../api";
import { MarkupType } from "../../utils/markupUtils";
import { FormContainer } from "@cobira/ui-library";
import RateMarkupInput from "../TelcoRateForm/RateMarkupInput";
import { CreateRatePlanSchemaType } from "./CreateRatePlanForm";
import { NetworkAccessRateFormSchema } from "../TelcoRateForm/NetworkAccessRateFormSchema";

export const RatePlanNetworkAccessRateSchema = z.object({
    networkAccessRate: NetworkAccessRateFormSchema,
});

const CreateRatePlanNetworkAccessRateFormStep = () => {
    const {
        formState: { errors },
        register,
    } = useFormContext<CreateRatePlanSchemaType>();
    return (
        <FormContainer>
            <RateMarkupInput
                unit={"IMSI"}
                currency={Currency.Undefined}
                markupType={MarkupType.PERCENTAGE}
                error={errors?.dataRate?.rate?.markupAmount}
                registration={register("networkAccessRate.percentageMarkup", { valueAsNumber: true })}
            />
        </FormContainer>
    );
};

export default CreateRatePlanNetworkAccessRateFormStep;
