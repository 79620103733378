import {
    CurrencyIcon,
    DataText,
    decimalNumberToString,
    formatDecimal,
    InfoCard,
    Loading,
    NotFound,
    PageBackButton,
    PageLayout,
    PageTitle,
    Paper,
    TextColumn,
} from "@cobira/ui-library";
import React from "react";
import { useParams } from "react-router";
import { useUserSettings } from "../../hooks/useUserSettings";
import { MonthLabels } from "../../labels/MonthLabels";
import { useAggregatedBillingReport } from "../../hooks/useAggregatedBillingReport";
import { Grid, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import BillableFeeTable from "../../components/BillingReportTables/BillableFeeTable/BillableFeeTable";
import CostFeeTable from "../../components/BillingReportTables/CostFeeTable/CostFeeTable";
import { CustomerCell } from "../../components/Cells/CustomerCell/CustomerCell";
import MergedUsageTable from "../../components/BillingReportTables/MergedUsageTable/MergedUsageTable";

const BillingPeriodReportsPage = () => {
    const { billingReportId } = useParams();
    const { currency } = useUserSettings();
    const billingReportQuery = useAggregatedBillingReport({
        targetCurrency: currency,
        billingReportId: billingReportId,
    });

    if (billingReportQuery?.isFetching) {
        return <Loading fullScreen />;
    }
    const billingReport = billingReportQuery?.data;
    if (!billingReport) {
        return <NotFound resource={"Billing Report"} />;
    }

    const totalCost = billingReport.totalFeeCost + billingReport.totalUsageCost;
    const totalBillable = billingReport.totalFeeBillable + billingReport.totalUsageBillable;
    const margin = totalBillable - totalCost;

    const totalBytesUsed = billingReport.usage.billables
        .filter(billable => billable.classifier === "DATA")
        .reduce((bytes, billable) => bytes + billable.amount, 0);
    const totalMoSMS = billingReport.usage.billables
        .filter(billable => billable.classifier === "MO_SMS")
        .reduce((smsCount, billable) => smsCount + billable.amount, 0);
    const totalMtSMS = billingReport.usage.billables
        .filter(billable => billable.classifier === "MT_SMS")
        .reduce((smsCount, billable) => smsCount + billable.amount, 0);
    const totalActiveImsis = billingReport.fees.costs
        .filter(cost => cost.classifier === "ACTIVE_IMSI")
        .reduce((imsiCount, cost) => imsiCount + cost.amount, 0);

    return (
        <PageLayout
            title={<PageTitle title={`Billing Report ${MonthLabels[billingReport.month]} - ${billingReport.year}`} />}
            navigation={
                <PageBackButton
                    text={"Back to billing reports"}
                    path={`/billing/reports/${billingReport.year}/${billingReport.month}`}
                />
            }
        >
            <VStack w={"100%"} align={"stretch"} gap={4}>
                <HStack w={"100%"} align={"stretch"} gap={4}>
                    <Paper header={"Summary"} withDivider cardProps={{ w: "80%" }}>
                        <Grid templateColumns="auto auto" gap="4">
                            <TextColumn heading="Customer" grid isLoading={billingReportQuery?.isFetching}>
                                <CustomerCell customerId={billingReport.customerId} />
                            </TextColumn>
                            <TextColumn heading="Total Data" grid isLoading={billingReportQuery?.isFetching}>
                                <DataText bytes={totalBytesUsed} />
                            </TextColumn>
                            <TextColumn heading="Total MO SMS" grid isLoading={billingReportQuery?.isFetching}>
                                <Text>{totalMoSMS} Messages</Text>
                            </TextColumn>
                            <TextColumn heading="Total MT SMS" grid isLoading={billingReportQuery?.isFetching}>
                                <Text>{totalMtSMS} Messages</Text>
                            </TextColumn>
                            <TextColumn heading="Total Active IMSI" grid isLoading={billingReportQuery?.isFetching}>
                                <Text>{totalActiveImsis} IMSIs</Text>
                            </TextColumn>
                        </Grid>
                    </Paper>
                    <VStack gap={4} align={"stretch"} w={"20%"}>
                        <InfoCard
                            color={margin > 0 ? "green" : "red"}
                            title={"Margin"}
                            value={`${decimalNumberToString(formatDecimal(margin, 2), 2)}`}
                            icon={CurrencyIcon(billingReport.currency)}
                        />
                        <InfoCard
                            color={"green"}
                            title={"Total Billable"}
                            value={`${decimalNumberToString(formatDecimal(totalBillable, 2), 2)}`}
                            icon={CurrencyIcon(billingReport.currency)}
                        />
                        <InfoCard
                            color={"red"}
                            title={"Total Cost"}
                            value={`${decimalNumberToString(formatDecimal(totalCost, 2), 2)}`}
                            icon={CurrencyIcon(billingReport.currency)}
                        />
                    </VStack>
                </HStack>
                <Heading size={"md"}>Traffic</Heading>
                <MergedUsageTable
                    classifierFilter={["DATA", "MT_SMS", "MO_SMS"]}
                    items={billingReport.usage.merged}
                    isLoading={billingReportQuery?.isFetching}
                />
                <Heading size={"md"}>Network Access</Heading>
                <MergedUsageTable
                    classifierFilter={["NETWORK_ACCESS"]}
                    items={billingReport.usage.merged}
                    isLoading={billingReportQuery?.isFetching}
                />
                <Heading size={"md"}>Billable Fees</Heading>
                <BillableFeeTable
                    customerId={billingReport.customerId}
                    items={billingReport.fees.billables}
                    isLoading={billingReportQuery?.isFetching}
                />
                <Heading size={"md"}>Fee Costs</Heading>
                <CostFeeTable items={billingReport.fees.costs} isLoading={billingReportQuery?.isFetching} />
            </VStack>
        </PageLayout>
    );
};

export default BillingPeriodReportsPage;
