/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SetOverageState = {
    Allowed: 'ALLOWED',
    Disallowed: 'DISALLOWED'
} as const;
export type SetOverageState = typeof SetOverageState[keyof typeof SetOverageState];


export function SetOverageStateFromJSON(json: any): SetOverageState {
    return SetOverageStateFromJSONTyped(json, false);
}

export function SetOverageStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOverageState {
    return json as SetOverageState;
}

export function SetOverageStateToJSON(value?: SetOverageState | null): any {
    return value as any;
}

