import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { Customer, CustomerApiDeleteCustomerRecurringFeeRequest, CustomerRecurringFee } from "../../../api";

export interface ConfirmDeleteCustomerRecurringFeeModalProps {
    onClose: () => void;
    customerRecurringFee?: CustomerRecurringFee;
    customer?: Customer;
}

const ConfirmDeleteCustomerRecurringFeeModal = ({
    onClose,
    customerRecurringFee,
    customer,
}: ConfirmDeleteCustomerRecurringFeeModalProps) => {
    const { customerApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteCustomerRecurringFee = useMutation<void, Error, CustomerApiDeleteCustomerRecurringFeeRequest>(
        variables => customerApi.deleteCustomerRecurringFee(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && customerRecurringFee?.id && customer?.id) {
                deleteCustomerRecurringFee.mutate(
                    { customerId: customer.id, customerRecurringFeeId: customerRecurringFee.id },
                    {
                        onSuccess: () => {
                            notifications.success("Customer Recurring Fee deleted");
                            queryClient.invalidateQueries(["customers", customer.id, "recurringfees"]);
                        },
                    },
                );
            }
            onClose();
        },
        [customer, customerRecurringFee, deleteCustomerRecurringFee, notifications, onClose, queryClient],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!customer && !!customerRecurringFee}
            title={"Delete Customer Recurring Fee"}
            description={
                <Container>
                    <Text>Are you sure you want to delete the recurring fee?</Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteCustomerRecurringFeeModal;
