import React from "react";
import { InfoPopover, LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { NetworkCredential } from "../../../api";
import { useApi } from "../../../hooks/useApi";
import { Text } from "@chakra-ui/react";

export interface SimCardNetworkCredentialsCellProps {
    icc?: string;
}

export const SimCardNetworkCredentialsCell = ({ icc }: SimCardNetworkCredentialsCellProps) => {
    const { simCardApi } = useApi();

    if (!icc) {
        return <PlaceholderText text={null} />;
    }

    const mapNetworkCredential = (networkCredential: NetworkCredential): string => networkCredential.imsi || "";
    const mapNetworkCredentialCellText = (networkCredentials?: NetworkCredential[]) => {
        if (networkCredentials == undefined || networkCredentials.length === 0) {
            return "No IMSIs";
        } else if (networkCredentials.length === 1) {
            return networkCredentials[0].imsi;
        } else {
            return `${networkCredentials.length} IMSIs`;
        }
    };

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => simCardApi.getSimCardNetworkCredentials({ icc: icc }),
                queryKey: ["simcards", icc, "networkcredentials"],
            }}
            render={result => (
                <InfoPopover value={result?.data?.map(mapNetworkCredential) || []}>
                    <Text>{mapNetworkCredentialCellText(result?.data)}</Text>
                </InfoPopover>
            )}
        />
    );
};
