import { Box, SimpleGrid } from "@chakra-ui/react";
import {
    CobiraAreaChartPaper,
    DataPoints,
    MergedMetric,
    Metric,
    MetricLabelFormatters,
    MetricsProvider,
    MetricTooltipFormatters,
    newRequest,
    TickFormatters,
} from "@cobira/ui-library";
import React, { useMemo } from "react";
import { useMetricsResolver } from "../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";
import { Customer } from "../../../api";

type UnknownMergedMetric = MergedMetric<{ [key: string]: Metric }>;

export interface CustomerCompareChartsProps {
    customers: Customer[];
}

const CustomerCompareCharts = ({ customers }: CustomerCompareChartsProps) => {
    const metricsResolver = useMetricsResolver();

    const requests = useMemo(() => {
        const now = new Date();
        const twelveMonthsAgo = subMonths(now, 12);

        const createCustomerDataPointRequest = (dataPoint: DataPoints, twelveMonthsAgo: Date, now: Date) => {
            return customers.reduce(
                (request, customer) => ({
                    ...request,
                    [customer.id]: newRequest({
                        bucket: "MONTHLY",
                        dataPoint: dataPoint,
                        group: "CUSTOMER",
                        groupId: customer.id,
                        fromDateTime: twelveMonthsAgo,
                        toDateTime: now,
                    }),
                }),
                {},
            );
        };

        return {
            sms: createCustomerDataPointRequest("SMS_USAGE", twelveMonthsAgo, now),
            data: createCustomerDataPointRequest("DATA_USAGE", twelveMonthsAgo, now),
            activeSim: createCustomerDataPointRequest("ACTIVE_SIM", twelveMonthsAgo, now),
            totalSim: createCustomerDataPointRequest("ASSIGNED_SIM", twelveMonthsAgo, now),
        };
    }, [customers]);

    return (
        <SimpleGrid columns={1} spacing={4}>
            <Box w={"100%"} h={"600px"}>
                <MetricsProvider requests={requests.data} resolver={metricsResolver}>
                    {data => (
                        <CobiraAreaChartPaper
                            title={"Data Usage"}
                            chartContext={"Last 12 months"}
                            areas={customers.map(customer => ({
                                dataSet: (data: UnknownMergedMetric) => data[customer.id]?.value,
                                dataSetName: customer.customerName,
                            }))}
                            data={data}
                            chartConfig={{
                                tooltipFormatter: MetricTooltipFormatters.dataTooltip,
                                yAxisTickFormatter: TickFormatters.dataTickFormatter,
                                xAxisValueLabel: data =>
                                    MetricLabelFormatters.yearAndMonth(Object.values(data)[0] as Metric),
                            }}
                        />
                    )}
                </MetricsProvider>
            </Box>
            <Box w={"100%"} h={"600px"}>
                <MetricsProvider requests={requests.sms} resolver={metricsResolver}>
                    {data => (
                        <CobiraAreaChartPaper
                            title={"SMS Usage"}
                            chartContext={"Last 12 months"}
                            areas={customers.map(customer => ({
                                dataSet: (data: UnknownMergedMetric) => data[customer.id]?.value,
                                dataSetName: customer.customerName,
                            }))}
                            data={data}
                            chartConfig={{
                                xAxisValueLabel: data =>
                                    MetricLabelFormatters.yearAndMonth(Object.values(data)[0] as Metric),
                            }}
                        />
                    )}
                </MetricsProvider>
            </Box>
            <Box w={"100%"} h={"600px"}>
                <MetricsProvider requests={requests.activeSim} resolver={metricsResolver}>
                    {data => (
                        <CobiraAreaChartPaper
                            title={"Active SIM Cards"}
                            chartContext={"Last 12 months"}
                            areas={customers.map(customer => ({
                                dataSet: (data: UnknownMergedMetric) => data[customer.id]?.value,
                                dataSetName: `${customer.customerName}`,
                            }))}
                            data={data}
                            chartConfig={{
                                xAxisValueLabel: data =>
                                    MetricLabelFormatters.yearAndMonth(Object.values(data)[0] as Metric),
                            }}
                        />
                    )}
                </MetricsProvider>
            </Box>
            <Box w={"100%"} h={"600px"}>
                <MetricsProvider requests={requests.totalSim} resolver={metricsResolver}>
                    {data => (
                        <CobiraAreaChartPaper
                            title={"Total SIM Cards"}
                            chartContext={"Last 12 months"}
                            areas={customers.map(customer => ({
                                dataSet: (data: UnknownMergedMetric) => data[customer.id]?.value,
                                dataSetName: `${customer.customerName}`,
                            }))}
                            data={data}
                            chartConfig={{
                                xAxisValueLabel: data =>
                                    MetricLabelFormatters.yearAndMonth(Object.values(data)[0] as Metric),
                            }}
                        />
                    )}
                </MetricsProvider>
            </Box>
        </SimpleGrid>
    );
};

export default CustomerCompareCharts;
