import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Tenant, TenantApiUpdateTenantRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import UpdateTenantForm, { UpdateTenantFormSchemaType } from "../../../forms/UpdateTenantForm/UpdateTenantForm";

export interface UpdateTenantModalProps {
    tenant?: Tenant;
    isOpen: boolean;
    onClose: () => void;
}

const UpdateTenantModal = ({ tenant, isOpen, onClose }: UpdateTenantModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const updateTenant = useMutation<Tenant, Error, TenantApiUpdateTenantRequest>(
        variables => tenantApi.updateTenant(variables),
        {
            onSuccess: data => {
                notifications.success(`Tenant '${data.name}' updated`);
                queryClient.invalidateQueries(["tenants", tenant?.id]);
                queryClient.invalidateQueries(["tenants"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: UpdateTenantFormSchemaType) => {
            updateTenant.mutate({
                tenantId: tenant?.id || "",
                updateTenant: {
                    name: form.name,
                    adminName: form.adminName,
                    adminEmail: form.adminEmail,
                },
            });
            onClose();
        },
        [updateTenant, tenant?.id, onClose],
    );

    if (!tenant) {
        return <></>;
    }

    return (
        <BasicModal title={`Update Tenant`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <UpdateTenantForm onSubmit={handleSubmit} onCancel={onClose} tenant={tenant} />
            </VStack>
        </BasicModal>
    );
};

export default UpdateTenantModal;
