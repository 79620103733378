import React from "react";
import { useSimCardCount } from "../../../hooks/useSimCardCount";
import useSimCardSearch from "../../../hooks/useSimCardSearch";
import { Box, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { InfoCard, SimCardIcon } from "@cobira/ui-library";
import { UsagePackageType } from "../../../api";

export interface UsagePackageTypeInfoCardsProps {
    usagePackageType?: UsagePackageType;
    isLoading?: boolean;
}

const UsagePackageTypeInfoCards = ({ usagePackageType, isLoading }: UsagePackageTypeInfoCardsProps) => {
    const search = useSimCardSearch({});
    const assignedToSimCards = useSimCardCount({
        filter: { usagePackageTypeId: usagePackageType?.id },
        isLoading: isLoading,
    });

    const setUsagePackageTypeSimCardFilter = () => () => {
        search.setState({
            usagePackageType: {
                value: usagePackageType,
            },
        });
    };

    return (
        <Box h={"fit-content"}>
            <LinkBox h={"100%"}>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setUsagePackageTypeSimCardFilter()}>
                    <InfoCard
                        title={"Assigned SIM Cards"}
                        value={`${assignedToSimCards}`}
                        icon={SimCardIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
        </Box>
    );
};

export default UsagePackageTypeInfoCards;
