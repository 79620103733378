import React from "react";
import { BasicModal, chakraIcon, DateTime, Duration, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { WebhookSubscription } from "../../../api";
import { List, ListIcon, ListItem, VStack } from "@chakra-ui/react";
import { WebhookSubscriptionTypeLabels } from "../../../labels/WebhookSubscriptionTypeLabels";
import { faGear } from "@fortawesome/pro-regular-svg-icons";

export interface WebhookDetailsModalProps {
    webhook?: WebhookSubscription;
    onClose: () => void;
}

const WebhookDetailsModal = ({ onClose, webhook }: WebhookDetailsModalProps) => {
    return (
        <BasicModal isOpen={!!webhook} onClose={onClose} title={webhook?.name} modalProps={{ size: "xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <TextColumn heading={"Name"}>
                    <PlaceholderText text={webhook?.name} />
                </TextColumn>
                <TextColumn heading={"Description"}>
                    <PlaceholderText text={webhook?.description} />
                </TextColumn>
                <TextColumn heading={"Target URL"}>
                    <PlaceholderText text={webhook?.target} />
                </TextColumn>
                <TextColumn heading={"Response Timeout"}>
                    {webhook?.deliveryTimeout ? (
                        <Duration value={webhook?.deliveryTimeout} />
                    ) : (
                        <PlaceholderText text={undefined} placeholderText="Never" />
                    )}
                </TextColumn>
                <TextColumn heading={"Max Attempts"}>
                    <PlaceholderText text={`${webhook?.maxAttempts}`} />
                </TextColumn>
                <TextColumn heading={"Retry Interval"}>
                    {webhook?.retryDelay ? (
                        <Duration value={webhook?.retryDelay} />
                    ) : (
                        <PlaceholderText text={undefined} />
                    )}
                </TextColumn>
                {webhook?.pausedAt && (
                    <TextColumn heading={"Pause"}>
                        Yes, webhook delivery was paused at
                        <DateTime value={webhook?.pausedAt} />
                    </TextColumn>
                )}
                <TextColumn heading={"Subscribed Types"}>
                    <List spacing={1}>
                        {Array.from(webhook?.subscriptions || []).map(subscription => (
                            <ListItem key={subscription}>
                                <ListIcon as={chakraIcon(faGear)} color="primary.500" />
                                {WebhookSubscriptionTypeLabels[subscription]}
                            </ListItem>
                        ))}
                    </List>
                </TextColumn>
            </VStack>
        </BasicModal>
    );
};

export default WebhookDetailsModal;
