import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { Customer, Invoice } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { CobiraTable, usePageNavigation, usePagination } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { createColumnHelper } from "@tanstack/react-table";
import { MonthLabels } from "../../../../labels/MonthLabels";

interface CustomerInvoicesPanelProps {
    query: UseQueryResult<Customer>;
}

const customerInvoiceColumn = createColumnHelper<Invoice>();
const COLUMNS = [
    customerInvoiceColumn.accessor("year", {
        id: "year",
        header: "Year",
        cell: row => row.getValue(),
    }),
    customerInvoiceColumn.accessor(originalRow => originalRow.month, {
        id: "month",
        header: "Month",
        cell: row => MonthLabels[row.getValue() || "UNDEFINED"],
    }),
];

const CustomerInvoicesPanel = ({ query: { data: customer } }: CustomerInvoicesPanelProps) => {
    const { invoiceApi } = useApi();
    const { navigate } = usePageNavigation<Invoice>({
        route: value => `/customers/${value.customerId}/invoices/${value.id}`,
    });
    const { pageState, setPageState } = usePagination({ pageIndex: 0, pageSize: 20 });

    const { data: customerInvoices, isLoading: isLoadingCustomerInvoices } = useQuery(
        ["customers", customer?.id, "invoices", pageState],
        () =>
            invoiceApi.getInvoices({
                customerId: customer?.id || "",
                pageSize: pageState.pageSize,
                pageNumber: pageState.pageIndex,
            }),
        { enabled: customer?.id !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={COLUMNS}
                data={customerInvoices?.content || []}
                isLoading={isLoadingCustomerInvoices}
                withPagination={{
                    pageIndex: pageState.pageIndex,
                    pageSize: pageState.pageSize,
                    onPaginationChange: setPageState,
                    totalRowCount: customerInvoices?.pageProperties?.totalElements || 0,
                    totalPageCount: customerInvoices?.pageProperties?.totalPages || 0,
                }}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: navigate,
                }}
            />
        </TabPanel>
    );
};

export default CustomerInvoicesPanel;
