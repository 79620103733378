/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AmountType } from './AmountType';
import {
    AmountTypeFromJSON,
    AmountTypeFromJSONTyped,
    AmountTypeToJSON,
} from './AmountType';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface CostUsageLine
 */
export interface CostUsageLine {
    /**
     * 
     * @type {number}
     * @memberof CostUsageLine
     */
    cost: number;
    /**
     * 
     * @type {Currency}
     * @memberof CostUsageLine
     */
    currency: Currency;
    /**
     * 
     * @type {number}
     * @memberof CostUsageLine
     */
    amount: number;
    /**
     * 
     * @type {AmountType}
     * @memberof CostUsageLine
     */
    amountType: AmountType;
    /**
     * 
     * @type {string}
     * @memberof CostUsageLine
     */
    ratePlanId: string;
    /**
     * 
     * @type {string}
     * @memberof CostUsageLine
     */
    classifier: CostUsageLineClassifierEnum;
    /**
     * 
     * @type {string}
     * @memberof CostUsageLine
     */
    discriminator: CostUsageLineDiscriminatorEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof CostUsageLine
     */
    costKey: Set<string>;
    /**
     * Can be matched with the equivalent `costReferenceId` found on one of the billable usage lines
     * @type {string}
     * @memberof CostUsageLine
     */
    billableReferenceId: string;
}


/**
 * @export
 */
export const CostUsageLineClassifierEnum = {
    Data: 'DATA',
    MoSms: 'MO_SMS',
    MtSms: 'MT_SMS',
    NetworkAccess: 'NETWORK_ACCESS'
} as const;
export type CostUsageLineClassifierEnum = typeof CostUsageLineClassifierEnum[keyof typeof CostUsageLineClassifierEnum];

/**
 * @export
 */
export const CostUsageLineDiscriminatorEnum = {
    Default: 'DEFAULT',
    ServiceType: 'SERVICE_TYPE',
    Country: 'COUNTRY',
    Network: 'NETWORK',
    NetworkCredentialType: 'NETWORK_CREDENTIAL_TYPE'
} as const;
export type CostUsageLineDiscriminatorEnum = typeof CostUsageLineDiscriminatorEnum[keyof typeof CostUsageLineDiscriminatorEnum];


/**
 * Check if a given object implements the CostUsageLine interface.
 */
export function instanceOfCostUsageLine(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "amountType" in value;
    isInstance = isInstance && "ratePlanId" in value;
    isInstance = isInstance && "classifier" in value;
    isInstance = isInstance && "discriminator" in value;
    isInstance = isInstance && "costKey" in value;
    isInstance = isInstance && "billableReferenceId" in value;

    return isInstance;
}

export function CostUsageLineFromJSON(json: any): CostUsageLine {
    return CostUsageLineFromJSONTyped(json, false);
}

export function CostUsageLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostUsageLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cost': json['cost'],
        'currency': CurrencyFromJSON(json['currency']),
        'amount': json['amount'],
        'amountType': AmountTypeFromJSON(json['amountType']),
        'ratePlanId': json['ratePlanId'],
        'classifier': json['classifier'],
        'discriminator': json['discriminator'],
        'costKey': json['costKey'],
        'billableReferenceId': json['billableReferenceId'],
    };
}

export function CostUsageLineToJSON(value?: CostUsageLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cost': value.cost,
        'currency': CurrencyToJSON(value.currency),
        'amount': value.amount,
        'amountType': AmountTypeToJSON(value.amountType),
        'ratePlanId': value.ratePlanId,
        'classifier': value.classifier,
        'discriminator': value.discriminator,
        'costKey': Array.from(value.costKey as Set<any>),
        'billableReferenceId': value.billableReferenceId,
    };
}

