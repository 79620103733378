/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        NetworkPage,
    } from '../models';
        import {
            NetworkPageFromJSON,
            NetworkPageToJSON,
        } from '../models';

            export interface NetworkApiGetNetworksRequest {
                pageNumber: number;
                pageSize: number;
                plmn?: string;
                networkName?: string;
                country?: string;
            }

        /**
        * 
        */
            export class NetworkApi extends runtime.BaseAPI {

            /**
                * Fetch networks that are available through your assigned network credential types. Depending on the configuration, all of the networks may be available to your SIM cards or some may be disabled.  The network lists may change over time depending on the agreements with the connectivity providers.
                * Get available networks
            */
            async getNetworksRaw(requestParameters: NetworkApiGetNetworksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getNetworks.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getNetworks.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.plmn !== undefined) {
                            queryParameters['plmn'] = requestParameters.plmn;
                    }

                    if (requestParameters.networkName !== undefined) {
                            queryParameters['networkName'] = requestParameters.networkName;
                    }

                    if (requestParameters.country !== undefined) {
                            queryParameters['country'] = requestParameters.country;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkPageFromJSON(jsonValue));
            }

            /**
                * Fetch networks that are available through your assigned network credential types. Depending on the configuration, all of the networks may be available to your SIM cards or some may be disabled.  The network lists may change over time depending on the agreements with the connectivity providers.
                * Get available networks
            */
                async getNetworks(requestParameters: NetworkApiGetNetworksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkPage> {
                    const response = await this.getNetworksRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
