import React from "react";
import { Text } from "@chakra-ui/react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";

export interface CustomerRecurringFeeCellProps {
    customerId?: string;
    customerRecurringFeeId?: string;
}

export const CustomerRecurringFeeCell = ({ customerId, customerRecurringFeeId }: CustomerRecurringFeeCellProps) => {
    const { customerApi } = useApi();
    if (!customerId || !customerRecurringFeeId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () =>
                    customerApi.getCustomerRecurringFee({
                        customerId: customerId,
                        customerRecurringFeeId: customerRecurringFeeId,
                    }),
                queryKey: ["customers", customerId, "recurringfees", customerRecurringFeeId],
            }}
            render={result => <Text>{result?.data?.name}</Text>}
        />
    );
};
