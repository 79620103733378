import React, { useMemo, useState } from "react";
import { IconButton, TabPanel } from "@chakra-ui/react";
import { Customer, CustomerRecurringFee } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import {
    CobiraTable,
    DateTime,
    DeleteIcon,
    Month,
    monthToInt,
    PlaceholderText,
    usePageNavigation,
    usePagination,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { createColumnHelper } from "@tanstack/react-table";
import { RecurringFeeContextLabels } from "../../../../labels/RecurringFeeContextLabels";
import ConfirmDeleteCustomerRecurringFeeModal from "../../../../components/Modal/ConfirmDeleteCustomerRecurringFeeModal/ConfirmDeleteCustomerRecurringFeeModal";
import { RecurrenceLabels } from "../../../../labels/RecurrenceLabels";
import CustomerRecurringFeePricingCell from "../../../../components/Cells/CustomerRecurringFeePricingCell/CustomerRecurringFeePricingCell";
import BillingGroupsCell from "../../../../components/Cells/BillingGroupsCell/BillingGroupsCell";

interface CustomerRecurringFeesPanelProps {
    query: UseQueryResult<Customer>;
}

type OnDeleteCustomerRecurringFeeCallback = (customerRecurringFee: CustomerRecurringFee) => void;

const customerRecurringFeeColumns = createColumnHelper<CustomerRecurringFee>();
const COLUMNS = (onDeleteCustomerRecurringFee: OnDeleteCustomerRecurringFeeCallback) => [
    customerRecurringFeeColumns.accessor("anniversary", {
        id: "anniversary",
        header: "Anniversary",
        cell: row => (
            <DateTime
                value={new Date(row.getValue().year, monthToInt(row.getValue().month as Month))}
                format={"MMMM, yyyy"}
            />
        ),
    }),
    customerRecurringFeeColumns.accessor("recurrence", {
        id: "recurrence",
        header: "Recurrence in Month",
        cell: row => RecurrenceLabels(row.getValue()),
    }),
    customerRecurringFeeColumns.accessor(row => row, {
        id: "pricing",
        header: "Pricing",
        cell: row => <CustomerRecurringFeePricingCell customerRecurringFee={row.getValue()} />,
    }),
    customerRecurringFeeColumns.accessor("name", {
        id: "recurringFeeName",
        header: "Name",
        cell: row => row.getValue(),
    }),
    customerRecurringFeeColumns.accessor("context", {
        id: "recurringFeeContext",
        header: "Context",
        cell: row => <PlaceholderText text={RecurringFeeContextLabels[row.getValue()]} />,
    }),
    customerRecurringFeeColumns.accessor("billingGroupIds", {
        id: "billingGroups",
        header: "Coverage",
        cell: row => <BillingGroupsCell billingGroupIds={row.getValue()} />,
    }),
    customerRecurringFeeColumns.display({
        id: "customerRecurringFeeDeleteAction",
        meta: {
            isButton: true,
        },
        cell: ({ row }) => (
            <IconButton
                ml={"auto"}
                aria-label={"delete"}
                icon={<DeleteIcon />}
                colorScheme={"red"}
                variant={"icon"}
                isRound
                onClick={() => onDeleteCustomerRecurringFee(row.original)}
            />
        ),
    }),
];

const CustomerRecurringFeesPanel = ({ query: { data: customer } }: CustomerRecurringFeesPanelProps) => {
    const { customerApi } = useApi();
    const [customerRecurringFeeToDelete, setCustomerRecurringFeeToDelete] = useState<CustomerRecurringFee | undefined>(
        undefined,
    );
    const navigate = usePageNavigation<CustomerRecurringFee>({
        route: value => `/customers/${customer?.id}/recurringfees/${value.id}`,
    });
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const { data: customerRecurringFees, isLoading: isLoadingCustomerRecurringFees } = useQuery(
        ["customers", customer?.id, "recurringfees"],
        () =>
            customerApi.getCustomerRecurringFees({
                customerId: customer?.id || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
            }),
        { enabled: customer?.id !== undefined },
    );

    const columns = useMemo(() => COLUMNS(setCustomerRecurringFeeToDelete), []);

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={columns}
                data={customerRecurringFees?.content || []}
                isLoading={isLoadingCustomerRecurringFees}
                withPagination={{
                    pageIndex: pageState.pageIndex,
                    pageSize: pageState.pageSize,
                    onPaginationChange: setPageState,
                    totalRowCount: customerRecurringFees?.pageProperties?.totalElements || 0,
                    totalPageCount: customerRecurringFees?.pageProperties?.totalPages || 0,
                }}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: navigate.navigate,
                }}
            />
            <ConfirmDeleteCustomerRecurringFeeModal
                onClose={() => setCustomerRecurringFeeToDelete(undefined)}
                customerRecurringFee={customerRecurringFeeToDelete}
                customer={customer}
            />
        </TabPanel>
    );
};

export default CustomerRecurringFeesPanel;
