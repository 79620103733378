/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SimCardCapabilityName = {
    CellularLocationReport: 'CELLULAR_LOCATION_REPORT',
    Ping: 'PING',
    PrivateApn: 'PRIVATE_APN',
    PublicApn: 'PUBLIC_APN',
    HardwareRegistration: 'HARDWARE_REGISTRATION',
    SignalConditionsReport: 'SIGNAL_CONDITIONS_REPORT',
    UsageSimulation: 'USAGE_SIMULATION',
    UsagePackageControl: 'USAGE_PACKAGE_CONTROL',
    Sms: 'SMS',
    NoOp: 'NO_OP'
} as const;
export type SimCardCapabilityName = typeof SimCardCapabilityName[keyof typeof SimCardCapabilityName];


export function SimCardCapabilityNameFromJSON(json: any): SimCardCapabilityName {
    return SimCardCapabilityNameFromJSONTyped(json, false);
}

export function SimCardCapabilityNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardCapabilityName {
    return json as SimCardCapabilityName;
}

export function SimCardCapabilityNameToJSON(value?: SimCardCapabilityName | null): any {
    return value as any;
}

