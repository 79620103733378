import React, { useMemo, useState } from "react";
import { PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { Button } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import ConfirmDeleteUsagePackageTypeModal from "../../../components/Modal/ConfirmDeleteUsagePackageTypeModal/ConfirmDeleteUsagePackageTypeModal";
import UsagePackageTypeDetails from "../../../components/UsagePackageTypeDetails/UsagePackageTypeDetails";

type UsagePackageTypeOperations = "delete";

export interface UsagePackageTypeDetailPageProps {
    originPath: "configuration" | "customer";
}

const UsagePackageTypeDetailPage = ({ originPath }: UsagePackageTypeDetailPageProps) => {
    const { usagePackageApi } = useApi();
    const { usagePackageTypeId, customerId } = useParams();
    const [chosenOperation, setChosenOperation] = useState<UsagePackageTypeOperations | null>(null);

    const usagePackageTypeQuery = useQuery(
        ["usagepackagetypes", usagePackageTypeId],
        () => usagePackageApi.getUsagePackageType({ usagePackageTypeId: usagePackageTypeId || "" }),
        {
            enabled: !!usagePackageTypeId,
        },
    );

    const BackButton = useMemo(() => {
        switch (originPath) {
            case "customer":
                return <PageBackButton text={"Back to catalogue"} path={`/customers/${customerId}?tab=3`} />;
            case "configuration":
            default:
                return <PageBackButton text={"Back to bundles"} path={"/configuration?tab=2"} />;
        }
    }, [customerId, originPath]);

    return (
        <PageLayout
            title={<PageTitle title={usagePackageTypeQuery?.data?.customName || "Bundle"} />}
            navigation={BackButton}
            pageContext={<Button onClick={() => setChosenOperation("delete")}>De-Activate</Button>}
        >
            <UsagePackageTypeDetails query={usagePackageTypeQuery} />
            <ConfirmDeleteUsagePackageTypeModal
                isOpen={chosenOperation === "delete"}
                onClose={() => setChosenOperation(null)}
                usagePackageType={usagePackageTypeQuery?.data}
            />
        </PageLayout>
    );
};

export default UsagePackageTypeDetailPage;
