import React, { useState } from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { NetworkCredentialType, SimCardCapabilityName, SimConfiguration, Tenant } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import {
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePagination,
    usePersistedSearch,
} from "@cobira/ui-library";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import SimConfigurationTable from "../../../../components/SimConfigurationTable/SimConfigurationTable";
import ConfirmDeleteChildTenantSimConfigurationModal from "../../../../components/Modal/ConfirmDeleteChildTenantSimConfigurationModal/ConfirmDeleteChildTenantSimConfigurationModal";
import ConfirmRestoreChildTenantSimConfigurationModal from "../../../../components/Modal/ConfirmRestoreChildTenantSimConfigurationModal/ConfirmRestoreChildTenantSimConfigurationModal";
import { BooleanLabels } from "../../../../labels/BooleanLabels";
import { SimCardCapabilityNameLabels } from "../../../../labels/SimCardCapabilityNameLabels";
import { SearchSelectNetworkCredentialTypeInput } from "../../../../components/SearchSelectNetworkCredentialTypeInput/SearchSelectNetworkCredentialTypeInput";
import { mapBooleanFilter } from "../../../../utils/mapperUtils";

export interface TenantSimConfigurationPanelProps {
    query: UseQueryResult<Tenant>;
}

const TenantSimConfigurationPanel = ({ query: { data: tenant } }: TenantSimConfigurationPanelProps) => {
    const { tenantApi } = useApi();
    const { pageState, setPageState, resetPageState } = usePagination({ pageIndex: 0, pageSize: 20 });
    const { navigate } = usePageNavigation<SimConfiguration>({
        route: value => `/tenants/${tenant?.id}/simconfigurations/${value.id}`,
    });

    const search = usePersistedSearch({
        persistenceKey: "child-tenant-sim-configuration-search",
        onChange: resetPageState,
        config: {
            search: singleInput<string>("Search"),
            includeDeleted: singleInput<boolean>("Include Deleted", { display: BooleanLabels }),
            simCapability: singleInput<SimCardCapabilityName>("SIM Capability", {
                display: value => SimCardCapabilityNameLabels[value],
            }),
            networkCredentialType: singleInput<NetworkCredentialType>("Network Credential Type"),
        },
    });

    const [simConfigurationToDelete, setSimConfigurationToDelete] = useState<SimConfiguration | undefined>(undefined);
    const [simConfigurationToRestore, setSimConfigurationToRestore] = useState<SimConfiguration | undefined>(undefined);
    const simConfigurationQuery = useQuery(
        ["tenants", tenant?.id, "simconfigurations", pageState, search.state],
        () =>
            tenantApi.getTenantSimConfigurations({
                tenantId: tenant?.id || "",
                pageSize: pageState.pageSize,
                pageNumber: pageState.pageIndex,
                includeDeleted: mapBooleanFilter(search.state?.includeDeleted?.value),
                search: search.state?.search?.value || undefined,
                simCapability: search.state?.simCapability?.value || undefined,
                networkCredentialTypeSearch: search.state?.networkCredentialType?.value?.id || undefined,
            }),
        { enabled: !!tenant?.id },
    );

    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Search by name"}
                                />
                            ),
                        },
                        {
                            id: "includeDeleted",
                            menuLabel: "Include Deleted",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "includeDeleted" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "simCapability",
                            menuLabel: "SIM Capability",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "simCapability" })}
                                    items={Object.values(SimCardCapabilityName)}
                                    autocompleteAbleMapping={value => SimCardCapabilityNameLabels[value]}
                                    displayMapping={value => SimCardCapabilityNameLabels[value]}
                                />
                            ),
                        },
                        {
                            id: "networkCredentialType",
                            menuLabel: "Network Credential Type",
                            inputComponent: (
                                <SearchSelectNetworkCredentialTypeInput
                                    registration={search.registerInput({ id: "networkCredentialType" })}
                                />
                            ),
                        },
                    ]}
                />
                <SimConfigurationTable
                    simConfigurationQuery={simConfigurationQuery}
                    pagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: simConfigurationQuery?.data?.pageProperties?.totalElements || 0,
                        totalPageCount: simConfigurationQuery?.data?.pageProperties?.totalPages || 0,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigate,
                    }}
                    actions={{
                        onDeleteClicked: setSimConfigurationToDelete,
                        onRestoreClicked: setSimConfigurationToRestore,
                    }}
                />
            </VStack>
            <ConfirmDeleteChildTenantSimConfigurationModal
                isOpen={!!simConfigurationToDelete}
                onClose={() => setSimConfigurationToDelete(undefined)}
                simConfiguration={simConfigurationToDelete}
                childTenant={tenant}
            />
            <ConfirmRestoreChildTenantSimConfigurationModal
                isOpen={!!simConfigurationToRestore}
                onClose={() => setSimConfigurationToRestore(undefined)}
                simConfiguration={simConfigurationToRestore}
                childTenant={tenant}
            />
        </TabPanel>
    );
};

export default TenantSimConfigurationPanel;
