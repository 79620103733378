/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of a network credential type, representing a type of IMSI providing access to the networks.
 * Each network credential type may provide a different set of feature, networks and costs.
 * @export
 * @interface NetworkCredentialType
 */
export interface NetworkCredentialType {
    /**
     * 
     * @type {string}
     * @memberof NetworkCredentialType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCredentialType
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCredentialType
     */
    imsiProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCredentialType
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof NetworkCredentialType
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the NetworkCredentialType interface.
 */
export function instanceOfNetworkCredentialType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "plmn" in value;

    return isInstance;
}

export function NetworkCredentialTypeFromJSON(json: any): NetworkCredentialType {
    return NetworkCredentialTypeFromJSONTyped(json, false);
}

export function NetworkCredentialTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkCredentialType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'plmn': json['plmn'],
        'imsiProvider': !exists(json, 'imsiProvider') ? undefined : json['imsiProvider'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}

export function NetworkCredentialTypeToJSON(value?: NetworkCredentialType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'plmn': value.plmn,
        'imsiProvider': value.imsiProvider,
        'description': value.description,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

