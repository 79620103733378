/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingGroup } from './BillingGroup';
import {
    BillingGroupFromJSON,
    BillingGroupFromJSONTyped,
    BillingGroupToJSON,
} from './BillingGroup';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * Page of Billing Groups
 * @export
 * @interface BillingGroupPage
 */
export interface BillingGroupPage {
    /**
     * 
     * @type {Page}
     * @memberof BillingGroupPage
     */
    pageProperties?: Page;
    /**
     * 
     * @type {Array<BillingGroup>}
     * @memberof BillingGroupPage
     */
    content?: Array<BillingGroup>;
}

/**
 * Check if a given object implements the BillingGroupPage interface.
 */
export function instanceOfBillingGroupPage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BillingGroupPageFromJSON(json: any): BillingGroupPage {
    return BillingGroupPageFromJSONTyped(json, false);
}

export function BillingGroupPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingGroupPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': !exists(json, 'pageProperties') ? undefined : PageFromJSON(json['pageProperties']),
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(BillingGroupFromJSON)),
    };
}

export function BillingGroupPageToJSON(value?: BillingGroupPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(BillingGroupToJSON)),
    };
}

