import React, { ReactNode, useEffect, useState } from "react";
import { Skeleton, Text } from "@chakra-ui/react";
import { useMetadata } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

interface AccountSidebarLinkProps {
    defaultTitle?: ReactNode;
}

const AccountSidebarLink: React.FC<AccountSidebarLinkProps> = ({ defaultTitle = "Account" }) => {
    const [title, setTitle] = useState<ReactNode>(defaultTitle);
    const metadata = useMetadata();
    const { tenantApi } = useApi();

    const { isLoading: isTenantLoading, data: tenant } = useQuery(
        ["tenants", metadata?.tenantId],
        () => tenantApi.getTenant({ tenantId: metadata?.tenantId ?? "" }),
        {
            enabled: !!metadata?.tenantId,
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (!isTenantLoading && tenant?.name) {
            setTitle(tenant.name);
        }
    }, [isTenantLoading, tenant?.name]);

    return (
        <Skeleton isLoaded={!isTenantLoading}>
            <Text as="span" fontSize={"md"} fontWeight={"medium"} color={"white"}>
                {title}
            </Text>
        </Skeleton>
    );
};

export default AccountSidebarLink;
