/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HardwareConnectionState = {
    Connected: 'CONNECTED',
    Disconnected: 'DISCONNECTED',
    Unknown: 'UNKNOWN'
} as const;
export type HardwareConnectionState = typeof HardwareConnectionState[keyof typeof HardwareConnectionState];


export function HardwareConnectionStateFromJSON(json: any): HardwareConnectionState {
    return HardwareConnectionStateFromJSONTyped(json, false);
}

export function HardwareConnectionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): HardwareConnectionState {
    return json as HardwareConnectionState;
}

export function HardwareConnectionStateToJSON(value?: HardwareConnectionState | null): any {
    return value as any;
}

