import { AbsoluteMarkup, Markup, PercentageMarkup } from "../api";

export enum MarkupType {
    ABSOLUTE = "ABSOLUTE",
    PERCENTAGE = "PERCENTAGE",
    OFFSET = "OFFSET",
}

export const resolveMarkupType = (markup: Markup): MarkupType => {
    if ((markup as AbsoluteMarkup).absoluteMarkup !== undefined) {
        return MarkupType.ABSOLUTE;
    } else if ((markup as PercentageMarkup).percentageMarkup !== undefined) {
        return MarkupType.PERCENTAGE;
    } else {
        return MarkupType.OFFSET;
    }
};

export const mapMarkup = (markupType: MarkupType, amount: number, absoluteMarkupConversionRate: number): Markup => {
    if (markupType === MarkupType.ABSOLUTE) {
        return {
            absoluteMarkup: amount / absoluteMarkupConversionRate,
        };
    }
    if (markupType === MarkupType.OFFSET) {
        return {
            offsetMarkup: amount,
        };
    }
    if (markupType === MarkupType.PERCENTAGE) {
        return {
            percentageMarkup: amount,
        };
    }

    return {};
};
