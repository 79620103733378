/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes the hierarchical structure of a rate plan that will be applied to the usage of a billing group when computing the invoice.
 * @export
 * @interface RatePlan
 */
export interface RatePlan {
    /**
     * 
     * @type {string}
     * @memberof RatePlan
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RatePlan
     */
    customName: string;
    /**
     * 
     * @type {string}
     * @memberof RatePlan
     */
    description?: string;
}

/**
 * Check if a given object implements the RatePlan interface.
 */
export function instanceOfRatePlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customName" in value;

    return isInstance;
}

export function RatePlanFromJSON(json: any): RatePlan {
    return RatePlanFromJSONTyped(json, false);
}

export function RatePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatePlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customName': json['customName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function RatePlanToJSON(value?: RatePlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customName': value.customName,
        'description': value.description,
    };
}

