import {
    CobiraBarChartPaper,
    Loading,
    MetricLabelFormatters,
    MetricsProvider,
    MetricTooltipFormatters,
    newRequest,
    TickFormatters,
} from "@cobira/ui-library";
import React from "react";
import { BillingGroup } from "../../../api";
import { useMetricsResolver } from "../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";

export interface BillingGroupDataUsagePaperProps {
    billingGroup?: BillingGroup;
    isLoading?: boolean;
}

const BillingGroupDataUsagePaper = ({ billingGroup }: BillingGroupDataUsagePaperProps) => {
    const resolver = useMetricsResolver();
    const now = new Date();
    const twelveMonthsAgo = subMonths(now, 12);

    if (!billingGroup) {
        return <Loading />;
    }

    const requests = {
        dataUsage: newRequest({
            bucket: "MONTHLY",
            dataPoint: "DATA_USAGE",
            group: "BILLING_GROUP",
            groupId: billingGroup.id,
            fromDateTime: twelveMonthsAgo,
            toDateTime: now,
        }),
    };

    return (
        <MetricsProvider requests={requests} resolver={resolver}>
            {data => {
                return (
                    <CobiraBarChartPaper
                        title={"Data Usage"}
                        bars={[
                            {
                                dataSet: data => data.dataUsage.value,
                                dataSetName: billingGroup?.name,
                            },
                        ]}
                        data={data}
                        chartConfig={{
                            xAxisValueLabel: data => MetricLabelFormatters.yearAndMonth(data.dataUsage),
                            yAxisTickFormatter: TickFormatters.dataTickFormatter,
                            tooltipFormatter: MetricTooltipFormatters.dataTooltip,
                        }}
                    />
                );
            }}
        </MetricsProvider>
    );
};

export default BillingGroupDataUsagePaper;
