import { useApi } from "../../../hooks/useApi";
import {
    ActionCompletedNotification,
    ActionFailedNotification,
    Action as ActionType,
    BasicModal,
    pluralize,
    useActionNotification,
} from "@cobira/ui-library";
import { Action, SetOverageState, SimCardActionRequestFilter, SimcardApiSetSimCardOverageRequest } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { Text, VStack } from "@chakra-ui/react";
import SimCardActionInProgressNotification from "../../ActionNotification/SimCardActionInProgressNotification/SimCardActionInProgressNotification";
import { useSimCardCount } from "../../../hooks/useSimCardCount";
import SetOverageStateForm, { SetOverageStateFormType } from "../../../forms/SetOverageStateForm/SetOverageStateForm";
import { SetOverageStateLabels } from "../../../labels/OverageStateLabels";

export interface SetOverageStateModalProps {
    filter: SimCardActionRequestFilter;
    isOpen: boolean;
    onClose: () => void;
}

const SetOverageStateModal = ({ isOpen, filter, onClose }: SetOverageStateModalProps) => {
    const { simCardApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});

    const simCardCount = useSimCardCount({ filter });

    const setOverageState = useMutation<Action[], Error, SimcardApiSetSimCardOverageRequest>(
        variables => simCardApi.setSimCardOverage(variables),
        {
            onSuccess: (data, variables) => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: action =>
                                successMessage(action, variables.setOverageActionRequest.overageState),
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
        },
    );

    const handleSubmit = useCallback(
        (form: SetOverageStateFormType) => {
            setOverageState.mutate({
                setOverageActionRequest: {
                    simCardFilter: filter,
                    overageState: form.overageState,
                },
            });
            onClose();
        },
        [setOverageState, filter, onClose],
    );

    const successMessage = useCallback(
        (data: ActionType, overage: SetOverageState) => (
            <ActionCompletedNotification
                action={data}
                successTitle={"Overage State Updated"}
                successMessage={`Overage state has been set to ${SetOverageStateLabels[overage]} for ${
                    data.totalItems
                } SIM ${pluralize("Card", data.totalItems)}`}
                failureComponent={<ActionFailedNotification title={"Failed to update overage state"} />}
            />
        ),
        [],
    );

    const errorMessage = useCallback(() => <ActionFailedNotification title={"Failed to update overage state"} />, []);
    const loadingMessage = useCallback(
        (action: Action) => (
            <SimCardActionInProgressNotification
                actionApi={actionApi}
                actionId={action.id}
                title={"Setting overage state"}
                message={(processedSimCount, totalSimCount) =>
                    `Overage state updated for ${processedSimCount} SIM ${pluralize(
                        "Card",
                        processedSimCount,
                    )} of ${totalSimCount}`
                }
            />
        ),
        [actionApi],
    );

    return (
        <BasicModal title={`Set Overage State`} onClose={onClose} isOpen={isOpen} modalProps={{ size: "xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <Text>
                    Set the overage state for <b>{simCardCount}</b> SIM {pluralize("Card", simCardCount)}
                </Text>
                <SetOverageStateForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};
export default SetOverageStateModal;
