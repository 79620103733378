import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { User, UserApiCreateUserRequest, UserApiSendUserInviteRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";

export interface ConfirmUnblockUserModalProps {
    onClose: () => void;
    user?: User;
}

const ConfirmUnblockUserModal = ({ onClose, user }: ConfirmUnblockUserModalProps) => {
    const { userApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createUser = useMutation<User, Error, UserApiCreateUserRequest>(variables => userApi.createUser(variables), {
        onSuccess: user => {
            queryClient.invalidateQueries(["users"]);
            inviteUser.mutate({ userId: user.id });
        },
    });

    const inviteUser = useMutation<void, Error, UserApiSendUserInviteRequest>(
        variables => userApi.sendUserInvite(variables),
        {
            onSuccess: () => {
                notifications.success("Team member has been unblocked and re-invited");
            },
        },
    );

    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && user?.id) {
                createUser.mutate({
                    createUser: {
                        name: user.name,
                        email: user.email,
                    },
                });
            }
            onClose();
        },
        [user, onClose, createUser],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!user}
            title={"Unblock Team Member"}
            description={
                <Container>
                    <Text>Are you sure you want to unblock the team member?</Text>
                    <Text as={"b"}>
                        {user?.name} ({user?.email})
                    </Text>
                </Container>
            }
        />
    );
};

export default ConfirmUnblockUserModal;
