/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
} from './Invoice';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * Page of invoices
 * @export
 * @interface InvoicePage
 */
export interface InvoicePage {
    /**
     * 
     * @type {Page}
     * @memberof InvoicePage
     */
    pageProperties?: Page;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof InvoicePage
     */
    content?: Array<Invoice>;
}

/**
 * Check if a given object implements the InvoicePage interface.
 */
export function instanceOfInvoicePage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvoicePageFromJSON(json: any): InvoicePage {
    return InvoicePageFromJSONTyped(json, false);
}

export function InvoicePageFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicePage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': !exists(json, 'pageProperties') ? undefined : PageFromJSON(json['pageProperties']),
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(InvoiceFromJSON)),
    };
}

export function InvoicePageToJSON(value?: InvoicePage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(InvoiceToJSON)),
    };
}

