import React from "react";
import {
    CobiraTable,
    PageLayout,
    PageTitle,
    PlaceholderText,
    usePageNavigation,
    usePagination,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { RatePlan } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { Button, VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";

const ratePlanColumn = createColumnHelper<RatePlan>();
const COLUMNS = [
    ratePlanColumn.accessor("customName", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    ratePlanColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
];

const RatePlanListPage = () => {
    const { ratePlanApi } = useApi();
    const { navigate: handleRowClicked } = usePageNavigation<RatePlan>({ route: value => `/rateplans/${value.id}` });
    const { navigate: handleCreateNewRatePlanClicked } = usePageNavigation<never>({ route: () => `/rateplans/create` });

    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: ratePlans, isLoading } = useQuery(["rateplans", pageState], () => {
        return ratePlanApi.getRatePlans({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
        });
    });

    return (
        <>
            <PageLayout
                title={<PageTitle title="Rate Plans" />}
                pageContext={<Button onClick={handleCreateNewRatePlanClicked}>Create Rate Plan</Button>}
            >
                <VStack w={"100%"} gap={2}>
                    <CobiraTable
                        sizing={"fit-page"}
                        columns={COLUMNS}
                        data={ratePlans?.content || []}
                        isLoading={isLoading}
                        withPagination={{
                            pageIndex: pageState.pageIndex,
                            pageSize: pageState.pageSize,
                            onPaginationChange: setPageState,
                            totalRowCount: ratePlans?.pageProperties?.totalElements || 0,
                            totalPageCount: ratePlans?.pageProperties?.totalPages || 0,
                        }}
                        withRowClick={{
                            enableHoverStyle: true,
                            onRowClicked: handleRowClicked,
                        }}
                    />
                </VStack>
            </PageLayout>
        </>
    );
};

export default RatePlanListPage;
