import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { BillingGroup, BillingGroupApiCreateBillingGroupRequest, Customer } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import CreateBillingGroupForm, {
    CreateBillingGroupFormType,
} from "../../../forms/CreateBillingGroupForm/CreateBillingGroupForm";

export interface CreateBillingGroupModalProps {
    customer?: Customer;
    isOpen: boolean;
    onClose: () => void;
}

const CreateBillingGroupModal = ({ customer, isOpen, onClose }: CreateBillingGroupModalProps) => {
    const { billingGroupApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createBillingGroup = useMutation<BillingGroup, Error, BillingGroupApiCreateBillingGroupRequest>(
        variables => billingGroupApi.createBillingGroup(variables),
        {
            onSuccess: data => {
                notifications.success(`Billing Group '${data.name}' created`);
                queryClient.invalidateQueries(["customers", customer?.id, "billinggroups"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: CreateBillingGroupFormType) => {
            createBillingGroup.mutate({
                customerId: customer?.id || "",
                createBillingGroup: {
                    name: form.name,
                    description: form.description,
                    ratePlanId: form.ratePlan.id,
                },
            });
            onClose();
        },
        [createBillingGroup, onClose, customer],
    );

    if (!customer) {
        return <></>;
    }

    return (
        <BasicModal title={`Create Billing Group`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <CreateBillingGroupForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default CreateBillingGroupModal;
