import { useApi } from "../../../hooks/useApi";
import { BasicModal, useNotifications } from "@cobira/ui-library";
import { Tenant, TenantApiCreateTenantRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import CreateTenantForm, { CreateTenantFormSchemaType } from "../../../forms/CreateTenantForm/CreateTenantForm";

export interface CreateTenantModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateTenantModal = ({ isOpen, onClose }: CreateTenantModalProps) => {
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createTenant = useMutation<Tenant, Error, TenantApiCreateTenantRequest>(
        variables => tenantApi.createTenant(variables),
        {
            onSuccess: data => {
                notifications.success(`Tenant '${data.name}' created`);
                queryClient.invalidateQueries(["tenants"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: CreateTenantFormSchemaType) => {
            createTenant.mutate({
                createTenant: {
                    name: form.name,
                    adminName: form.adminName,
                    adminEmail: form.adminEmail,
                },
            });
            onClose();
        },
        [createTenant, onClose],
    );

    return (
        <BasicModal title={`Create New Reseller`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <CreateTenantForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default CreateTenantModal;
