/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PercentageMarkup } from './PercentageMarkup';
import {
    PercentageMarkupFromJSON,
    PercentageMarkupFromJSONTyped,
    PercentageMarkupToJSON,
} from './PercentageMarkup';

/**
 * Network Access Rate, used when determining the margin for Network Credential Types accessing networks with a cost associated.
 * This cost will only be paid once for each unique Network Credential on the network with the associated cost.
 * 
 * The rate can be controlled on a global level using just the DEFAULT discriminator, or it may be specified for each of the Network Credential Types.
 * @export
 * @interface NetworkAccessRate
 */
export interface NetworkAccessRate {
    /**
     * The `DEFAULT` rate discriminator does not support grouping.
     * All other rate discriminators support mutually exclusive groups.
     * @type {string}
     * @memberof NetworkAccessRate
     */
    rateDiscriminator: NetworkAccessRateRateDiscriminatorEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof NetworkAccessRate
     */
    rateKey: Set<string>;
    /**
     * 
     * @type {PercentageMarkup}
     * @memberof NetworkAccessRate
     */
    markup: PercentageMarkup;
    /**
     * 
     * @type {Array<NetworkAccessRate>}
     * @memberof NetworkAccessRate
     */
    childRates?: Array<NetworkAccessRate>;
}


/**
 * @export
 */
export const NetworkAccessRateRateDiscriminatorEnum = {
    Default: 'DEFAULT',
    NetworkCredentialType: 'NETWORK_CREDENTIAL_TYPE'
} as const;
export type NetworkAccessRateRateDiscriminatorEnum = typeof NetworkAccessRateRateDiscriminatorEnum[keyof typeof NetworkAccessRateRateDiscriminatorEnum];


/**
 * Check if a given object implements the NetworkAccessRate interface.
 */
export function instanceOfNetworkAccessRate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rateDiscriminator" in value;
    isInstance = isInstance && "rateKey" in value;
    isInstance = isInstance && "markup" in value;

    return isInstance;
}

export function NetworkAccessRateFromJSON(json: any): NetworkAccessRate {
    return NetworkAccessRateFromJSONTyped(json, false);
}

export function NetworkAccessRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkAccessRate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rateDiscriminator': json['rateDiscriminator'],
        'rateKey': json['rateKey'],
        'markup': PercentageMarkupFromJSON(json['markup']),
        'childRates': !exists(json, 'childRates') ? undefined : ((json['childRates'] as Array<any>).map(NetworkAccessRateFromJSON)),
    };
}

export function NetworkAccessRateToJSON(value?: NetworkAccessRate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rateDiscriminator': value.rateDiscriminator,
        'rateKey': Array.from(value.rateKey as Set<any>),
        'markup': PercentageMarkupToJSON(value.markup),
        'childRates': value.childRates === undefined ? undefined : ((value.childRates as Array<any>).map(NetworkAccessRateToJSON)),
    };
}

