import { useFieldArray, useFormContext } from "react-hook-form";
import { ControlledAutoCompleteSingleSelectInput, FormField } from "@cobira/ui-library";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    HStack,
    Input,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { Currency, PostProcessingRatingStrategy, PostProcessingUsageContext } from "../../api";
import { PostProcessingUsageContextLabels } from "../../labels/PostProcessingContextLabels";
import { PostProcessingRatingStrategyLabels } from "../../labels/PostProcessingRatingStratregyLabels";
import { MarkupTypeLabels } from "../../labels/MarkupTypeLabels";
import RateMarkupInput from "./RateMarkupInput";
import { MarkupType } from "../../utils/markupUtils";
import { CreateRatePlanSchemaType } from "../CreateRatePlanForm/CreateRatePlanForm";
import BoundInput from "./BoundInput";
import { CurrencyLabels } from "../../labels/CurrencyLabels";

export interface DiscountPricingInputProps {
    rateIdentifier: "dataRate" | "moSmsRate" | "mtSmsRate";
}

const DiscountPricingInput = ({ rateIdentifier }: DiscountPricingInputProps) => {
    const {
        formState: { errors },
        control,
        watch,
        register,
    } = useFormContext<CreateRatePlanSchemaType>();

    const currency: Currency = watch(`${rateIdentifier}.currency`);
    const discountMarkupType: MarkupType = watch(`${rateIdentifier}.postProcessing.discountTiers.markup`);
    const discountTierCount: number = watch(`${rateIdentifier}.postProcessing.discountTierCount`);
    const { append, remove, fields } = useFieldArray({
        control,
        name: `${rateIdentifier}.postProcessing.discountTiers.bounds`,
    });

    useEffect(() => {
        if (discountTierCount && fields.length !== discountTierCount) {
            remove();
            for (let i = 0; i < discountTierCount; i++) {
                if (i === discountTierCount - 1) {
                    append({
                        upperBound: undefined,
                        markupAmount: 0,
                    });
                } else {
                    append({
                        upperBound: 0,
                        markupAmount: 0,
                    });
                }
            }
        }
    }, [append, discountTierCount, fields, remove]);

    const renderedFields = useMemo(() => {
        return fields.map((_, index) => {
            const notLastBound = index < fields.length - 1;
            return (
                <AccordionItem w={"100%"} gap={2} key={index}>
                    <h2>
                        <AccordionButton>
                            Bound {index + 1}
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel>
                        <HStack gap={2}>
                            {notLastBound && <BoundInput rateIdentifier={rateIdentifier} index={index} />}
                            <RateMarkupInput
                                unit={rateIdentifier === "dataRate" ? "MB" : "SMS"}
                                currency={currency}
                                markupType={discountMarkupType}
                                registration={register(
                                    `${rateIdentifier}.postProcessing.discountTiers.bounds.${index}.markupAmount`,
                                    {
                                        valueAsNumber: true,
                                    },
                                )}
                            />
                        </HStack>
                    </AccordionPanel>
                </AccordionItem>
            );
        });
    }, [currency, discountMarkupType, fields, rateIdentifier, register]);

    return (
        <>
            <HStack gap={2} w={"100%"}>
                <FormField label={"Context Adjustment"} error={errors[rateIdentifier]?.postProcessing?.context}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: `${rateIdentifier}.postProcessing.context` }}
                        input={{
                            items: Object.values(PostProcessingUsageContext),
                            displayMapping: value =>
                                PostProcessingUsageContextLabels[value as PostProcessingUsageContext],
                            autocompleteAbleMapping: value =>
                                PostProcessingUsageContextLabels[value as PostProcessingUsageContext],
                        }}
                    />
                </FormField>
                <FormField label={"Discount Strategy"} error={errors[rateIdentifier]?.postProcessing?.strategy}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: `${rateIdentifier}.postProcessing.strategy` }}
                        input={{
                            items: Object.values(PostProcessingRatingStrategy),
                            displayMapping: value =>
                                PostProcessingRatingStrategyLabels[value as PostProcessingRatingStrategy],
                            autocompleteAbleMapping: value =>
                                PostProcessingRatingStrategyLabels[value as PostProcessingRatingStrategy],
                        }}
                    />
                </FormField>
            </HStack>
            <HStack gap={2} w={"100%"}>
                <FormField
                    label={"Discount pricing"}
                    error={errors[rateIdentifier]?.postProcessing?.discountTiers?.markup}
                >
                    <ControlledAutoCompleteSingleSelectInput
                        control={{
                            control: control,
                            name: `${rateIdentifier}.postProcessing.discountTiers.markup`,
                        }}
                        input={{
                            items: Object.values(MarkupType),
                            displayMapping: value => MarkupTypeLabels[value as MarkupType],
                            autocompleteAbleMapping: value => MarkupTypeLabels[value as MarkupType],
                        }}
                    />
                </FormField>
                <FormField label={"Discount Levels"} error={errors[rateIdentifier]?.postProcessing?.discountTierCount}>
                    <Input
                        {...register(`${rateIdentifier}.postProcessing.discountTierCount`, { valueAsNumber: true })}
                        type={"number"}
                    />
                </FormField>
            </HStack>
            <HStack w={"100%"} mb={4}>
                <Box w={"50%"}>
                    <FormField label={"Currency"} error={errors[rateIdentifier]?.currency}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{ control: control, name: `${rateIdentifier}.currency` }}
                            input={{
                                items: Object.values(Currency).filter(currency => currency !== Currency.Undefined),
                                displayMapping: value => CurrencyLabels[value as Currency],
                                autocompleteAbleMapping: value => CurrencyLabels[value as Currency],
                            }}
                        />
                    </FormField>
                </Box>
            </HStack>
            <Accordion w={"100%"} reduceMotion>
                {renderedFields}
            </Accordion>
        </>
    );
};

export default DiscountPricingInput;
