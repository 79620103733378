import React, { ReactElement } from "react";
import { IndicatorLabel, LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { NetworkState } from "../../../api";
import { useApi } from "../../../hooks/useApi";

export interface SimCardStateCellProps {
    icc?: string;
}

const simCardStateToColor = (networkStates: NetworkState[] | undefined): ReactElement => {
    const allActive = networkStates?.every(state => state === NetworkState.Active) && networkStates?.length;
    const allSuspended = networkStates?.every(state => state === NetworkState.Suspended) && networkStates?.length;
    const allTerminated = networkStates?.every(state => state === NetworkState.Terminated) && networkStates?.length;

    if (allActive) {
        return <IndicatorLabel text={"Activated"} color={"green"} />;
    } else if (allSuspended) {
        return <IndicatorLabel text={"Suspended"} color={"red"} />;
    } else if (allTerminated) {
        return <IndicatorLabel text={"Terminated"} color={"yellow"} />;
    }
    return <IndicatorLabel text={"Idle"} color={"gray"} />;
};

export const SimCardStateCell = ({ icc }: SimCardStateCellProps) => {
    const { simCardApi } = useApi();
    if (!icc) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => simCardApi.getSimCardNetworkCredentials({ icc: icc }),
                queryKey: ["simcards", icc, "networkcredentials"],
            }}
            render={result => simCardStateToColor(result?.data?.map(nc => nc.networkState || "IDLE"))}
        />
    );
};
