/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NetworkState } from './NetworkState';
import {
    NetworkStateFromJSON,
    NetworkStateFromJSONTyped,
    NetworkStateToJSON,
} from './NetworkState';
import type { OverageState } from './OverageState';
import {
    OverageStateFromJSON,
    OverageStateFromJSONTyped,
    OverageStateToJSON,
} from './OverageState';

/**
 * Network credential representation
 * @export
 * @interface NetworkCredential
 */
export interface NetworkCredential {
    /**
     * 
     * @type {string}
     * @memberof NetworkCredential
     */
    imsi: string;
    /**
     * 
     * @type {NetworkState}
     * @memberof NetworkCredential
     */
    networkState: NetworkState;
    /**
     * 
     * @type {OverageState}
     * @memberof NetworkCredential
     */
    overageState?: OverageState;
    /**
     * 
     * @type {string}
     * @memberof NetworkCredential
     */
    externalIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCredential
     */
    networkCredentialTypeId: string;
}

/**
 * Check if a given object implements the NetworkCredential interface.
 */
export function instanceOfNetworkCredential(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imsi" in value;
    isInstance = isInstance && "networkState" in value;
    isInstance = isInstance && "networkCredentialTypeId" in value;

    return isInstance;
}

export function NetworkCredentialFromJSON(json: any): NetworkCredential {
    return NetworkCredentialFromJSONTyped(json, false);
}

export function NetworkCredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkCredential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imsi': json['imsi'],
        'networkState': NetworkStateFromJSON(json['networkState']),
        'overageState': !exists(json, 'overageState') ? undefined : OverageStateFromJSON(json['overageState']),
        'externalIdentifier': !exists(json, 'externalIdentifier') ? undefined : json['externalIdentifier'],
        'networkCredentialTypeId': json['networkCredentialTypeId'],
    };
}

export function NetworkCredentialToJSON(value?: NetworkCredential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imsi': value.imsi,
        'networkState': NetworkStateToJSON(value.networkState),
        'overageState': OverageStateToJSON(value.overageState),
        'externalIdentifier': value.externalIdentifier,
        'networkCredentialTypeId': value.networkCredentialTypeId,
    };
}

