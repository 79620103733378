/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible actions that can be performed in the MVNO service.
 * @export
 */
export const ActionType = {
    Unknown: 'UNKNOWN',
    AssignSimCard: 'ASSIGN_SIM_CARD',
    NetworkActivateSimCard: 'NETWORK_ACTIVATE_SIM_CARD',
    NetworkResetSimCard: 'NETWORK_RESET_SIM_CARD',
    NetworkSuspendSimCard: 'NETWORK_SUSPEND_SIM_CARD',
    NetworkTerminateSimCard: 'NETWORK_TERMINATE_SIM_CARD',
    NetworkManageUsagePackageAction: 'NETWORK_MANAGE_USAGE_PACKAGE_ACTION',
    NetworkSetImeiLockState: 'NETWORK_SET_IMEI_LOCK_STATE',
    NetworkUpdateImeiLock: 'NETWORK_UPDATE_IMEI_LOCK',
    NetworkManageSimCapabilityAction: 'NETWORK_MANAGE_SIM_CAPABILITY_ACTION',
    NetworkSetSimProvisioningState: 'NETWORK_SET_SIM_PROVISIONING_STATE',
    NetworkSetOverage: 'NETWORK_SET_OVERAGE',
    NetworkUsagePackageTypeCreate: 'NETWORK_USAGE_PACKAGE_TYPE_CREATE',
    BillingPrepareInvoice: 'BILLING_PREPARE_INVOICE',
    BillingComputeRecurringFeesInvoiceLines: 'BILLING_COMPUTE_RECURRING_FEES_INVOICE_LINES',
    BillingComputeTelcoInvoiceLines: 'BILLING_COMPUTE_TELCO_INVOICE_LINES',
    BillingFinalizeTelcoInvoice: 'BILLING_FINALIZE_TELCO_INVOICE',
    BillingComputeNetworkAccessInvoiceLines: 'BILLING_COMPUTE_NETWORK_ACCESS_INVOICE_LINES',
    BillingComputeBillingReport: 'BILLING_COMPUTE_BILLING_REPORT'
} as const;
export type ActionType = typeof ActionType[keyof typeof ActionType];


export function ActionTypeFromJSON(json: any): ActionType {
    return ActionTypeFromJSONTyped(json, false);
}

export function ActionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionType {
    return json as ActionType;
}

export function ActionTypeToJSON(value?: ActionType | null): any {
    return value as any;
}

