import { CustomerRecurringFee, PriceBound, RecurringFeeContext, RecurringFeePricingStrategy } from "../../../api";
import { Currency, formatMoney, SimpleInfo } from "@cobira/ui-library";
import { RecurringFeeContextLabels } from "../../../labels/RecurringFeeContextLabels";
import { priceBoundLabel, sortPriceBounds } from "../../../utils/pricingUtils";

const mapFixedPricing = (pricing: number, currency: Currency, context: RecurringFeeContext): SimpleInfo[] => {
    switch (context) {
        case "ACTIVE_IMSI":
        case "ACTIVE_SIM":
        case "INVENTORY_SIM":
            return [
                {
                    title: "Fixed Price",
                    description: `${formatMoney(pricing, currency as Currency)} / ${RecurringFeeContextLabels[context]}`,
                },
            ];
        case "NONE":
        default:
            return [
                {
                    title: "Fixed Price",
                    description: `${formatMoney(pricing, currency as Currency)}`,
                },
            ];
    }
};

const mapPriceBoundDescription = (
    price: number,
    currency: Currency,
    context: RecurringFeeContext,
    strategy: RecurringFeePricingStrategy,
) => {
    if (strategy === "FIXED") {
        return `${formatMoney(price, currency as Currency)}`;
    } else {
        return `${formatMoney(price, currency as Currency)} / ${RecurringFeeContextLabels[context]}`;
    }
};

const mapPriceBounds = (
    pricing: PriceBound[],
    currency: Currency,
    context: RecurringFeeContext,
    strategy: RecurringFeePricingStrategy,
): SimpleInfo[] => {
    const sortedPricing = sortPriceBounds(pricing);
    if (pricing.length === 0) {
        return [];
    } else if (pricing.length === 1) {
        return mapFixedPricing(pricing[0].price, currency, context);
    } else {
        return sortedPricing.map(bound => ({
            title: priceBoundLabel(bound, context),
            description: mapPriceBoundDescription(bound.price, currency, context, strategy),
        }));
    }
};

export const recurringFeePopoverContentMapper = (customerRecurringFee: CustomerRecurringFee): SimpleInfo[] => {
    return mapPriceBounds(
        customerRecurringFee.pricing.priceBounds,
        customerRecurringFee.pricing.currency,
        customerRecurringFee.context,
        customerRecurringFee.pricing.pricingStrategy,
    );
};
