import React from "react";
import { TelcoRate } from "../../../../api";
import { Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { pluralize } from "@cobira/ui-library";
import NetworkInfo from "../../../NetworkInfo/NetworkInfo";

export interface TelcoRateNetworkDetailProps {
    telcoRate: TelcoRate;
}

const TelcoRateNetworkDetail = ({ telcoRate }: TelcoRateNetworkDetailProps) => {
    const plmns = Array.from(telcoRate.rateKey);
    const plmnCount = Array.from(telcoRate.rateKey).length;

    return (
        <VStack w={"100"} align={"start"}>
            <Text>
                This rate applies to the {pluralize("network", plmnCount)} shown below. The parent rates must all be
                applicable in order for this to be applied.
            </Text>
            <Wrap spacing={2}>
                {plmns.map(plmn => (
                    <WrapItem key={plmn}>
                        <NetworkInfo plmn={plmn} />
                    </WrapItem>
                ))}
            </Wrap>
        </VStack>
    );
};

export default TelcoRateNetworkDetail;
