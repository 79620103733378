import { Currency, PriceBound, RecurringFeeContext, RecurringFeePricingStrategy } from "../../api";
import React, { useMemo } from "react";
import { Money, TextColumn } from "@cobira/ui-library";
import { RecurringFeeContextLabels } from "../../labels/RecurringFeeContextLabels";
import { HStack, Text } from "@chakra-ui/react";
import { priceBoundLabel } from "../../utils/pricingUtils";

export interface CustomerRecurringFeePriceBoundProps {
    priceBound: PriceBound;
    strategy: RecurringFeePricingStrategy;
    currency: Currency;
    recurringFeeContext: RecurringFeeContext;
}

const CustomerRecurringFeePriceBound = ({
    priceBound,
    strategy,
    currency,
    recurringFeeContext,
}: CustomerRecurringFeePriceBoundProps) => {
    const boundsLabel = useMemo(
        () => priceBoundLabel(priceBound, recurringFeeContext),
        [recurringFeeContext, priceBound],
    );

    if (strategy === "FIXED") {
        return (
            <TextColumn key={priceBound.lowerBound} heading={boundsLabel}>
                <HStack>
                    <Money value={priceBound.price} currency={currency} />
                    <Text>will be charged</Text>
                </HStack>
            </TextColumn>
        );
    } else {
        return (
            <TextColumn key={priceBound.lowerBound} heading={boundsLabel}>
                <HStack>
                    <Text>All {RecurringFeeContextLabels[recurringFeeContext]} will be charged at</Text>
                    <Money value={priceBound.price} currency={currency} />
                    <Text>per piece</Text>
                </HStack>
            </TextColumn>
        );
    }
};

export default CustomerRecurringFeePriceBound;
