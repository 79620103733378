import React from "react";
import { InfoPopover, pluralize, SimpleInfo } from "@cobira/ui-library";
import { BillingGroup } from "../../../api";
import { SkeletonText, Text } from "@chakra-ui/react";
import { useQueries } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";

export interface BillingGroupsCellProps {
    billingGroupIds?: Set<string> | string[] | null;
}

const BillingGroupsCell = ({ billingGroupIds }: BillingGroupsCellProps) => {
    const { billingGroupApi } = useApi();
    const billingGroupIdArray = Array.from(billingGroupIds || []);

    const billingGroupQueries = useQueries({
        queries: billingGroupIdArray.map(billingGroupId => ({
            queryKey: ["billinggroups", billingGroupId],
            queryFn: () => billingGroupApi.getBillingGroup({ billingGroupId: billingGroupId }),
        })),
    });

    const isLoading = billingGroupQueries
        .map(result => result.isLoading)
        .reduce((previousValue, currentValue) => previousValue || currentValue, false);
    const billingGroups = billingGroupQueries.map(result => result?.data);

    const mapBillingGroup = (billingGroup: BillingGroup): SimpleInfo => ({
        title: billingGroup.name,
        description: billingGroup.description,
    });

    const mapNetworkCredentialTypesText = (billingGroups: Array<BillingGroup | undefined>) => {
        if (billingGroups.length === 0) {
            return "Covers all billing groups";
        } else {
            return `${billingGroups.length} ${pluralize("billing group", billingGroups.length)}`;
        }
    };

    if (billingGroupIdArray.length === 0) {
        return <Text>{mapNetworkCredentialTypesText([])}</Text>;
    }

    if (isLoading) {
        return <SkeletonText />;
    }

    return (
        <InfoPopover value={billingGroups.filter(Boolean).map(value => mapBillingGroup(value as BillingGroup))}>
            <Text>{mapNetworkCredentialTypesText(billingGroups)}</Text>
        </InfoPopover>
    );
};

export default BillingGroupsCell;
