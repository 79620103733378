import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";
import { Tenant } from "../../api";

const UpdateTenantFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    adminName: z.string().min(1, "Admin name is required"),
    adminEmail: z.string().email("Admin email must be a valid email").min(1, "Admin email is required"),
});

export type UpdateTenantFormSchemaType = z.infer<typeof UpdateTenantFormSchema>;

export interface UpdateTenantFormProps {
    tenant: Tenant;
    onSubmit: (form: UpdateTenantFormSchemaType) => void;
    onCancel?: () => void;
}

const UpdateTenantForm = ({ tenant, onSubmit, onCancel }: UpdateTenantFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<UpdateTenantFormSchemaType>({
        resolver: zodResolver(UpdateTenantFormSchema),
        defaultValues: {
            name: tenant.name,
            adminName: tenant.adminName,
            adminEmail: tenant.adminEmail,
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <FormField label={"Admin Name"} error={errors?.adminName}>
                    <Input {...register("adminName")} />
                </FormField>
                <FormField label={"Admin Email"} error={errors?.adminEmail}>
                    <Input {...register("adminEmail")} type={"email"} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Update
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default UpdateTenantForm;
