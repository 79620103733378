/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImeiLockState } from './ImeiLockState';
import {
    ImeiLockStateFromJSON,
    ImeiLockStateFromJSONTyped,
    ImeiLockStateToJSON,
} from './ImeiLockState';
import type { ImeiLockViolationBehaviour } from './ImeiLockViolationBehaviour';
import {
    ImeiLockViolationBehaviourFromJSON,
    ImeiLockViolationBehaviourFromJSONTyped,
    ImeiLockViolationBehaviourToJSON,
} from './ImeiLockViolationBehaviour';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';

/**
 * 
 * @export
 * @interface SetImeiLockStateAction
 */
export interface SetImeiLockStateAction {
    /**
     * 
     * @type {ImeiLockState}
     * @memberof SetImeiLockStateAction
     */
    imeiLockState?: ImeiLockState;
    /**
     * 
     * @type {ImeiLockViolationBehaviour}
     * @memberof SetImeiLockStateAction
     */
    imeiLockViolationBehaviour?: ImeiLockViolationBehaviour;
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof SetImeiLockStateAction
     */
    simCardFilter?: SimCardActionRequestFilter;
}

/**
 * Check if a given object implements the SetImeiLockStateAction interface.
 */
export function instanceOfSetImeiLockStateAction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SetImeiLockStateActionFromJSON(json: any): SetImeiLockStateAction {
    return SetImeiLockStateActionFromJSONTyped(json, false);
}

export function SetImeiLockStateActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetImeiLockStateAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imeiLockState': !exists(json, 'imeiLockState') ? undefined : ImeiLockStateFromJSON(json['imeiLockState']),
        'imeiLockViolationBehaviour': !exists(json, 'imeiLockViolationBehaviour') ? undefined : ImeiLockViolationBehaviourFromJSON(json['imeiLockViolationBehaviour']),
        'simCardFilter': !exists(json, 'simCardFilter') ? undefined : SimCardActionRequestFilterFromJSON(json['simCardFilter']),
    };
}

export function SetImeiLockStateActionToJSON(value?: SetImeiLockStateAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imeiLockState': ImeiLockStateToJSON(value.imeiLockState),
        'imeiLockViolationBehaviour': ImeiLockViolationBehaviourToJSON(value.imeiLockViolationBehaviour),
        'simCardFilter': SimCardActionRequestFilterToJSON(value.simCardFilter),
    };
}

