/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        DataPoint,
        Dimension,
        MetricGroup,
        MetricResponse,
        TimeBucket,
    } from '../models';
        import {
            DataPointFromJSON,
            DataPointToJSON,
            DimensionFromJSON,
            DimensionToJSON,
            MetricGroupFromJSON,
            MetricGroupToJSON,
            MetricResponseFromJSON,
            MetricResponseToJSON,
            TimeBucketFromJSON,
            TimeBucketToJSON,
        } from '../models';

            export interface MetricsApiGetMetricsRequest {
                dataPoint: DataPoint;
                group: MetricGroup;
                bucket: TimeBucket;
                groupId: string;
                fromDateTime: Date;
                toDateTime?: Date;
                dimensions?: Array<Dimension>;
                dimensionIds?: Array<string>;
            }

        /**
        * 
        */
            export class MetricsApi extends runtime.BaseAPI {

            /**
                * Retrieve metrics based on data points, metric groups, time buckets, group ID, and a specific datetime.
                * Fetch metric data
            */
            async getMetricsRaw(requestParameters: MetricsApiGetMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MetricResponse>>> {
                    if (requestParameters.dataPoint === null || requestParameters.dataPoint === undefined) {
                    throw new runtime.RequiredError('dataPoint','Required parameter requestParameters.dataPoint was null or undefined when calling getMetrics.');
                    }

                    if (requestParameters.group === null || requestParameters.group === undefined) {
                    throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling getMetrics.');
                    }

                    if (requestParameters.bucket === null || requestParameters.bucket === undefined) {
                    throw new runtime.RequiredError('bucket','Required parameter requestParameters.bucket was null or undefined when calling getMetrics.');
                    }

                    if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
                    throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getMetrics.');
                    }

                    if (requestParameters.fromDateTime === null || requestParameters.fromDateTime === undefined) {
                    throw new runtime.RequiredError('fromDateTime','Required parameter requestParameters.fromDateTime was null or undefined when calling getMetrics.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.dataPoint !== undefined) {
                            queryParameters['dataPoint'] = requestParameters.dataPoint;
                    }

                    if (requestParameters.group !== undefined) {
                            queryParameters['group'] = requestParameters.group;
                    }

                    if (requestParameters.bucket !== undefined) {
                            queryParameters['bucket'] = requestParameters.bucket;
                    }

                    if (requestParameters.groupId !== undefined) {
                            queryParameters['groupId'] = requestParameters.groupId;
                    }

                    if (requestParameters.fromDateTime !== undefined) {
                        queryParameters['fromDateTime'] = (requestParameters.fromDateTime as any).toISOString();
                    }

                    if (requestParameters.toDateTime !== undefined) {
                        queryParameters['toDateTime'] = (requestParameters.toDateTime as any).toISOString();
                    }

                    if (requestParameters.dimensions) {
                        queryParameters['dimensions'] = requestParameters.dimensions;
                    }

                    if (requestParameters.dimensionIds) {
                        queryParameters['dimensionIds'] = requestParameters.dimensionIds;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetricResponseFromJSON));
            }

            /**
                * Retrieve metrics based on data points, metric groups, time buckets, group ID, and a specific datetime.
                * Fetch metric data
            */
                async getMetrics(requestParameters: MetricsApiGetMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MetricResponse>> {
                    const response = await this.getMetricsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
