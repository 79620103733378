import React, { useMemo } from "react";
import { NodeApi } from "react-arborist";
import { TelcoRate } from "../../../api";
import { Tag } from "@chakra-ui/react";
import MarkupDetails from "../../MarkupDetails/MarkupDetails";
import { determineMoneyContext } from "./telcoRateNodeUtils";

export interface NodePriceTiersTagProps {
    node: NodeApi<TelcoRate>;
}

const NodePriceTiersTag = ({ node }: NodePriceTiersTagProps) => {
    const markup = node.data.rateMarkup;
    const moneyContext = useMemo(() => determineMoneyContext(node), [node]);

    return (
        <Tag colorScheme={"yellow"} h={"fit-content"} ml={"auto"}>
            <MarkupDetails markup={markup} currency={node.data.currency || "UNDEFINED"} moneyUnit={moneyContext} />
        </Tag>
    );
};

export default NodePriceTiersTag;
