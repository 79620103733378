import React from "react";
import { Grid, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import {
    CountryInfo,
    DateTime,
    getCountry,
    ImeiInfo,
    LocationUpdateStatusIndicator,
    Paper,
    PlaceholderText,
    TextColumn,
} from "@cobira/ui-library";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import { useQuery } from "@tanstack/react-query";
import { SimCard } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { RadioTechnologyLabels } from "../../../../labels/RadioTechnologyLabels";

export interface SimCardNetworkStatePaperProps {
    simCard?: SimCard;
}

const SimCardNetworkStatePaper = ({ simCard }: SimCardNetworkStatePaperProps) => {
    const { simCardApi } = useApi();
    const { data: networkState, isLoading: isLoadingNetworkState } = useQuery(
        ["simcards", simCard?.icc, "networkstate"],
        () => simCardApi.getSimCardNetworkState({ icc: simCard?.icc || "" }),
        { enabled: simCard?.icc !== undefined },
    );
    return (
        <Paper header={"Network & device diagnostics"} withDivider>
            <Grid templateColumns="repeat(2, 1fr)" gap="4">
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"sm"}>Circuit Switching State</Heading>
                            <LocationUpdateStatusIndicator
                                status={networkState?.circuitSwitchingLocationUpdate?.status}
                            />
                        </HStack>
                    }
                    grid
                    isLoading={isLoadingNetworkState}
                >
                    <DateTime value={networkState?.circuitSwitchingLocationUpdate?.recordedAt} />
                </TextColumn>
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"sm"}>Packet Switching State</Heading>
                            <LocationUpdateStatusIndicator
                                status={networkState?.packetSwitchingLocationUpdate?.status}
                            />
                        </HStack>
                    }
                    grid
                    isLoading={isLoadingNetworkState}
                >
                    <DateTime value={networkState?.packetSwitchingLocationUpdate?.recordedAt} />
                </TextColumn>
                <TextColumn heading="Current IMSI" grid>
                    <PlaceholderText text={networkState?.networkCredential?.imsi} />
                </TextColumn>
                <TextColumn heading="Current MSISDN" grid>
                    <PlaceholderText text={networkState?.subscriberData?.msisdn} />
                </TextColumn>
                <TextColumn heading="Country" grid>
                    <CountryInfo country={getCountry(networkState?.network?.country)} />
                </TextColumn>
                <TextColumn heading="Network" grid>
                    <NetworkInfo plmn={networkState?.network?.plmn} />
                </TextColumn>
                <TextColumn heading="Current IMEI" grid>
                    <ImeiInfo imei={networkState?.imei?.value} showCheckDigit as={"text"} />
                </TextColumn>
                <TextColumn heading="Radio Technology" grid>
                    <PlaceholderText text={RadioTechnologyLabels(networkState?.subscriberData?.radioTechnology)} />
                </TextColumn>
                <TextColumn heading="Assigned IP" grid>
                    <VStack alignItems={"flex-start"}>
                        <PlaceholderText text={networkState?.ip?.ip} />
                        {networkState?.ip?.ip && (
                            <HStack>
                                <Text>Issued At:</Text>
                                <DateTime value={networkState?.ip?.issuedAt} />
                            </HStack>
                        )}
                    </VStack>
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default SimCardNetworkStatePaper;
