import React from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { Currency, PostProcessingTier } from "../../../../api";
import MarkupDetails from "../../../MarkupDetails/MarkupDetails";
import TelcoRatePostProcessingBound from "./TelcoRatePostProcessingBound";
import { MoneyUnit } from "@cobira/ui-library";

export interface TelcoRatePostProcessingTiersProps {
    moneyUnit?: MoneyUnit;
    currency: Currency;
    tiers: Array<PostProcessingTier>;
}

const TelcoRatePostProcessingTiers = ({ tiers, currency, moneyUnit }: TelcoRatePostProcessingTiersProps) => {
    if (tiers.length === 0) {
        return <Text>No tiers specified</Text>;
    }
    if (tiers.length === 1) {
        const firstTier = tiers[0];
        return (
            <HStack align={"start"}>
                <Text>Everything will be charged at</Text>
                <MarkupDetails markup={firstTier.markup} currency={currency} moneyUnit={moneyUnit} />
            </HStack>
        );
    }

    const renderTier = (tier: PostProcessingTier) => {
        return (
            <TelcoRatePostProcessingBound key={tier.lowerBound} tier={tier} moneyUnit={moneyUnit}>
                <MarkupDetails markup={tier.markup} currency={currency} moneyUnit={moneyUnit} />
            </TelcoRatePostProcessingBound>
        );
    };

    return <VStack align={"start"}>{tiers.map(renderTier)}</VStack>;
};

export default TelcoRatePostProcessingTiers;
