import React from "react";
import { NetworkCredentialType } from "../../api";
import { Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { UseQueryResult } from "@tanstack/react-query";
import { Grid } from "@chakra-ui/react";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import DeletedAt from "../DeletedAt/DeletedAt";

export interface NetworkCredentialTypePaperProps {
    networkCredentialTypeQuery: UseQueryResult<NetworkCredentialType>;
}

const NetworkCredentialTypePaper = ({ networkCredentialTypeQuery }: NetworkCredentialTypePaperProps) => {
    const { data: networkCredentialType, isLoading } = networkCredentialTypeQuery;
    return (
        <Paper header={"Details"}>
            <Grid templateColumns="auto auto" gap="4">
                <TextColumn heading="PLMN" grid isLoading={isLoading}>
                    {networkCredentialType?.plmn}
                </TextColumn>
                <TextColumn heading="Description" grid isLoading={isLoading}>
                    <PlaceholderText text={networkCredentialType?.description} />
                </TextColumn>
                <TextColumn heading={"IMSI Donor"} grid isLoading={isLoading}>
                    <NetworkInfo plmn={networkCredentialType?.plmn} />
                </TextColumn>
                <TextColumn heading={"Provider"} grid isLoading={isLoading}>
                    <PlaceholderText text={networkCredentialType?.imsiProvider} />
                </TextColumn>
                <TextColumn heading="Deleted At" grid isLoading={isLoading}>
                    <DeletedAt deletedAt={networkCredentialType?.deletedAt} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default NetworkCredentialTypePaper;
