/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AutomaticActivationTypes } from './AutomaticActivationTypes';
import {
    AutomaticActivationTypesFromJSON,
    AutomaticActivationTypesFromJSONTyped,
    AutomaticActivationTypesToJSON,
} from './AutomaticActivationTypes';
import type { DataUsageAllowance } from './DataUsageAllowance';
import {
    DataUsageAllowanceFromJSON,
    DataUsageAllowanceFromJSONTyped,
    DataUsageAllowanceToJSON,
} from './DataUsageAllowance';
import type { SmsUsageAllowance } from './SmsUsageAllowance';
import {
    SmsUsageAllowanceFromJSON,
    SmsUsageAllowanceFromJSONTyped,
    SmsUsageAllowanceToJSON,
} from './SmsUsageAllowance';

/**
 * Usage package types may apply allowance limitations to both SMS and DATA.
 * The limitation may be applied to just a single usage type while allowing unconstrained usage of the other type, e.g. an allowance of 10 SMS while having unlimited DATA allowance.
 * This can be controlled by disabling an allowance amount, for example by setting `dataUsageAllowance.enabled=false`.
 * @export
 * @interface CreateUsagePackageType
 */
export interface CreateUsagePackageType {
    /**
     * 
     * @type {string}
     * @memberof CreateUsagePackageType
     */
    customName: string;
    /**
     * List of alpha2 country codes, where the usage package type allows access.
     * @type {Array<string>}
     * @memberof CreateUsagePackageType
     */
    supportedCountries: Array<string>;
    /**
     * ISO formatted duration. e.g. P30D for 30 days.
     * @type {string}
     * @memberof CreateUsagePackageType
     */
    packageDuration: string;
    /**
     * 
     * @type {AutomaticActivationTypes}
     * @memberof CreateUsagePackageType
     */
    automaticActivationType: AutomaticActivationTypes;
    /**
     * 
     * @type {DataUsageAllowance}
     * @memberof CreateUsagePackageType
     */
    dataUsageAllowance: DataUsageAllowance;
    /**
     * 
     * @type {SmsUsageAllowance}
     * @memberof CreateUsagePackageType
     */
    smsUsageAllowance: SmsUsageAllowance;
    /**
     * Set of network credential types the usage package type will work for.
     * The SIM Configuration must still support usage packages for it to work as well.
     * @type {Set<string>}
     * @memberof CreateUsagePackageType
     */
    networkCredentialTypes: Set<string>;
}

/**
 * Check if a given object implements the CreateUsagePackageType interface.
 */
export function instanceOfCreateUsagePackageType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customName" in value;
    isInstance = isInstance && "supportedCountries" in value;
    isInstance = isInstance && "packageDuration" in value;
    isInstance = isInstance && "automaticActivationType" in value;
    isInstance = isInstance && "dataUsageAllowance" in value;
    isInstance = isInstance && "smsUsageAllowance" in value;
    isInstance = isInstance && "networkCredentialTypes" in value;

    return isInstance;
}

export function CreateUsagePackageTypeFromJSON(json: any): CreateUsagePackageType {
    return CreateUsagePackageTypeFromJSONTyped(json, false);
}

export function CreateUsagePackageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUsagePackageType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customName': json['customName'],
        'supportedCountries': json['supportedCountries'],
        'packageDuration': json['packageDuration'],
        'automaticActivationType': AutomaticActivationTypesFromJSON(json['automaticActivationType']),
        'dataUsageAllowance': DataUsageAllowanceFromJSON(json['dataUsageAllowance']),
        'smsUsageAllowance': SmsUsageAllowanceFromJSON(json['smsUsageAllowance']),
        'networkCredentialTypes': json['networkCredentialTypes'],
    };
}

export function CreateUsagePackageTypeToJSON(value?: CreateUsagePackageType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customName': value.customName,
        'supportedCountries': value.supportedCountries,
        'packageDuration': value.packageDuration,
        'automaticActivationType': AutomaticActivationTypesToJSON(value.automaticActivationType),
        'dataUsageAllowance': DataUsageAllowanceToJSON(value.dataUsageAllowance),
        'smsUsageAllowance': SmsUsageAllowanceToJSON(value.smsUsageAllowance),
        'networkCredentialTypes': Array.from(value.networkCredentialTypes as Set<any>),
    };
}

