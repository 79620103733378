/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OverageState = {
    Undetermined: 'UNDETERMINED',
    Allowed: 'ALLOWED',
    Disallowed: 'DISALLOWED'
} as const;
export type OverageState = typeof OverageState[keyof typeof OverageState];


export function OverageStateFromJSON(json: any): OverageState {
    return OverageStateFromJSONTyped(json, false);
}

export function OverageStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverageState {
    return json as OverageState;
}

export function OverageStateToJSON(value?: OverageState | null): any {
    return value as any;
}

