import { capitalizeFirst } from "@cobira/ui-library";

export const serviceTypeLabels = (key: string): string => {
    switch (key) {
        case "DATA":
            return "Data";
        case "SMS_MOBILE_ORIGINATED":
            return "MO SMS";
        case "SMS_MOBILE_TERMINATED":
            return "MT SMS";
        default:
            return capitalizeFirst(key.replaceAll("_", " ").toLowerCase()) || "N/A";
    }
};
