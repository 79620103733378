/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AbsoluteMarkup } from './AbsoluteMarkup';
import {
    AbsoluteMarkupFromJSON,
    AbsoluteMarkupFromJSONTyped,
    AbsoluteMarkupToJSON,
} from './AbsoluteMarkup';
import type { OffsetMarkup } from './OffsetMarkup';
import {
    OffsetMarkupFromJSON,
    OffsetMarkupFromJSONTyped,
    OffsetMarkupToJSON,
} from './OffsetMarkup';
import type { PercentageMarkup } from './PercentageMarkup';
import {
    PercentageMarkupFromJSON,
    PercentageMarkupFromJSONTyped,
    PercentageMarkupToJSON,
} from './PercentageMarkup';

/**
 * Supported markup types.
 * @export
 * @interface Markup
 */
export interface Markup {
    /**
     * 
     * @type {number}
     * @memberof Markup
     */
    absoluteMarkup?: number;
    /**
     * 
     * @type {number}
     * @memberof Markup
     */
    percentageMarkup?: number;
    /**
     * 
     * @type {number}
     * @memberof Markup
     */
    offsetMarkup?: number;
}

/**
 * Check if a given object implements the Markup interface.
 */
export function instanceOfMarkup(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MarkupFromJSON(json: any): Markup {
    return MarkupFromJSONTyped(json, false);
}

export function MarkupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Markup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'absoluteMarkup': !exists(json, 'absoluteMarkup') ? undefined : json['absoluteMarkup'],
        'percentageMarkup': !exists(json, 'percentageMarkup') ? undefined : json['percentageMarkup'],
        'offsetMarkup': !exists(json, 'offsetMarkup') ? undefined : json['offsetMarkup'],
    };
}

export function MarkupToJSON(value?: Markup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'absoluteMarkup': value.absoluteMarkup,
        'percentageMarkup': value.percentageMarkup,
        'offsetMarkup': value.offsetMarkup,
    };
}

